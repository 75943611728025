<template>
  <f7-popup
    :opened="isPopupOpened"
    @popup:closed="handlePopupClosed"
    @popup:opened="() => {}"
  >
    <progress-bar ref="progressBar"></progress-bar>
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ title }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="doneAndClose">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <div
        v-if="!projectId"
        class="box-project-not-show"
      >
        <div class="project-not-show">
          <div class="bg-no-data">
            <f7-icon
              f7="photo"
              size="50"
            ></f7-icon>
          </div>
          <p>Please select Project/Job Name first to load photos</p>
        </div>
      </div>

      <div v-else>
        <div
          class="upload-photo"
          @click="openUploadPhoto"
        >
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <f7-icon
              size="50"
              f7="cloud_upload_fill"
            ></f7-icon>
            <span>Upload photos</span>
          </div>
          <input
            ref="imageInput"
            type="file"
            @change="onUploadImage"
            class="display-none"
            multiple
            accept=".png, .jpg, .jpeg, .webp"
          />
        </div>

        <div
          v-if="projectPhotoList.length === 0"
          class="camera"
        >
          <p>No photos!</p>
        </div>
        <div v-else>
          <div class="button-container margin-top">
            <div>
              <b>{{ photoIds.length }}</b> photos selected
            </div>
          </div>
          <div
            v-for="(item, index) in photosComputed"
            :key="index"
          >
            <f7-block>
              <div
                @click="
                  onSelectAlbumPhoto(
                    item.data.every(photo => photoIds.includes(photo.id))
                      ? false
                      : true,
                    item.data
                  )
                "
                class="display-flex align-items-center"
              >
                <f7-checkbox
                  :checked="item.data.every(r => photoIds.includes(r.id))"
                  @click.native.stop
                  @change.native.stop="
                    onSelectAlbumPhoto($event.target.checked, item.data)
                  "
                ></f7-checkbox>
                <h3 class="bold margin-left-half">{{ item.albumName }}</h3>
              </div>
              <div class="img-grid">
                <div
                  v-for="photo in item.data"
                  :key="photo.id"
                  @touchstart="handlePressAndHold(photo.id)"
                  @touchend="handleTouchEnd"
                  @mouseenter="handleMouseEnter(photo.id)"
                  @mouseleave="handleSelected(null)"
                >
                  <div
                    class="img-item"
                    style="border-radius: 4px"
                    @click.stop="clickPhoto(photo.id, true)"
                  >
                    <img
                      class="image-show"
                      :src="photo.thumbnailUrl"
                    />
                    <div
                      v-if="
                        $device.desktop &&
                        selectionMode &&
                        hoveredPhotoIds.includes(photo.id)
                      "
                      class="img-bg img-bg-select"
                    >
                      <div class="img-bg-content">
                        <f7-checkbox
                          style="
                            position: absolute;
                            top: 10%;
                            left: 10%;
                            transform: scale(1.5);
                          "
                          :checked="photoIds.includes(photo.id)"
                          @click.native.stop
                          @change.native.stop="clickPhoto(photo.id)"
                        ></f7-checkbox>
                      </div>
                    </div>
                    <div
                      v-if="!$device.desktop && selectionMode"
                      class="img-bg img-bg-select"
                    >
                      <div class="img-bg-content">
                        <f7-checkbox
                          style="
                            position: absolute;
                            top: 10%;
                            left: 10%;
                            transform: scale(1.2);
                          "
                          :checked="photoIds.includes(photo.id)"
                          @click.native.stop
                          @change.native.stop="clickPhoto(photo.id)"
                        ></f7-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </f7-block>
          </div>
        </div>
      </div>
    </f7-page>
    <photo-browser
      :photos="photoBrowserList"
      :isShowNoteInput="isShowNoteInput"
      theme="dark"
      ref="pageDark"
      @onChangePhotoProp="
        (prop, value, photo) => onChangePhotoProp(prop, value, photo)
      "
    ></photo-browser>
  </f7-popup>
</template>

<script>
import { groupBy } from '@/utility/filter-tools';
import { sortLatest } from '@/utility/date-time-tool';
import PhotoBrowser from '@/components/photo/PhotoBrowser.vue';
import _ from 'lodash';
import Vue from 'vue';
import ImageTools from '../../utility/image-tools';
import ProgressBar from '@/components/progress-bar/ProgressBar.vue';
import { uuid } from 'vue-uuid';
import { mapActions, mapGetters } from 'vuex';
import { generateTextProcessBar, isVideo } from '@/utility/common';
export default {
  props: {
    title: String,
    projectId: String,
    projectPhotoAlbumList: { type: Array, default: () => [] },
    projectPhotoList: { type: Array, default: () => [] },
    selectedPhotos: { type: Array, default: () => [] },
    isShowNoteInput: { type: Boolean, default: true },
    uploadProjectPhoto: { type: Function, default: () => {} },
    createProjectPhoto: { type: Function, default: () => {} },
    showProgressBar: { type: Boolean, default: false },
    isUploadVideo: { type: Boolean, default: true },
  },

  components: {
    PhotoBrowser,
    ProgressBar,
  },

  data: () => ({
    isPopupOpened: false,
    photoIds: [],
    photoList: [],
    hoveredPhotoIds: [],
    selectionMode: false,
    pressHoldEvent: null,
    uuid: uuid.v4(),
    countLargeFiles: 0
  }),
  mounted() {
    this.createTooltips();
  },
  computed: {
    ...mapGetters('common/photo', ['projectPhotoSelected']),

    photosComputed() {
      const filterPhotFromPhotoList = _.cloneDeep(this.photoList || []).filter(
        item => {
          return !isVideo(item.photoUrl);
        }
      );

      let projectPhotos = filterPhotFromPhotoList;
      // group by album
      projectPhotos = groupBy(projectPhotos, i => i.albumId);
      projectPhotos = projectPhotos.map(r => ({
        ...r,
        albumName:
          r.key === 'undefined' || r.key === 'null'
            ? 'Other'
            : this.albumDetail(r.key).name,
        priority:
          r.key === 'undefined' || r.key === 'null'
            ? null
            : this.albumDetail(r.key).priority || null,
      }));
      // sort data
      const albumListDefault = (
        projectPhotos.filter(r => r.priority) || []
      ).sort((a, b) => a.priority - b.priority);
      const albumListRest = sortLatest(
        projectPhotos.filter(r => !r.priority && r.albumName !== 'Other') || []
      );

      const result = albumListDefault.concat(albumListRest);
      return result;
    },

    photosList() {
      let listPhotos = _.cloneDeep(this.photosComputed);
      return listPhotos.reduce((prev, album) => {
        return prev.concat(album.data);
      }, []);
    },

    photoBrowserList() {
      return this.photosComputed.reduce((prev, item) => {
        prev = prev.concat(item.data);
        return prev.map(r => ({
          ...r,
          notes: r.notes || '',
          url: r.photoUrl || '',
          comments: r.comments || [],
          photoFullPath: r.photoFullPath || '',
        }));
      }, []);
    },
  },

  methods: {
    ...mapActions('common/photo', [
      'deleteProjectPhoto',
      'removePhoto',
      'downloadPhoto',
      'getProjectPhotoListBys',
    ]),
    ...mapActions('photo/album', ['createProjectPhotoAlbum']),
    handleDetele() {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: `Delete 
            ${app.photoIds.length === 1 ? 'photo/video' : 'photos/videos'}
            `,
        content: `Are you sure you want to delete ?`,
        textButton: 'Delete',
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            const photos = app.projectPhotoSelected(app.photoIds);
            photos.forEach(i => {
              if (i.thumbnailFullPath) app.removePhoto(i.thumbnailFullPath);
              if (i.photoFullPath) app.removePhoto(i.photoFullPath);
            });
            app.photoIds.forEach(i => {
              app.deleteProjectPhoto(i);
            });
            _sefl.app.dialog.close();
            this.clearSelected();
          }
        },
      });
    },
    handleDownload() {
      this.$f7.toast
        .create({
          text: 'Downloading photos...',
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 3000,
        })
        .open();
      const photos = this.projectPhotoSelected(this.photoIds);
      photos.forEach(i =>
        this.downloadPhoto({ fullPath: i.photoFullPath, name: i.photoName })
      );
      // this.cancelSelection();
      this.createTooltips();
      this.clearSelected();
    },
    createTooltips() {
      if (!this.$device.desktop) return;
      const tooltips = [
        { targetEl: `.delete-tooltip-${this.uuid}`, text: 'Delete photos' },
        { targetEl: `.download-tooltip-${this.uuid}`, text: 'Download photos' },
      ];

      tooltips.forEach(tooltip => {
        const el = this.$f7.tooltip.get(tooltip.targetEl);
        if (el) {
          this.$f7.tooltip.destroy(tooltip.targetEl);
        }
        this.$f7.tooltip.create({
          targetEl: tooltip.targetEl,
          cssClass: 'tooltip-fab-button-photo',
          text: tooltip.text,
        });
      });
    },
    openPopup() {
      this.isPopupOpened = true;
      this.getProjectPhotoListBys([
        { prop: 'projectId', val: this.projectId, op: '==' },
      ]);
      if (_.isEmpty(this.selectedPhotos) || _.isEmpty(this.projectPhotoList))
        return;

      const photoIdsList = this.photosList.map(photo =>
        photo.id ? photo.id : photo
      );
      const selectedPhotoList = this.selectedPhotos.filter(photo =>
        photoIdsList.includes(photo.id)
      );
      this.photoIds = selectedPhotoList.map(r => r.id);
      this.photoList = _.cloneDeep(this.projectPhotoList).map(r => ({
        ...r,
        notes: (this.selectedPhotos.find(p => p.id === r.id) || {}).notes || '',
      }));
    },

    albumDetail(id) {
      return this.projectPhotoAlbumList.find(r => r.id === id) || {};
    },

    selectAll() {
      this.createTooltips();
      this.photoIds = (this.photosList || []).map(r => r.id);
      this.hoveredPhotoIds = this.hoveredPhotoIds.concat(this.photoIds);
      this.selectionMode = true;
    },

    clearSelected() {
      this.photoIds = [];
    },

    doneAndClose() {
      const photos =
        this.photoList.filter(r => this.photoIds.includes(r.id)) || [];
      const self = this;
      this.$emit('onDone', {
        photos,
        callback: () => {
          self.handlePopupClosed();
        },
      });
    },

    handlePopupClosed() {
      this.isPopupOpened = false;
      this.photoIds = [];
    },

    clickPhoto(id, isShowPhoto = false) {
      if (isShowPhoto) {
        const index = this.photoBrowserList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelectPhotoChange(id);
      }
    },

    onSelectAlbumPhoto(checked, photos) {
      const photoIds = photos.map(r => r.id);

      this.photoIds = this.photoIds.filter(r => !photoIds.includes(r));
      if (checked) {
        this.photoIds = this.photoIds.concat(photoIds);
        this.hoveredPhotoIds = this.hoveredPhotoIds.concat(photoIds);
      } else {
        this.hoveredPhotoIds = this.hoveredPhotoIds.filter(
          i => !photoIds.includes(i)
        );
      }
      if (this.hoveredPhotoIds.length > 0) {
        this.selectionMode = true;
      } else {
        this.selectionMode = false;
      }
    },

    onSelectPhotoChange(id) {
      if (this.photoIds.includes(id)) {
        const index = this.photoIds.indexOf(id);
        if (index > -1) {
          this.photoIds.splice(index, 1);
        }
      } else {
        this.photoIds.push(id);
      }
    },

    onChangePhotoProp(prop, value, photo) {
      let index = this.photoList.findIndex(r => r.id == photo.id);
      if (index < 0) return;
      let item = { ...this.photoList[index] };
      item[prop] = value;
      Vue.set(this.photoList, index, item);
    },

    openUploadPhoto() {
      this.$refs.imageInput.click();
    },

    onStartUpload() {
      this.$refs.progressBar.show();
    },
    onUploadProgress({ photoCount, videoCount, photoTotal, videoTotal }) {
      this.$refs.progressBar.setText(generateTextProcessBar(photoTotal, photoCount, videoTotal, videoCount, this.countLargeFiles));
      if (photoCount == photoTotal && videoCount == videoTotal) {
        this.$refs.progressBar.hide();
        this.countLargeFiles = 0
      }
    },
    async onUploadImage(event) {
      const app = this;
      let files = app.$refs.imageInput.files;
      let photoCount = 0;
      let videoCount = 0;
      const failedVideos = [];
      let imgTool = new ImageTools(this.$f7.device);
      const imageFilesCount = Array.from(files).filter(file =>
        file.type.startsWith('image/')
      ).length;
      const videoFilesCount = Array.from(files).filter(file =>
        file.type.startsWith('video/')
      ).length;
      let albumInvoiceId = this.projectPhotoAlbumList.find(
        r => r.name === 'Invoice'
      )?.id;
      if (!albumInvoiceId) {
        albumInvoiceId = await app.createProjectPhotoAlbum({
          name: 'Invoice',
          projectId: app.projectId,
        });
      }
      if (!albumInvoiceId) return app.openWarningDialogUploadPhoto();
      if (files.length > 0 && this.showProgressBar) this.onStartUpload();
      if (!this.showProgressBar) app.$f7.preloader.show();

      const uploadPromises = [];

      for (let i = 0; i < files.length; i += 1) {
        const maxSize = 10 * 1024 * 1024; // 10MB

        if (files[i].size > maxSize) {
          this.countLargeFiles += 1
        }

        if (
          files[i].type === 'image/png' ||
          files[i].type === 'image/jpeg' ||
          files[i].type === 'image/jpg' ||
          files[i].type === 'image/webp'
        ) {
          const uploadPromise = (async () => {
            let photo = {};
            const name = files[i].name;
            const resizedFile = await imgTool.resize(files[i], {
              width: 256,
              height: 256,
            });
            if (!resizedFile.name) resizedFile.name = name;

            const thumbnailData = await app.uploadProjectPhoto({
              file: resizedFile,
              projectId: app.projectId,
            });
            photo = {
              thumbnailUrl: thumbnailData.url,
              thumbnailFullPath: thumbnailData.fullPath,
            };

            if (app.showProgressBar) {
              app.onUploadProgress({
                photoCount: photoCount,
                videoCount: videoCount,
                photoTotal: imageFilesCount,
                videoTotal: videoFilesCount,
              });
            }

            if (photo.thumbnailUrl) {
              const fullSizeFile = await imgTool.resize(files[i], {
                width: 1920,
                height: 1080,
              });
              if (!fullSizeFile.name) fullSizeFile.name = name;

              const photoData = await app.uploadProjectPhoto({
                file: fullSizeFile,
                projectId: app.projectId,
              });
              photo = {
                ...photo,
                projectId: app.projectId,
                photoUrl: photoData.url,
                photoName: name,
                albumId: albumInvoiceId,
                photoFullPath: photoData.fullPath,
              };

              await app.createProjectPhoto(photo);
              photoCount += 1;

              if (this.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount,
                });
              }
            }
          })().catch(() => {
            photoCount += 1;
            if (this.showProgressBar) {
              app.onUploadProgress({
                photoCount: photoCount,
                videoCount: videoCount,
                photoTotal: imageFilesCount,
                videoTotal: videoFilesCount,
              });
            }
            failedVideos.push(files[i].name);
          });

          uploadPromises.push(uploadPromise);
        } else if (files[i].type.startsWith('video/') && this.isUploadVideo) {
          const uploadPromise = (async () => {
            try {
              let photo = {};
              const name = files[i].name;
              const cover = await imgTool.getVideoThumbnail(files[i]);
              const fileThumb = new File([cover], name + '.webp', {
                type: 'image/webp',
              });

              const resizedFile = await imgTool.resize(fileThumb, {
                width: 256,
                height: 256,
              });
              if (!resizedFile.name) resizedFile.name = name;

              const thumbnailData = await app.uploadProjectPhoto({
                file: resizedFile,
                projectId: app.projectId,
              });
              photo = {
                thumbnailUrl: thumbnailData.url,
                thumbnailFullPath: thumbnailData.fullPath,
              };

              if (app.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount,
                });
              }

              if (photo.thumbnailUrl) {
                const videoData = await app.uploadProjectPhoto({
                  file: files[i],
                  projectId: app.projectId,
                });
                photo = {
                  ...photo,
                  projectId: app.projectId,
                  photoUrl: videoData.url,
                  photoName: name,
                  albumId: albumInvoiceId,
                  photoFullPath: videoData.fullPath,
                };

                await app.createProjectPhoto(photo);
                videoCount += 1;

                if (this.showProgressBar) {
                  app.onUploadProgress({
                    photoCount: photoCount,
                    videoCount: videoCount,
                    photoTotal: imageFilesCount,
                    videoTotal: videoFilesCount,
                  });
                }
              }
            } catch (error) {
              videoCount += 1;
              if (this.showProgressBar) {
                app.onUploadProgress({
                  photoCount: photoCount,
                  videoCount: videoCount,
                  photoTotal: imageFilesCount,
                  videoTotal: videoFilesCount,
                });
              }
              failedVideos.push(files[i].name);
            }
          })();

          uploadPromises.push(uploadPromise);
        } else {
          failedVideos.push(files[i].name);
        }
      }

      await Promise.all(uploadPromises);
      if (photoCount == imageFilesCount && videoCount == videoFilesCount) {
        event.target.value = null;
        if (!this.showProgressBar) app.$f7.preloader.hide();
      }
      if (failedVideos.length > 0) {
        app.$f7.preloader.hide();
        const failedVideosContent = `There's a problem with the files.</br>${failedVideos
          .map((file, index) => `${index + 1}. ${file}`)
          .join('<br>')}`;
        this.openWarningDialogUploadPhoto(failedVideosContent);
      }
    },
    openWarningDialogUploadPhoto(content) {
      const app = this;
      app.$ri.dialog.openWarningDialog({
        title: 'Invalid file!',
        content: content,
        hideCancelButton: true,
        cssClass: 'photo-dialog',
        onClick: (_sefl, index) => {
          if (index === 0) {
            app.$refs.progressBar.hide();
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$refs.progressBar.hide();
            _sefl.app.dialog.close();
          }
        },
      });
    },
    handleMouseEnter(id) {
      if (this.$device.desktop) {
        this.handleSelected(id);
      }
    },
    handlePressAndHold(id) {
      if (!this.$device.desktop) {
        this.pressHoldEvent = setTimeout(() => {
          this.handleSelected(id, true);
        }, 1000);
      }
    },
    handleTouchEnd() {
      clearTimeout(this.pressHoldEvent);
      this.pressHoldEvent = null;
    },
    handleSelected(id, isSelectPhoto = false) {
      this.createTooltips();
      if (this.photoIds.length === 0 && id === null) {
        this.selectionMode = false;
        this.hoveredPhotoIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredPhotoIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredPhotoIds[lastIndex] !== null &&
          !this.photoIds.includes(this.hoveredPhotoIds[lastIndex])
        ) {
          this.hoveredPhotoIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredPhotoIds.includes(id)) {
        this.selectionMode = true;
        this.hoveredPhotoIds.push(id);
        if (isSelectPhoto) {
          this.clickPhoto(id);
        }
      }
    },
  },

  watch: {
    projectPhotoList: {
      handler(val) {
        const list = (_.cloneDeep(val) || []).map(r => ({
          ...r,
          notes:
            ((this.selectedPhotos || []).find(p => p.id === r.id) || {})
              .notes || '',
        }));
        this.photoList = list;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header-pic .card-header {
  height: 200px;
  background-size: cover;
  background-position: center;
  color: #fff;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: white;
}
.img-bg {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
}
.img-checkbox {
  position: absolute;
  left: 0px;
  bottom: 0px;
  display: flex;
  ::v-deep .icon-checkbox {
    border-color: white;
    border-radius: 0;
  }
}
.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}
.button-container {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
}
.upload-photo {
  height: 100px;
  display: grid;
  place-items: center;
  border-width: 2px;
  border-style: dashed;
  border-color: var(--f7-color-bg-12-neutral);
  border-radius: 3px;
  margin: 20px;
  background-color: var(--f7-color-bg-11-neutral);
  cursor: pointer;
}
.upload-photo:hover {
  background-color: var(--f7-color-week-hover-neutral);
}
.camera {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.project-not-show {
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  color: orange;
  margin-top: -38px;
}
.box-project-not-show {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}
.bg-no-data {
  width: 150px;
  height: 150px;
  background-color: var(--f7-color-bg-11-neutral);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox ::v-deep i {
  border-color: white;
}
.tab-btn {
  right: var(--global-search-btn-right);
  &_1 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        var(--f7-fab-size) - 44px
    );
  }
  &_2 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) - 12px) * 2
    );
  }
}
</style>
