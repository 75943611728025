<template>
  <signature-popup ref="signaturePopup" @saveSignature="save" :isUserSign="isCustomer"></signature-popup>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { firebase } from '@/services/firebase.service';
import _ from 'lodash';
import moment from 'moment';
import SignaturePopup from '../../../../components/popups/SignaturePopup.vue';

export default {
  components: { SignaturePopup },
  props: {
    proposalDetail: { type: Object, default: () => ({}) },
    proposalSection: { type: Array, default: () => [] },
    isCustomer: { type: Boolean, default: false },
  },
  data() {
    return {
      additionalData: {},
    };
  },
  computed: {
    ...mapGetters('setting/app/profile', ['currentUser']),
    ...mapGetters('common/user-signature', ['signatureList']),
    signatureSection() {
      return this.proposalSection.find(
        r => r.sectionId === 'signature-section'
      );
    },

    indexOfSignatureSection() {
      return this.proposalSection.findIndex(
        r => r.sectionId === 'signature-section'
      );
    },

    indexOfTermsSection() {
      return this.proposalSection.findIndex(
        r => r.sectionId === 'terms-and-conditions'
      );
    },
  },
  methods: {
    ...mapActions({
      createProposalSection: 'common/proposal-section/createProposalSection',
      updateProposalSection: 'common/proposal-section/updateProposalSection',
    }),
    ...mapActions('proposal/proposal', ['updateProposal', 'uploadSignImage']),
    ...mapActions('proposal/template', ['getProposalTemplateBys']),
    ...mapActions('common/user-signature', ['getSignListBys']),
    ...mapActions({
      getSetting: 'setting/app/system/getSetting',
    }),
    async openPopup() {
      this.$f7.preloader.show();
      const proposalSign = await this.$refs.signaturePopup.getAdditionalData();
      this.additionalData = this.proposalDetail.userIp
        ? {
          signerIp: this.proposalDetail.userIp,
          time: firebase.firestore.Timestamp.now(),
        }
        : proposalSign;
      if (this.currentUser.uid) {
        await this.getSignListBys(this.currentUser.uid);
        let userHasSignature = this.signatureList[0];

        if (userHasSignature && !this.isCustomer) {
          this.$f7.preloader.hide();
          this.checkAskSignBefore(() => {
            this.save({
              imageBase64: userHasSignature.userSignature,
              fullName: userHasSignature.userSignName,
              currentUser: this.currentUser,
              signerIp: this.additionalData,
              callback: () => { },
            });
          }, userHasSignature);
        } else {
          this.$f7.preloader.hide();
          this.$refs.signaturePopup.openPopup();
        }
      } else {
        this.$f7.preloader.hide();
        this.$refs.signaturePopup.openPopup();
      }
    },

    showErrorDialog(errorMessage) {
      this.$ri.dialog.openErrorDialog({
        title: 'Error',
        content: errorMessage,
        hideCancelButton: true,
        onClick: (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            _sefl.app.dialog.close();
          }
        },
      });
    },

    async uploadSignatureImage(imageBase64) {
      if (this.isCustomer) {
        try {
          const response = await axios.post(
            `proposal/upload-file/${this.proposalDetail.id}`,
            {
              base64Data: imageBase64.split(',')[1],
              fileName: 'owner-signature',
              meta: { type: 'image/png' },
            },
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      const response = await this.uploadSignImage({
        proposalId: this.proposalDetail.id,
        base64Data: imageBase64,
        fileName: 'company-signature',
      });
      return { success: true, data: response };
    },

    async getProposalTemplate() {
      if (this.isCustomer) {
        try {
          const response = await axios.get(
            `proposal/proposal-template/${this.proposalDetail.proposalTemplateId}/${this.proposalDetail.id}`,
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      const response = await this.getProposalTemplateBys([
        {
          prop: 'id',
          op: '==',
          val: this.proposalDetail.proposalTemplateId,
        },
      ]);
      return { success: true, data: response[0] || {} };
    },

    async updateProposalDoc(doc) {
      if (this.isCustomer) {
        try {
          const response = await axios.post(
            `proposal/update/${this.proposalDetail.id}`,
            doc,
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      await this.updateProposal({
        id: this.proposalDetail.id,
        doc,
      });
      return { success: true };
    },

    async addOrUpdateProposalSectionDoc(doc, isUpdate) {
      if (this.isCustomer) {
        try {
          const response = isUpdate ? await axios.post(
            `common/proposal-section/update/${doc.id}/${this.proposalDetail.id}`,
            doc,
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          ) : await axios.post(
            `common/proposal-section/add/${this.proposalDetail.id}`,
            doc,
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      isUpdate ? await this.updateProposalSection({
        id: doc.id,
        doc,
      }) : await this.createProposalSection(doc)
      return { success: true };
    },

    async getSettingDoc() {
      if (this.isCustomer) {
        try {
          const response = await axios.get(
            `proposal/setting/${this.proposalDetail.id}`,
            {
              baseURL: import.meta.env.VITE_BASE_API,
              withCredentials: true,
              headers: {
                token: this.$f7route.query.token,
              },
            }
          );
          return response.data;
        } catch (error) {
          return { success: false, message: error.message };
        }
      }
      const response = await this.getSetting(this.currentUser.tenantId);
      return { success: true, data: response };
    },

    checkAskSignBefore(callback, userHasSignature) {
      const app = this;
      const content = `<div style="margin-top: 16px">
          <div style="margin-bottom: 14px; width: 100%; border-radius: 4px; overflow: hidden; background-color: var(--f7-color-bg-13-neutral)">
            <img style="max-width: 257px; max-height: 200px; width: 100%; object-fit: contain; background-color: var(--f7-color-bg-13-neutral)" src="${userHasSignature.userSignature}" alt=""/>
          </div>
          <div style="text-align: center;">${userHasSignature.userSignName}</div>
        </div>`;
      app.$ri.dialog.openInfoDialog({
        title: 'Do you want to use the signature that has been saved before?',
        content,
        cancelButtonCaption: 'Sign again',
        textButton: 'Use it',
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
            this.$refs.signaturePopup.openPopup();
          } else if (index === 1) {
            _sefl.app.dialog.close();
            if (callback) callback();
          }
        },
      });
    },

    async save({ imageBase64, fullName, currentUser, signerIp, callback }) {
      this.$f7.preloader.show();
      if (this.isCustomer) {
        signerIp = _.cloneDeep(this.additionalData);
      }
      const replaceSignatureHtml = (html, newValue) => {
        const className = this.isCustomer
          ? 'customer-signature'
          : 'company-signature';
        const regex = new RegExp(
          `<div[^>]*style="flex: 1;border-bottom: 2px solid #1A79A1;"[^>]*class="${className}"[^>]*>.*?</div>`,
          'g'
        );

        const matches = html.match(regex);
        if (matches && matches.length > 0) {
          html = html.replace(matches[0], newValue);
          for (let i = 1; i < matches.length; i++) {
            html = html.replace(matches[i], '');
          }
        }
        return html.replace(
          `<div style="flex: 1;border-bottom: 2px solid #1A79A1;" class="${this.isCustomer ? 'customer-signature' : 'company-signature'
          }"></div>`,
          newValue
        );
      };

      // upload base64 to firestore
      const responseUpload = await this.uploadSignatureImage(imageBase64);
      if (!responseUpload.success) {
        this.showErrorDialog(responseUpload.message);
        this.$f7.preloader.hide();
        return;
      }

      let newSignatureSection = {}
      const newHtmlValue = `<div style="width: 100%; display: flex; align-items: flex-end; justify-content: space-between; padding-bottom: 12px;">
        <div>
          <img style="max-width: 450px; max-height: 200px; object-fit: contain;" src="${responseUpload.data.url
        }" alt=""/>
          <div style="text-align: center;">${fullName}</div>
        </div>
        <div style="text-align: right;">
          <span style="font-size: 16px;">Date: ${moment().format(
          'MM/DD/YYYY'
        )}</span>
        <div style="font-size: 12px; color: #A0A0A0;">IP: ${signerIp.signerIp
        }</div>
          </div>
      </div>`;
      if (this.signatureSection) {
        let htmlValue = Object.assign({}, this.signatureSection).htmlValue;

        htmlValue = replaceSignatureHtml(htmlValue, newHtmlValue);

        newSignatureSection = Object.assign({}, this.signatureSection);
        newSignatureSection.htmlValue = htmlValue;
        await this.addOrUpdateProposalSectionDoc({ ...newSignatureSection, isDeleted: false }, true);
      } else {
        const [proposalTemplate, responseGetSetting] = await Promise.all([
          this.getProposalTemplate(),
          this.getSettingDoc(),
        ]);
        if (!proposalTemplate.success) {
          this.showErrorDialog(proposalTemplate.message);
          this.$f7.preloader.hide();
          return;
        }

        if (!responseGetSetting.success) {
          this.showErrorDialog(responseGetSetting.message);
          this.$f7.preloader.hide();
          return;
        }

        if (!_.isEmpty(proposalTemplate)) {
          // get org signature section in template
          if (!_.isEmpty(proposalTemplate.data)) {
            newSignatureSection = proposalTemplate.data.sections.find(
              section => section.sectionId === 'signature-section'
            );

            newSignatureSection.htmlValue = newSignatureSection.htmlValue.replace(
              '<span style="background-color: #f05034;">[Roofing Company Name]</span>',
              responseGetSetting.data.companyName || ''
            );

            newSignatureSection.htmlValue = replaceSignatureHtml(
              newSignatureSection.htmlValue,
              newHtmlValue
            );
            const insertIndex =
              this.indexOfTermsSection === -1
                ? this.proposalSection.length
                : this.indexOfTermsSection;
            await this.addOrUpdateProposalSectionDoc({ ...newSignatureSection, proposalId: this.proposalDetail.id, index: insertIndex }, false);
          }
        }
      }

      const signatureData = {
        signatureImageUrl: responseUpload.data.url,
        signatureImageFullPath: responseUpload.data.fullPath,
        signatureAt: firebase.firestore.Timestamp.now(),
        userSignId: currentUser.uid || null,
        signerIp,
        fullNameOfSigner: fullName,
      };

      const doc = {
        ...(this.isCustomer
          ? { ownerSigner: signatureData }
          : { companyRepresentativeSigner: signatureData }),
        ...(this.isCustomer ? { status: 'signed' } : {}),
      };

      const responseUpdate = await this.updateProposalDoc(doc);
      if (!responseUpdate.success) {
        this.showErrorDialog(responseUpdate.message);
        this.$f7.preloader.hide();
        return;
      }

      this.$f7.preloader.hide();

      callback();
      this.$emit('reloadPages', doc);
    },
  },
};
</script>
