import { checkAuth } from '../../../services/authentication.service';
import ProposalClientViewPage from '../pages/ProposalClientViewPage.vue';
import TechnicalDataPreviewPage from '../pages/TechnicalDataPreviewPage.vue';
function routeList() {
  let routes = [];
  routes.push({
    path: '',
    title: 'Proposal/Contract',
    displayRoles: ['owner', 'user'],
    routes: [
      {
        path: '/proposal',
        title: 'Proposal/Contract',
        parent: 'dashboard-main',
        name: 'proposal-main',
        displayRoles: ['owner', 'user'],
        icon: 'book',
        // eslint-disable-next-line no-unused-vars
        async: checkAuth(routeTo => {
          return import('../pages/ProposalListPage.vue');
        }),
        master: true,
        detailRoutes: [
          // new
          {
            path: '/proposal/new',
            title: 'New Proposal',
            async: checkAuth(() => import('../pages/ProposalNew.vue')),
          },
          // from estimate
          {
            path: '/proposal/business/:businessCode/projectId/:projectId/estimateId/:estimateId/new',
            title: 'New Proposal',
            name: 'new-proposal-estimate',
            meta: { fromEstimate: true },
            async: checkAuth(() => import('../pages/ProposalNew.vue')),
          },
          // from dashboard
          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/property/:propertyId/new-proposal',
            title: 'New Proposal',
            name: 'new-proposal-swimlane-commercial',
            async: checkAuth(() => import('../pages/ProposalNew.vue')),
          },
          // from swimlane board
          {
            path: '/dashboard/type/:boardType/business/:businessCode/action/:actionId/project/:projectId/contact/:contactId/new-proposal',
            title: 'New Proposal',
            name: 'new-proposal-swimlane-residential',
            async: checkAuth(() => import('../pages/ProposalNew.vue')),
          },
          //residential
          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/contact/:contactId/new-proposal',
            title: 'New Proposal',
            name: 'new-proposal-swimlane-service',
            async: checkAuth(() => import('../pages/ProposalNew.vue')),
          },
          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/new-proposal',
            title: 'New Proposal',
            name: 'new-proposal-swimlane',
            async: checkAuth(() => import('../pages/ProposalNew.vue')),
          },
          // details
          {
            path: '/proposal-template/:id',
            title: 'Proposal Template',
            async: checkAuth(() => import('../pages/ProposalTemplate.vue')),
          },
          {
            path: '/proposal/:proposalNumber',
            title: 'Proposal',
            async: checkAuth(() => import('../pages/ProposalDetailPage.vue')),
          },
          {
            path: '/proposal/:proposalNumber/preview',
            title: 'Proposal',
            async: checkAuth(() => import('../pages/ProposalPreview.vue')),
          },
          // {
          //   path: "/proposal/:proposalNumber/edit",
          //   title: "Proposal Template",
          //   meta: { isEditing: true },
          //   async: checkAuth(() => import("../pages/ProposalTemplate.vue"))
          // },
          {
            path: '/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/proposal/:proposalNumber',
            title: 'Proposal Details',
            name: 'proposal-detail',
            async: checkAuth(() => import('../pages/ProposalDetailPage.vue')),
          },
          // {
          //   path:
          //     "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/proposal/:proposalNumber/edit",
          //   title: "Proposal Details",
          //   meta: { isEditing: true },
          //   async: checkAuth(() => import("../pages/ProposalTemplate.vue"))
          // },
          // {
          //   path:
          //     "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/task/:taskId/proposal/:proposalNumber",
          //   title: "Proposal Details",
          //   async: checkAuth(() => import("../pages/ProposalTemplate.vue"))
          // },
          // {
          //   path:
          //     "/dashboard/type/:boardType/business/:businessCode/board/:boardId/action/:actionId/project/:projectId/task/:taskId/proposal/:proposalNumber/edit",
          //   title: "Proposal Details",
          //   async: checkAuth(() => import("../pages/ProposalTemplate.vue"))
          // }
        ],
      },
    ],
  });
  routes.push({
    path: '',
    title: 'Proposal View',
    name: 'proposal-view',
    routes: [
      {
        path: '/proposal-view',
        title: 'proposal',
        name: 'proposal',
        icon: 'doc_text',
        component: ProposalClientViewPage,
      },
    ],
  });
  routes.push({
    path: '',
    title: 'Proposal Preview',
    name: 'proposal-preview',
    routes: [
      {
        path: '/proposal-preview',
        title: 'proposal',
        name: 'proposal',
        icon: 'doc_text',
        component: ProposalClientViewPage,
      },
    ],
  });
  routes.push({
    path: '',
    title: 'Preview',
    name: 'preview',
    routes: [
      {
        path: '/preview/:url',
        title: 'proposal',
        name: 'proposal',
        icon: 'doc_text',
        component: TechnicalDataPreviewPage,
      },
    ],
  });
  return routes;
}

export default routeList();
