<template>
  <div>
    <f7-list v-show="showTitle">
      <!-- Main list item -->
      <f7-list-item
        v-for="(item, index) in attachments"
        :key="index"
        :swipeout="!$device.desktop"
        text-color="blue"
      >
        <a
          class="cursor-pointer"
          slot="title"
          @click="openLink(attachments, item)"
        >
          {{ item.fileName }}
        </a>
        <f7-button
          v-if="item.description"
          slot="after"
          :popover-open="`.popover-description-${index}`"
        >
          <f7-icon
            style="margin-bottom: 5px"
            f7="ellipses_bubble"
          ></f7-icon>
        </f7-button>
        <f7-popover
          :class="
            $device.desktop
              ? `popover-description-${index} popover-description-desktop`
              : `popover-description-${index} popover-description-mobile`
          "
        >
          <f7-list class="cursor-pointer menu-actions">
            <f7-list-item popover-close>
              <div
                style="word-break: break-word"
                v-html="item.description"
              ></div>
            </f7-list-item>
          </f7-list>
        </f7-popover>
        <f7-button
          v-if="item.description"
          slot="after"
          @click="openEditAttachmentDescription(item, index, true)"
        >
          <f7-icon
            style="margin-bottom: 10px"
            f7="pencil_ellipsis_rectangle"
          ></f7-icon>
        </f7-button>
        <f7-button
          v-if="!item.description"
          slot="after"
          @click="openEditAttachmentDescription(item, index, false)"
        >
          <f7-icon
            style="margin-bottom: 10px"
            f7="pencil_ellipsis_rectangle"
          ></f7-icon>
        </f7-button>

        <div
          v-show="$device.desktop"
          @click="deleteFileWithConfirm(index)"
          slot="after"
          class="add-button-icon cursor-pointer"
        >
          <f7-icon f7="trash"></f7-icon>
        </div>
        <f7-radio
          v-if="selectDefault"
          slot="media"
          name="demo-radio-inline"
          :checked="item.default"
          @change="onRadioChange(index, $event.target.checked)"
        ></f7-radio>

        <div slot="footer">
          Uploaded on {{ toDisplayDateString(item.createdAt) }} by
          {{ item.createdBy }}
          <span
            v-if="selectDefault && item.default"
            class="official-item"
            >(Official)</span
          >
        </div>

        <f7-swipeout-actions
          right
          v-show="!$device.desktop"
        >
          <f7-swipeout-button
            color="primary"
            :close="true"
            @click="deleteFileWithConfirm(index)"
            >Delete</f7-swipeout-button
          >
        </f7-swipeout-actions>
      </f7-list-item>

      <!-- Add list item -->
      <f7-list-item
        v-show="!readOnly"
        @click.native="openFileChooser"
      >
        <div
          slot="media"
          class="add-button-icon cursor-pointer display-flex"
        >
          <f7-icon f7="plus_circle"></f7-icon>
        </div>
        <div
          class="cursor-pointer"
          slot="title"
        >
          {{ addButtonTitle || 'Add ' + attachmentType }}
        </div>
        <input
          v-show="false"
          ref="fileInput"
          type="file"
          multiple
          @change="onAddAttachment"
        />
      </f7-list-item>
    </f7-list>
    <attachment-popup
      @done="onDone"
      :filesInput="filesInput"
      ref="attachmentPopup"
    ></attachment-popup>
    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>
    <edit-attachment-description-popup
      @onEdited="onEditedDescription"
      ref="editAttachmentDescription"
    ></edit-attachment-description-popup>
  </div>
</template>

<script>
import {
  firebase,
  uploadFile,
  removeFile,
  auth,
} from '../../../../services/firebase.service';
import { toDisplayDateString } from '../../../../utility/datetime';
import _ from 'lodash';
import AttachmentPopup from '@/components/popups/AttachmentPopup.vue';
import { createMediaArray, openAttackLink } from '@/utility/common';
import EditAttachmentDescriptionPopup from '@/components/popups/EditAttachmentDescriptionPopup.vue';
export default {
  components: {
    AttachmentPopup,
    EditAttachmentDescriptionPopup,
  },
  props: {
    estimateId: String,
    buildingId: String,
    modelType: { type: String, default: 'estimate' },
    value: { type: Array, default: () => [] },
    title: String,
    attachmentType: { type: String, default: 'attachment' },
    addButtonTitle: String,
    selectDefault: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    // showAddBtnOnTop: { type: Boolean, default: false }
  },

  data: () => {
    return {
      confirmDeleteTitle: 'Confirm delete!',
      confirmDeleteMessage: 'Are you sure you want to delete this item?',
      filesInput: [],
      productPhotos: [],
    };
  },

  computed: {
    attachments() {
      return this.value.filter(
        item => item.attachmentType === this.attachmentType
      );
    },

    showTitle() {
      return !this.readOnly || (this.readOnly && this.attachments.length > 0);
    },
  },

  methods: {
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    openLink(item, attach) {
      this.productPhotos = createMediaArray(item);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },

    deleteFile(index) {
      this.$f7.preloader.show();
      const value = _.cloneDeep(this.value);
      const attachments = value.filter(
        item => item.attachmentType === this.attachmentType
      );
      const file = attachments[index];
      removeFile(file.fullPath).then(() => {
        const removeIndex = value.findIndex(item => item === file);
        value.splice(removeIndex, 1);
        this.$emit('input', value);
        this.$f7.preloader.hide();
      });
    },

    onRadioChange(index, val) {
      const value = _.cloneDeep(this.value);
      const attachments = value.filter(
        item => item.attachmentType === this.attachmentType
      );
      for (const item of attachments) {
        item.default = false;
      }
      attachments[index].default = val;
      this.$emit('input', value);
    },

    checkExistFiles(uploadFiles) {
      for (const uploadFile of uploadFiles) {
        for (const attachment of this.attachments) {
          if (uploadFile.name === attachment.fileName) {
            return true;
          }
        }
      }
      return false;
    },
    onDone(uploadFiles, descriptions) {
      const value = _.cloneDeep(this.value);
      for (const file of uploadFiles) {
        this.$f7.preloader.show();
        const attachment = {
          fileName: file.name,
          fullPath: `${this.modelType}/${this.attachmentType}/${this.estimateId}/${this.buildingId}/${file.name}`,
          url: '',
          attachmentType: this.attachmentType,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
          description: descriptions[uploadFiles.indexOf(file)],
        };

        uploadFile(attachment.fullPath, file).then(url => {
          attachment.url = url;

          value.push(attachment);

          this.$emit('input', value);
          this.$f7.preloader.hide();
        });
      }
    },
    onAddAttachment() {
      const uploadFiles = this.$refs.fileInput.files;
      this.filesInput = Array.from(uploadFiles);
      const exist = this.checkExistFiles(uploadFiles);
      if (!exist) {
        this.$refs.attachmentPopup.open();
      } else {
        this.$ri.dialog.openWarningDialog({
          title: 'Please choose another one',
          content: 'That file has already existed!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }
      this.$refs.fileInput.value = '';
    },

    openFileChooser() {
      this.$refs.fileInput.click();
    },

    deleteFileWithConfirm(index) {
      const app = this;
      this.$ri.dialog.openInfoDialog({
        title: this.confirmDeleteTitle,
        content: this.confirmDeleteMessage,
        textButton: 'Delete',
        onClick: (_sefl, indexx) => {
          if (indexx === 0) {
            _sefl.app.dialog.close();
          } else if (indexx === 1) {
            app.deleteFile(index);
          }
        },
      });
    },
    openEditAttachmentDescription(item, index, isEdit) {
      this.$refs.editAttachmentDescription.open(item, index, isEdit);
    },
    onEditedDescription(description, index) {
      let value = _.cloneDeep(this.value);
      value[index].description = description;
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.official-item {
  color: var(--f7-theme-color);
  font-weight: 600;
}
.add-button {
  display: flex;
  padding: 0rem 1rem;
}
.add-button a i {
  font-size: 20px;
}
.header-section {
  display: flex;
  justify-content: space-between;
}
.popover-description-desktop {
  max-height: 300px;
  width: auto;
  max-width: 500px;
}
.popover-description-mobile {
  width: auto;
  height: auto;
}

.list ::v-deep .item-title {
  white-space: normal;
}
</style>
