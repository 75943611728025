<template>
  <div class="user-list">
    <f7-searchbar
      :value="searchValue"
      disable-button-text
      placeholder="Search user"
      :clear-button="true"
      @input="onSearch"
      @searchbar:disable="resetSearch"
      class="text-color-white search-list"
    ></f7-searchbar>
    <div>
      <f7-list class="no-margin users-list">
        <f7-list-group
          v-for="item in groupedUsers"
          :key="item.key"
          media-list
        >
          <f7-list-item
            :title="item.key"
            group-title
          ></f7-list-item>
          <f7-list-item
            v-for="user in item.data"
            :key="user.uid"
            :title="user.displayName"
            reloadDetail
            :link="`/employee-certifications/${user.uid}`"
            :text-color="user.uid === selectedId ? 'primary' : 'default'"
          >
            <!-- <div slot="text">
              <div>{{ user.customClaims ? user.customClaims.role : '' }}</div>
            </div> -->
            <div style="display: flex; gap: 5px">
              <f7-chip
                v-if="countCertificateStatus(user.certificates).active > 0"
                slot="text"
                :text="`${countCertificateStatus(user.certificates).active} active`"
                color="green"
              ></f7-chip>
              <f7-chip
                v-if="
                  countCertificateStatus(user.certificates).expiringSoon > 0
                "
                slot="text"
                :text="`${
                  countCertificateStatus(user.certificates).expiringSoon
                } expired soon`"
                color="yellow"
              ></f7-chip>
              <f7-chip
                v-if="countCertificateStatus(user.certificates).expired > 0"
                slot="text"
                :text="`${countCertificateStatus(user.certificates).expired} expired`"
                color="red"
              ></f7-chip>
            </div>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  getCurrentInstance,
  watch,
} from 'vue';
import { applyFilter, groupBy } from '@/utility/filter-tools';
import { useMyCertificatesStore } from '../../../../stores/my-certificates/actions';
import _ from 'lodash';

function sortBy(a: string, b: string): number {
  return a > b ? 1 : b > a ? -1 : 0;
}

export default defineComponent({
  name: 'PayRatePage',
  components: {},
  props: {
    certificatesList: { type: Array, default: () => [] },
  },
  setup(props) {
    const { proxy } = getCurrentInstance()!; // Access the Vue instance
    const store = proxy.$store;
    const certificates = useMyCertificatesStore();
    const instance = getCurrentInstance() as any;

    const hits = computed(() => certificates.hits);
    const page = computed(() => certificates.page);
    const nbPages = computed(() => certificates.nbPages);
    const isShowExportPopup = ref(false);
    const searchValue = ref('');
    const certificatesList = ref<any>(null);

    let allowInfinite = ref(true);
    let hasMoreItems = ref(true);
    let hasData = ref(true);
    const userListByHours = computed(
      () => store.getters['time-tracking/user/userListByHours']
    );
    const user = computed(() => store.getters['time-tracking/user/user']);

    const filteredUsers = computed(() => {
      const list = userListByHours.value.map((x: any) => ({
        ...x,
        active: x.disabled ? 'Inactive' : 'Active',
      }));
      return applyFilter(list, searchValue.value, [
        'displayName',
        'active',
        'email',
        (i: any) => i.customClaims.role,
      ]);
    });

    const groupedUsers = computed(() => {
      const dataGroup = groupBy(filteredUsers.value, (item: any) =>
        ((item.displayName || '').charAt(0) || '').toUpperCase()
      ).sort((a, b) => sortBy(a.key, b.key));
      return dataGroup.map((i: any) => ({
        ...i,
        data: i.data
          .map((user: any) => ({
            ...user,
            certificates: certificatesList?.value?.filter(
              (certificate: any) =>
                certificate.userId === user.uid && !certificate.isDeleted
            ),
          }))
          .sort((a: any, b: any) => sortBy(a.displayName, b.displayName)),
      }));
    });

    const selectedId = computed(() => (user.value || {}).uid);

    const displayEmail = (user: any): string => {
      let email = user.email;
      if (user.customClaims.displayEmail) {
        email = user.customClaims.displayEmail;
      }
      return email;
    };

    const onlineClass = (status: boolean | undefined) => {
      return status === true
        ? { color: '', text: 'Inactive' }
        : status === false
          ? { color: 'green', text: 'Active' }
          : { color: 'yellow', text: 'Pending' };
    };

    const onSearch = (event: Event) => {
      const target = event.target as HTMLInputElement;
      searchValue.value = target.value;
    };

    const resetSearch = () => {
      searchValue.value = '';
    };

    const bindGroupList = () => {
      store.dispatch('time-tracking/group/bindGroupList');
    };

    const unbindGroupList = () => {
      store.dispatch('time-tracking/group/unbindGroupList');
    };

    const getUserList = () => {
      store.dispatch('time-tracking/user/getUserList');
    };
    const showUser = (user: any) => {
      console.log(user);
    };
    const dateToValue = (a: any) => {
      return a.seconds * 1000 + a.nanoseconds / 1000000;
    };
    const countCertificateStatus = (certificates: any[]) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const statusCount = {
        active: 0,
        expiringSoon: 0,
        expired: 0,
      };

      certificates?.forEach(certificate => {
        const expiredDate = new Date(dateToValue(certificate.expiredDate));
        expiredDate.setHours(0, 0, 0, 0);

        if (!expiredDate || isNaN(expiredDate.getTime())) {
          statusCount.active++;
          return;
        }

        const now = new Date();
        if (expiredDate.getTime() === today.getTime()) {
          const endOfToday = new Date(today);
          endOfToday.setHours(23, 59, 59, 999);
          if (now.getTime() <= endOfToday.getTime()) {
            statusCount.expiringSoon++;
          } else {
            statusCount.expired++;
          }
        } else if (expiredDate.getTime() > today.getTime()) {
          const diffTime = expiredDate.getTime() - today.getTime();
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays > 30) {
            statusCount.active++;
          } else {
            statusCount.expiringSoon++;
          }
        } else {
          statusCount.expired++;
        }
      });

      return statusCount;
    };
    watch(
      () => certificates.myCertificatesList,
      () => {
        certificatesList.value = certificates.myCertificatesList;
      }
    );
    onMounted(async () => {
      getUserList();
      bindGroupList();
      await certificates.bindMyCertificates();

      instance.proxy.$nextTick(() => {
        certificatesList.value = certificates.myCertificatesList;
      });
      if (proxy?.$device.desktop) {
        document
          .querySelector<HTMLInputElement>('.search-list.searchbar input')
          ?.focus();
      }
    });

    onBeforeUnmount(() => {
      unbindGroupList();
    });

    return {
      isShowExportPopup,
      searchValue,
      groupedUsers,
      selectedId,
      displayEmail,
      onlineClass,
      onSearch,
      resetSearch,
      showUser,
      countCertificateStatus,
    };
  },
});
</script>
<style scoped>
.user-list {
  max-height: 100%;
  overflow-y: auto;
}
</style>
