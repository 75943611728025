<template>
  <div>
    <f7-block-title>Notes</f7-block-title>

    <f7-list
      media-list
      class="note-editor"
    >
      <div class="note-editor-content">
        <user-avatar
          style="margin-top: 15px"
          :avatarSize="35"
          :imageUrl="userInfo(uid) ? userInfo(uid).photoURL : ''"
          :name="userInfo(uid) ? userInfo(uid).displayName : ''"
        >
        </user-avatar>
        <f7-text-editor
          ref="noteEditor"
          class="editor"
          style="flex: 1"
          resizable
          placeholder="Enter note..."
          :value="noteContent"
          @texteditor:change="changeValue"
          :buttons="buttons"
          :custom-buttons="customButtons"
        ></f7-text-editor>
      </div>
      <div
        class="note-editor-bottom"
        v-show="noteContent != ''"
      >
        <f7-button @click.native="saveNote"> Save </f7-button>
      </div>
    </f7-list>

    <f7-list
      inset
      media-list
      v-for="(item, index) in noteList"
      :key="index"
    >
      <f7-list-item class="note-content">
        <div slot="media">
          <user-avatar
            :avatarSize="35"
            :imageUrl="
              userInfo(item.createdById || '')
                ? userInfo(item.createdById || '').photoURL
                : ''
            "
            :name="
              userInfo(item.createdById || '')
                ? userInfo(item.createdById || '').displayName
                : item.createdBy
            "
          >
          </user-avatar>
        </div>
        <div
          slot="title"
          v-show="$device.desktop || $device.ipad"
        >
          <strong>{{ item.createdBy }}</strong> added a
          {{ item.title }}
        </div>
        <div slot="after">
          {{ toLocaleString(item.createdAt) }}
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 3px;
            white-space: pre-wrap;
            word-wrap: break-word;
          "
        >
          <span
            v-html="
              item.translatedText ? item.translatedText : item.htmlContent
            "
          ></span>
          <div>
            <div
              style="display: flex; flex-direction: row; gap: 10px"
              v-if="item.isShowOrigin"
            >
              <f7-icon
                f7="arrow_turn_down_right"
                color="#ababab6b"
              ></f7-icon>
              <span
                v-html="item.htmlContent"
                class="note-content"
              ></span>
            </div>
            <span
              v-if="item.translatedText && item.translatedText !== item.htmlContent"
              class="text-translated"
              :class="item.isShowOrigin && 'text-translated-active'"
              @click="() => handleShowOrigin(item)"
              >{{ item.isShowOrigin ? 'Hide original' : 'Show original' }}</span
            >
          </div>
        </div>
      </f7-list-item>
    </f7-list>

    <select-color-popup ref="selectColor" />
  </div>
</template>

<script>
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import { firebase, auth } from '../../../../services/firebase.service';
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import SelectColorPopup from '@/plugins/proposal/components/popup/SelectColorPopup.vue';

import { buttonsForTextFormatBar } from '@/utility/const';
import {
  customButtonForTextFormatBar,
  fetchTranslatedComments,
} from '@/utility/common';
import {
  getActiveLanguage,
  handleDetectLanguage,
  handleTranslateText,
} from '@/lang/translation';

export default {
  components: { UserAvatar, SelectColorPopup },

  props: {
    value: { type: Array, default: () => [] },
    card: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      noteContent: '',
      customButtons: customButtonForTextFormatBar(this, false),
      buttons: buttonsForTextFormatBar,
      translatedNotes: [],
    };
  },

  created() {
    if (_.isEmpty(this.userList)) {
      this.getUserList();
    }
  },

  computed: {
    ...mapState('swimlane/user', ['userList']),

    noteList() {
      if (_.isEmpty(this.translatedNotes)) return [];
      return _.cloneDeep(this.translatedNotes).sort((a, b) => {
        return b.createdAt.seconds - a.createdAt.seconds;
      });
    },

    userInfo() {
      return uid =>
        this.userList.find(user => {
          return user.uid === uid;
        });
    },

    uid() {
      return auth.currentUser.uid;
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        fetchTranslatedComments(newValue || []).then(data => {
          this.translatedNotes = data;
        });
      },
    },
  },

  methods: {
    ...mapActions('swimlane/user', ['getUserList']),

    handleShowOrigin(item) {
      const newDataComments = this.translatedNotes.map(comment => {
        if (comment.id === item.id) {
          return {
            ...comment,
            isShowOrigin: !comment.isShowOrigin,
          };
        }
        return comment;
      });

      this.translatedNotes = newDataComments;
    },

    openDialogInsertLinkTextEditor() {
      this.$f7.dialog
        .create({
          title: `<p style="font-size: 14px;">Insert Link</p>`,
          content: `<input type="text" id="insert-link" placeholder="Insert link URL" style="width: 100%; border: 1px solid #2a2a2a; padding: 4px; border-radius:3px;">`,
          buttons: [
            {
              text: 'Cancel',
              color: 'gray',
            },
            {
              text: 'OK',
              cssClass: 'primary',
            },
          ],

          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              const editor = this.$refs.noteEditor;
              if (this.currentRange) {
                editor.$el.f7TextEditor.setSelectionRange(this.currentRange);
                const inputElement = document.getElementById('insert-link');
                const inputValue = inputElement.value;
                document.execCommand('createLink', true, inputValue);
                _sefl.app.dialog.close();
                this.currentRange = null;
              }
            }
          },
        })
        .open();
    },
    changeValue(value) {
      const convertValue = value.replace(
        /<a href="(https?:\/\/[^"]+)"/g,
        (match, url) => `<a href="#" onclick="window.open('${url}', '_blank')" `
      );
      this.noteContent = convertValue;
    },
    async saveNote() {
      let noteList = _.cloneDeep(this.value) || [];

      const { message } = await handleDetectLanguage(this.noteContent);

      noteList.push({
        title: 'Note',
        code: 'note',
        createdAt: firebase.firestore.Timestamp.now(),
        createdBy: auth.currentUser.displayName || auth.currentUser.email,
        createdById: auth.currentUser.uid,
        htmlContent: this.noteContent,
        language: message,
      });
      this.$emit('change', noteList);
      this.noteContent = '';
    },
    toLocaleString(firebaseDate) {
      return firebaseDate.toDate().toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.note-editor {
  background-color: var(--f7-block-strong-bg-color);
  margin: 0;
  .note-editor-content {
    margin-top: 12px;
    display: flex;
    padding: 0 15px;
  }
  .note-editor-bottom {
    padding: 0 15px;
    display: flex;
    justify-content: flex-end;
    a {
      display: inline;
      margin-right: var(--f7-text-editor-margin);
      margin-bottom: 15px;
    }
  }
}
.text-translated {
  cursor: pointer;
  font-size: 13px;
  color: var(--f7-theme-color);
}
.text-translated-active {
  font-weight: bold;
}
.note-content {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
