import _ from 'lodash';
import {
  BUSINESS_CODES,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
} from '../../utility/const';
export default {
  projectList: state => {
    let projects = [...state.hits];
    return projects;
  },

  // Fulltext search
  order: state => state.order,
  searchText: state => state.searchText,

  hits: state => state.hits,
  hitsPerPage: state => state.hitsPerPage,
  nbHits: state => state.nbHits,
  nbPages: state => state.nbPages,
  page: state => state.page,
  queryFilters: state => {
    const { jobTypes, collection, createdFromDate, createdToDate } =
      state.projectFilter;
    let filters = [];
    if (jobTypes.length > 0) {
      filters.push(
        `(${jobTypes.map(i => `projectBusinessCode:${i}`).join(' OR ')})`
      );
    } else if (state.isHiddenResJob) {
      filters.push(
        `(projectBusinessCode:${BUSINESS_CODE_COMMERCIAL} OR projectBusinessCode:${BUSINESS_CODE_SERVICE})`
      );
    }

    if (collection === 'project-open') {
      filters.push(`projectStatus:open`);
    } else if (collection === 'archived') {
      filters.push(
        `(${['cancel', 'close', 'lost-deal', 'put-a-lien']
          .map(i => `projectStatus:${i}`)
          .join(' OR ')})`
      );
    } else {
      filters.push(
        `(assigneeIDs:${auth.currentUser.uid} OR additionalAssigneeIDs:${auth.currentUser.uid})`
      );
    }

    if (!_.isEmpty(createdFromDate)) {
      filters.push(
        `createdAtUnixTime >= ${moment(createdFromDate[0], 'YYYY-MM-DD')
          .startOf('day')
          .unix()}`
      );
    }
    if (!_.isEmpty(createdToDate)) {
      filters.push(
        `createdAtUnixTime <= ${moment(createdToDate[0], 'YYYY-MM-DD')
          .endOf('day')
          .unix()}`
      );
    }
    return `${filters.join(' AND ')}`;
  },
  actionList: state => [...state.actionList].sort((a, b) => a.code - b.code),
  actionByCode: state => actionCode => {
    return state.actionList.find(action => action.code === actionCode) || {};
  },
  projectFilter: state => state.projectFilter,
};
