<template>
  <f7-popup
    :opened="popupOpened"
    @popup:close="closePopup"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click.native="closePopup">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>
          {{ albumDetail.name }}
        </f7-nav-title>
        <f7-nav-right>
          <f7-link
            v-if="selectedPhotoIds.length > 0"
            @click="cancelSelection"
            >Cancel</f7-link
          >
        </f7-nav-right>
      </f7-navbar>
      <div class="photo-page">
        <div
          class="upload-photo"
          @click="handleAddPhoto"
        >
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <f7-icon
              size="50"
              f7="cloud_upload_fill"
            ></f7-icon>
            <span>Upload photos</span>
          </div>
        </div>
        <div
          v-if="photosByAlbumIdGroup.length === 0 && !loading"
          class="camera"
        >
          <p>No photos!</p>
        </div>
        <div class="popup-scroll-bar">
          <div class="button-container">
            <f7-block>
              <div v-if="selectionMode && selectedPhotoIds.length > 0">
                <b>{{ selectedPhotoIds.length }}</b> photos selected
              </div>
            </f7-block>
          </div>
          <div>
            <div v-if="photosByAlbumIdGroup.length > 0 && !loading">
              <div
                v-for="projectPhoto in photosByAlbumIdGroup"
                :key="projectPhoto.key"
              >
                <div
                  class="display-flex flex-direction-row align-items-center margin-left-half"
                  @click="
                    handleSelectAllDay({
                      event: projectPhoto.data.every(photo =>
                        selectedPhotoIds.includes(photo.id)
                      )
                        ? false
                        : true,
                      ids: projectPhoto.data.map(photo => photo.id),
                    })
                  "
                  @mouseenter="$emit('handleMouseEnter')"
                >
                  <f7-checkbox
                    :style="
                      $device.desktop
                        ? 'transform: scale(1.5);'
                        : 'transform: scale(1.2); top: 8px'
                    "
                    class="margin-top-half margin-left"
                    :checked="
                      projectPhoto.data.every(photo =>
                        selectedPhotoIds.includes(photo.id)
                      )
                    "
                    @click.native.stop
                    @change.native.stop="
                      handleSelectAllDay({
                        event: $event.target.checked,
                        ids: projectPhoto.data.map(photo => photo.id),
                      })
                    "
                  ></f7-checkbox>
                  <f7-block-title style="cursor: pointer">{{
                    projectPhoto.key
                  }}</f7-block-title>
                </div>
                <f7-block>
                  <div class="img-grid">
                    <div
                      v-for="photo in projectPhoto.data"
                      :key="photo.id"
                      @touchstart="handlePressAndHold(photo.id)"
                      @touchend="handleTouchEnd"
                      @mouseenter="handleMouseEnter(photo.id)"
                      @mouseleave="handleSelected(null)"
                    >
                      <div
                        class="img-item"
                        style="border-radius: 4px"
                        @click.stop="clickPhoto(photo.id, true)"
                      >
                        <img
                          class="image-show"
                          :src="photo.thumbnailUrl"
                        />
                        <div
                          v-if="
                            $device.desktop &&
                            selectionMode &&
                            hoveredPhotoIds.includes(photo.id)
                          "
                          class="img-bg img-bg-select"
                        >
                          <div class="img-bg-content">
                            <f7-checkbox
                              style="
                                position: absolute;
                                top: 10%;
                                left: 10%;
                                transform: scale(1.5);
                              "
                              :checked="selectedPhotoIds.includes(photo.id)"
                              @click.native.stop
                              @change.native.stop="clickPhoto(photo.id)"
                            ></f7-checkbox>
                          </div>
                        </div>
                        <div
                          v-if="!$device.desktop && selectionMode"
                          class="img-bg img-bg-select"
                        >
                          <div class="img-bg-content">
                            <f7-checkbox
                              style="
                                position: absolute;
                                top: 10%;
                                left: 10%;
                                transform: scale(1.2);
                              "
                              :checked="selectedPhotoIds.includes(photo.id)"
                              @click.native.stop
                              @change.native.stop="clickPhoto(photo.id)"
                            ></f7-checkbox>
                          </div>
                        </div>

                        <div
                          v-else
                          class="img-bg img-bg-not-select"
                        >
                          <div
                            v-if="
                              photo.photoFullPath &&
                              (photo.photoFullPath
                                .toLowerCase()
                                .includes('.mp4') ||
                                photo.photoFullPath
                                  .toLowerCase()
                                  .includes('.mov') ||
                                photo.photoFullPath
                                  .toLowerCase()
                                  .includes('.m4v') ||
                                photo.photoFullPath
                                  .toLowerCase()
                                  .includes('.webm'))
                            "
                            class="play-button"
                          >
                            <i class="icon f7-icons size-20"
                              >play_circle_fill</i
                            >
                          </div>
                        </div>
                      </div>
                      <span class="img-title">{{
                        (photo.createdAt && photo.createdAt.toDate()) | TIME
                      }}</span>
                    </div>
                  </div>
                </f7-block>
              </div>
            </div>
          </div>
        </div>
      </div>

      <upload-photo
        ref="uploadPhoto"
        :albumId="albumDetail.id"
        :showProgressBar="true"
      ></upload-photo>
      <template v-if="selectedPhotoIds.length > 0">
        <delete-photo
          slot="fixed"
          class="tab-btn tab-btn_1"
          :class="{ 'btn-photo': $device.desktop }"
          :selectedIds="selectedPhotoIds"
          @cancelSelect="cancelSelection"
        ></delete-photo>
        <f7-fab
          :class="`tab-btn tab-btn_2 download-tooltip-${uuid}`"
          position="right-bottom"
          slot="fixed"
          color="default"
          @click="handleDownload"
        >
          <f7-icon f7="cloud_download"></f7-icon>
        </f7-fab>
      </template>
      <photo-browser
        :photos="photoList"
        theme="dark"
        ref="pageDark"
        @clickEdit="clickEdit"
        @clickDownload="clickDownload"
      ></photo-browser>
    </f7-page>
    <edit-photo-popup ref="editPhoto"> </edit-photo-popup>
  </f7-popup>
</template>

<script>
import UploadPhoto from './components/UploadPhoto.vue';
import DeletePhoto from './buttons/DeletePhoto.vue';
import EditPhotoPopup from './components/EditPhotoPopup.vue';
import PhotoBrowser from './components/PhotoBrowser.vue';
import { mapActions, mapGetters } from 'vuex';
import { groupBy } from '../../../../utility/filter-tools';
import { sortLatest } from '../../../../utility/date-time-tool';
import moment from 'moment';
import { uuid } from 'vue-uuid';

export default {
  components: {
    UploadPhoto,
    DeletePhoto,
    EditPhotoPopup,
    PhotoBrowser,
  },
  props: {
    loading: Boolean,
  },
  data: () => {
    return {
      popupOpened: false,
      albumDetail: {},
      hoveredPhotoIds: [],
      uuid: uuid.v4(),
    };
  },
  computed: {
    ...mapGetters('dashboard/photo', [
      'selectionMode',
      'selectedPhotoIds',
      'projectPhotoList',
      'projectPhotoSelected',
    ]),
    photoList() {
      return (
        this.projectPhotoList.filter(i => i.albumId === this.albumDetail.id) ||
        []
      ).map(i => ({
        id: i.id,
        url: i.photoUrl,
        comments: i.comments,
        photoFullPath: i.photoFullPath,
      }));
    },
    photosByAlbumIdGroup() {
      const photosByAlbumId =
        this.projectPhotoList.filter(i => i.albumId === this.albumDetail.id) ||
        [];
      return groupBy(sortLatest(photosByAlbumId), i =>
        i.createdAt
          ? moment(i.createdAt.toDate()).format('dddd, MMMM Do YYYY')
          : ''
      );
    },
  },

  methods: {
    ...mapActions('dashboard/photo', [
      'changeSelectionMode',
      'cancelSelectionMode',
      'onSelect',
      'downloadPhoto',
    ]),
    openPopup(album) {
      this.albumDetail = album;
      this.popupOpened = true;
    },
    closePopup() {
      this.popupOpened = false;
      this.albumDetail = {};
      this.cancelSelectionMode();
    },
    clickPhoto(id, isShowPhoto = false) {
      if (isShowPhoto) {
        const index = this.photoList.findIndex(i => i.id === id);
        this.$refs.pageDark.open(index);
      } else {
        this.onSelect(id);
      }
    },
    onSelection() {
      this.changeSelectionMode(true);
    },
    cancelSelection() {
      this.cancelSelectionMode();
      this.hoveredPhotoIds = [];
    },
    handleAddPhoto() {
      this.$refs.uploadPhoto.openUploadPhoto();
    },
    clickEdit(id) {
      this.$f7.preloader.show();
      const photo = this.projectPhotoList.find(i => i.id === id);
      this.$refs.editPhoto.open(photo);
      this.$f7.preloader.hide();
    },
    handleMouseEnter(id) {
      if (this.$device.desktop) {
        this.handleSelected(id);
      }
    },
    handlePressAndHold(id) {
      if (!this.$device.desktop) {
        this.pressHoldEvent = setTimeout(() => {
          this.handleSelected(id, true);
        }, 1000);
      }
    },
    handleTouchEnd() {
      clearTimeout(this.pressHoldEvent);
      this.pressHoldEvent = null;
    },
    handleSelected(id, isSelectPhoto = false) {
      if (this.selectedPhotoIds.length === 0 && id === null) {
        this.changeSelectionMode(false);
        this.hoveredPhotoIds = [];
      } else if (id === null) {
        const lastIndex = this.hoveredPhotoIds.length - 1;
        if (
          lastIndex >= 0 &&
          this.hoveredPhotoIds[lastIndex] !== null &&
          !this.selectedPhotoIds.includes(this.hoveredPhotoIds[lastIndex])
        ) {
          this.hoveredPhotoIds.splice(lastIndex, 1);
        }
      } else if (id && !this.hoveredPhotoIds.includes(id)) {
        this.changeSelectionMode(true);
        this.hoveredPhotoIds.push(id);
        if (isSelectPhoto) {
          this.clickPhoto(id);
        }
      }
    },
    handleSelectAllDay(data) {
      const photosIds = (data.ids || []).filter(
        i => !this.selectedPhotoIds.includes(i)
      );
      if (data.event) {
        for (const id of photosIds) {
          this.onSelect(id);
        }
        this.hoveredPhotoIds = this.hoveredPhotoIds.concat(photosIds);
        this.changeSelectionMode(true);
      } else {
        for (const id of photosIds) {
          this.onSelect(id);
        }
        this.hoveredPhotoIds = this.hoveredPhotoIds.filter(
          i => !data.ids.includes(i)
        );
        this.cancelSelectionMode();
      }
    },
    clickDownload(id) {
      const existedPhotoId = this.selectedPhotoIds.find(i => i === id);
      if (!existedPhotoId) {
        this.onSelect(id);
      }
      this.handleDownload();
    },
    handleDownload() {
      this.$f7.toast
        .create({
          text: 'Downloading photos...',
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 3000,
        })
        .open();
      const photos = this.projectPhotoSelected(this.selectedPhotoIds);
      photos.forEach(i =>
        this.downloadPhoto({ fullPath: i.photoFullPath, name: i.photoName })
      );
      this.cancelSelection();
    },
  },
};
</script>
<style scoped lang="scss">
.bg-no-data {
  width: 150px;
  height: 150px;
  background: var(--f7-color-img-neutral);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-no-data {
  font-size: 100px;
  color: var(--f7-color-icon-neutral);
}
.camera {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.img-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
}
.img-item {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  cursor: pointer;
  background-position: 50% center;
  background: white;
}
.img-bg {
  position: absolute;
  border-radius: 4px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
}
.img-bg-select {
  transition: all 150ms ease-in-out 0s;
  background: linear-gradient(rgba(50, 50, 50, 0.4), rgba(50, 50, 50, 0.7));
}
.img-item:hover .img-bg-not-select {
  background: linear-gradient(rgba(50, 50, 50, 0.2), rgba(50, 50, 50, 0.4));
}
.img-bg-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 1;
}
.img-title {
  font-size: 12px;
}
.edit-button {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 24px;
  text-align: center;
  color: white;
  font-size: 12px;
  background: #6666667a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.edit-button:hover {
  font-weight: 600;
  background: #0808087a;
}
.image-show {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  object-fit: contain;
}
.checkbox ::v-deep i {
  border-color: white;
}
.tab-btn {
  right: var(--global-search-btn-right);
  &_1 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        var(--f7-fab-size) - 44px
    );
  }
  &_2 {
    bottom: calc(
      var(--global-search-btn-bottom) - var(--menu-mobile-height) +
        (var(--f7-fab-size) - 12px) * 2
    );
  }
}

.play-button {
  position: absolute;
  width: 100%;
  top: 34%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
