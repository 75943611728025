<template>
  <div class="files-tab">
    <!-- segmented buttons -->
    <f7-block>
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          :active="activeButton === 'documents'"
          @click.native="activeButton = 'documents'"
          >Documents</f7-button
        >
        <f7-button
          :active="activeButton === 'project-photos'"
          @click.native="activeButton = 'project-photos'"
          >Project Photos</f7-button
        >
      </f7-segmented>
    </f7-block>
    <div class="tab-content-height">
      <div
        class="popup-scroll-bar"
        v-show="activeButton === 'documents'"
      >
        <attachment-input
          :projectId="getProject(constructionProject, card).id || ''"
          title="Attachments"
          attachment-type="attachment"
          add-button-title="Add an Attachment"
          :value="getProject(constructionProject, card).attachmentFiles || []"
          @input="changeCardValue('attachmentFiles', $event)"
        ></attachment-input>
        <estimate-list
          ref="estimateList"
          @closePopup="closeActionPopup"
        ></estimate-list>
        <proposal-list
          ref="proposalList"
          @closePopup="closeActionPopup"
        ></proposal-list>
        <!-- file -->
        <attachment-list
          v-show="
            attachmentFilesByType('taxExempt').length > 0 && card.taxExempt
          "
          :value="attachmentFilesByType('taxExempt')"
          title="Tax Exempt"
        ></attachment-list>
        <!-- file -->
        <attachment-list
          v-show="
            attachmentFilesByType('resalesCertificate').length > 0 &&
            card.resalesCertificate
          "
          :value="attachmentFilesByType('resalesCertificate')"
          title="Resale Certificate"
        ></attachment-list>
        <!-- file -->
        <attachment-list
          v-show="
            attachmentFilesByType('manufacturerVerbal').length > 0 &&
            card.manufacturerVerbal
          "
          :value="attachmentFilesByType('manufacturerVerbal')"
          title="Manufacturer Verbal Approved System Docs"
        ></attachment-list>
        <!-- file -->
        <attachment-list
          v-show="
            attachmentFilesByType('requiredSystemLetter').length > 0 &&
            card.requiredSystemLetter
          "
          :value="attachmentFilesByType('requiredSystemLetter')"
          title="Required System Letter Docs"
        ></attachment-list>
        <!-- file -->
        <attachment-list
          v-show="attachmentFilesByType('proposal').length > 0"
          :value="attachmentFilesByType('proposal')"
          title="Proposal Docs"
        ></attachment-list>
        <!-- file -->
        <attachment-list
          v-show="attachmentFilesByType('own-proposal').length > 0"
          :value="attachmentFilesByType('own-proposal')"
          title="Uploaded Proposals"
        ></attachment-list>
        <!-- file -->
        <attachment-list
          v-show="attachmentFilesByType('contract').length > 0"
          :value="attachmentFilesByType('contract')"
          title="Contract Docs"
        ></attachment-list>
      </div>

      <div
        v-show="activeButton === 'project-photos'"
        style="height: 100%; position: relative"
      >
        <project-photo
          ref="projectPhoto"
          :loading="loadingPhoto"
        ></project-photo>
      </div>
    </div>
  </div>
</template>

<script>
import AttachmentList from '../list/AttachmentList.vue';
import EstimateList from '../list/EstimateList.vue';
import ProposalList from '../list/ProposalList.vue';
import ProjectPhoto from '../photo/ProjectPhoto.vue';
import AttachmentInput from '../input/AttachmentInput.vue';

import { mapGetters, mapActions } from 'vuex';
import { toDisplayDateString } from '../../../../utility/datetime';
import _ from 'lodash';
import methods from '../../mixin/methods';

export default {
  components: {
    AttachmentList,
    EstimateList,
    ProposalList,
    ProjectPhoto,
    AttachmentInput,
  },
  mixins: [methods],

  data() {
    return {
      activeButton: 'documents',
      loadingPhoto: false,
    };
  },

  computed: {
    ...mapGetters('dashboard/project', ['constructionProject', 'card']),

    attachmentFilesByType() {
      return type => {
        if (!_.isEmpty(this.card)) {
          return (
            (this.card.attachmentFiles || []).filter(
              item => item.attachmentType === type
            ) || []
          );
        }
        return [];
      };
    },
  },

  methods: {
    ...mapActions('dashboard/photo', ['bindProjectPhotoListBy']),
    ...mapActions('dashboard/album', ['bindProjectPhotoAlbumListBy']),
    ...mapActions('dashboard/project', ['updateCard']),

    init() {
      this.loadPhoto();
      this.$refs.estimateList.initData();
      this.$refs.proposalList.initData();
    },

    /**
     * Init data
     */
    showMainContent() {
      this.activeButton = 'documents';
    },

    changeCardValue(field, value) {
      this.updateCard({
        id: this.card.id,
        doc: {
          [field]: value,
        },
      });
    },
    loadPhoto() {
      if (this.card && this.card.id) {
        this.loadingPhoto = true;
        const promises = [];
        promises.push(
          this.bindProjectPhotoListBy({
            prop: 'projectId',
            val: this.card.id,
            op: '==',
          })
        );
        promises.push(
          this.bindProjectPhotoAlbumListBy({
            prop: 'projectId',
            val: this.card.id,
            op: '==',
          })
        );
        Promise.all(promises).then(res => {
          if (res) this.loadingPhoto = false;
        });
      }
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    formatArrayDate(array) {
      let newArray = array.map(item => {
        return toDisplayDateString(item.date);
      });
      return newArray.join(', ');
    },

    closeActionPopup(callback) {
      this.$emit('doClosePopup', callback);
    },

    /**
     * Clear data
     */
    clearActionData() {
      this.$refs.projectPhoto.cancelSelect();
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-link {
  margin-left: 7px;
}

.files-tab {
  height: calc(100% - var(--f7-block-margin-vertical));
}
.scoll-bar {
  height: calc(88%);
  position: relative;
  overflow: auto;
}
.tab-content-height {
  height: calc(
    100% - var(--f7-block-margin-vertical) - var(--f7-button-height)
  );
}
</style>
