<template>
  <div>
    <f7-list>
      <f7-list-input
        type="datepicker"
        label="Schedule of Value Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
          },
        }"
        placeholder="Enter schedule of value due date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon
          slot="media"
          icon="today"
        ></input-icon>
      </f7-list-input>
    </f7-list>

    <create-schedule-input
      :value="displayCard.scheduleOfValueId"
      @input="setAttachment('scheduleOfValueId', $event)"
      @gotoDetails="navigateToSchedule($event)"
    ></create-schedule-input>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <attachment-input
      :projectId="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="note = $event"
      :value="note"
      :isSaveButton="true"
      @onSave="
        onSaveNote({
          displayCard,
          title: 'Schedule of Value Note',
          note,
          callback: () => {
            note = '';
          },
        })
      "
    ></note-editor>

    <date-popup
      ref="dueDatePopup"
      title="Enter Schedule of Value Date"
      label="Schedule of Value Due Date"
      v-model="scheduleValueDueDate"
      @done="resolveScheduleValue"
      validate
      not-allow-past
    ></date-popup>
  </div>
</template>

<script>
import UserInput from '../input/UserInput.vue';
import AttachmentInput from '../input/AttachmentInput.vue';
import CreateScheduleInput from '../input/CreateScheduleInput.vue';
import DatePopup from '../popup/DatePopup.vue';
import InputIcon from '../icon/InputIcon.vue';
import PurchaseOrderInput from '../input/PurchaseOrderInput.vue';

import moment from 'moment';

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString,
} from '../../../../utility/datetime';

import { firebase, auth } from '../../../../services/firebase.service';
import { mapState, mapActions } from 'vuex';

import _ from 'lodash';

import methodsMixins from '../../mixin/methods';
import NoteEditor from '../note/NoteEditor.vue';

export default {
  mixins: [methodsMixins],
  components: {
    CreateScheduleInput,
    DatePopup,
    UserInput,
    InputIcon,
    AttachmentInput,
    PurchaseOrderInput,
    NoteEditor,
  },

  data: () => {
    return {
      note: '',
      currentResponse: {},

      displayCard: {},
      dueDate: [],

      scheduleValueDueDate: [],
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      },
    },
  },

  computed: {
    ...mapState('dashboard/project', ['card', 'action', 'response', 'users']),
  },
  methods: {
    ...mapActions('dashboard/project', ['updateCard']),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.displayCard.retainage = this.displayCard.retainage || false;
      }
    },

    resolveScheduleValue() {
      this.dueDate = this.scheduleValueDueDate;
      this.resolve();
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case 'task-completed':
          this.currentResponse = response;
          // this.$refs.dueDatePopup.open();
          this.$ri.dialog.openInfoDialog({
            title: 'Move to Upfront Payment',
            content: 'Are you sure you want to move to Upfront Payment?',
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                this.resolve();
              }
            },
          });
          break;

        case 'save-and-close':
          this.save();
          this.$emit('doClosePopup', () => {});
          break;
      }
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit('doResolve', this.currentResponse);
      this.$emit('doClosePopup', () => {});
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'Schedule of Value Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });
      }

      this.displayCard.commentAndNote = commentAndNote;
      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard,
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },

    navigateToSchedule({ id, isNew }) {
      let newSchedule = isNew ? 'new' : 'old';
      this.$emit('doClosePopup', () => {});
      this.$f7router.navigate(
        `/schedule/swimlane/${this.$f7route.route.meta.businessCode}/pre-construction/project/${this.card.id}/action/${this.action.id}/schedule/${id}/${newSchedule}`,
        {
          pushState: true,
          reloadAll: true,
        }
      );
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            'day'
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) },
          });
        }
      }
    },
  },
};
</script>

<style></style>
