<template>
  <div>
    <f7-popup
      tablet-fullscreen
      class="demo-popup"
      :opened="popupAddNewProductItem"
      @popup:closed="handlePopupClosed"
      @popup:opened="handlePopupOpened"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Select Product Item</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="addProductItem">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <!-- Products table -->
        <data-table
          :headers="headers"
          :items="productItemComputed"
          :footerProps="{
            pageSizeOption: [25, 50, 100, 200, 300],
          }"
          :pageSize="25"
          :totals="productItemComputed.length"
          :checkbox="true"
          :resetPagination="!popupAddNewProductItem"
          :isUsePagingFooterDefault="!$device.desktop"
          @selected:change="checkBoxChange"
          @callBackChangePage="createColorPhotoTooltip"
        >
          <template slot="card-header">
            <f7-list>
              <f7-searchbar
                placeholder="Search in items"
                :clear-button="true"
                disable-button-text
                :value="searchValue"
                @input="searchValue = $event.target.value"
                @searchbar:disable="searchValue = ''"
                @searchbar:clear="searchValue = ''"
                ref="searchProductPopup"
              >
                <f7-button
                  outline
                  color="primary"
                  class="margin-left-half"
                  style="min-width: 120px"
                  @click="openCustomizeTablePopup"
                  >Customize</f7-button
                >
              </f7-searchbar>
            </f7-list>
          </template>
          <template v-slot:body="{ item }">
            <td
              v-for="(header, index) in headers"
              :key="index"
            >
              <div v-if="header.value === 'sku'">{{ item.sku }}</div>
              <div v-else-if="header.value === 'productItem'">
                {{ item.productItem }}
              </div>
              <div v-else-if="header.value === 'manufacturer'">
                {{ item.manufacturer }}
              </div>
              <div v-else-if="header.value === 'vendor'">
                {{ vendorName(item.vendorId) }}
              </div>
              <div v-else-if="header.value === 'roofType'">
                {{ productRoofTypeNames(item.roofTypes) }}
              </div>
              <div v-else-if="header.value === 'productCategory'">
                {{ item.categoryName }}
              </div>
              <div v-else-if="header.value === 'subCategory'">
                {{ subCategoryName(item.subCategoryId) }}
              </div>
              <div v-else-if="header.value === 'technicalData'">
                <ul class="technical-data">
                  <li
                    v-for="(attach, index) in item.technicalData"
                    :key="index"
                    :id="`technical-${item.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`"
                  >
                    <a
                      class="display-block"
                      @click.stop="openLink(item, attach)"
                    >
                      {{ attach.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <div v-else-if="header.value === 'color'">
                <f7-link
                  v-for="(color, index) in item.colorPhotos || []"
                  :key="index"
                  @click.native.stop="clickPhoto(color)"
                  :id="`add-product-${item.id}-${color.id}`"
                >
                  {{
                    `${color.colorName}${
                      index + 1 === (item.colorPhotos || []).length
                        ? ''
                        : ',&nbsp;'
                    }`
                  }}
                </f7-link>
              </div>
              <div v-else-if="header.value === 'packaging'">
                {{ item.packaging }}
              </div>
              <div v-else-if="header.value === 'unitPack'">
                {{ item.unitPack }}
              </div>
              <div v-else-if="header.value === 'unitSize'">
                {{ item.unitSize }}
              </div>
              <div v-else-if="header.value === 'uom'">{{ item.uom }}</div>
              <div v-else-if="header.value === 'price'">
                {{ item.price | currencyUSD }}
              </div>
              <div
                v-else-if="header.value === 'copyAction'"
                class="text-align-center"
              >
                <a
                  href="#"
                  @click.stop="copyProduct(item)"
                >
                  <i
                    class="f7-icons text-color-blue"
                    style="margin: 2px"
                    >doc_on_doc</i
                  >
                </a>
              </div>
            </td>
          </template>
        </data-table>
      </f7-page>
    </f7-popup>

    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>
    <customize-table-popup
      ref="customizeTablePopup"
      :tableType="CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT"
      :defaultColumns="defaultColumns"
      :customTable="customTable"
    ></customize-table-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DataTable from '@/components/datatables';
import CustomizeTablePopup from '@/components/popups/CustomizeTablePopup';
import { applyFilter } from '@/utility/filter-tools';
import {
  CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT,
  DEFAULT_STATUS_PRODUCT_ITEM,
} from '@/utility/const';
import _ from 'lodash';
import {
  isVideo,
  isImage,
  createMediaArray,
  openAttackLink,
} from '@/utility/common';
export default {
  components: {
    DataTable,
    CustomizeTablePopup,
  },

  props: {
    popupAddNewProductItem: Boolean,
    productItemList: Array,
    existingProductItems: Array,
    sectionId: String,
    roofType: String,
    items: { type: Array, default: () => [] },
  },

  data: () => ({
    CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT,
    searchValue: '',
    productPhotos: [],
    selectedProducts: [],
  }),

  computed: {
    ...mapGetters({
      subCategoryObjects:
        'estimate/estimate-page/estimate/sub-category/objectList',
      vendors: 'estimate/estimate-page/estimate/vendor/objectList',
    }),
    ...mapGetters('common/app-constant', ['roofTypeBy', 'roofTypeList']),
    ...mapGetters({
      categories: 'estimate/estimate-page/estimate/category/objectList',
    }),
    ...mapGetters('common/custom-table', ['customTable']),
    ...mapGetters('common/app-constant', ['defaultColumns']),

    headers() {
      let headers = [];
      if (_.isEmpty(this.customTable)) {
        headers = _.cloneDeep(this.defaultColumns);
      } else {
        headers =
          _.cloneDeep(this.customTable.sortedColumns).filter(r => r.checked) ||
          [];
      }
      headers = headers.map(r => {
        return {
          ...r,
          text: r.title,
          value: r.value,
          sortable: false,
          align: 'left',
        };
      });
      headers.push({
        text: 'Copy',
        value: 'copyAction',
        sortable: false,
        align: 'center',
      });
      return headers;
    },

    section() {
      return (
        (this.roofTypeBy(this.roofType).estimateTemplate || []).find(
          item => item.sectionId === this.sectionId
        ) || {}
      );
    },

    category() {
      return this.categories.find(c => c.name === this.section.category) || {};
    },

    productItemComputed() {
      let list = this.productItemList;

      //Filter by statu active
      list = list.filter(
        x =>
          x.status === DEFAULT_STATUS_PRODUCT_ITEM &&
          x.categoryId === this.category.id
      );

      const filteredProducts = applyFilter([...list], this.searchValue, [
        'productItem',
        'sku',
        i => i.manufacturer,
        i => this.vendorName(i.vendorId),
        i => this.subCategoryName(i.subCategoryId),
        'uom',
        i => this.technicalDataNames(i.technicalData),
      ]);

      return filteredProducts.map(product => ({
        ...product,
        checked: this.selectedProducts.some(p => p.id === product.id),
      }));
    },
  },

  methods: {
    productRoofTypeNames(roofTypes = []) {
      const rt = (this.roofTypeList || []).filter(r =>
        roofTypes.includes(r.value)
      );
      return rt.map(r => r.displayName).join(', ');
    },

    openLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },

    async copyProduct(item) {
      delete item.checked;
      if (this.selectedProducts.length !== 0) {
        this.$emit('copyProduct', { item: item, checked: true });
      } else {
        this.$emit('copyProduct', { item: item, checked: false });
      }
    },

    addProductItem() {
      this.$emit('onAddProductItems', this.selectedProducts);
    },

    showNotification(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: 'RooferIntel',
          titleRightText: 'now',
          subtitle,
          text,
          closeTimeout: 3000,
        });
      }
      self.notificationFull.open();
    },

    vendorName(id) {
      const vendor = this.vendors.find(r => r.id === id);
      return (vendor || {}).companyName;
    },

    subCategoryName(subCategoryId) {
      const subCategory = this.subCategoryObjects.find(
        r => r.id == subCategoryId
      );
      return (subCategory || {}).name;
    },

    technicalDataNames(technicalData = []) {
      return technicalData.map(r => r.name).join(', ');
    },

    handlePopupOpened() {
      this.selectedProducts = _.cloneDeep(this.existingProductItems);
      if (this.$device.desktop) {
        this.$refs.searchProductPopup.$el
          .querySelector('.searchbar input')
          .focus();
      }
    },
    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },
    createColorPhotoTooltip() {
      this.$nextTick(() => {
        this.productItemComputed.forEach(item => {
          (item.colorPhotos || []).forEach(color => {
            const el = this.$f7.tooltip.get(
              `#add-product-${item.id}-${color.id}`
            );
            if (el) {
              this.$f7.tooltip.destroy(`#add-product-${item.id}-${color.id}`);
            }
            const photo = (color.photos || [])[0];
            const tooltipContent = photo
              ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
              : `<span>No image !</span>`;
            this.$f7.tooltip.create({
              targetEl: `#add-product-${item.id}-${color.id}`,
              cssClass: photo ? 'tooltip-image-preview' : '',
              text: tooltipContent,
            });
          });
        });
        this.productItemComputed.forEach(attachMent => {
          (attachMent.technicalData || []).forEach(item => {
            const el1 = this.$f7.tooltip.get(
              `#technical-${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
            );
            if (el1) {
              this.$f7.tooltip.destroy(
                `#technical-${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
              );
            }
            const tooltipContent = isImage(item.url)
              ? `<img class="tooltip-image-show" src="${item?.url}" />`
              : isVideo(item.url)
                ? `<video class="tooltip-image-show" src="${item?.url}" ></video>`
                : `<span>${item?.name}</span>`;
            this.$f7.tooltip.create({
              targetEl: `#technical-${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
              cssClass:
                isImage(item.url) || isVideo(item.url)
                  ? 'tooltip-image-preview'
                  : '',
              text: tooltipContent,
            });
          });
        });
      });
    },

    checkBoxChange(data) {
      const foundedItem = this.selectedProducts.find(
        item => item.id === data.id
      );
      // remove unchecked item
      if (foundedItem && data.checked === false) {
        this.selectedProducts = this.selectedProducts.filter(
          item => item.id !== foundedItem.id
        );
      }
      // add checked item
      else if (!foundedItem && data.checked === true) {
        const productToAdd = this.productItemList.find(
          item => item.id === data.id
        );
        if (productToAdd) {
          this.selectedProducts.push({ ...productToAdd, checked: true });
        }
      }

      // foundedItem && data.checked === true => do notthing
    },

    openCustomizeTablePopup() {
      this.$refs.customizeTablePopup.open();
    },

    handlePopupClosed() {
      this.$emit('close');
      this.searchValue = '';
      this.productPhotos = [];
      this.selectedProducts = [];
    },
  },
  updated() {
    this.createColorPhotoTooltip();
  },
};
</script>

<style scoped>
.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}
.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

.technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
</style>
