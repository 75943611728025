<template>
  <div>
    <f7-row>
      <f7-col
        v-if="!$device.desktop"
        width="100"
        class="display-flex justify-content-flex-end"
        :class="{ summary: !$device.desktop }"
      >
        <f7-button
          v-if="isEdit"
          fill
          color="primary"
          class="margin-right"
          @click="addAnotherLine"
        >
          Add another line
        </f7-button>
        <f7-button
          v-if="isEdit"
          fill
          color="primary"
          @click="selectFromPriceList"
        >
          Select from Price list
        </f7-button>
      </f7-col>
    </f7-row>

    <data-table
      :headers="isEdit ? productTableHeaders : detailHeader"
      :items="items"
      :pageSize="items.length"
      class="label-cell"
      :class="{
        'dark-header': isEdit,
        'light-header': !isEdit,
        'no-margin-horizontal': $device.desktop,
      }"
    >
      <template #header="{ column }">
        <column-type
          :column="column"
          :discountType="discountType"
          @change-select="onChangeSelectTypeDiscount"
          v-if="column.selectType"
          :isApproveTax="isApproveTax"
          v-on="$listeners"
        />
        <div v-else>
          {{ column.text }}
        </div>
      </template>

      <template #body="{ item, index }">
        <td>
          <input
            style="width: 100%"
            ref="input"
            type="text"
            placeholder="Type or select from Price list"
            :value="item.productName"
            @input="onChangeItemName(index, $event.target.value)"
            :class="{ 'error-input': productNameErrorMessage(index) }"
          />
        </td>
        <td>
          <f7-input
            type="select"
            :value="item.category"
            @change="selectCategory($event.target.value, index, item)"
            :disabled="isDisableCategory(item)"
            :class="{ 'error-input': categoryErrorMessage(index) }"
          >
            <option
              v-for="(category, idx) in productCategories(item)"
              :key="idx"
              :value="category.name"
            >
              {{ category.name }}
            </option>
            <required-asterisk slot="label" />
          </f7-input>
        </td>
        <td
          @mouseover="isEdit ? (hoverIndex = index) : ''"
          @mouseout="hoverIndex = ''"
          class="numeric-cell"
          :style="isEdit ? 'height: 55px;' : ''"
        >
          <div v-if="!isEdit">{{ item.quantity }}</div>
          <div
            v-else
            :class="hoverIndex === index ? 'stepper-raised stepper' : ''"
            class="stepper-qty stepper-small stepper-init margin-top-half"
            :style="isEdit ? 'width: 180px; height: 28px; float: right;' : ''"
          >
            <div
              :class="
                hoverIndex === index ? 'display-inline-block' : 'display-none'
              "
              class="stepper-button-minus"
              @click="minusPlusQty(index, 'minus')"
            ></div>
            <cell-number-input
              :stepper="hoverIndex === index ? 'true' : 'false'"
              placeholder="Quantity"
              :value="item.quantity"
              :allowNegativeNumber="false"
              :fractionDigits="0"
              @done="
                updateProductProperty({
                  propName: 'quantity',
                  value: $event,
                  index,
                });
                validate();
              "
              :isDark="isDark"
              :error-message="productQuantityErrorMessage(index)"
            ></cell-number-input>

            <div
              :class="
                hoverIndex === index ? 'display-inline-block' : 'display-none'
              "
              class="stepper-button-plus"
              @click="minusPlusQty(index, 'plus')"
            ></div>
          </div>
        </td>

        <td>
          <cell-number-input
            placeholder="Price"
            numberType="currency"
            :value="item.priceWithProfitAndMisc"
            @done="
              updateProductProperty({
                propName: 'priceWithProfitAndMisc',
                value: $event,
                index,
              });
              validate();
            "
            :isDark="isDark"
            :allowNegativeNumber="false"
            :error-message="priceErrorMessage(index)"
          ></cell-number-input>
        </td>
        <td class="numeric-cell">
          {{ item.amount | currencyUSD }}
        </td>
        <td>
          <cell-number-input
            placeholder="Discount"
            :value="item.discountValue"
            :fractionDigits="discountType === 'percent' ? 3 : 2"
            :numberType="discountType === 'percent' ? 'percent' : 'currency'"
            @done="
              updateProductProperty({
                propName: 'discountValue',
                value: $event,
                index,
              });
              validate();
            "
            :isDark="isDark"
            :allowNegativeNumber="false"
          ></cell-number-input>
        </td>
        <td class="numeric-cell">
          {{ item.netSales | currencyUSD }}
        </td>
        <td>
          <cell-number-input
            placeholder="Tax Percent"
            numberType="percent"
            :value="isApproveTax ? item.taxPercent : 0"
            :fractionDigits="3"
            :readonly="!isApproveTax"
            @done="
              updateProductProperty({
                propName: 'taxPercent',
                value: $event,
                index,
              });
              validate();
            "
            :isDark="isDark"
            :allowNegativeNumber="false"
          ></cell-number-input>
        </td>
        <td class="numeric-cell">
          {{ item.intoMoney | currencyUSD }}
        </td>
        <td class="numeric-cell">
          <div class="display-flex align-items-center">
            <f7-link
              class="margin-right-half"
              icon-f7="doc_on_doc"
              color="blue"
              @click="cloneItem(item)"
            ></f7-link>
            <f7-link
              icon-f7="xmark_circle"
              color="red"
              @click="deleteItem(index)"
            ></f7-link>
          </div>
        </td>
      </template>
    </data-table>

    <f7-row>
      <f7-col
        v-if="$device.desktop"
        width="100"
        medium="40"
        class="display-flex"
      >
        <f7-button
          v-if="isEdit"
          fill
          color="primary"
          class="margin-right"
          @click="addAnotherLine"
        >
          Add another line
        </f7-button>
        <f7-button
          v-if="isEdit"
          fill
          color="primary"
          @click="selectFromPriceList"
        >
          Select from Price list
        </f7-button>
      </f7-col>

      <div>
        <slot name="summary"></slot>
      </div>
    </f7-row>

    <!-- popup -->
    <add-product-from-price-list-popup
      @onAddProductItems="selectedProduct"
      ref="selectFromPriceList"
      :existingProductItems="items"
    >
    </add-product-from-price-list-popup>
  </div>
</template>

<script>
import DataTable from '@/components/datatables';
import CellNumberInput from '@/components/inputs/CellNumberInput.vue';
import AddProductFromPriceListPopup from '../popups/AddProductFromPriceListPopup.vue';
import { mapGetters, mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required, minValue, helpers } from '@vuelidate/validators';
import _ from 'lodash';
import Vue from 'vue';
import ColumnType from './ColumnType.vue';
import { VALIDATION_MESSAGE } from '@/utility/const';

const initFieldNewProductItem = Object.freeze({
  quantity: 1,
  discount: false,
  tax: false,
  isAutoAdded: true,
  crossProfitMarginRatio: 0,
  miscPercent: 0,
  discountValue: 0,
  taxPercent: 0,
});

export default {
  name: 'ItemizeTable',

  components: {
    DataTable,
    CellNumberInput,
    AddProductFromPriceListPopup,
    ColumnType,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    buildingList: {
      type: Array,
      default: () => [],
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    defaultTax: {
      type: Number,
      default: 0,
    },
    invoiceDetail: { type: Object, default: () => {} },
    isApproveTax: {
      type: Boolean,
      default: true,
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  data() {
    return {
      hoverIndex: '',
      selectedProductItems: [],
      currentProductItems: [],
      buildingsEstimate: [],
      discountType: 'percent',
    };
  },

  computed: {
    ...mapGetters('invoices/category', ['objectList']),
    ...mapGetters({
      estimateList: 'invoices/estimate/estimateList',
    }),
    ...mapGetters('common/app-constant', ['roofTypeBy']),

    isDisableCategory() {
      return item => {
        return !item.isEditCategory && item.category ? true : false;
      };
    },

    isEdit() {
      return true;
    },

    categoryEstimateTemplate() {
      return item => {
        const building = this.buildingList.find(e => e.id === item.buildingId);
        if (!building || !building.roofType) return [];
        const templateSettings =
          this.roofTypeBy(building.roofType).estimateTemplate || [];
        return templateSettings.map(e => ({ ...e, name: e.category }));
      };
    },

    productCategories() {
      return item => {
        if (!item.isEditCategory && !item.category) {
          return this.categoryEstimateTemplate(item);
        }

        return this.objectList?.length
          ? [...this.objectList.map((item, index) => ({ ...item, key: index }))]
          : [];
      };
    },

    productTableHeaders() {
      return [
        {
          text: 'Item Details',
          align: 'left',
          sortable: false,
          value: 'productName',
          width: '20%',
        },
        {
          text: 'Category',
          align: 'left',
          sortable: false,
          value: 'category',
          width: '5%',
        },
        {
          text: 'Quantity',
          align: 'right',
          sortable: false,
          value: 'quantity',
          width: '5%',
        },
        {
          text: 'Price',
          align: 'right',
          sortable: false,
          value: 'priceWithProfitAndMisc',
          width: '10%',
        },

        {
          text: 'Amount',
          align: 'right',
          sortable: false,
          value: 'amount',
          width: '10%',
        },
        {
          text: 'Discount',
          align: 'right',
          sortable: false,
          value: 'discountValue',
          width: '10%',
          selectType: true,
        },
        {
          text: 'Net Sales',
          align: 'right',
          sortable: false,
          value: 'netSales',
          width: '10%',
        },
        {
          text: 'Tax',
          align: 'right',
          sortable: false,
          value: 'taxPercent',
          width: '10%',
          selectType: true,
        },
        {
          text: 'Total',
          align: 'right',
          sortable: false,
          value: 'intoMoney',
          width: '10%',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'action',
          width: '10%',
        },
      ];
    },

    productQuantityErrorMessage() {
      return index => {
        if (
          this.v$.currentProductItems.$each.$response.$errors[index].quantity
            .length === 0
        )
          return null;
        return (
          this.v$.currentProductItems.$each.$response.$errors[index].quantity[0]
            .$message || ''
        );
      };
    },

    priceErrorMessage() {
      return index => {
        if (
          this.v$.currentProductItems.$each.$response.$errors[index]
            .priceWithProfitAndMisc.length === 0
        )
          return null;
        return (
          this.v$.currentProductItems.$each.$response.$errors[index]
            .priceWithProfitAndMisc[0].$message || ''
        );
      };
    },

    productNameErrorMessage() {
      return index => {
        if (
          this.v$.currentProductItems.$each.$response.$errors[index].productName
            .length === 0
        )
          return null;
        return (
          this.v$.currentProductItems.$each.$response.$errors[index]
            .productName[0].$message || ''
        );
      };
    },

    categoryErrorMessage() {
      return index => {
        if (
          this.v$.currentProductItems.$each.$response.$errors[index].category
            .length === 0
        )
          return null;
        return (
          this.v$.currentProductItems.$each.$response.$errors[index].category[0]
            .$message || ''
        );
      };
    },

    ...mapGetters('common/app-constant', ['tenantId']),
  },

  watch: {
    buildingList: {
      immediate: true,
      deep: true,
      handler(value) {
        this.buildingsEstimate = _.cloneDeep(value);
      },
    },
    items: {
      deep: true,
      handler(value) {
        this.currentProductItems = _.cloneDeep(value);
      },
    },
    isApproveTax: {
      deep: true,
      immediate: true,
      handler(value) {
        this.updateProductItems(value);
      },
    },
  },

  created() {
    if (!_.isEmpty(this.items)) {
      this.currentProductItems = _.cloneDeep(this.items);
    }
    if (this.invoiceDetail.discountType) {
      this.onChangeSelectTypeDiscount(this.invoiceDetail.discountType);
    }
  },

  methods: {
    ...mapActions('invoices/product', [
      'getProduct',
      'updateCategoryProductItem',
      'updateBuilding',
    ]),

    updateProductProperty({ propName, value, index }) {
      let newItem = _.cloneDeep(this.currentProductItems[index]);
      newItem[propName] = value;

      // update fields relative
      const itemReCalculation = this.reCalculationProductItemize(newItem);

      Vue.set(this.currentProductItems, index, itemReCalculation);
      this.changeItems();
    },

    reCalculationProductItemize(item, isApproveTax = true) {
      const amount = (item.quantity || 1) * (item.priceWithProfitAndMisc || 0);

      const discountAmount =
        this.discountType === 'percent'
          ? (amount * (item.discountValue || 0)) / 100
          : item.discountValue || 0;
      const netSales = amount - (discountAmount || 0);

      const taxPercent = isApproveTax ? item.taxPercent : 0;

      const intoMoney = netSales * (1 + taxPercent / 100);

      return {
        ...item,
        amount,
        netSales,
        intoMoney,
        discountType: this.discountType,
      };
    },

    updateProductItems(isApproveTax) {
      this.currentProductItems.forEach((item, index) => {
        const itemReCalculation = this.reCalculationProductItemize(
          item,
          isApproveTax
        );
        Vue.set(this.currentProductItems, index, itemReCalculation);
      });
      this.changeItems();
    },

    changeItems() {
      this.$emit('onChangeItems', this.currentProductItems);
    },

    selectCategory(categoryName, index, product) {
      if (!product.isEditCategory && !product.category) {
        this.$emit('onSelectCategory', { categoryName, index });
        const category = this.objectList.find(e => e.name === categoryName);
        const productId = product.id;
        const dataCategory = {
          categoryId: category.id,
          categoryName: category.name,
        };

        this.updateCategoryProductItem({ productId, dataCategory }),
          delete product.category;
        this.updateCategoryProductItemInBuilding(
          product,
          dataCategory,
          product.buildingId
        );
        return;
      }
      this.$emit('onSelectCategory', { categoryName, index });
    },

    updateCategoryProductItemInBuilding(product, dataCategory, buildingId) {
      const building = this.buildingsEstimate.find(e => e.id === buildingId);
      const templateSettings = this.categoryEstimateTemplate(product) || [];
      const sectionCategory = templateSettings.find(
        e => e.category === dataCategory.categoryName
      );
      const newProductDataBuilding = [...building.productData];
      const productItem = this.findProductByIdInProductDataInBuilding(
        product.id,
        newProductDataBuilding
      );

      if (!sectionCategory || !productItem) return;

      const indexExistsCategoryInProductData = newProductDataBuilding.findIndex(
        e => e.sectionId === sectionCategory.sectionId
      );

      const removeProduct = () => {
        // Find and remove products from lists of other productList
        newProductDataBuilding.forEach((item, index) => {
          if (indexExistsCategoryInProductData > -1) {
            if (index !== indexExistsCategoryInProductData) {
              const productIndexToRemove = item.productList.findIndex(
                item => item.id === productItem.id
              );
              if (productIndexToRemove > -1) {
                item.productList.splice(productIndexToRemove, 1);
              }
            }
          } else {
            if (item.category === null) {
              const productIndexToRemove = item.productList.findIndex(
                item => item.id === productItem.id
              );
              if (productIndexToRemove > -1) {
                item.productList.splice(productIndexToRemove, 1);
              }
            }
          }
        });
      };

      if (indexExistsCategoryInProductData > -1) {
        newProductDataBuilding[
          indexExistsCategoryInProductData
        ].productList.push({
          ...productItem,
          categoryId: dataCategory.categoryId,
          categoryName: dataCategory.categoryName,
        });
        removeProduct();
      } else {
        newProductDataBuilding.push({
          index: sectionCategory.index,
          category: sectionCategory.category,
          sectionId: sectionCategory.sectionId,
          productList: [
            {
              ...productItem,
              categoryId: dataCategory.categoryId,
              categoryName: dataCategory.categoryName,
            },
          ],
        });
        removeProduct();
      }

      this.buildingsEstimate = this.buildingsEstimate.map(e =>
        e.id === buildingId ? { ...e, productData: newProductDataBuilding } : e
      );

      const productData = this.getProductDataInBuilding(buildingId);

      if (!productData) return;

      this.updateBuilding({
        estimateId: building.estimateId,
        buildingId: building.id,
        building: { ...building, productData: productData },
        tenantId: this.tenantId,
      });
    },

    getProductDataInBuilding(buildingId) {
      const building = this.buildingsEstimate.find(b => b.id === buildingId);
      if (!building) return [];

      const projectDataList = building.productData;

      const filteredProjectDataList = projectDataList.filter(item => {
        return !(item.productList.length === 0);
      });

      return filteredProjectDataList;
    },

    findProductByIdInProductDataInBuilding(productId, productDataBuilding) {
      for (let i = 0; i < productDataBuilding.length; i++) {
        const product = productDataBuilding[i].productList.find(
          p => p.id === productId
        );
        if (product) {
          return product;
        }
      }
      return null;
    },

    handleRemoveProductItem(product) {
      this.currentProductItems = this.currentProductItems.filter(
        item => item.id !== product.id
      );
      this.changeItems();
    },

    minusPlusQty(index, type) {
      const sectionProducts = _.cloneDeep(this.currentProductItems);
      const currentProduct = sectionProducts[index];
      let quantity = currentProduct.quantity || 0;

      // increase or decrease quantity
      quantity = type === 'minus' ? Math.max(quantity - 1, 0) : quantity + 1;

      //update qty
      this.updateProductProperty({
        propName: 'quantity',
        value: quantity,
        index,
      });
    },
    validate() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return false;
      }
      return true;
    },

    addAnotherLine() {
      this.currentProductItems.push({
        ...initFieldNewProductItem,
        taxPercent: this.isApproveTax ? this.defaultTax : 0,
        productName: '',
        price: 0,
        category: '',
        priceWithProfitAndMisc: 0,
        amount: 0,
        netSales: 0,
        intoMoney: 0,
        isAutoAdded: false,
        isEditCategory: true,
      });

      this.changeItems();
    },

    onChangeItemName: _.debounce(function (index, value) {
      this.updateProductProperty({
        propName: 'productName',
        value: value,
        index,
      });
      this.validate();
    }, 500),

    selectFromPriceList() {
      this.$refs.selectFromPriceList.open();
    },

    cloneItem(item) {
      this.currentProductItems.push(item);
      this.changeItems();
    },

    deleteItem(index) {
      this.currentProductItems.splice(index, 1);
      this.changeItems();
    },

    isServiceType() {
      return (
        (
          this.estimateList.find(r => r.id == this.invoiceDetail.estimateId) ||
          {}
        ).businessCode === 'service'
      );
    },

    async selectedProduct(products = []) {
      this.$f7.preloader.show();
      const productIds = products.map(item => item.id);
      //remove all products
      if (_.isEmpty(productIds)) {
        if (this.currentProductItems.length > 0) {
          const productsRemove = _.cloneDeep(this.currentProductItems);
          for (const prod of productsRemove) {
            this.handleRemoveProductItem(prod);
          }
        }
        this.$f7.preloader.hide();
        return;
      }

      // Step #1:
      const existingProductIds = (this.currentProductItems || []).map(
        item => item.id
      );
      const newProductToAdd = products.filter(
        item => !existingProductIds.includes(item.id)
      );

      // calculate price and save product to section
      let items = newProductToAdd.map(r => {
        const miscPercent =
          r.categoryName !== 'Labor' ? this.invoiceDetail.miscPercent || 0 : 0;

        let priceWithProfitAndMisc =
          r.price /
            ((100 -
              ((this.invoiceDetail.crossProfitMarginRatio || 0) +
                miscPercent)) /
              100) || 0;

        const amount = (r.quantity || 1) * (priceWithProfitAndMisc || 0);

        const discountValue = 0;

        const netSales = amount;

        const taxPercent = this.defaultTax || 0;

        const intoMoney = netSales * (1 + taxPercent / 100);

        return {
          id: r.id,
          productName: r.productItem,
          price: r.price,
          quantity: 1,
          crossProfitMarginRatio: 0,
          category: r.categoryName,
          categoryId: r.categoryId,
          priceWithProfitAndMisc,
          intoMoney,
          taxPercent,
          discountValue,
          netSales,
          amount,
        };
      });

      this.currentProductItems = this.currentProductItems.concat(items);
      this.changeItems();

      // Step #2: keep existing products => do nothing

      // Step #3: remove the product from the section
      const oldProductIds = existingProductIds.filter(
        id => !productIds.includes(id)
      );
      for (const id of oldProductIds) {
        const orgProduct = await this.getProduct(id);
        if (!_.isEmpty(orgProduct)) {
          this.handleRemoveProductItem(orgProduct);
        }
      }

      this.$f7.preloader.hide();
      return;
    },

    onChangeSelectTypeDiscount(value) {
      this.discountType = value;
      this.$emit('onChangeDiscountType', value);

      //update all items with new discount type
      this.currentProductItems = this.currentProductItems.map(item => {
        const newItem = this.reCalculationProductItemize(item);
        return { ...newItem };
      });

      this.changeItems();
    },
  },

  validations() {
    return {
      currentProductItems: {
        $each: helpers.forEach({
          quantity: {
            required: helpers.withMessage(
              VALIDATION_MESSAGE.REQUIRED_FIELD,
              required
            ),
            minValue: helpers.withMessage(
              VALIDATION_MESSAGE.GREATER_THAN_ZERO,
              minValue(1)
            ),
          },
          priceWithProfitAndMisc: {
            required: helpers.withMessage(
              VALIDATION_MESSAGE.REQUIRED_FIELD,
              required
            ),
            minValue: helpers.withMessage(
              VALIDATION_MESSAGE.GREATER_THAN_ZERO,
              minValue(0.01)
            ),
          },
          productName: {
            required: helpers.withMessage(
              VALIDATION_MESSAGE.REQUIRED_FIELD,
              required
            ),
          },
          category: {
            required: helpers.withMessage(
              VALIDATION_MESSAGE.REQUIRED_FIELD,
              required
            ),
          },
        }),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.error-input {
  border-bottom: 1px solid red;
}
</style>
