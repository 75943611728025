<template>
  <div>
    <f7-popup
      tablet-fullscreen
      class="demo-popup"
      :opened="popupAddNewProductItem"
      @popup:closed="handlePopupClosed"
      @popup:opened="handlePopupOpened"
    >
      <f7-page>
        <f7-navbar>
          <f7-nav-left>
            <f7-link popup-close>Cancel</f7-link>
          </f7-nav-left>
          <f7-nav-title>Select Product Item</f7-nav-title>
          <f7-nav-right>
            <f7-link @click.native="addProductItem">Done</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <!-- Products table -->
        <data-table
          :headers="headers"
          :items="productItemComputed"
          :pageSize="productItemComputed.length"
          :checkbox="true"
          @selected:change="checkBoxChange"
          @callBackChangePage="createColorPhotoTooltip"
        >
          <template slot="card-header">
            <f7-list>
              <f7-searchbar
                placeholder="Search in items"
                :clear-button="true"
                disable-button-text
                :value="searchValue"
                @input="handleSearch($event.target.value)"
                @searchbar:disable="handleSearch('')"
                @searchbar:clear="handleSearch('')"
                ref="searchProductPopup"
              >
                <f7-button
                  outline
                  color="primary"
                  class="margin-left-half"
                  style="min-width: 120px"
                  @click="openCustomizeTablePopup"
                  >Customize</f7-button
                >
              </f7-searchbar>
            </f7-list>
          </template>
          <template v-slot:body="{ item }">
            <td
              v-for="(header, index) in headers"
              :key="index"
            >
              <div v-if="header.value === 'sku'">{{ item.sku }}</div>
              <div v-else-if="header.value === 'productItem'">
                {{ item.productItem }}
              </div>
              <div v-else-if="header.value === 'manufacturer'">
                {{ item.manufacturer }}
              </div>

              <div v-else-if="header.value === 'vendor'">
                {{ vendorName(item.vendorId) }}
              </div>
              <div v-else-if="header.value === 'roofType'">
                {{ productRoofTypeNames(item.roofTypes) }}
              </div>
              <div v-else-if="header.value === 'productCategory'">
                {{ categoryName(item.categoryId) }}
              </div>
              <div v-else-if="header.value === 'subCategory'">
                {{ subCategoryName(item.subCategoryId) }}
              </div>
              <div v-else-if="header.value === 'technicalData'">
                <ul class="technical-data">
                  <li
                    v-for="(attach, index) in item.technicalData"
                    :key="index"
                    :id="`${item.id}-${attach.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`"
                  >
                    <a
                      class="display-block"
                      @click.stop="openLink(item, attach)"
                    >
                      {{ attach.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <div v-else-if="header.value === 'color'">
                <f7-link
                  v-for="(color, index) in item.colorPhotos || []"
                  :key="index"
                  @click.native.stop="clickPhoto(color)"
                  :id="`add-product-${item.id}-${color.id}`"
                >
                  {{
                    `${color.colorName}${
                      index + 1 === (item.colorPhotos || []).length
                        ? ''
                        : ',&nbsp;'
                    }`
                  }}
                </f7-link>
              </div>
              <div v-else-if="header.value === 'packaging'">
                {{ item.packaging }}
              </div>
              <div v-else-if="header.value === 'unitPack'">
                {{ item.unitPack }}
              </div>
              <div v-else-if="header.value === 'unitSize'">
                {{ item.unitSize }}
              </div>
              <div v-else-if="header.value === 'uom'">{{ item.uom }}</div>
              <div v-else-if="header.value === 'price'">
                {{ item.price | currencyUSD }}
              </div>
              <div
                v-else-if="header.value === 'copyAction'"
                class="text-align-center"
              >
                <a
                  href="#"
                  @click.stop="copyProduct(item)"
                >
                  <i
                    class="f7-icons text-color-blue"
                    style="margin: 2px"
                    >doc_on_doc</i
                  >
                </a>
              </div>
            </td>
          </template>

          <template slot="paging-footer">
            <div
              v-if="hits.length > 0"
              class="data-table-footer"
              :class="$device.desktop ? '' : 'mobile-container'"
            >
              <div class="data-table-rows-select">
                Per page:
                <div class="input input-dropdown">
                  <select
                    @input="onChangeLimit($event.target.value.trim())"
                    :value="hitsPerPage"
                  >
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                  </select>
                </div>
              </div>
              <div
                class="data-table-pagination"
                :style="$device.desktop ? '' : 'margin-left:-20px'"
              >
                <span
                  v-if="page === 0"
                  class="display-flex align-items-center margin-left color-gray"
                  disabled
                  ><f7-icon f7="chevron_left"></f7-icon>First</span
                >
                <a
                  v-else
                  @click="onGoToPage('first')"
                  class="display-flex align-items-center margin-left"
                  disabled
                  ><f7-icon f7="chevron_left"></f7-icon>First</a
                >

                <span
                  v-if="page === 0"
                  class="margin-left color-gray"
                  >Previous</span
                >
                <a
                  v-else
                  @click="onGoToPage('prev')"
                  class="margin-left"
                  >Previous</a
                >

                <span class="data-table-pagination-label margin-left">{{
                  pagingMessage
                }}</span>

                <a
                  v-if="page < nbPages - 1"
                  @click="onGoToPage('next')"
                  class="margin-left"
                  >Next</a
                >
                <span
                  v-else
                  class="margin-left color-gray"
                  >Next</span
                >

                <a
                  v-if="page < nbPages - 1"
                  @click="onGoToPage('last')"
                  class="display-flex align-items-center margin-left"
                  >Last<f7-icon f7="chevron_right"></f7-icon
                ></a>
                <span
                  v-else
                  class="display-flex align-items-center margin-left color-gray"
                  >Last<f7-icon f7="chevron_right"></f7-icon
                ></span>
              </div>
            </div>
          </template>
        </data-table>
      </f7-page>
    </f7-popup>
    <f7-photo-browser
      :photos="productPhotos"
      theme="dark"
      :ref="`detail_pageDark`"
      type="popup"
    ></f7-photo-browser>
    <customize-table-popup
      ref="customizeTablePopup"
      :tableType="CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT"
      :defaultColumns="defaultColumns"
      :customTable="customTable"
    ></customize-table-popup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from '@/components/datatables';
import CustomizeTablePopup from '@/components/popups/CustomizeTablePopup';
import _ from 'lodash';
import {
  isVideo,
  isImage,
  createMediaArray,
  openAttackLink,
} from '@/utility/common';
import {
  CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT,
  STATUS_PRODUCT_ITEM_ACTIVE,
} from '../../../../utility/const';

export default {
  components: {
    DataTable,
    CustomizeTablePopup,
  },

  props: {
    popupAddNewProductItem: Boolean,
    existingProductItems: Array,
    roofType: { type: String, default: 'all-roof-type' },
  },

  data: () => ({
    selectedProducts: [],
    CUSTOM_TABLE_TYPE_ESTIMATE_PRODUCT,
    productPhotos: [],

    isChangePerPage: false,
    searchValue: '',
  }),

  computed: {
    ...mapGetters({
      subCategoryObjects:
        'estimate/estimate-page/estimate/sub-category/objectList',
      vendors: 'estimate/estimate-page/estimate/vendor/objectList',
    }),

    ...mapGetters('common/app-constant', ['roofTypeBy', 'roofTypeList']),

    ...mapGetters({
      categories: 'estimate/estimate-page/estimate/category/objectList',
    }),

    ...mapGetters('estimate/estimate-page/product-item', [
      'hits',
      'searchText',
      'hitsPerPage',
      'nbPages',
      'page',
      'nbHits',
    ]),

    ...mapGetters('estimate/estimate-page/price-list', {
      priceListObject: 'priceList',
    }),
    ...mapGetters('common/custom-table', ['customTable']),
    ...mapGetters('common/app-constant', ['defaultColumns']),

    headers() {
      let headers = [];
      if (_.isEmpty(this.customTable)) {
        headers = _.cloneDeep(this.defaultColumns);
      } else {
        headers =
          _.cloneDeep(this.customTable.sortedColumns).filter(r => r.checked) ||
          [];
      }
      headers = headers.map(r => {
        return {
          ...r,
          text: r.title,
          value: r.value,
          sortable: false,
          align: 'left',
        };
      });
      headers.push({
        text: 'Copy',
        value: 'copyAction',
        sortable: false,
        align: 'center',
      });
      return headers;
    },

    pagingMessage() {
      const fromRow = this.page * this.hitsPerPage + 1;
      const toRow = this.page * this.hitsPerPage + this.hits.length;
      return `${fromRow} - ${toRow} of ${this.nbHits}`;
    },

    categoryName() {
      return id => {
        return (this.categories.find(c => c.id === id) || {}).name || '';
      };
    },

    productItemComputed() {
      const products = _.cloneDeep(this.hits);

      return products.map(orgProduct => {
        const refProduct = (
          (this.priceListObject || {}).productRefs || []
        ).find(item => item.productId === orgProduct.id);
        return {
          ...orgProduct,
          price: !Number.isNaN(Number.parseFloat(refProduct?.price))
            ? refProduct.price
            : orgProduct.price,
          checked: this.selectedProducts.some(p => p.id === orgProduct.id),
        };
      });
    },
  },

  methods: {
    ...mapActions('estimate/estimate-page/product-item', [
      'searchProduct',
      'setSearchText',
      'goToPage',
      'setProductNumberOfRows',
    ]),

    productRoofTypeNames(roofTypes = []) {
      const rt = (this.roofTypeList || []).filter(r =>
        roofTypes.includes(r.value)
      );
      return rt.map(r => r.displayName).join(', ');
    },

    openLink(item, attach) {
      this.productPhotos = createMediaArray(item.technicalData);
      openAttackLink(attach, this.productPhotos, this.$refs[`detail_pageDark`]);
    },

    // TODO: double-check this case!!!
    async copyProduct(item) {
      delete item.checked;
      if (this.selectedProducts.length !== 0) {
        this.$emit('copyProduct', { item: item, checked: true });
      } else {
        this.$emit('copyProduct', { item: item, checked: false });
      }
    },

    // on click DONE
    addProductItem() {
      this.$emit('onAddProductItems', this.selectedProducts);
    },

    showNotification(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: 'RooferIntel',
          titleRightText: 'now',
          subtitle,
          text,
          closeTimeout: 3000,
        });
      }
      self.notificationFull.open();
    },

    vendorName(id) {
      const vendor = this.vendors.find(r => r.id === id);
      return (vendor || {}).companyName;
    },

    subCategoryName(subCategoryId) {
      const subCategory = this.subCategoryObjects.find(
        r => r.id == subCategoryId
      );
      return (subCategory || {}).name;
    },

    technicalDataNames(technicalData = []) {
      return technicalData.map(r => r.name).join(', ');
    },

    /**
     * handle init when popup opened
     */
    handlePopupOpened() {
      // this.selectedProducts will keep all templ products for calculate when add/remove products
      this.selectedProducts = _.cloneDeep(this.existingProductItems);
      this.search();
      if (this.$device.desktop) {
        this.$refs.searchProductPopup.$el
          .querySelector('.searchbar input')
          .focus();
      }
    },

    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },

    createColorPhotoTooltip() {
      this.$nextTick(() => {
        this.hits.forEach(item => {
          (item.colorPhotos || []).forEach(color => {
            const el = this.$f7.tooltip.get(
              `#add-product-${item.id}-${color.id}`
            );
            if (el) {
              this.$f7.tooltip.destroy(`#add-product-${item.id}-${color.id}`);
            }
            const photo = (color.photos || [])[0];
            const tooltipContent = photo
              ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
              : `<span>No image !</span>`;
            this.$f7.tooltip.create({
              targetEl: `#add-product-${item.id}-${color.id}`,
              cssClass: photo ? 'tooltip-image-preview' : '',
              text: tooltipContent,
            });
          });
        });
        this.hits.forEach(attachMent => {
          (attachMent.technicalData || []).forEach(item => {
            const el1 = this.$f7.tooltip.get(
              `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
            );
            if (el1) {
              this.$f7.tooltip.destroy(
                `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`
              );
            }
            const tooltipContent = isImage(item.url)
              ? `<img class="tooltip-image-show" src="${item?.url}" />`
              : isVideo(item.url)
                ? `<video class="tooltip-image-show" src="${item?.url}" ></video>`
                : `<span>${item?.name}</span>`;
            this.$f7.tooltip.create({
              targetEl: `#${attachMent.id}-${item.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '')}`,
              cssClass:
                isImage(item.url) || isVideo(item.url)
                  ? 'tooltip-image-preview'
                  : '',
              text: tooltipContent,
            });
          });
        });
      });
    },

    composeSearchFilters() {
      let result = `priceListRefs:${this.priceListObject.id} AND status:${STATUS_PRODUCT_ITEM_ACTIVE}`;
      if (this.roofType != '' && this.roofType != 'all-roof-type') {
        result += ` AND roofTypes:${this.roofType}`;
      }
      return result;
    },

    search() {
      this.$f7.preloader.show();
      const filters = this.composeSearchFilters();
      this.searchProduct({
        query: this.searchValue,
        filters,
      }).finally(() => {
        this.isChangePerPage = false;
        this.$f7.preloader.hide();
      });
    },

    handleSearch: _.debounce(function (value) {
      this.searchValue = value;
      this.search();
    }, 500),

    onGoToPage(pageName) {
      this.$f7.preloader.show();

      const filters = this.composeSearchFilters();
      this.$emit('goToPage');
      this.goToPage({
        query: this.searchValue,
        pageName,
        filters,
        // ...this.compileConditionsToSearchByPL
      }).finally(() => {
        this.$f7.preloader.hide();
      });
    },

    onChangeLimit(value) {
      this.isChangePerPage = true;
      this.setProductNumberOfRows(value);
      this.search();
    },

    /**
     *
     * @param {*} data the changing item
     * this method will be called when checkbox changed (checked or unchecked) immedialy when table is initing
     */
    checkBoxChange(data) {
      const foundedItem = this.selectedProducts.find(
        item => item.id === data.id
      );

      // remove unchecked item
      if (foundedItem && data.checked === false) {
        this.selectedProducts = this.selectedProducts.filter(
          item => item.id !== foundedItem.id
        );
      }
      // add checked item
      else if (!foundedItem && data.checked === true) {
        const productToAdd = this.hits.find(item => item.id === data.id);
        if (productToAdd) {
          this.selectedProducts.push({ ...productToAdd, checked: true });
        }
      }

      // foundedItem && data.checked === true => do notthing
    },

    openCustomizeTablePopup() {
      this.$refs.customizeTablePopup.open();
    },

    handlePopupClosed() {
      this.$emit('close');
      this.selectedProducts = [];
      this.productPhotos = [];
      this.isChangePerPage = false;
      this.searchValue = '';
    },
  },

  updated() {
    this.createColorPhotoTooltip();
  },
};
</script>

<style scoped>
.mobile-container {
  justify-content: center;
  flex-direction: column-reverse;
}
.mobile-container > * {
  padding: 10px 0;
}
.technical-data {
  width: 120px;
  display: inline-block;
  list-style-type: disc;
}
.technical-data a {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.technical-data li {
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--f7-theme-color);
}

.technical-data li::marker {
  color: var(--f7-color-text-neutral);
}
</style>
