<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="closePopup"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New system</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onCreateNewBuilding">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-input
          placeholder="Enter system #"
          type="text"
          :value="buildingName"
          error-message-force
          validate
          validate-on-blur
          :error-message="buildingNameErrorMessage"
          @input="
            buildingName = $event.target.value.trim();
            v$.buildingName.$touch();
          "
        >
          <input-icon
            slot="media"
            icon="building_2"
          ></input-icon>
          <div
            slot="inner-start"
            class="list-item-inner-start"
          >
            System #<required-asterisk />
          </div>
        </f7-list-input>

        <f7-list-input
          label="Number of Building/Sections"
          :value="numberOfCopy"
          class="number-building-input"
          placeholder="Enter number of buidings/sections"
          type="number"
          error-message-force
          validate
          validate-on-blur
          :error-message="numberOfBuildingsErrorMessage"
          @input="
            numberOfCopy = parseInt($event.target.value.trim());
            v$.numberOfCopy.$touch();
          "
        >
          <input-icon
            slot="media"
            icon="building_2"
          ></input-icon>
        </f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>
<script>
import InputIcon from '@/components/icons/InputIcon.vue';

import { useVuelidate } from '@vuelidate/core';
import { required, minValue, maxValue } from '@vuelidate/validators';
import buidingMixin from '../../utility/building-mixins';
import { BUSINESS_CODE_SERVICE, VALIDATION_MESSAGE } from '@/utility/const';
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    InputIcon,
  },
  mixins: [buidingMixin],
  data: () => {
    return {
      popupOpened: false,
      projectInfo: {},
      estimateInfo: {},
      buildingName: '',
      numberOfCopy: 1,
    };
  },
  computed: {
    buildingNameErrorMessage() {
      if (!this.v$.buildingName.$error) return null;
      if (this.v$.buildingName.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },

    numberOfBuildingsErrorMessage() {
      if (!this.v$.numberOfCopy.$error) return '';
      if (this.v$.numberOfCopy.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.numberOfCopy.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      if (this.v$.numberOfCopy.maxValue.$invalid) {
        return VALIDATION_MESSAGE.LESS_THAN_OR_EQUAL_TO_200;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('estimate/estimate-page/estimate', [
      'updateEstimate',
      'createBuilding',
      'getTax',
    ]),
    openPopup({ projectInfo, estimateInfo }) {
      this.projectInfo = _.cloneDeep(projectInfo);
      this.estimateInfo = _.cloneDeep(estimateInfo);
      this.buildingName = `SYSTEM_${
        (this.estimateInfo.buildings || []).length + 1
      }`;
      this.popupOpened = true;
    },
    closePopup() {
      this.popupOpened = false;
      this.projectInfo = {};
      this.estimateInfo = {};
      this.buildingName = '';
      this.numberOfCopy = 1;
      this.v$.$reset();
    },
    async onCreateNewBuilding() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      const buildingInfoDefault = await this.getBuildingInfoDefault(
        this.projectInfo
      );
      const workType = this.getWorkType(this.projectInfo);
      const self = this;
      this.$f7.preloader.show();
      let buildingTax = {};
      if (this.estimateInfo.businessCode === BUSINESS_CODE_SERVICE) {
        buildingTax = {
          taxApplyType: this.estimateInfo.taxApplyType || '',
          saleTax: this.estimateInfo.saleTax || 0,
        };
      } else {
        const saleTax = await this.getTax({
          projectData: this.projectInfo,
          buildingData: { workType: workType || buildingInfoDefault.workType },
          estimateData: this.estimateInfo,
        });
        buildingTax = {
          saleTax,
        };
      }
      this.createBuilding({
        estimateId: this.estimateInfo.id,
        building: {
          ...buildingInfoDefault,
          buildingName: this.buildingName,
          estimateId: this.estimateInfo.id,
          numberOfCopy: this.numberOfCopy,
          workType: workType || buildingInfoDefault.workType,
          ...buildingTax,
        },
      })
        .then(buildingData => {
          const buildings = self.estimateInfo.buildings || [];
          buildings.push(buildingData);
          return self
            .updateEstimate({
              id: self.estimateInfo.id,
              doc: {
                buildings: buildings,
                numberOfBuildings: buildings.length,
              },
            })
            .then(() => {
              return {
                estimateNumber: self.estimateInfo.estimateNumber,
                buildingName: buildingData.buildingName,
              };
            });
        })
        .then(data => {
          self.$f7.preloader.hide();
          // Navigate to building detail
          self.$f7router.navigate(
            self.buildingUrl(data.estimateNumber, data.buildingName)
          );
        })
        .finally(() => {
          self.$f7.preloader.hide();
          self.closePopup();
        });
    },
  },
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  validations: {
    buildingName: {
      required,
    },
    numberOfCopy: { required, minValue: minValue(1), maxValue: maxValue(200) },
  },
};
</script>
