<template>
  <div>
    <f7-block-header>Information</f7-block-header>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        label="Closing Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
          },
        }"
        placeholder="Enter closing date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon
          slot="media"
          icon="timer"
        ></input-icon>
      </f7-list-input>
    </f7-list>

    <attachment-input
      :projectId="displayCard.id"
      title="Contract"
      attachment-type="contract"
      add-button-title="Add a Contract"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <f7-list no-hairlines-md>
      <f7-list-input
        type="text"
        label="Total Project Value"
        placeholder="$ Enter Project Amount"
        :value="totalProjectValue"
        @input="
          totalProjectValue = formatPriceNumber($event.target.value.trim())
        "
      >
        <input-icon
          slot="media"
          icon="money_dollar_circle"
        ></input-icon>
      </f7-list-input>
      <f7-list-input
        type="text"
        label="Mobilization %"
        placeholder="% Enter Mobilization Percentage"
        :value="mobilization"
        @input="mobilization = formatFloatNumber($event.target.value.trim())"
      >
        <input-icon
          slot="media"
          icon="percent"
        ></input-icon>
      </f7-list-input>
      <f7-list-item title="Retainage">
        <f7-toggle
          slot="after"
          :checked="displayCard.retainage"
          @toggle:change="displayCard.retainage = $event"
        ></f7-toggle>
        <input-icon
          slot="media"
          icon="slash_circle"
        ></input-icon>
      </f7-list-item>
    </f7-list>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <attachment-input
      :projectId="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <!-- <note-editor
      @onChange="changeValueNotes"
      :value="note"
      :isSaveButton="true"
      @onSave="
        onSaveNote({
          displayCard,
          title: 'Closing Note',
          note,
          callback: () => {
            note = '';
          },
        })
      "
    ></note-editor> -->

    <note-input
      :value="displayCard ? displayCard.commentAndNote || [] : []"
      :card="card"
      @change="saveNote($event)"
    ></note-input>

    <!-- Popup -->
    <date-popup
      ref="preinstallationDueDatePopup"
      title="Enter Preinstallation Date"
      label="Preinstallation Date"
      v-model="preinstallationDueDate"
      @done="resolvePreinstallation"
      validate
      not-allow-past
    ></date-popup>

    <date-popup
      ref="closingDueDatePopup"
      title="Enter New Closing Date"
      label="New Closing Date"
      v-model="closingDueDate"
      @done="resolveClosing"
      validate
      not-allow-past
    ></date-popup>

    <note-popup
      ref="notePopup"
      title="Enter Note"
      label="Note"
      v-model="note"
      @done="resolve"
    ></note-popup>
  </div>
</template>

<script>
import UserInput from '../input/UserInput.vue';
import AttachmentInput from '../input/AttachmentInput.vue';
import DatePopup from '../popup/DatePopup.vue';
import NotePopup from '../popup/NotePopup.vue';
import InputIcon from '../icon/InputIcon.vue';
import PurchaseOrderInput from '../input/PurchaseOrderInput.vue';

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString,
} from '../../../../utility/datetime';

import { firebase, auth } from '../../../../services/firebase.service';
import { mapState, mapActions } from 'vuex';

import _ from 'lodash';
import methodsMixins from '../../mixin/methods';

import moment from 'moment';
import NoteInput from '@/plugins/swimlane/components/inputs/NoteInput.vue';

export default {
  mixins: [methodsMixins],

  components: {
    UserInput,
    AttachmentInput,
    DatePopup,
    NotePopup,
    InputIcon,
    PurchaseOrderInput,
    NoteInput,
  },

  data: () => {
    return {
      note: '',
      currentResponse: {},

      displayCard: {},
      dueDate: [],

      totalProjectValue: '',
      mobilization: '',

      preinstallationDueDate: [],
      closingDueDate: [],
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      },
    },
  },

  computed: {
    ...mapState('dashboard/project', ['card', 'action', 'response', 'users']),
  },

  methods: {
    ...mapActions('dashboard/project', ['updateCard']),

    changeValueNotes(value) {
      this.note = value;
    },

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.displayCard.retainage = this.displayCard.retainage || false;

        this.totalProjectValue = this.displayCard.totalProjectValue
          ? this.formatPriceNumber(
              this.displayCard.totalProjectValue.toString()
            )
          : '';
        this.mobilization = this.displayCard.mobilization || '';
      }
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case 'move-to-preinstallation':
          this.currentResponse = response;
          this.$refs.preinstallationDueDatePopup.open();
          break;

        case 'reschedule-closing':
          this.currentResponse = response;
          this.$refs.closingDueDatePopup.open();
          break;

        case 'unsold':
          this.currentResponse = response;
          this.$refs.notePopup.open();
          break;
      }
    },

    resolvePreinstallation() {
      this.dueDate = this.preinstallationDueDate;
      this.resolve();
    },

    resolveClosing() {
      this.dueDate = this.closingDueDate;
      this.resolve();
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit('doResolve', this.currentResponse);
      this.$emit('doClosePopup');
      this.$f7.preloader.hide();
    },

    async saveNote(event) {
      if (event) {
        const commentAndNote = event.map(item => ({
          ...item,
          title: 'Closing Note',
        }));

        this.displayCard.commentAndNote = commentAndNote;

        await this.updateCard({
          id: this.displayCard.id,
          doc: {
            commentAndNote: commentAndNote
          },
        })
      }
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'Closing Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });
      }

      this.displayCard.commentAndNote = commentAndNote;
      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      if (!_.isEmpty(this.totalProjectValue)) {
        const cost = this.formatDots(this.totalProjectValue)
          .replaceAll('$', '')
          .replaceAll(',', '');
        this.displayCard.totalProjectValue = parseFloat(cost) || null;
      }

      if (!_.isEmpty(this.mobilization)) {
        this.displayCard.mobilization = this.mobilization;
      }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard,
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            'day'
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) },
          });
        }
      }
    },
  },
};
</script>

<style></style>
