<template>
  <f7-popup
    :opened="popupOpened"
    :tablet-fullscreen="true"
    @popup:closed="close"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Edit Photo</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="done">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <div class="image-edit-container">
        <div class="image-editor">
          <tui-image-editor
            v-if="popupOpened"
            ref="tuiImageEditor"
            :include-ui="useDefaultUI"
            :options="options"
          >
          </tui-image-editor>
        </div>
        <f7-col class="comment-section">
          <comment
            :photoId="(photo || {}).id"
            :comments="(photo || {}).comments"
          ></comment>
        </f7-col>
      </div>
      <!-- mobile element -->
      <div
        class="comment-button"
        @click="openCommentPopup"
      >
        <i
          class="icon f7-icons"
          style="font-size: 22px"
          >bubble_left_bubble_right</i
        >
      </div>
      <f7-popup
        class="demo-popup"
        :opened="popupCommentOpened"
        @popup:closed="popupCommentOpened = false"
      >
        <f7-page>
          <f7-navbar title="Comments">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-block>
            <comment
              :photoId="(photo || {}).id"
              :comments="(photo || {}).comments"
            ></comment>
          </f7-block>
        </f7-page>
      </f7-popup>
    </f7-page>
  </f7-popup>
</template>

<script>
// Load Style Code
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import { ImageEditor } from '@toast-ui/vue-image-editor';
import Comment from '../comment/Comment';
import { mapActions, mapGetters } from 'vuex';
import { urltoFile } from '@/utility/gmail-tool';
import ImageTools from '@/utility/image-tools';
import { convertUrlToBase64 } from '@/services/utils';
import { getImageDimensions } from '@/utility/common';

export default {
  components: {
    'tui-image-editor': ImageEditor,
    Comment,
  },
  data() {
    return {
      popupOpened: false,
      useDefaultUI: true,
      popupCommentOpened: false,
      options: {
        includeUI: {
          loadImage: {
            name: 'EditImage',
          },
          initMenu: 'draw',
        },
        usageStatistics: false,
        cssMaxWidth: 1000,
        cssMaxHeight: 600,
      },
    };
  },
  computed: {
    ...mapGetters('dashboard/photo', ['photo']),
  },
  methods: {
    ...mapActions('dashboard/photo', [
      'uploadBase64Photo',
      'uploadPhoto',
      'updateProjectPhoto',
      'removePhoto',
      'bindPhoto',
    ]),
    async open(photo) {
      const photoBase64 = await convertUrlToBase64(photo.photoUrl);
      this.options.includeUI.loadImage.path = photoBase64;
      if (!this.$device.desktop) {
        const { width, height } = await getImageDimensions(photo.photoUrl);
        const aspectRatio = width - height;
        if (aspectRatio > 0) {
          this.options.cssMaxHeight = 300;
        } else {
          this.options.cssMaxWidth = 600;
        }
      }
      this.bindPhoto(photo.id);
      this.popupOpened = true;
      setTimeout(() => {
        this.addImageObject(photo.photoUrl);
      }, 100);
    },
    close() {
      this.popupOpened = false;
    },
    cancel() {
      this.close();
    },

    async done() {
      this.$f7.preloader.show();
      if (this.photo.thumbnailFullPath)
        await this.removePhoto(this.photo.thumbnailFullPath);
      if (this.photo.photoFullPath)
        await this.removePhoto(this.photo.photoFullPath);

      let imageDataUrl = this.$refs.tuiImageEditor.editorInstance.toDataURL();
      const arr = imageDataUrl.split(',');
      const mimeType = arr[0].match(/:(.*?);/)[1];
      const file = await urltoFile(
        imageDataUrl,
        this.photo.photoName,
        mimeType
      );

      const app = this;
      let imgTool = new ImageTools(this.$f7.device);
      let photo = {};
      const name = file.name;
      imgTool
        .resize(file, { width: 256, height: 256 })
        .then(file => {
          if (!file.name) file.name = name;
          return app
            .uploadPhoto({ file, projectId: app.photo.projectId })
            .then(data => {
              photo = {
                thumbnailUrl: data.url,
                thumbnailFullPath: data.fullPath,
              };
              return photo;
            });
        })
        .then(res => {
          if (res.thumbnailUrl) {
            imgTool.resize(file, { width: 1920, height: 1080 }).then(file => {
              if (!file.name) file.name = name;
              app
                .uploadPhoto({ file, projectId: app.photo.projectId })
                .then(data => {
                  photo = {
                    ...photo,
                    photoUrl: data.url,
                    photoFullPath: data.fullPath,
                  };
                  app
                    .updateProjectPhoto({
                      id: this.photo.id,
                      doc: photo,
                    })
                    .then(() => {
                      this.$f7.preloader.hide();
                      this.close();
                    });
                });
            });
          }
        });
    },
    addImageObject(url) {
      this.$f7.preloader.show();
      this.$refs.tuiImageEditor.editorInstance
        .loadImageFromURL(url, 'image')
        .then(() => {
          this.$f7.preloader.hide();
        });
    },
    openCommentPopup() {
      this.popupCommentOpened = true;
    },
  },
};
</script>

<style>
div.tui-colorpicker-clearfix {
  display: inline-flex;
}
.tui-image-editor-header-logo,
.tui-image-editor-header-buttons {
  display: none;
}
.tie-btn-mask {
  display: none !important;
}
.tie-btn-filter {
  display: none !important;
}
.comment-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 99;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--f7-color-bg-12-neutral);
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid #939191 2px;
  display: none;
}

.comment-button:hover {
  background-color: #3d3b3b;
}

.tui-image-editor-align-wrap {
  overflow: hidden;
}
.image-edit-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.image-editor {
  flex: 7.5;
  height: 100%;
  position: relative;
}

.comment-section {
  height: 100%;
  overflow: scroll;
  flex: 2.5;
}
@media (min-width: 480px) and (max-width: 1200px) {
  .comment-section {
    flex: 3;
  }
}
/* mobile css */
@media (min-width: 320px) and (max-width: 480px) {
  /* disable function on mobile */
  .tui-image-editor-menu-rotate .tui-image-editor-range-wrap,
  .tui-image-editor-menu-shape .tui-image-editor-range-wrap,
  .tui-image-editor-menu-draw .tui-image-editor-range-wrap,
  .tui-image-editor-menu-text .tui-image-editor-range-wrap {
    display: none !important;
  }

  .tie-icon-add-button .tui-image-editor-button:nth-child(5),
  .tie-icon-add-button .tui-image-editor-button:nth-child(6),
  .tie-icon-add-button .tui-image-editor-button:nth-child(7),
  .tie-icon-add-button .tui-image-editor-button:nth-child(8) {
    display: none !important;
  }

  .tui-image-editor,
  .tui-image-editor-canvas-container,
  .lower-canvas,
  .upper-canvas {
    object-fit: cover !important;
  }
  .tui-image-editor-container .svg_ic-menu {
    width: 18px !important;
    height: 18px !important;
  }
  .tui-image-editor-item {
    padding: 7px 4px 3px 4px !important;
  }
  .tui-image-editor-container .svg_ic-submenu {
    width: 25px !important;
    height: 25px !important;
  }
  .tui-image-editor-container.bottom .tui-image-editor-submenu > div {
    padding-bottom: 5px !important;
  }
  .tui-image-editor-container .tui-image-editor-submenu {
    height: auto;
  }
  .tui-image-editor-partition {
    display: none !important;
  }

  .tui-image-editor-menu-crop .tui-image-editor-submenu-item {
    display: flex;
    flex-direction: column;
  }

  .tui-image-editor-button {
    margin: 0 4px 0 4px !important;
    font-size: 10px !important;
    min-width: 36px !important;
  }
  .tie-text-color .color-picker-control {
    top: -124px !important;
    left: -62vw !important;
    width: 52vw !important;
  }
  .tie-icon-color .color-picker-control {
    top: -124px !important;
    left: -64vw !important;
    width: 52vw !important;
  }

  .tie-color-stroke .color-picker-control {
    top: -124px !important;
    left: -50vw !important;
    width: 52vw !important;
  }

  .tie-draw-color .color-picker-control {
    top: -124px !important;
    left: -35vw !important;
    width: 52vw !important;
  }

  .color-picker-control .triangle {
    display: none !important;
  }

  .comment-button {
    display: flex;
  }
  .comment-section {
    display: none;
  }
}
/* iphone 5s,5c */
@media (min-width: 320px) and (max-width: 350px) {
  .tui-image-editor-item {
    padding: 5px 2px 3px 2px !important;
  }
}
</style>
