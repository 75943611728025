import { FirebaseActions } from '@/services/firebase.service';
import * as constant from '@/utility/const';

export default tenant => {
  const ProposalSectionActions = new FirebaseActions(
    `/system_client/${tenant}/proposal_section`,
    'proposalSection'
  );

  return {
    unbindProposalSectionList: ProposalSectionActions.unbindCollection,

    bindProposalSectionListBys: ProposalSectionActions.bindCollectionBys,
    createProposalSection: ProposalSectionActions.createDocument,
    updateProposalSection: ProposalSectionActions.updateDocument,

    deleteProposalSection({ dispatch }, id) {
      return dispatch('updateProposalSection', {
        id,
        doc: { [constant.PROP_IS_DELETED]: true },
      });
    },
  };
};
