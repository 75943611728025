<template>
  <div>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        label="Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
          },
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon
          slot="media"
          icon="timer"
        ></input-icon>
      </f7-list-input>
    </f7-list>
    <send-email-input
      title="Submittals Email"
      email-type="submittal"
      :default-subject="`${setting ? setting.companyName || '' : ''} - Submittals`"
      :default-message="defaultMessage"
      :projectId="displayCard.id"
      :value="displayCard.submittalEmails"
      :default-email-address="defaultEmailAddess"
      @input="setAndSave('submittalEmails', $event)"
    ></send-email-input>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <attachment-input
      :projectId="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAndSave('attachmentFiles', $event)"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="note = $event"
      :value="note"
      :isSaveButton="true"
      @onSave="
        onSaveNote({
          displayCard,
          title: 'Submittals Note',
          note,
          callback: () => {
            note = '';
          },
        })
      "
    ></note-editor>

    <date-popup
      ref="dueDatePopup"
      title="Enter Schedule of Value Date"
      label="Schedule of Value Due Date"
      v-model="scheduleValueDueDate"
      @done="resolveScheduleValue"
      validate
      not-allow-past
    ></date-popup>
  </div>
</template>

<script>
import UserInput from '../input/UserInput.vue';
import AttachmentInput from '../input/AttachmentInput.vue';
import SendEmailInput from '../input/SendEmailInput.vue';
import DatePopup from '../popup/DatePopup.vue';
import InputIcon from '../icon/InputIcon.vue';
import PurchaseOrderInput from '../input/PurchaseOrderInput.vue';

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString,
} from '../../../../utility/datetime';

import { firebase, auth } from '../../../../services/firebase.service';
import { mapState, mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import _ from 'lodash';
import methodsMixins from '../../mixin/methods';
import computedsMixins from '../../mixin/computeds';
import moment from 'moment';
import NoteEditor from '../note/NoteEditor.vue';

export default {
  mixins: [methodsMixins, computedsMixins],
  components: {
    UserInput,
    AttachmentInput,
    SendEmailInput,
    DatePopup,
    InputIcon,
    PurchaseOrderInput,
    NoteEditor,
  },

  data: () => {
    return {
      note: '',
      currentResponse: {},

      displayCard: {},
      dueDate: [],

      scheduleValueDueDate: [],
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
        this.getClientCompanyName();
      },
    },
  },

  computed: {
    ...mapState('dashboard/project', ['card', 'action', 'response', 'users']),
    ...mapGetters('setting/app/profile', { currentUser: 'user' }),
    ...mapGetters('setting/app/system', ['setting']),

    defaultMessage() {
      return `<div><div><strong>Sending on behalf of ${this.currentUser.displayName}</strong></div><div><small><i>(${this.currentUser.email})</i></small></div><div>&nbsp;</div></div>`;
    },
    ...mapGetters('common/app-constant', ['tenantId']),
  },
  methods: {
    ...mapActions('dashboard/project', ['updateCard']),
    ...mapActions('setting/app/system', ['bindSetting']),

    getClientCompanyName() {
      if (_.isEmpty(this.setting)) {
        this.$f7.preloader.show();
        this.bindSetting(this.tenantId).then(() => {
          this.$f7.preloader.hide();
        });
      }
    },

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.displayCard.retainage = this.displayCard.retainage || false;
      }
    },

    setAndSave(key, val) {
      Vue.set(this.displayCard, key, val);
      this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard,
      });
    },

    resolveScheduleValue() {
      this.dueDate = this.scheduleValueDueDate;
      this.resolve();
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case 'save-and-close':
          this.$f7.preloader.show();
          this.save();
          this.$emit('doClosePopup');
          this.$f7.preloader.hide();
          // this.currentResponse = response;
          // this.resolve();
          break;

        case 'move-to-schedule-value':
          this.currentResponse = response;
          this.$refs.dueDatePopup.open();
          break;
      }
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit('doResolve', this.currentResponse);
      this.$emit('doClosePopup');
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'Submittals Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });
      }

      this.displayCard.commentAndNote = commentAndNote;
      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard,
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            'day'
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) },
          });
        }
      }
    },
  },
};
</script>

<style></style>
