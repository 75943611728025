import * as types from './types';
import Vue from 'vue';

export default {
  // Fulltext search
  [types.SET_SEARCH_RESULT](
    state,
    { hits, hitsPerPage, nbHits, nbPages, page }
  ) {
    Vue.set(state, 'hits', hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.SET_NUMBER_OF_ROWS](state, payload) {
    Vue.set(state, 'hitsPerPage', payload);
  },
  [types.SET_QUERY_FILTER](state, payload) {
    Vue.set(state, 'queryFilters', payload);
  },
  [types.SET_SEARCH_TEXT](state, payload) {
    Vue.set(state, 'searchText', payload);
  },

  [types.SET_SEARCH_ORDER](state, payload) {
    Vue.set(state, 'order', payload);
  },

  [types.DELETE_SEARCH_ITEM](state, payload) {
    const deletedItem = state.hits.findIndex(item => item.id === payload);
    state.hits.splice(deletedItem, 1);
  },

  [types.RESET_SEARCH](state) {
    Vue.set(state, 'order', 'desc');
    Vue.set(state, 'searchText', '');

    Vue.set(state, 'hits', []);
    Vue.set(state, 'hitsPerPage', 25);
    Vue.set(state, 'nbHits', 0);
    Vue.set(state, 'nbPages', 0);
    Vue.set(state, 'page', 0);
  },

  [types.ADD_MORE_RESULT](state, { hits, hitsPerPage, nbHits, nbPages, page }) {
    state.hits.push(...hits);
    Vue.set(state, 'hitsPerPage', hitsPerPage);
    Vue.set(state, 'nbHits', nbHits);
    Vue.set(state, 'nbPages', nbPages);
    Vue.set(state, 'page', page);
  },

  [types.SET_ACTION_LIST](state, payload) {
    Vue.set(state, 'actionList', payload);
  },
  [types.SET_PROJECT_FILTER_FIELD](state, { field, value }) {
    Vue.set(state.projectFilter, field, value);
  },
  [types.SET_IS_HIDDEN_RES_JOB](state, payload) {
    Vue.set(state, 'isHiddenResJob', payload);
  },
};
