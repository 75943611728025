<template>
  <div
    draggable="true"
    @dragstart="handleDragStart()"
  >
    <f7-link
      class="card-item"
      :popover-open="`.info-popover_${item.id}`"
      :style="`background-color: ${item.color ? hexToRgba(item.color) : '#EFEDED'}`"
    >
      <div
        class="card-item_left"
        :style="`background-color: ${item.color || 'black'}`"
      ></div>
      <div class="card-item_right">
        <div class="title text-ellipsis">
          <span class="fw-600"
            >{{ item.startTime | HMMA }} - {{ item.finishTime | HMMA }}</span
          >
          <span v-if="item.timeLogType === TIME_LOG_TYPE_TIME_OFF">{{
            timeOffType.value
          }}</span>
          <span v-else-if="item.timeLogType === TIME_LOG_TYPE_PROJECT">{{
            item.project && item.project.title
          }}</span>
          <span v-else>{{ item.title }}</span>
        </div>
      </div>
    </f7-link>

    <f7-popover
      :backdrop="true"
      :close-by-backdrop-click="true"
      :close-by-outside-click="true"
      :class="`info-popover info-popover_${item.id}`"
    >
      <f7-card>
        <f7-card-header class="justify-content-space-between">
          <div>{{ item.user && item.user.displayName }}</div>
          <div class="display-flex align-item-center">
            <div v-if="item.scheduleChainId">
              <chain-popover
                :icon="checkCreator ? 'pencil' : 'eye'"
                popoverClass="popover-edit"
                @clickItem="openEditPopup($event)"
              ></chain-popover>
            </div>
            <div v-else>
              <f7-button
                class="flex-center"
                small
                :icon-f7="checkCreator ? 'pencil' : 'eye'"
                popover-close
                @click="openEditPopup('')"
              ></f7-button>
            </div>

            <div v-if="item.scheduleChainId && checkCreator">
              <chain-popover
                icon="trash"
                popoverClass="popover-delete"
                @clickItem="handleDelete($event)"
              ></chain-popover>
            </div>
            <div v-else-if="!item.scheduleChainId && checkCreator">
              <f7-button
                class="flex-center"
                small
                icon-f7="trash"
                popover-close
                @click="handleDelete('')"
              ></f7-button>
            </div>

            <f7-button
              class="flex-center"
              small
              icon-f7="doc_on_doc"
              popover-close
              @click="$emit('copy', item)"
            ></f7-button>
            <f7-button
              class="flex-center margin-left-half"
              small
              fill
              popover-close
              @click="handleAddPhoto"
              >Add Photo</f7-button
            >
            <f7-button
              v-if="isMyScheduling"
              class="flex-center margin-left-half"
              small
              fill
              popover-close
              @click="openTimeLogPopup"
              >Start</f7-button
            >
          </div>
        </f7-card-header>
        <f7-card-content>
          <div class="display-flex align-items-start margin-bottom-half">
            <f7-icon
              class="margin-right-half"
              f7="clock"
              color="red"
            ></f7-icon>
            <div>
              {{ item.startTime | HMMA }} - {{ item.finishTime | HMMA }}
            </div>
          </div>
          <div class="fw-600 display-flex align-items-start margin-bottom-half">
            <f7-icon
              class="margin-right-half"
              f7="cube_box"
              color="red"
            ></f7-icon>
            <div v-if="item.timeLogType === TIME_LOG_TYPE_TIME_OFF">
              {{ timeOffType.value }}
            </div>
            <div v-else-if="item.timeLogType === TIME_LOG_TYPE_PROJECT">
              <f7-link
                popover-close
                @click.native="goToProjectDetail(item.userId, item.projectId)"
              >
                <span
                  v-if="
                    item.project &&
                    (item.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
                      (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                        item.project.customerType === BUSINESS_CODE_COMMERCIAL))
                  "
                >
                  {{
                    item.project.propertyName
                      ? `${item.project.propertyName}`
                      : ''
                  }}{{
                    item.project.propertyName &&
                    getFullAddress(item.project.propertyAddress)
                      ? ' - '
                      : ''
                  }}{{ getFullAddress(item.project.propertyAddress) }}
                </span>

                <span
                  v-else-if="
                    item.project &&
                    (item.project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
                      (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                        (item.project.customerType ===
                          BUSINESS_CODE_RESIDENTIAL ||
                          item.project.customerType ===
                            BUSINESS_CODE_MULTIFAMILY)))
                  "
                >
                  {{
                    item.project.contactName
                      ? `${item.project.contactName}`
                      : ''
                  }}{{
                    item.project.contactName &&
                    getFullAddress(item.project.projectAddress)
                      ? ' - '
                      : ''
                  }}{{ getFullAddress(item.project.projectAddress) }}
                </span>
              </f7-link>
            </div>
            <div v-else>{{ item.title }}</div>
          </div>
          <div
            v-if="
              item.project &&
              item.timeLogType === TIME_LOG_TYPE_PROJECT &&
              ((getFullAddress(item.project.propertyAddress) &&
                (item.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
                  (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                    item.project.customerType === BUSINESS_CODE_COMMERCIAL))) ||
                (getFullAddress(item.project.projectAddress) &&
                  (item.project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
                    (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                      (item.project.customerType ===
                        BUSINESS_CODE_RESIDENTIAL ||
                        item.project.customerType ===
                          BUSINESS_CODE_MULTIFAMILY)))))
            "
            class="fw-600 display-flex align-items-start margin-bottom-half"
          >
            <f7-icon
              class="margin-right-half"
              f7="placemark"
              color="red"
            ></f7-icon>
            <f7-link
              v-if="
                item.project &&
                (item.project.businessCode === BUSINESS_CODE_COMMERCIAL ||
                  (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                    item.project.customerType === BUSINESS_CODE_COMMERCIAL))
              "
              popover-close
              @click.native="openAddressOnMap(item.project.propertyAddress)"
            >
              View Map
            </f7-link>
            <f7-link
              v-else-if="
                item.project &&
                (item.project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
                  (item.project.businessCode === BUSINESS_CODE_SERVICE &&
                    (item.project.customerType === BUSINESS_CODE_RESIDENTIAL ||
                      item.project.customerType === BUSINESS_CODE_MULTIFAMILY)))
              "
              popover-close
              @click.native="openAddressOnMap(item.project.projectAddress)"
            >
              View Map
            </f7-link>
          </div>
          <div
            v-if="item.notes"
            class="display-flex align-items-start"
          >
            <f7-icon
              class="margin-right-half"
              f7="square_pencil"
              color="red"
            ></f7-icon>
            <div
              class="text-notes"
              v-html="displayNotes"
            ></div>
          </div>
        </f7-card-content>
      </f7-card>
    </f7-popover>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ChainPopover from '../popover/ChainPopover.vue';
import { hexToRgba } from '../../../../utility/theme';
import {
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
  BUSINESS_CODE_MULTIFAMILY,
  COLLECTION_MY_SCHEDULING,
} from '../../../../utility/const';
import { getFullAddress } from '@/utility/address';
import commonMixin from '../../mixins/common';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    ChainPopover,
  },
  mixins: [commonMixin],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isDrag: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      BUSINESS_CODE_COMMERCIAL,
      BUSINESS_CODE_SERVICE,
      BUSINESS_CODE_RESIDENTIAL,
      BUSINESS_CODE_MULTIFAMILY,
    };
  },
  async mounted() {
    await this.bindTimeTrackingDraf([
      {
        prop: 'user_id',
        val: this.currentUser.uid,
        op: '==',
      },
    ]);
  },
  methods: {
    ...mapActions('scheduling/scheduling', [
      'setScheduleId',
      'setEditType',
      'setIsShowDetail',
      'getSchedulesByChainIdAndDate',
    ]),
    ...mapActions('scheduling/chain', ['getChain', 'resetChain']),
    ...mapActions('scheduling/project', ['getProject', 'resetProject']),
    ...mapMutations('time-tracking/time-tracking', ['openTimerPopup']),
    ...mapActions('time-tracking/time-tracking', ['setTimeLogInfoAvailable']),
    ...mapActions({
      bindTimeTrackingDraf: 'time-tracking/time-tracking-draf/bindTimerListBys',
    }),
    ...mapActions('common/notification', ['createNotificationByType']),
    hexToRgba(hex) {
      return hexToRgba(hex);
    },
    handleDragStart() {
      if (this.isDrag) {
        this.$emit('dragstart');
      }
    },
    convertDateToTimestamp(date) {
      return this.$google.firebase.firestore.Timestamp.fromDate(
        new Date(moment(date, 'MM/DD/YYYY').format('MM/DD/YYYY'))
      );
    },
    async openEditPopup(type) {
      this.setIsShowDetail(!this.checkCreator);
      this.setScheduleId(this.item.id);
      if (this.item.scheduleChainId) {
        this.resetChain();
        await this.getChain(this.item.scheduleChainId);
        switch (type) {
          case 'thisShift':
            await this.getSchedulesByChainIdAndDate({
              startDate: this.convertDateToTimestamp(this.schedule.date),
              endDate: this.convertDateToTimestamp(this.schedule.date),
              chainId: this.item.scheduleChainId,
            });
            break;
          case 'allShifts':
            await this.getSchedulesByChainIdAndDate({
              startDate: this.chain.startDate,
              endDate: this.chain.endDate,
              chainId: this.item.scheduleChainId,
            });
            break;
          case 'thisShiftAndAllRemaining':
            await this.getSchedulesByChainIdAndDate({
              startDate: this.convertDateToTimestamp(this.schedule.date),
              endDate: this.chain.endDate,
              chainId: this.item.scheduleChainId,
            });
            break;
        }
      }
      this.setEditType(type);
      this.$emit('openEditPopup');
    },

    async handleDelete(type) {
      this.setScheduleId(this.item.id);
      this.setEditType(type);
      if (this.item.scheduleChainId) {
        this.resetChain();
        await this.getChain(this.item.scheduleChainId);
        switch (type) {
          case 'thisShift':
            await this.getSchedulesByChainIdAndDate({
              startDate: this.convertDateToTimestamp(this.schedule.date),
              endDate: this.convertDateToTimestamp(this.schedule.date),
              chainId: this.item.scheduleChainId,
            });
            break;
          case 'allShifts':
            await this.getSchedulesByChainIdAndDate({
              startDate: this.chain.startDate,
              endDate: this.chain.endDate,
              chainId: this.item.scheduleChainId,
            });
            break;
          case 'thisShiftAndAllRemaining':
            await this.getSchedulesByChainIdAndDate({
              startDate: this.convertDateToTimestamp(this.schedule.date),
              endDate: this.chain.endDate,
              chainId: this.item.scheduleChainId,
            });
            break;
        }
      }
      this.$ri.dialog.openWarningDialog({
        title: 'Delete shift',
        content: 'Are you sure you want to delete?',
        textButton: 'Delete',
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            this.$f7.preloader.show();
            await this.createNotificationByType({
              data: {
                assignees: [this.item.userId],
                project: {
                  title: this.item.title,
                  id: this.item.projectId,
                  entityName: COLLECTION_MY_SCHEDULING,
                },
              },
              type: 'delete-shift-user',
            });
            this.$emit('handleDelete', type);
            this.$f7.preloader.hide();
            _sefl.app.dialog.close();
          }
        },
      });
    },
    handleAddPhoto() {
      this.$emit('openAlbumPhoto', this.item.projectId);
    },

    getFullAddress(address) {
      return getFullAddress(address);
    },

    goToProjectDetail(userId, projectId) {
      this.navigateToBoard(
        this.isMyScheduling ? 'my-scheduling' : 'employee-scheduling',
        userId,
        projectId
      );
    },

    openAddressOnMap(address) {
      this.$emit('openAddressOnMap', getFullAddress(address));
    },

    getValuesFromLocalStorage(keys, suffix) {
      const data = {};
      keys.forEach(key => {
        const value = localStorage.getItem(`${key}${suffix}`);
        if (value !== null) {
          data[key] = value;
        }
      });
      return data;
    },

    openTimeLogPopup() {
      //get data unsubmit

      let timeLogInfoUnsubmit = {
        timeLogType:
          this.lastDrafTimeTracking.timeLogTypeUnsubmittedService || '',
        projectId: this.lastDrafTimeTracking.projectIdUnsubmittedService || '',
        classItem: this.lastDrafTimeTracking.classItemUnsubmittedService || '',
        serviceItem:
          this.lastDrafTimeTracking.serviceItemUnsubmittedService || '',
        title: this.lastDrafTimeTracking.titleUnsubmittedService || '',
      };
      //if all value "" => set timeLogInfoUnsubmit = {}
      if (
        Object.keys(timeLogInfoUnsubmit).every(
          key => timeLogInfoUnsubmit[key] === ''
        )
      ) {
        timeLogInfoUnsubmit = {};
      }

      //show popup submit old time log when !isSubmitTimeLog && timeLogInfoUnsubmit
      //else => don't show popup
      if (
        !this.lastDrafTimeTracking.submitted &&
        !_.isEmpty(timeLogInfoUnsubmit)
      ) {
        this.$ri.dialog.openWarningDialog({
          title: 'Submit previous timelog',
          content:
            'Before logging a new time log, please submit your previous time log.',
          textButton: 'See Timelog',
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              this.openTimerPopup();
              this.setTimeLogInfoAvailable({ ...this.item });
            }
          },
        });
      } else {
        this.openTimerPopup();
        this.setTimeLogInfoAvailable({ ...this.item });
      }
    },
  },

  computed: {
    ...mapGetters('scheduling/scheduling', [
      'scheduleId',
      'isMyScheduling',
      'schedule',
    ]),
    ...mapGetters('scheduling/app-constant', ['timeOffTypeList']),
    ...mapGetters('scheduling/project', ['project']),
    ...mapGetters('scheduling/chain', ['chain']),
    ...mapGetters('setting/app/group', ['isAdminGroup']),
    ...mapGetters('setting/app/profile', ['currentUser']),
    ...mapGetters({
      time_tracking_drafList: 'time-tracking/time-tracking-draf/timerList',
    }),

    lastDrafTimeTracking() {
      if (
        !this.time_tracking_drafList &&
        this.time_tracking_drafList.length < 1
      )
        return null;
      return this.time_tracking_drafList[0];
    },
    timeOffType() {
      return (
        this.timeOffTypeList.find(r => r.value === this.item.timeOffType) || {}
      );
    },

    displayNotes() {
      return this.item.notes.replace(/\r\n|\r|\n/g, '<br />');
    },

    checkCreator() {
      return (
        this.item.createdBy ===
          (this.currentUser.displayName || this.currentUser.email) ||
        (this.currentUser.customClaims || {}).role === 'owner' ||
        this.isAdminGroup
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.card-item {
  display: block;
  color: black;
  position: relative;
  margin: 5px 2px;
  padding: 3px 0px;
  border-radius: 4px 0px 0px 4px;
  box-sizing: border-box;

  &_left {
    position: absolute;
    width: 7px;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 4px 0px 0px 4px;
  }

  &_right {
    padding-left: 9px;
  }
}
.card-item:hover {
  background-color: #f1f1f1 !important;
}
.title {
  font-size: 12px;
}
.fw-600 {
  font-weight: 600;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 100%;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-popover {
  width: 500px;
}
.text-notes {
  max-height: 250px;
  width: 100%;
  overflow-y: auto;
}
</style>
