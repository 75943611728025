<template>
  <div></div>
</template>
<script>
export default {
  data: () => {
    return {
      value: 0,
      isDarkMode: false,
      progressBar: null,
    };
  },
  created() {
    this.$nextTick(() => {
      localStorage.getItem('themeDark') === 'true'
        ? (this.isDarkMode = true)
        : (this.isDarkMode = false);
    });
  },
  methods: {
    show() {
      this.value = 0;
      this.progressBar = this.$f7.dialog.create({
        title: "Uploading...",
        content: `<span class="loader-upload-processing"></span>`,
      })
      .open();
    },
    hide() {
      this.$f7.dialog.close();
      this.value = 0;
    },
    setText(text) {
      const htmlTitle = `
      <div style="display: flex; flex-direction: column; align-items: center; gap: 3px;">
        <span>Uploading....</span>
        ${text}
      </div>
      `
      this.progressBar.setTitle(htmlTitle); 
    },
    setValue(value) {
      this.value = value;
    },
  },

};
</script>
<style lang="scss" scoped>
.progress-bar-container {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: var(--f7-theme-color);
  border-radius: 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
  .progress-bar-container {
    width: 60%;
    bottom: 70px;
  }
}
</style>
