<template>
  <div class="files-tab">
    <!-- segmented buttons -->
    <f7-block>
      <f7-segmented
        strong
        tag="p"
      >
        <f7-button
          :active="activeButton === 'document'"
          @click.native="activeButton = 'document'"
          >Document</f7-button
        >
        <f7-button
          :active="activeButton === 'photo'"
          @click.native="activeButton = 'photo'"
          >Project Photo</f7-button
        >
      </f7-segmented>
    </f7-block>

    <div class="tab-content-height">
      <div
        class="popup-scroll-bar"
        v-show="activeButton === 'document'"
      >
        <attachment-input
          :projectId="detail.id"
          title="Attachments"
          attachment-type="attachment"
          add-button-title="Add an Attachment"
          :value="detail.attachmentFiles"
          @input="changeCardValue('attachmentFiles', $event)"
        ></attachment-input>
      </div>
      <div
        v-show="activeButton === 'photo'"
        style="height: 100%; position: relative"
      >
        <project-photo :loading="loadingPhoto"></project-photo>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectPhoto from '../photo/ProjectPhoto.vue';
import AttachmentInput from '../inputs/AttachmentInput.vue';

export default {
  components: { ProjectPhoto, AttachmentInput },

  data() {
    return {
      activeButton: 'document',
      loadingPhoto: false,
    };
  },

  computed: {
    ...mapGetters({
      detail: 'swimlane/card/detail',
    }),
  },

  methods: {
    ...mapActions('swimlane/album', ['getProjectPhotoAlbumListByProject']),

    ...mapActions({
      update: 'swimlane/card/update',
    }),

    init() {
      this.loadPhoto();
    },

    changeCardValue(field, value) {
      this.update({
        id: this.detail.id,
        doc: {
          [field]: value,
        },
      });
    },

    loadPhoto() {
      if (this.detail.id) {
        this.loadingPhoto = true;

        this.getProjectPhotoAlbumListByProject(this.detail.id).then(() => {
          this.loadingPhoto = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.files-tab {
  height: calc(100% - var(--f7-block-margin-vertical));
}
.scoll-bar {
  height: calc(88%);
  position: relative;
  overflow: auto;
}
.tab-content-height {
  height: calc(
    100% - var(--f7-block-margin-vertical) - var(--f7-button-height)
  );
}
</style>
