<template>
  <f7-popup
    class="demo-popup"
    :opened="popupAddNewProductItem"
    @popup:closed="$emit('close')"
    @popup:opened="handlePopupOpened"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>Add New Product Item</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="addProductItem">Add</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <data-table
        :headers="headers"
        :items="productItemListComputed"
        :pageSize="productItemListComputed.length"
        :checkbox="true"
        @selected:change="checkBoxChange"
        @callBackChangePage="createColorPhotoTooltip"
        class="add-new-po-product-table"
      >
        <template slot="card-header">
          <f7-list>
            <f7-searchbar
              placeholder="Search in items"
              :clear-button="true"
              disable-button-text
              @input="searchtext = $event.target.value"
              @searchbar:disable="searchtext = ''"
              ref="searchProductItemPopup"
            ></f7-searchbar>
          </f7-list>
        </template>
        <template v-slot:body="{ item }">
          <td>{{ item.vendorName }}</td>
          <td>{{ item.manufacturer }}</td>
          <td>{{ item.subCategoryName }}</td>
          <td>{{ item.sku }}</td>
          <td>{{ item.productItem }}</td>
          <td>
            <f7-link
              v-for="(color, index) in item.colorPhotos || []"
              :key="index"
              @click.native.stop="clickPhoto(color)"
              :id="`${color.id}-${item.id}`"
            >
              {{
                `${color.colorName}${
                  index + 1 === (item.colorPhotos || []).length ? '' : ',&nbsp;'
                }`
              }}
            </f7-link>
          </td>
          <td>{{ item.packaging }}</td>
          <td>{{ item.unitPack }}</td>
          <td>
            {{
              item.unitSize && item.uom && item.packaging
                ? `${item.unitSize} ${item.uom}/${item.packaging}`
                : ''
            }}
          </td>
          <td>{{ item.uom }}</td>
          <td>{{ item.price | currencyUSD }}</td>
        </template>
      </data-table>
      <f7-photo-browser
        :photos="productPhotos"
        theme="dark"
        :ref="`detail_pageDark`"
        type="popup"
      ></f7-photo-browser>
    </f7-page>
  </f7-popup>
</template>

<script>
import DataTable from '@/components/datatables';
import { applyFilter } from '@/utility/filter-tools';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    DataTable,
  },

  props: {
    popupAddNewProductItem: Boolean,
    productItemList: Array,
    existingProductItems: Array,
  },

  data: () => ({
    headers: [
      {
        text: 'Vendor',
        align: 'left',
        sortable: false,
        value: 'vendor',
      },
      {
        text: 'Manufacturer',
        align: 'left',
        sortable: false,
        value: 'manufacturer',
      },
      {
        text: 'Sub-Product Category',
        value: 'sub_category',
        sortable: false,
        align: 'left',
      },
      {
        text: 'SKU',
        value: 'sku',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Product Item',
        value: 'productItem',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Color',
        value: 'color',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Packaging',
        value: 'packaging',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Unit Packs',
        value: 'unitPack',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Unit Size',
        value: 'unitSize',
        sortable: false,
        align: 'left',
        width: '150%',
      },
      {
        text: 'UoM',
        value: 'UoM',
        sortable: false,
        align: 'left',
      },
      {
        text: 'Price',
        value: 'price',
        sortable: false,
        align: 'left',
      },
    ],

    searchtext: '',
    productPhotos: [],
    selectedProducts: [],
  }),

  created() {
    if (_.isEmpty(this.subCategoryList)) {
      this.bindSubCategoryList();
    }
  },

  computed: {
    ...mapGetters('dashboard/client', ['companyById']),
    ...mapGetters('dashboard/sub-category', ['subCategoryList']),
    /**
     * productItemListComputed
     */
    productItemListComputed() {
      const filteredProducts = applyFilter(
        this.productItemList,
        this.searchtext,
        [
          'productItem',
          'sku',
          'manufacturer',
          'uom',
          i => this.subCategoryName(i.subCategoryId),
          i => (this.companyById(i.vendorId) || {}).companyName,
          i => i.color,
        ]
      );
      return filteredProducts.map(product => ({
        ...product,
        checked: this.selectedProducts.some(p => p.id === product.id),
      }));
    },
  },

  methods: {
    ...mapActions('dashboard/sub-category', ['bindSubCategoryList']),
    /**
     * addProductItem
     */
    addProductItem() {
      this.$emit('onAddProductItems', this.selectedProducts);
    },

    /**
     * showNotification
     */
    showNotification(subtitle, text) {
      const self = this;
      if (!self.notificationFull) {
        self.notificationFull = self.$f7.notification.create({
          icon: '<i class="icon icon-f7"></i>',
          title: 'RooferIntel',
          titleRightText: 'now',
          subtitle,
          text,
          closeTimeout: 3000,
        });
      }
      self.notificationFull.open();
    },

    subCategoryName(subCategoryId) {
      const subCategory = (this.subCategoryList || []).find(
        r => r.id == subCategoryId
      );
      return (subCategory || {}).name;
    },

    handlePopupOpened() {
      this.selectedProducts = _.cloneDeep(this.existingProductItems);
      if (this.$device.desktop) {
        this.$refs.searchProductItemPopup.$el
          .querySelector('.searchbar input')
          .focus();
      }
    },
    clickPhoto(item) {
      this.productPhotos = (item || {}).photos || [];
      if (this.productPhotos.length > 0) {
        setTimeout(() => this.$refs[`detail_pageDark`].open(0), 100);
      }
    },

    createColorPhotoTooltip() {
      this.$nextTick(() => {
        this.productItemListComputed.forEach(product => {
          (product.colorPhotos || []).forEach(color => {
            const el = this.$f7.tooltip.get(`#${color.id}-${product.id}`);
            if (el) {
              this.$f7.tooltip.destroy(`#${color.id}-${product.id}`);
            }
            const photo = (color.photos || [])[0];
            const tooltipContent = photo
              ? `<img class="tooltip-image-show" src="${photo.thumbnailUrl}" />`
              : `<span>No image !</span>`;
            this.$f7.tooltip.create({
              targetEl: `#${color.id}-${product.id}`,
              cssClass: photo ? 'tooltip-image-preview' : '',
              text: tooltipContent,
            });
          });
        });
      });
    },

    checkBoxChange(data) {
      const foundedItem = this.selectedProducts.find(
        item => item.id === data.id
      );
      // remove unchecked item
      if (foundedItem && data.checked === false) {
        this.selectedProducts = this.selectedProducts.filter(
          item => item.id !== foundedItem.id
        );
      }
      // add checked item
      else if (!foundedItem && data.checked === true) {
        const productToAdd = this.productItemList.find(
          item => item.id === data.id
        );
        if (productToAdd) {
          this.selectedProducts.push({ ...productToAdd, checked: true });
        }
      }

      // foundedItem && data.checked === true => do notthing
    },
  },
  updated() {
    this.createColorPhotoTooltip();
  },
};
</script>
<style lang="scss" scoped>
.add-new-po-product-table ::v-deep table {
  table-layout: fixed;
}
.add-new-po-product-table::v-deep thead tr {
  // Product Item
  th:nth-child(1) {
    width: 50px;
  }
  // Color
  th:nth-child(2) {
    width: 80px;
  }
  // Manufacturer
  th:nth-child(3) {
    width: 100px;
  }
  // Technical Data
  th:nth-child(4) {
    width: 150px;
  }
  // Waste Factor
  th:nth-child(5) {
    width: 150px;
  }
  // Qty
  th:nth-child(6) {
    width: 100px;
  }
  // Package Qty
  th:nth-child(7) {
    width: 100px;
  }
  // Price
  th:nth-child(8) {
    width: 80px;
  }
  // Total Cost
  th:nth-child(9) {
    width: 80px;
  }
  // Price Override
  th:nth-child(10) {
    width: 150px;
  }
  // Action
  th:nth-child(11) {
    width: 80px;
  }
  th:nth-child(12) {
    width: 100px;
  }
  td {
    word-wrap: break-word;
  }
}
</style>
