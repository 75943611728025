<template>
  <div>
    <div
      :class="typeSwitchScreen !== 'step' && 'container-info'"
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.INFO
        )
      "
    >
      <f7-block-title>Roof and Substrate</f7-block-title>
      <f7-list :inset="$device.desktop">
        <div
          :class="
            typeSwitchScreen === 'step' && $device.desktop
              ? 'container-info-step-row'
              : 'container-info-step-column'
          "
        >
          <div style="flex: 1">
            <f7-list
              v-show="
                currentBuilding.workType === 'recover' ||
                currentBuilding.workType === 'tear-off'
              "
            >
              <f7-list-input
                type="select"
                label="What is the existing roof thickness? (inches)"
                :value="currentBuilding.roofThickness || ''"
                @change="
                  saveBuildingValue('roofThickness', $event.target.value)
                "
                error-message-force
                validate
                validate-on-blur
                :error-message="roofThicknessErrorMessage"
              >
                <input-icon
                  slot="media"
                  icon="expand"
                ></input-icon>
                <option
                  value=""
                  hidden
                >
                  Select roof thickness
                </option>
                <option
                  v-for="item in roofThicknessList"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.displayName }}
                </option>
              </f7-list-input>
            </f7-list>

            <f7-list>
              <f7-list-input
                type="select"
                :label="`What type of substrate?${
                  currentBuilding.slopeType == 'steep-slope'
                    ? ' (optional)'
                    : ''
                }`"
                :value="currentBuilding.substrate || ''"
                @change="onChangeSubtrate($event.target.value)"
                error-message-force
                validate
                validate-on-blur
                :error-message="substrateErrorMessage"
              >
                <input-icon
                  slot="media"
                  icon="map"
                ></input-icon>
                <option
                  value=""
                  :hidden="!(currentBuilding.slopeType == 'steep-slope')"
                >
                  Select type of substrate
                </option>
                <option
                  v-for="item in substrateList"
                  :key="item.id"
                  :value="item.value"
                >
                  {{ item.displayName }}
                </option>
              </f7-list-input>
            </f7-list>
          </div>
          <div style="flex: 1">
            <attachment-input
              v-if="currentBuilding.workType != WORK_TYPE_NEW"
              :add-button-title="`Existing Roof Photos`"
              :estimateId="currentBuilding.estimateId"
              :buildingId="currentBuilding.id"
              attachment-type="attachment"
              :value="currentBuilding.attachmentFiles"
              @input="saveBuildingValue('attachmentFiles', $event)"
            ></attachment-input>
          </div>
        </div>
      </f7-list>

      <f7-block-title>Measurement Information</f7-block-title>
      <f7-list :inset="$device.desktop">
        <div
          :class="
            typeSwitchScreen === 'step' && $device.desktop
              ? 'container-info-step-row'
              : 'container-info-step-column'
          "
        >
          <div style="flex: 1">
            <f7-list>
              <f7-list-item header="Field Area (SQ)">
                <f7-input
                  slot="title"
                  placeholder="Enter Field Area"
                  :value="currentBuilding.fieldArea"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'fieldArea',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    });
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                    });
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_FASTENERS,
                    });
                    updateQtyLabor({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_LABOR,
                      fieldArea: currentBuilding.fieldArea,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('fieldArea', 'Area', [
                      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                      SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                      SECTION_TYPE_SHINGLE_FASTENERS,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>
              <f7-list-item header="Ridge Length (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Ridge Length"
                  :value="currentBuilding.ridgeLength"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'ridgeLength',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    }).then(() => {
                      updateProducts({
                        roofType: ROOF_TYPE_SHINGLE,
                        sectionId: SECTION_TYPE_SHINGLE_LABOR,
                      });
                    });

                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_VENTS,
                    });
                  "
                >
                </f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('ridgeLength', 'Linear', [
                      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                      SECTION_TYPE_SHINGLE_VENTS,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>

              <f7-list-item header="Hip Length (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Hip Length"
                  :value="currentBuilding.hipLength"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'hipLength',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    }).then(() => {
                      updateProducts({
                        roofType: ROOF_TYPE_SHINGLE,
                        sectionId: SECTION_TYPE_SHINGLE_LABOR,
                      });
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('hipLength', 'Linear', [
                      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>
            </f7-list>
          </div>

          <div style="flex: 1">
            <f7-list>
              <f7-list-item header="Eave (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Eave"
                  :value="currentBuilding.eaveLength"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'eaveLength',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    setField(
                      'perimeter',
                      Math.abs(parseFloat($event.target.value.trim()) || 0) +
                        (currentBuilding.rakeLength || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    }).then(() => {
                      updateProducts({
                        roofType: ROOF_TYPE_SHINGLE,
                        sectionId: SECTION_TYPE_SHINGLE_LABOR,
                      });
                    });
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('eaveLength', 'Linear', [
                      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                      SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>
              <f7-list-item header="Rake (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Rake"
                  :value="currentBuilding.rakeLength"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'rakeLength',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    setField(
                      'perimeter',
                      (currentBuilding.eaveLength || 0) +
                        Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    }).then(() => {
                      updateProducts({
                        roofType: ROOF_TYPE_SHINGLE,
                        sectionId: SECTION_TYPE_SHINGLE_LABOR,
                      });
                    });
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('rakeLength', 'Linear', [
                      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                      SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>

              <f7-list-input
                disabled
                label="Perimeter (LF)"
                placeholder="Enter Perimeter"
                :value="currentBuilding.perimeter"
                type="number"
                min="0"
              >
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
              </f7-list-input>

              <f7-list-item header="Valley Length (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Valley Length"
                  :value="currentBuilding.valleyLength"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'valleyLength',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                    });

                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('valleyLength', 'Linear', [
                      SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                      SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>
            </f7-list>
          </div>
        </div>
      </f7-list>

      <f7-block-title>Leads and Vents</f7-block-title>
      <f7-list :inset="$device.desktop">
        <div
          :class="
            typeSwitchScreen === 'step' && $device.desktop
              ? 'container-info-step-row'
              : 'container-info-step-column'
          "
        >
          <div style="flex: 1">
            <f7-list>
              <f7-list-item header="Ridge Length (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Ridge Length"
                  :value="currentBuilding.ridgeLength"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'ridgeLength',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    });

                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_VENTS,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('ridgeLength', 'Linear', [
                      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                      SECTION_TYPE_SHINGLE_VENTS,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>

              <f7-list-item>
                <input-icon
                  slot="media"
                  icon="bandage_fill"
                ></input-icon>
                <span>Ridge Vent</span>
                <f7-toggle
                  :checked="!!currentBuilding.ridgeVent"
                  @toggle:change="onRidgeVentToggleChange"
                ></f7-toggle>
              </f7-list-item>
            </f7-list>
          </div>
        </div>
      </f7-list>

      <f7-block-title>Miscellaneous and Labor</f7-block-title>
      <f7-list :inset="$device.desktop">
        <div
          :class="
            typeSwitchScreen === 'step' && $device.desktop
              ? 'container-info-step-row'
              : 'container-info-step-column'
          "
        >
          <div style="flex: 1">
            <f7-list>
              <f7-list-item
                width="100"
                header="Number of Skylight"
              >
                <f7-input
                  slot="title"
                  type="number"
                  min="0"
                  step="1"
                  :value="currentBuilding.numberOfSkylight || 0"
                  @change="
                    setField(
                      'numberOfSkylight',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );

                    updateProducts({
                      roofType: currentRoofType,
                      sectionId: SECTION_TYPE_TPO_MISCELLANEOUS,
                    });

                    updateProducts({
                      roofType: currentRoofType,
                      sectionId: SECTION_TYPE_TPO_LABOR,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="number_square"
                ></input-icon>
                <!-- <span slot="header" class="input-title-desc">
                (Need to double if Overflow Drain is checked)
              </span> -->
              </f7-list-item>
            </f7-list>
          </div>
          <div style="flex: 1"></div>
        </div>
      </f7-list>
    </div>
    <div :class="typeSwitchScreen !== 'step' && 'container-info'">
      <f7-list
        :inset="$device.desktop"
        v-if="
          checkShowContentScreenSingleInEstimate(
            showContentType,
            CONTENT_TYPE_EST.INFO
          )
        "
      >
        <div
          :class="
            typeSwitchScreen === 'step' && $device.desktop
              ? 'container-info-step-row'
              : 'container-info-step-column'
          "
        >
          <div style="flex: 1">
            <f7-list>
              <f7-list-item header="Side Wall (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Side Wall"
                  :value="currentBuilding.sideWall"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'sideWall',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="app_badge"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('sideWall', 'Linear', [
                      SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>

              <f7-list-item header="Head Wall (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Head Wall"
                  :value="currentBuilding.headWall"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'headWall',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="app_badge"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('headWall', 'Linear', [
                      SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>

              <f7-list-item header="Eave (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Eave"
                  :value="currentBuilding.eaveLength"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'eaveLength',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    setField(
                      'perimeter',
                      Math.abs(parseFloat($event.target.value.trim()) || 0) +
                        (currentBuilding.rakeLength || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    });
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('eaveLength', 'Linear', [
                      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                      SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>

              <f7-list-item header="Rake (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Rake"
                  :value="currentBuilding.rakeLength"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'rakeLength',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    setField(
                      'perimeter',
                      (currentBuilding.eaveLength || 0) +
                        Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    });
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('rakeLength', 'Linear', [
                      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                      SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>
            </f7-list>
          </div>
          <div style="flex: 1">
            <f7-list>
              <f7-list-input
                disabled
                label="Perimeter (LF)"
                placeholder="Enter Perimeter"
                :value="currentBuilding.perimeter"
                type="number"
                min="0"
              >
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
              </f7-list-input>

              <f7-list-item header="Chimney">
                <f7-input
                  slot="title"
                  placeholder="Enter Chimney"
                  :value="currentBuilding.chimney"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'chimney',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="app_badge"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('chimney', 'Count', [
                      SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>

              <f7-list-item header="Valley Length (LF)">
                <f7-input
                  slot="title"
                  placeholder="Enter Valley Length"
                  :value="currentBuilding.valleyLength"
                  type="number"
                  min="0"
                  @change="
                    setField(
                      'valleyLength',
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                    );
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                    });

                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                    });
                  "
                ></f7-input>
                <input-icon
                  slot="media"
                  icon="arrow_right_square"
                ></input-icon>
                <f7-button
                  v-if="currentBuilding.stackctIntegration"
                  raised
                  small
                  class="display-flex align-items-center"
                  slot="after"
                  @click="
                    openSelectValue('valleyLength', 'Linear', [
                      SECTION_TYPE_SHINGLE_PREFAB_METAL,
                      SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                    ])
                  "
                  icon-f7="arrow_down_square"
                >
                  &nbsp;&nbsp;StackCT
                </f7-button>
              </f7-list-item>
            </f7-list>
          </div>
        </div>
      </f7-list>

      <section-product-table
        v-if="
          checkShowContentScreenSingleInEstimate(
            showContentType,
            CONTENT_TYPE_EST.TABLE
          )
        "
        :items="tableData(SECTION_TYPE_SHINGLE_PREFAB_METAL)"
        :roofType="ROOF_TYPE_SHINGLE"
        :sectionId="SECTION_TYPE_SHINGLE_PREFAB_METAL"
        @onChangeProductItem="
          onChangeProductItem(
            ROOF_TYPE_SHINGLE,
            SECTION_TYPE_SHINGLE_PREFAB_METAL,
            $event
          )
        "
      ></section-product-table>
    </div>
    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_SHINGLE_MAIN_SHINGLE)"
      :roofType="ROOF_TYPE_SHINGLE"
      :sectionId="SECTION_TYPE_SHINGLE_MAIN_SHINGLE"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_SHINGLE,
          SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_SHINGLE_UNDERLAYMENT)"
      :roofType="ROOF_TYPE_SHINGLE"
      :sectionId="SECTION_TYPE_SHINGLE_UNDERLAYMENT"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_SHINGLE,
          SECTION_TYPE_SHINGLE_UNDERLAYMENT,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_SHINGLE_FASTENERS)"
      :roofType="ROOF_TYPE_SHINGLE"
      :sectionId="SECTION_TYPE_SHINGLE_FASTENERS"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_SHINGLE,
          SECTION_TYPE_SHINGLE_FASTENERS,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_SHINGLE_VENTS)"
      :roofType="ROOF_TYPE_SHINGLE"
      :sectionId="SECTION_TYPE_SHINGLE_VENTS"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_SHINGLE,
          SECTION_TYPE_SHINGLE_VENTS,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_SHINGLE_LEADS)"
      :roofType="ROOF_TYPE_SHINGLE"
      :sectionId="SECTION_TYPE_SHINGLE_LEADS"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_SHINGLE,
          SECTION_TYPE_SHINGLE_LEADS,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_TPO_MISCELLANEOUS)"
      :roofType="currentRoofType"
      :sectionId="SECTION_TYPE_TPO_MISCELLANEOUS"
      :alertMessage="alertMessage"
      @onChangeProductItem="
        onChangeProductItem(
          currentRoofType,
          SECTION_TYPE_TPO_MISCELLANEOUS,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_TPO_LABOR)"
      :roofType="currentRoofType"
      :sectionId="SECTION_TYPE_TPO_LABOR"
      @onChangeProductItem="
        onChangeProductItem(currentRoofType, SECTION_TYPE_TPO_LABOR, $event)
      "
    ></section-product-table>

    <!-- Takeoff select value -->
    <dynamic-single-select
      ref="selectValue"
      search-placeholder="Search in Takeoffs"
      title="Takeoffs from StackCT"
      :options="takeoffListOptions"
      @changed="changeBuildingValue"
    >
    </dynamic-single-select>
  </div>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import SectionProductTable from '../tables/SectionProductTable.vue';
import DynamicSingleSelect from '../inputs/DynamicSingleSelect.vue';

import AttachmentInput from '../inputs/AttachmentInput.vue';

import { mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import mixins from '../../utility/mixins';
import buildingMixins from '../../utility/building-mixins';

import {
  WORK_TYPE_NEW,
  ROOF_TYPE_SHINGLE,
  SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
  SECTION_TYPE_SHINGLE_UNDERLAYMENT,
  SECTION_TYPE_SHINGLE_FASTENERS,
  SECTION_TYPE_SHINGLE_VENTS,
  SECTION_TYPE_SHINGLE_PREFAB_METAL,
  SECTION_TYPE_SHINGLE_LABOR,
  SECTION_TYPE_SHINGLE_LEADS,
  SECTION_TYPE_TPO_MISCELLANEOUS,
  SECTION_TYPE_TPO_LABOR,
  JOB_TYPE_NEW,
  CONTENT_TYPE_EST,
} from '../../../../utility/const';
import { checkShowContentScreenSingleInEstimate } from '@/utility/common';

export default {
  components: {
    InputIcon,
    AttachmentInput,
    SectionProductTable,
    DynamicSingleSelect,
  },

  props: {
    roofType: String,
    showContentType: {
      type: String,
      default: 'all',
    },
  },

  mixins: [mixins, buildingMixins],

  data() {
    return {
      WORK_TYPE_NEW,
      ROOF_TYPE_SHINGLE,
      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
      SECTION_TYPE_SHINGLE_UNDERLAYMENT,
      SECTION_TYPE_SHINGLE_FASTENERS,
      SECTION_TYPE_SHINGLE_VENTS,
      SECTION_TYPE_SHINGLE_PREFAB_METAL,
      SECTION_TYPE_SHINGLE_LABOR,
      SECTION_TYPE_SHINGLE_LEADS,
      CONTENT_TYPE_EST,

      SECTION_TYPE_TPO_MISCELLANEOUS,
      SECTION_TYPE_TPO_LABOR,
      JOB_TYPE_NEW,
    };
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    currentBuilding: {
      roofThickness: {
        required(val) {
          return (
            (val &&
              (this.workType === 'recover' || this.workType === 'tear-off')) ||
            this.workType === 'new'
          );
        },
      },
      substrate: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters('common/app-constant', [
      'roofThicknessList',
      'substrateList',
    ]),
    ...mapGetters('estimate/estimate-page/project', ['project']),
    ...mapGetters('estimate/estimate-page/estimate', [
      'currentBuilding',
      'estimate',
      'typeSwitchScreen',
    ]),

    roofThicknessErrorMessage() {
      return '';
    },

    substrateErrorMessage() {
      return '';
    },

    workType() {
      return this.currentBuilding.workType || '';
    },

    currentRoofType() {
      return (this.currentBuilding || {}).roofType;
    },

    alertMessage() {
      if (
        this.project &&
        this.project.jobType !== JOB_TYPE_NEW &&
        this.project.numberOfRoofSytem === 1 &&
        this.project.looseGravel === true
      ) {
        return 'Alert: A Vacuum truck is needed due to loose gravel on the roof';
      } else if (
        this.project &&
        this.project.jobType !== JOB_TYPE_NEW &&
        this.project.numberOfRoofSytem > 1
      ) {
        return `Tear of labor and dumpsters are needed to remove ${this.project.numberOfRoofSytem} existing roof systems`;
      }
      return '';
    },
  },

  methods: {
    validate() {
      this.v$.$touch();
      return false;
    },

    init() {},

    checkShowContentScreenSingleInEstimate,

    changeBuildingValue(property, value, sections = []) {
      if (!property) return;
      this.setField(property, value || 0);
      // update dependency data
      if (property == 'eaveLength' || property == 'rakeLength') {
        const perimeter =
          (parseFloat(this.currentBuilding.eaveLength) || 0) +
          (parseFloat(this.currentBuilding.rakeLength) || 0);
        this.setField('perimeter', perimeter);
      }
      // update items of sections
      sections.forEach(sectionId => {
        this.updateProducts({
          sectionId,
          roofType: ROOF_TYPE_SHINGLE,
        });
      });
    },

    openSelectValue(propery, type, sections, dependDataCallback = null) {
      this.$refs.selectValue.openSelectPopup(
        propery,
        type,
        sections,
        dependDataCallback
      );
    },

    onChangeSubtrate(value) {
      this.saveBuildingValue('substrate', value);
    },

    onRidgeVentToggleChange(value) {
      this.setField('ridgeVent', value);
      this.updateProducts({
        roofType: ROOF_TYPE_SHINGLE,
        sectionId: SECTION_TYPE_SHINGLE_VENTS,
      });
    },
  },
};
</script>

<style lang="scss">
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}

.container-info {
  background-color: #000;
  padding: 1px 0 16px 0;
}

.container-info-step-row {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.container-info-step-column {
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>
