<template>
  <f7-row>
    <f7-col :width="$device.desktop ? 70 : 100">
      <f7-list
        :class="{
          'list-inline-center': $device.desktop,
          'list-inline-center-mobile': !$device.desktop,
        }"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Contact Name</div>
          <div class="content">{{ project.contactName }}</div>
        </f7-list-item>
        <f7-list-item
          v-for="(phone, index) in (contact || {}).phones"
          :key="`phone-${index}`"
        >
          <div class="title">Phone</div>
          <div class="content">{{ phone.value }}</div>
        </f7-list-item>
        <f7-list-item
          v-for="(email, index) in (contact || {}).emails"
          :key="`email-${index}`"
        >
          <div class="title">Email</div>
          <div class="content">{{ email.value }}</div>
        </f7-list-item>
        <f7-list-item
          v-for="(address, index) in (contact || {}).addresss"
          :key="`address-${index}`"
        >
          <div class="title">Address</div>
          <div class="content">{{ getFullAddress(address) }}</div>
        </f7-list-item>
      </f7-list>
      <f7-list
        :class="{
          'list-inline-center': $device.desktop,
          'list-inline-center-mobile': !$device.desktop,
        }"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Company Name</div>
          <div class="content">{{ project.companyName }}</div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Property Name</div>
          <div class="content">{{ project.propertyName }}</div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Property Address</div>
          <div class="content">
            {{ getFullAddress(project.propertyAddress) }}
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list
        :class="{
          'list-inline-center': $device.desktop,
          'list-inline-center-mobile': !$device.desktop,
        }"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Site Visit Date</div>
          <div class="content">
            {{ toDisplayDateString(project.siteVisitDate) }}
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Job Type</div>
          <div class="content">{{ project.jobType }}</div>
        </f7-list-item>
        <template v-for="(building, index) in project.buildings">
          <f7-list-item :key="index">
            <div class="title">System # {{ index + 1 }}</div>
            <div class="content">{{ building.buildingName }}</div>
          </f7-list-item>
          <f7-list-item :key="`${index}_`">
            <div class="title">Number of Building/Sections</div>
            <div class="content">{{ building.numberOfCopy }}</div>
          </f7-list-item>
        </template>
        <f7-list-item>
          <div class="title">Number of Roof Systems</div>
          <div class="content">{{ project.numberOfRoofSytem }}</div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Loose Gravel</div>
          <div class="content">
            <f7-toggle
              disabled
              :checked="project.looseGravel"
            ></f7-toggle>
          </div>
        </f7-list-item>
      </f7-list>
    </f7-col>
    <f7-col :width="$device.desktop ? 30 : 100">
      <f7-list
        :class="{ 'no-margin-top': !$device.desktop }"
        class="list-inline-right"
        inset
        inline-labels
      >
        <f7-list-item>
          <div class="title">Budget Meeting Date</div>
          <div class="content">
            {{ toDisplayDateString(project.budgetMeetingDate) }}
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Client Budget</div>
          <div class="content">{{ project.clientBudget | currencyUSD }}</div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Tax Exempt</div>
          <div class="content">
            <f7-toggle
              disabled
              :checked="project.taxExempt"
            ></f7-toggle>
          </div>
        </f7-list-item>
        <f7-list-item>
          <div class="title">Resale Certificate</div>
          <div class="content">
            <f7-toggle
              disabled
              :checked="project.resalesCertificate"
            ></f7-toggle>
          </div>
        </f7-list-item>
      </f7-list>
      <f7-list
        inset
        accordion-list
      >
        <f7-list-item
          accordion-item
          :accordion-item-opened="sortedProposalList.length > 0"
          title="Proposal"
        >
          <f7-accordion-content
            style="max-height: calc(100vh - 312px); overflow-y: auto"
          >
            <f7-list>
              <f7-list-item
                v-for="(proposal, index) in sortedProposalList"
                :key="index"
              >
                <f7-link
                  slot="title"
                  @click.native="
                    $f7route.query.tenant
                      ? gotoViewProposalDetail(proposal.id)
                      : onPreviewProposal(proposal)
                  "
                  >{{ proposal.proposalNumber }}</f7-link
                >
                <f7-chip
                  slot="after"
                  :text="getStatus(proposal.status).displayName"
                  :color="getStatus(proposal.status).color"
                ></f7-chip>
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>
      </f7-list>
      <f7-list
        inset
        inline-labels
      >
        <f7-list-item title="Photo">
          <f7-link
            v-if="$f7route.query.tenant"
            slot="after"
            target="_blank"
            external
            :href="urlViewProjectPhoto()"
            >View</f7-link
          >
          <f7-link
            v-else
            @click="openProjectPhotoPopup"
            >View</f7-link
          >
        </f7-list-item>
      </f7-list>
    </f7-col>
    <proposal-preview-popup ref="proposalPreviewPopup"></proposal-preview-popup>
  </f7-row>
</template>
<script>
import { getFullAddress } from '@/utility/address';
import { toDisplayDateString } from '@/utility/datetime';
import axiosService from '@/services/axios.service';
import ProposalPreviewPopup from '../popup/ProposalPreviewPopup.vue';
import { mapActions } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    ProposalPreviewPopup,
  },
  props: {
    project: Object,
    contact: Object,
    proposalList: Array,
    proposalStatusList: Array,
  },
  data() {
    return {
      getFullAddress,
      toDisplayDateString,
    };
  },
  computed: {
    sortedProposalList() {
      return _.cloneDeep(this.proposalList).sort(
        (a, b) => b.createdAt - a.createdAt
      );
    },
  },
  methods: {
    ...mapActions('dashboard/app-constant', [
      'setIsOpenProjectPhotosViewPopup',
    ]),
    getStatus(status) {
      return this.proposalStatusList.find(x => x.value === status) || {};
    },

    onPreviewProposal(itemDetail) {
      this.$refs.proposalPreviewPopup.open(itemDetail);
    },

    urlViewProjectPhoto() {
      return `${import.meta.env.VITE_HOST_DOMAIN}/share-photo/${this.$f7route.query.tenant}/${this.project.id}`;
    },

    openProjectPhotoPopup() {
      this.setIsOpenProjectPhotosViewPopup(true);
    },

    async gotoViewProposalDetail(proposalId) {
      let url = '';
      const response = await axiosService.post('/tenant/get-anonymous-token', {
        entity: 'proposal',
        entityId: proposalId,
      });
      if (response && response.status === 200) {
        url = `${import.meta.env.VITE_HOST_DOMAIN}/proposal-preview/?token=${response.data}&id=${proposalId}`;
      }
      window.open(url, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.list-inline-center {
  ::v-deep .item-inner {
    gap: 16px;

    .title {
      flex: 1;
    }

    .content {
      flex: 2;
    }
  }
}

.list-inline-right,
.list-inline-center-mobile {
  ::v-deep .item-inner {
    gap: 16px;

    .title {
      flex: 1;
    }

    .content {
      flex: 1;
    }
  }
}
</style>
