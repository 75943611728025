import * as types from './types';
import * as constant from '../../utility/const';
import { searchClient } from '../../services/search.service';
import { FirebaseActions } from '../../services/firebase.service';

export default tenant => {
  const projectActions = new FirebaseActions(
    `/system_client/${tenant}/operation_card`,
    'project'
  );

  const actionActions = new FirebaseActions(
    `/system_client/${tenant}/operation_dashboard`,
    'action'
  );

  const propertyIndexAsc = searchClient.initIndex(
    `${tenant}__globalSearchPriority__asc__entityName__asc__searchOrder__desc`
  );
  return {
    getProjectById: projectActions.getDocument,
    getActionById: actionActions.getDocument,
    getActionsBys: actionActions.getDocumentBys,

    getActionListBys({ dispatch, commit }, conditions) {
      return dispatch('getActionsBys', conditions).then(actions => {
        commit(types.SET_ACTION_LIST, actions);
        return actions;
      });
    },

    // Fulltext search
    /**
     * Paging and Search with Algolia
     */
    searchProject({ state, commit, getters }, { attributesToRetrieve }) {
      const index = propertyIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_CARD} AND projectStatus:open`,
        attributesToRetrieve:
          constant.OPERATION_CARD_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.OPERATION_CARD_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.SET_SEARCH_RESULT, result);
          return result;
        })
        .catch(error => {
          console.error(error.message);
          return {};
        });
    },

    loadMoreProject(
      { state, commit, getters },
      { attributesToRetrieve, page }
    ) {
      const index = propertyIndexAsc;
      const filters = getters.queryFilters;
      const requestOptions = {
        hitsPerPage: state.hitsPerPage,
        page: page,
        cacheable: false,
        filters: `entityName:${constant.COLLECTION_OPRATION_CARD} AND projectStatus:open`,
        attributesToRetrieve:
          constant.OPERATION_CARD_ATTRIBUTES_TO_RETRIEVE_DEFAULT,
        restrictSearchableAttributes:
          constant.OPERATION_CARD_RESTRICT_SEARCHABLE_ATTRIBUTES_DEFAULT,
      };
      if (attributesToRetrieve) {
        requestOptions.attributesToRetrieve = attributesToRetrieve;
      }
      if (filters) {
        requestOptions.filters += ` AND ${filters}`;
      }
      return index
        .search(state.searchText, requestOptions)
        .then(result => {
          commit(types.ADD_MORE_RESULT, result);
          return result;
        })
        .catch(error => {
          console.error(error.message);
          return {};
        });
    },

    setProjectNumberOfRows({ commit }, payload) {
      commit(types.SET_NUMBER_OF_ROWS, payload);
    },
    setQueryFilters({ commit }, payload) {
      commit(types.SET_QUERY_FILTER, payload);
    },
    setIsHiddenResJob({ commit }, payload) {
      commit(types.SET_IS_HIDDEN_RES_JOB, payload);
    },
    setProjectSearchText({ commit }, payload) {
      commit(types.SET_SEARCH_TEXT, payload);
    },

    setProjectSearchOrder({ commit }, payload) {
      commit(types.SET_SEARCH_ORDER, payload);
    },

    resetSearch({ commit }) {
      commit(types.RESET_SEARCH);
    },
    setProjectFilterField({ commit }, { field, value }) {
      commit(types.SET_PROJECT_FILTER_FIELD, { field, value });
    },

    getActionListResidential({ dispatch, commit }) {
      return dispatch('getActionListBys', [
        {
          prop: 'boards',
          op: 'array-contains',
          val: 'residential',
        },
        {
          prop: 'isDeleted',
          op: '==',
          val: false,
        },
      ]);
    },
  };
};
