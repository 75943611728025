<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="
      popupOpened = false;
      doAction();
    "
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link @click="popupOpened = false">Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{ title }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onDone">Done</f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-list>
        <f7-list-input
          placeholder="Enter Due Date"
          :label="label"
          type="datepicker"
          :calendar-params="{
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: notAllowPast
              ? {
                  from: null,
                  to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
                }
              : {},
          }"
          :value="value"
          @calendar:change="
            $emit('input', $event);
            $f7.calendar.close();
          "
        >
        </f7-list-input>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
    title: { type: String, default: () => '' },
    label: { type: String, default: () => '' },
    notAllowPast: { type: Boolean, default: false },
  },

  data: () => {
    return {
      popupOpened: false,
      isDone: false,
    };
  },

  methods: {
    open() {
      this.popupOpened = true;
      this.isDone = false;
    },

    onDone() {
      this.isDone = true;
      this.popupOpened = false;
    },

    doAction() {
      if (this.isDone === true) {
        this.$emit('done');
      }
    },
  },
};
</script>

<style></style>
