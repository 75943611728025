<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="popupOpened = false"
    @popup:opened="handlePopupOpened"
  >
    <f7-page v-show="!isNew">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Close</f7-link>
        </f7-nav-left>
        <f7-nav-title>Select Proposal Template</f7-nav-title>
        <f7-nav-right>
          <!-- <f7-link icon-f7="plus" @click.native="onAdd"></f7-link> -->
        </f7-nav-right>
      </f7-navbar>

      <f7-searchbar
        disable-button-text
        placeholder="Search proposal template"
        :clear-button="true"
        :value="searchValue"
        @input="searchValue = $event.target.value"
        @searchbar:disable="searchValue = ''"
        class="search-list-popup"
      ></f7-searchbar>

      <f7-list class="searchbar-not-found">
        <f7-list-item title="Nothing found"></f7-list-item>
      </f7-list>
      <!-- select contact for creating new card -->
      <f7-list class="search-list searchbar-found contact-list">
        <f7-list-item
          no-chevron
          link
          v-for="template in templateList"
          :key="template.id"
          :title="template.name"
          @click.native="select(template.id)"
        ></f7-list-item>
      </f7-list>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  props: {
    projectDetail: { type: Object, default: () => {} },
  },
  data: () => {
    return {
      popupOpened: false,
      value: '',
      isNew: false,
      currentRoofType: {},
      searchValue: '',
      templateList: [],
    };
  },

  computed: {
    ...mapGetters({
      proposalNew: 'proposal/proposal/proposalNew',
      proposalTemplateList: 'proposal/template/proposalTemplateList',
    }),
    proposalListOptions() {
      if (!this.proposalNew.projectId) return this.proposalTemplateList;
      if (this.projectDetail.businessCode === 'service')
        return this.proposalTemplateList.filter(
          r => r.proposalType === 'service'
        );
      return this.proposalTemplateList.filter(
        r => r.proposalType !== 'service'
      );
    },
  },

  methods: {
    ...mapActions('proposal/template', ['bindProposalTemplateListBy']),
    ...mapActions('proposal/estimate', ['getBuilding']),
    initData() {
      if (_.isEmpty(this.proposalTemplateList)) {
        this.$f7.preloader.show();
        this.bindProposalTemplateListBy({
          prop: 'status',
          val: 'active',
          op: '==',
        }).then(() => {
          this.$f7.preloader.hide();
        });
      }
    },
    async filterTemplateList() {
      try {
        // Fetch roof types for all buildings
        const results = await Promise.all(
          this.proposalNew.buildingIDs.map(buildingId =>
            this.getBuilding({
              estimateId: this.proposalNew.estimateId,
              buildingId: buildingId,
            })
          )
        );

        // Extract roof types from results
        const roofTypes = results.map(res => res.roofType).filter(Boolean);

        // Check if all roof types are the same
        const allSame =
          roofTypes.length > 0 &&
          roofTypes.every(roofType => roofType === roofTypes[0]);

        // Determine the template list based on conditions
        if (this.projectDetail.businessCode === 'service') {
          this.templateList = this.proposalTemplateList.filter(
            template => template.proposalType === 'service'
          );
        } else {
          const proposalTypes = allSame
            ? [roofTypes[0], 'commercial']
            : ['commercial'];

          this.templateList = this.proposalTemplateList.filter(template =>
            proposalTypes.includes(template.proposalType)
          );
        }
        // Select the first template if available
        if (this.templateList.length > 0) {
          const selectedTemplate =
            this.templateList.find(
              template =>
                template.proposalType ===
                (allSame ? roofTypes[0] : 'commercial')
            ) || this.templateList[0];
          this.select(selectedTemplate.id);
        }
      } catch (error) {
        console.error('Error in filterTemplateList:', error);
      }
    },

    open() {
      this.initData();
      this.popupOpened = true;
    },

    select(id) {
      this.$emit('onSelected', id);
      this.popupOpened = false;
    },

    handlePopupOpened() {
      if (this.$device.desktop) {
        this.$el.querySelector('.search-list-popup.searchbar input').focus();
      }
    },
  },
};
</script>

<style></style>
