import * as types from './types';
import Vue from 'vue';
import _ from 'lodash';

export default {
  [types.SET_PROPOSAL_SECTION](state, payload) {
    Vue.set(state, 'proposalSection', payload);
  },
  setProposalSectionList(state, payload) {
    Vue.set(state, 'proposalSectionList', payload);
  },
  setProposalSectionSubscribe(state, payload) {
    Vue.set(state, 'proposalSectionSubscribe', payload);
  },
};
