<template>
  <div :style="styleContent">
    <div
      v-for="(page, index) in pdfPages"
      :key="index"
      :style="
        index ===
        pdfPages.findIndex(element => element.pageId === 'project-photos-after')
          ? ' margin-top: 20px;'
          : ''
      "
    >
      <div
        style="width: 825px"
        v-html="page.htmlValue"
        :id="`${page.pageId}_${page.index}`"
        :ref="`${page.pageId}_${page.index}`"
      ></div>
    </div>
  </div>
</template>

<script>
import commonMixin from '../../mixins/common-mixin';
import {
  TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
  TABLE_CONTENT_TYPE_ITEMIZE,
  TABLE_CONTENT_TYPE_LABOR_MATERIAL,
} from '@/utility/const';
import Vue from 'vue';
import jsPDF from 'jspdf';
import { generateImage } from '../../../../services/utils';
import _ from 'lodash';
import moment from 'moment';
import { currencyUSD } from '@/utility/config';

import summaryFooterMixins from '../../mixins/summary-footer-mixin';
import { PDF_CONFIG } from '../../../../utility/pdf';

export default {
  mixins: [commonMixin, summaryFooterMixins],
  props: {
    invoiceDetail: { type: Object, default: () => {} },
    invoiceGroup: { type: Object, default: () => {} },
    invoiceTemplate: { type: Object, default: () => {} },
    pageWidth: { type: Number, default: 856 },
  },
  data: () => {
    return {
      TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
      TABLE_CONTENT_TYPE_ITEMIZE,
      sortedSections: [],
      styleContent: '',
      keyValueDict: {
        'Invoice Number': '&nbsp;',
        'Invoice Date': '&nbsp;',
        'Due Date': '&nbsp;',

        'Client Name': '',
        'Client Phone': '',
        'Client Email': '',
        'Client Address': '',
        'Client Company Tax Code': '&nbsp;',
        'Total Cost': '&nbsp;',
        'Payment Status': '&nbsp;',

        'Project Name': '&nbsp;',
        'Property Address': '&nbsp;',
        'Property Name': '&nbsp;',

        'Items Detail Table': '&nbsp;',
        'Project Attachment File': '&nbsp;',

        Notes: '&nbsp;',
        'Terms And Conditions': '&nbsp;',
        'Roofing Company Tax Code': '&nbsp;',
      },
    };
  },

  computed: {
    paymentStatus() {
      if (this.invoiceDetail.status === 'in-paid') {
        return { text: 'Paid', color: '#2CD1FF' };
      } else if (this.invoiceDetail.status === 'in-partial-paid') {
        return { text: 'Partial Paid', color: '#2CD1FF' };
      } else {
        return { text: 'Unpaid', color: '#FEC800' };
      }
    },
    pdfPages() {
      let sections = _.cloneDeep(this.sortedSections);
      const pages = [];
      for (let index = 0; index < sections.length; index++) {
        const section = sections[index];
        switch (section.sectionId) {
          case 'invoice-section':
            pages.push({
              htmlValue: section.htmlValue,
              pageId: section.sectionId,
              index,
            });
            break;
          case 'project-photos-before':
            pages.push({
              htmlValue: section.htmlValue,
              pageId: section.sectionId,
              index,
            });
            break;
          case 'project-photos-after':
            pages.push({
              htmlValue: section.htmlValue,
              pageId: section.sectionId,
              index,
            });
            break;
          default:
            break;
        }
      }

      return pages;
    },
  },
  watch: {
    invoiceDetail: {
      async handler(val) {
        if (val) {
          this.sortedSections = await this.compileSectionData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    gotoPaymentReceived() {
      //TODO go to payment received
    },

    lowResDesktopScreen(width) {
      this.$nextTick(() => {
        const letterFullWidth = this.pageWidth;
        if (width < letterFullWidth) {
          const scaleRatio = width / letterFullWidth;

          this.styleContent = `transform: scale(${scaleRatio}); transform-origin: top;`;
        } else {
          this.styleContent = '';
        }
      });
    },

    async initKeyData() {
      if (!_.isEmpty(this.invoiceDetail)) {
        if (this.invoiceDetail.invoiceNumber) {
          this.keyValueDict['Invoice Number'] =
            this.invoiceDetail.invoiceNumber;
        }
        if (this.invoiceDetail.invoiceDate) {
          this.keyValueDict['Invoice Date'] = this.invoiceDetail.invoiceDate;
        }
        if (this.invoiceDetail.dueDate) {
          this.keyValueDict['Due Date'] = this.invoiceDetail.dueDate;
        }
        if (this.invoiceDetail.clientName) {
          this.keyValueDict['Client Name'] = this.invoiceDetail.clientName;
        }
        if (this.invoiceDetail.clientAddress) {
          this.keyValueDict['Client Address'] =
            this.invoiceDetail.clientAddress;
        }
        if (this.invoiceDetail.clientPhoneNumber) {
          this.keyValueDict['Client Phone'] =
            this.invoiceDetail.clientPhoneNumber;
        }
        if (this.invoiceDetail.clientEmail) {
          this.keyValueDict['Client Email'] = this.invoiceDetail.clientEmail;
        }
        if (this.invoiceDetail.clientCompanyTaxCode) {
          this.keyValueDict['Client Company Tax Code'] =
            this.invoiceDetail.clientCompanyTaxCode;
        } else {
          this.keyValueDict['Client Company Tax Code'] = '&nbsp;';
        }
        if (this.invoiceGroup && this.invoiceGroup.roofingCompanyTaxCode) {
          this.keyValueDict['Roofing Company Tax Code'] =
            this.invoiceGroup.roofingCompanyTaxCode;
        }
        if (this.invoiceDetail) {
          const totalCost = this.totalInvoice(this.invoiceDetail);
          const formattedTotalCost = Vue.filter('currencyUSD')(totalCost);
          this.keyValueDict['Total Cost'] = formattedTotalCost;
        }
        if (this.paymentStatus) {
          this.keyValueDict['Payment Status'] =
            `<span style="border: 1px solid;border-radius: 9999px;padding: 3px 8px 3px 8px;font-weight: 700;border-color: ${this.paymentStatus.color}; background-color: ${this.paymentStatus.color}">${this.paymentStatus.text}</span>`;
        }
        if (this.invoiceGroup && this.invoiceGroup.jobName) {
          this.keyValueDict['Project Name'] =
            this.invoiceGroup && this.invoiceGroup.jobName;
        }
        if (this.invoiceGroup && this.invoiceGroup.propertyAddress) {
          this.keyValueDict['Property Address'] =
            this.invoiceGroup && this.invoiceGroup.propertyAddress;
        }
        if (this.invoiceGroup && this.invoiceGroup.propertyName) {
          this.keyValueDict['Property Name'] =
            this.invoiceGroup && this.invoiceGroup.propertyName;
        }
        if (this.invoiceDetail.termsAndConditions) {
          this.keyValueDict['Terms And Conditions'] =
            this.invoiceDetail.termsAndConditions;
        }
        if (this.invoiceDetail.notes) {
          this.keyValueDict['Notes'] = this.invoiceDetail.notes;
        }
        // if (this.invoiceDetail.Notes) {
        //   this.keyValueDict["Project Photos"] =
        //   this.invoiceDetail.Notes;
        // }
      }
    },
    isEmptyObject(obj) {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    async compileSectionData() {
      await this.initKeyData();
      if (this.isEmptyObject(this.invoiceTemplate) || !this.invoiceTemplate)
        return [];
      const templateSections = _.cloneDeep(this.invoiceTemplate.sections).sort(
        (a, b) => a.index - b.index
      );

      const desSections = [];
      for (const orgSection of templateSections) {
        desSections.push(this.compileSection(orgSection));
      }

      if (
        this.invoiceDetail.sections &&
        this.invoiceDetail.sections.length === 1
      ) {
        return desSections;
      }

      const desSectionsPhoto = (this.invoiceDetail.sections || []).filter(
        section => {
          if (section.index > 0) {
            return section;
          }
        }
      );

      return desSections.concat(desSectionsPhoto);
    },

    compileSection(orgSection) {
      let htmlValue = this.parseHtmlMetaContent(
        _.cloneDeep(orgSection.htmlValue)
      );
      return {
        htmlValue,
        sectionId: orgSection.sectionId,
        sectionName: orgSection.sectionName,
        index: orgSection.index,
      };
    },
    dateToValue(a) {
      return a.seconds * 1000 + a.nanoseconds / 1000000;
    },
    convertDateTime(date) {
      return moment(new Date(this.dateToValue(date))).toDate();
    },
    parseHtmlMetaContent(value) {
      if (!value) return '';
      if (typeof value === 'object') return value;
      let keys = Object.keys(this.keyValueDict);
      keys.forEach(key => {
        if (key === 'Items Detail Table') {
          let rows = '';
          let rowsNetSales = '';
          let rowsTax = '';

          const displayItems = _.cloneDeep(
            this.invoiceDetail.itemDetails || []
          );
          const taxList =
            this.getTaxList(
              this.invoiceDetail || {},
              this.invoiceDetail.isApproveTax
            ) || [];
          const totalNetSales = taxList.reduce(
            (acc, item) => acc + item.netSalesAmount,
            0
          );
          const totalTax = taxList.reduce(
            (acc, item) => acc + item.taxAmount,
            0
          );
          const totalDiscount = this.getTotalDiscount(this.invoiceDetail) || 0;
          const shippingChargeType =
            this.invoiceDetail &&
            this.invoiceDetail.shippingCharge &&
            this.invoiceDetail.shippingCharge.type === 'cash'
              ? '$'
              : '%';

          if (
            this.invoiceDetail.tableContentType === 'itemize' ||
            this.invoiceDetail.tableContentType === 'simple-summary'
          ) {
            // Render Total Net Sales
            for (let index = 0; index < taxList.length; index++) {
              let taxItem = taxList[index];
              rowsNetSales += `
                <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
                  <div style="width: 45%;">Net Sales ${
                    taxItem.taxName
                  } %</div>
                  <div style="width: 50%; text-align: right;">${currencyUSD(
                    taxItem.netSalesAmount.toFixed(2)
                  )}</div>
                </div>
              `;
            }

            // Render Total Tax
            for (let index = 0; index < taxList.length; index++) {
              let taxItem = taxList[index];
              rowsTax += `
                <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
                  <div style="width: 45%;">Tax ${taxItem.taxName} %</div>
                  <div style="width: 50%; text-align: right;">${currencyUSD(
                    taxItem.taxAmount.toFixed(2)
                  )}</div>
                </div>
              `;
            }
          }

          const tableTypesAcceptPercentTax = [
            TABLE_CONTENT_TYPE_LABOR_MATERIAL,
            TABLE_CONTENT_TYPE_ITEMIZE,
            TABLE_CONTENT_TYPE_SIMPLE_SUMMARY,
          ];

          for (let index = 0; index < displayItems.length; index++) {
            let product = displayItems[index];
            rows +=
              `<tr style="border-bottom: 1px solid #e5e7f2; font-size:14px">` +
              `<td style="padding: 5px;">${product.productName}</td>` +
              (this.invoiceDetail.tableContentType ===
              TABLE_CONTENT_TYPE_ITEMIZE
                ? `<td style="text-align: right;padding: 5px;">${product.category}</td>`
                : '') +
              (this.invoiceDetail.tableContentType ===
              TABLE_CONTENT_TYPE_ITEMIZE
                ? `<td style="text-align: right;padding: 5px;">${product.quantity}</td>`
                : '') +
              (this.invoiceDetail.tableContentType ===
              TABLE_CONTENT_TYPE_ITEMIZE
                ? `<td style="text-align: right;padding:5px">${Vue.filter(
                    'currencyUSD'
                  )(product.priceWithProfitAndMisc)}</td>`
                : '') +
              `<td style="text-align: right;padding:5px;">${Vue.filter(
                'currencyUSD'
              )(product.amount)}</td>` +
              `<td style="text-align: right;padding:5px;">${
                this.invoiceDetail.discountType === 'fixed'
                  ? Vue.filter('currencyUSD')(product.discountValue)
                  : product.discountValue + '%'
              }</td>` +
              `<td style="text-align: right;padding:5px;">${Vue.filter(
                'currencyUSD'
              )(product.netSales)}</td>` +
              `${
                !tableTypesAcceptPercentTax.includes(
                  this.invoiceDetail.tableContentType
                )
                  ? `<td style="text-align: right;padding:5px;">${currencyUSD(
                      product.taxAmount
                    )}</td>`
                  : `<td style="text-align: right;padding:5px;">${
                      (product.taxPercent && this.invoiceDetail.isApproveTax
                        ? product.taxPercent.toLocaleString('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : '0') + '%'
                    }</td>`
              }` +
              `<td style="text-align: right;padding:5px;">${Vue.filter(
                'currencyUSD'
              )(product.intoMoney)}</td>`;
          }
          const htmlValue =
            `<table style="width: 100%; border: 0px; border-collapse:collapse;box-shadow:0px 1px 2px rgba(0,0,0,0.15);border-radius:4px">
        <tr style="background-color: #a8a8a8; color: white; font-weight:500; font-size: 14px;">` +
            `<th style="text-align: left; padding: 5px;">Item Details</th>` +
            (this.invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
              ? `<th style="text-align: right; padding: 5px;">Category</th>`
              : '') +
            (this.invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
              ? `<th style="text-align: right; padding: 5px;">Quantity</th>`
              : '') +
            (this.invoiceDetail.tableContentType === TABLE_CONTENT_TYPE_ITEMIZE
              ? `<th style="text-align: right; padding: 5px;">Price</th>`
              : '') +
            `<th style="text-align: right; padding: 5px;">Amount</th>` +
            `<th style="text-align: right; padding: 5px;">Discount</th>` +
            `<th style="text-align: right; padding: 5px;">Net Sales</th>` +
            `<th style="text-align: right; padding: 5px;">Tax</th>` +
            `<th style="text-align: right; padding: 5px;">Total</th>` +
            `</tr>
        ${rows}
        </table>
        <div style="display: flex; justify-content: flex-end; font-size:14px">
          <div style="padding-top: 20px; width:45%;">
            <!-- net sale -->
            ${rowsNetSales}
            
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Total Net Sales</div>
              <div style="width: 50%; text-align: right;">${currencyUSD(
                totalNetSales.toFixed(2)
              )}</div>
            </div>

            <!-- tax -->
            ${rowsTax}
            
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Total Tax</div>
              <div style="width: 50%; text-align: right;">${currencyUSD(
                totalTax.toFixed(2)
              )}</div>
            </div>

            <!-- discount -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Total Discount</div>
              <div style="width: 50%; text-align: right;">${currencyUSD(
                totalDiscount.toFixed(2)
              )}</div>
            </div>

            <!-- shipping charge -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;">Shipping Charge</div>
              <div style="width: 20%;text-align: right;">${
                shippingChargeType === '%'
                  ? this.invoiceDetail.shippingCharge &&
                    this.invoiceDetail.shippingCharge.value + '%'
                  : this.invoiceDetail.shippingCharge &&
                    '$' + this.invoiceDetail.shippingCharge.value
              }</div>
              <div style="width: 30%; text-align: right;">$${this.getShippingChargeValue(
                this.invoiceDetail
              )}</div>
            </div>

            <!-- total -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 8px;">
              <div style="width: 40%;"><b>Total</b></div>
              <div style="width: 50%; text-align: right;"><b>$${Vue.filter(
                'currencyUSD'
              )(this.totalInvoice(this.invoiceDetail))}</b></div>
            </div>
          </div>
        </div>`;
          value = value.replace(
            `<span style="background-color: #f05034;">[${key}]</span>`,
            htmlValue
          );
        } else if (key === 'Project Attachment File') {
          if (
            !this.invoiceDetail.attachmentFiles ||
            this.invoiceDetail.attachmentFiles.length === 0
          ){
            value = value.replace(
              `<span style="background-color: #f05034;">[${key}]</span>`,
              ''
            );
          } else {
            let htmlValue =
              '<div style="font-size:14px"> <div style="font-weight: 700; margin-bottom:4px" class="">Attachment File:</div>';
            this.invoiceDetail.attachmentFiles.map(file => {
              htmlValue += `
            <div style="font-size: 14px; line-height: 24px; color: red;cursor: pointer;"><a onclick="window.open('${file.url}', '_blank')" href="#">${file.fileName}</a></div>\n`;
            });
            htmlValue.concat('</div>');
            value = value.replace(
              `<span style="background-color: #f05034;">[${key}]</span>`,
              htmlValue
            );
          }
        } else {
          value = value.replaceAll(
            `<span style="background-color: #f05034;">[${key}]</span>`,
            this.keyValueDict[key]
          );

          if (
            key === 'Client Company Tax Code' &&
            !this.invoiceDetail.clientCompanyTaxCode
          ) {
            const searchValue = `<div>Tax code:&nbsp;</div>`;
            const replaceValue = ``;

            value = this.replaceKeyInContent(value, searchValue, replaceValue);
          }
        }
      });
      return value;
    },

    replaceKeyInContent(value, searchValue, replaceValue) {
      const firstIndex = value.indexOf(searchValue);
      if (firstIndex === -1) {
        return value;
      }

      const secondIndex = value.indexOf(
        searchValue,
        firstIndex + searchValue.length
      );
      if (secondIndex === -1) {
        return value;
      }

      return (
        value.slice(0, secondIndex) +
        replaceValue +
        value.slice(secondIndex + searchValue.length)
      );
    },

    async handleDownloadPDF(invoice, invoiceGroup) {
      this.$f7.dialog.preloader('Downloading PDF. Please wait...');

      const { width, height } = PDF_CONFIG.DOWNLOAD_SIZE;

      const doc = new jsPDF('p', 'pt', [width, height]); //850x1100

      try {
        const images = await Promise.all(
          this.pdfPages.map(page => {
            return generateImage(
              this.$refs[`${page.pageId}_${page.index}`][0].innerHTML
            );
          })
        );

        for (let index = 0; index < images.length; index++) {
          const image = images[index];

          if (!_.isEmpty(image)) {
            if (index > 0) {
              doc.addPage([width, height], 'p');
            }
            doc.setPage(index + 1);

            doc.addImage(
              image,
              'PNG',
              0,
              0,
              width,
              height,
              `page-${index}`,
              'FAST'
            );
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(10);
            const pageNumber = index + 1;
            const pageNumberString = `${pageNumber}`;
            const xPosition = width / 2;
            doc.text(pageNumberString, xPosition, height - 10);
          }
        }

        doc.save(`${invoice.invoiceNumber} - ${invoiceGroup.jobName}.pdf`);
      } catch (error) {
        console.error(error.message);
        this.$f7.dialog.close();
      }
      this.$f7.dialog.close();
    },
  },
};
</script>
