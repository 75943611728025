<template>
  <div>
    <div v-if="$f7.device.desktop || $f7.device.ipad">
      <f7-row tag="p">
        <f7-col
          tag="span"
          width="50"
        >
          <f7-list inset>
            <f7-list-item
              title="Project #"
              :after="woTask.projectNumber || ''"
            ></f7-list-item>
            <f7-list-item title="Project Name">
              <span
                style="
                  width: 70%;
                  text-align: right;
                  color: var(--f7-list-item-after-text-color);
                "
                >{{ woTask.projectName || '' }}</span
              >
            </f7-list-item>
            <f7-list-item title="Property Name">
              <span
                style="
                  width: 70%;
                  text-align: right;
                  color: var(--f7-list-item-after-text-color);
                "
                >{{ woTask.propertyName || '' }}</span
              >
            </f7-list-item>
            <f7-list-item
              v-if="woTask.buildingName"
              title="System #"
              :after="woTask.buildingName"
            ></f7-list-item>
          </f7-list>
        </f7-col>
        <f7-col
          tag="span"
          width="50"
        >
          <f7-list inset>
            <f7-list-item
              title="Roof Type"
              :after="roofTypeBy(woTask.roofType).displayName || ''"
            ></f7-list-item>

            <f7-list-item
              v-if="woTask.estimateNumber"
              title="Estimate #"
              :after="woTask.estimateNumber"
            ></f7-list-item>

            <f7-list-item
              title="Vendor"
              :after="woTask.vendorName"
            >
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>

      <f7-row tag="p">
        <f7-col
          v-if="woTask.status != STATUS_PO_DRAFT"
          tag="span"
          width="50"
        >
          <f7-list inset>
            <f7-list-input
              label="Tracking #"
              type="text"
              placeholder="Tracking number"
              :value="trackingNumber || ''"
              @change="
                trackingNumber = $event.target.value;
                updateWorkOrderTaskProp('trackingNumber', $event.target.value);
              "
            >
              <!-- <input-icon slot="media" icon="map_pin_ellipse"></input-icon> -->
            </f7-list-input>
            <!-- Estimated arrival -->
            <f7-list-input
              type="datepicker"
              label="Estimated Arrival Date"
              placeholder="Estimated arrival date"
              :value="estimatedArrival"
              :calendar-params="{
                backdrop: true,
                openIn: 'customModal',
                header: true,
                footer: false,
                dateFormat: 'mm/dd/yyyy',
                disabled: {
                  from: null,
                  to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
                },
              }"
              @calendar:change="
                saveDueDate('estimatedArrival', $event);
                $f7.calendar.close();
              "
            >
              <!-- <input-icon slot="media" icon="alarm"></input-icon> -->
            </f7-list-input>
            <!-- Delivered date -->
            <f7-list-input
              ref="deliveredDate"
              type="datepicker"
              label="Delivered Date"
              placeholder="Delivered date"
              :value="deliveredDate"
              :calendar-params="{
                backdrop: true,
                openIn: 'customModal',
                header: true,
                footer: false,
                dateFormat: 'mm/dd/yyyy',
                disabled: {
                  from: null,
                  to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
                },
              }"
              @calendar:change="
                saveDueDate('deliveredDate', $event);
                $f7.calendar.close();
              "
              :error-message="deliveredDateErrorMessage"
              error-message-force
            >
              <!-- <input-icon slot="media" icon="alarm"></input-icon> -->
              <required-asterisk slot="label"></required-asterisk>
            </f7-list-input>
          </f7-list>
        </f7-col>
        <f7-col
          tag="span"
          width="50"
        >
          <f7-list inset>
            <f7-list-item
              title="Created on"
              :after="toDisplayDateString(woTask.createdAt)"
            >
            </f7-list-item>

            <f7-list-item
              v-if="woTask.orderedDate && woTask.orderedDate != ''"
              title="Ordered on"
              :after="toDisplayDateString(woTask.orderedDate)"
            >
            </f7-list-item>

            <f7-list-item
              v-if="woTask.canceledDate && woTask.canceledDate != ''"
              title="Canceled Date"
              :after="toDisplayDateString(woTask.canceledDate)"
            >
            </f7-list-item
            ><f7-list-item title="Status"
              ><f7-chip
                slot="after"
                :text="purchaseOrderStatusBy(woTask.status).displayName"
                :color="purchaseOrderStatusBy(woTask.status).color"
              ></f7-chip></f7-list-item
          ></f7-list>
        </f7-col>
      </f7-row>
    </div>

    <div v-else>
      <f7-list>
        <f7-list-item
          title="Project #"
          :after="project.cardNumber"
        ></f7-list-item>
        <f7-list-item
          title="Project Name"
          :after="project.title"
        ></f7-list-item>
        <f7-list-item
          title="Property Name"
          :after="property.propertyName"
        ></f7-list-item>
        <f7-list-item
          v-if="woTask.buildingName"
          title="System #"
          :after="woTask.buildingName"
        ></f7-list-item>
      </f7-list>

      <f7-list>
        <f7-list-item
          title="Roof Type"
          :after="roofTypeBy(woTask.roofType).displayName || ''"
        ></f7-list-item>

        <f7-list-item
          v-if="woTask.estimateNumber"
          title="Estimate #"
          :after="woTask.estimateNumber"
        ></f7-list-item>

        <f7-list-item
          title="Price List #"
          :after="priceList && priceList.priceListNumber"
        >
        </f7-list-item>
        <f7-list-item
          title="Price List Name"
          :after="priceList && priceList.displayName"
        >
        </f7-list-item>
        <!-- Price list -->
        <!-- <f7-list-input
        label="Roof Type"
        type="select"
        :disabled="woTask.status !== 'draft'"
        :value="roofType"
        @change="
          roofType = $event.target.value;
          updateWorkOrderTaskProp('roofType', $event.target.value);
          reloadPriceList().then(() => {
            updateNewPrice();
          });
        "
      >
        <option
          v-for="item in roofTypeList"
          :key="item.id"
          :value="item.value"
          :selected="roofType === item.value"
          >{{ item.displayName }}</option
        >
      </f7-list-input> -->
        <!-- Price list -->
        <!-- <f7-list-input
        label="Price List"
        type="select"
        :disabled="woTask.status !== 'draft'"
        :value="priceListId"
        @change="
          priceListId = $event.target.value;
          updateWorkOrderTaskProp('priceListId', $event.target.value);
        "
      >
        <option
          v-for="item in priceListItemList"
          :key="item.id"
          :value="item.id"
          :selected="priceListId === item.id"
          >{{ item.displayName }}</option
        >
      </f7-list-input> -->
      </f7-list>

      <f7-list>
        <f7-list-item
          title="Created Date"
          :after="toDisplayDateString(woTask.createdAt)"
        >
        </f7-list-item>

        <f7-list-item
          v-if="woTask.orderedDate && woTask.orderedDate != ''"
          title="Ordered Date"
          :after="toDisplayDateString(woTask.orderedDate)"
        >
        </f7-list-item>

        <f7-list-item
          v-if="woTask.canceledDate && woTask.canceledDate != ''"
          title="Canceled Date"
          :after="toDisplayDateString(woTask.canceledDate)"
        >
        </f7-list-item>
        <f7-list-item title="Status">
          <f7-chip
            slot="after"
            :text="purchaseOrderStatusBy(woTask.status).displayName"
            :color="purchaseOrderStatusBy(woTask.status).color"
          ></f7-chip>
        </f7-list-item>
      </f7-list>

      <f7-list v-if="woTask.status != STATUS_PO_DRAFT">
        <f7-list-input
          label="Tracking #"
          type="text"
          placeholder="Tracking number"
          :value="trackingNumber || ''"
          @change="
            trackingNumber = $event.target.value;
            updateWorkOrderTaskProp('trackingNumber', $event.target.value);
          "
        >
          <!-- <input-icon slot="media" icon="map_pin_ellipse"></input-icon> -->
        </f7-list-input>
        <!-- Estimated arrival -->
        <f7-list-input
          type="datepicker"
          label="Estimated Arrival Date"
          placeholder="Estimated arrival date"
          :value="estimatedArrival"
          :calendar-params="{
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
            },
          }"
          @calendar:change="
            saveDueDate('estimatedArrival', $event);
            $f7.calendar.close();
          "
        >
          <!-- <input-icon slot="media" icon="alarm"></input-icon> -->
        </f7-list-input>
        <!-- Delivered date -->
        <f7-list-input
          ref="deliveredDate"
          type="datepicker"
          label="Delivered Date"
          placeholder="Delivered date"
          :value="deliveredDate"
          :calendar-params="{
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
            },
          }"
          @calendar:change="
            saveDueDate('deliveredDate', $event);
            $f7.calendar.close();
          "
          :error-message="deliveredDateErrorMessage"
          error-message-force
        >
          <!-- <input-icon slot="media" icon="alarm"></input-icon> -->
          <required-asterisk slot="label"></required-asterisk>
        </f7-list-input>
      </f7-list>
    </div>
    <f7-list
      no-hairlines-md
      v-if="isShowDueDate === 'To do' || isShowDueDate === 'In Progress'"
    >
      <f7-list-input
        type="datepicker"
        label="Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
          },
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          autoSaveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon
          slot="media"
          icon="timer"
        ></input-icon>
      </f7-list-input>
    </f7-list>
    <f7-list accordion-list
      ><f7-list-item
        accordion-item
        title="Other Purchase Order(s)"
      >
        <f7-accordion-content>
          <f7-list>
            <f7-list-item
              :class="po.id === woTask.id ? 'current-po-list-item' : ''"
              link="#"
              @click="handlePOLink(po.id)"
              v-for="(po, index) in poList"
              :key="index"
            >
              <div
                :class="po.id === woTask.id ? 'current-po' : ''"
                slot="title"
              >
                {{ po.taskNumber }}
              </div>
              <f7-chip
                slot="after"
                :text="purchaseOrderStatusBy(po.status).displayName"
                :color="purchaseOrderStatusBy(po.status).color"
              ></f7-chip>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <attachment-input
      :projectId="woTask.id"
      modelType="work-order-tasks"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="attachmentFiles"
      @input="
        attachmentFiles = $event;
        updateWorkOrderTaskProp('attachmentFiles', $event);
      "
    ></attachment-input>

    <user-input
      :value="assigneeIds"
      modelType="wo-task"
      @input="updateWorkOrderTaskProp('assigneeIds', $event)"
      @onUserAdded="
        addWOTaskNotification($event);
        sendAddWOTaskMail($event);
      "
    ></user-input>

    <PO-send-email-input
      v-show="purchaseOrderEmails.length > 0"
      ref="emailInput"
      title="PO Email"
      :value="purchaseOrderEmails"
      :useDefaultTemplate="false"
    >
      <template v-slot:item="{ email }">
        <a
          class="cursor-pointer"
          slot="title"
          >{{ getEmailLinkTitle(email) }}</a
        >
      </template>
    </PO-send-email-input>

    <slot name="response"></slot>

    <!-- product data table -->
    <div>
      <f7-link
        target="_blank"
        external
        class="text-color-orange margin-left"
        href="https://firebasestorage.googleapis.com/v0/b/swadleys-appdev.appspot.com/o/Sales_Tax_chart.pdf?alt=media&token=26c6f681-92c2-4744-a054-c246ec04644f"
      >
        Based on your input information, you will
        <strong v-show="showNoTaxMessage">&nbsp;NOT&nbsp;</strong> pay tax on
        this Purchase Order. Please follow this link for more details.
      </f7-link>
      <purchase-order-table
        ref="purchaseOrder"
        :vendorId="woTask.vendorId"
        :items="items"
        :isBuildYourOwn="woTask.isBuildYourOwn"
        :workOrderTask="woTask"
        :isEditing="woTask.status === STATUS_PO_DRAFT"
        :woTaskStatus="woTask.status"
        :isPlacedOrder="
          woTask.status === STATUS_PO_WAITING ||
          woTask.status === STATUS_PO_RECEIVED ||
          woTask.status === STATUS_PO_PARTIAL_RECEIVED
        "
        :isCompleted="
          woTask.status === STATUS_PO_RECEIVED ||
          woTask.status === STATUS_PO_PARTIAL_RECEIVED
        "
        @onItemsChange="
          items = $event;
          updateWorkOrderTaskProp('items', $event);
        "
      ></purchase-order-table>
    </div>

    <a href="#"></a><a href="#"></a>

    <note-popup
      ref="poNotePopup"
      title="Received Purchase Order Note"
      label="Enter Note"
      v-model="note"
      @done="handleNotePopupDone"
      validate
    ></note-popup>

    <purchase-order-send-email-popup
      ref="poSendEmailPopup"
      :email-type="getEmailType()"
      :purchase-order-emails="purchaseOrderEmails"
      :projectId="woTask.id"
      :attachments="allAttachmentFiles"
      @input="handleEmailSent"
    ></purchase-order-send-email-popup>
  </div>
</template>

<script>
import PurchaseOrderTable from '../table/PurchaseOrderTable.vue';
import UserInput from '../input/UserInput.vue';
import AttachmentInput from '../input/AttachmentInput.vue';
import POSendEmailInput from '../input/POSendEmailInput.vue';
import NotePopup from '../popup/NotePopup.vue';
import PurchaseOrderSendEmailPopup from '../popup/PurchaseOrderSendEmailPopup.vue';

import { mapGetters, mapActions, mapState } from 'vuex';
import _ from 'lodash';
import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString,
} from '../../../../utility/datetime';
import constBoard from '../../mixin/constructionBoard';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { firebase, auth } from '../../../../services/firebase.service';
// import { ACTION_CODE_CONSTRUCTION } from "@/utility/const";

import {
  ACTION_CODE_CONSTRUCTION,
  STATUS_PO_RECEIVED,
  STATUS_PO_PARTIAL_RECEIVED,
  STATUS_PO_CANCEL,
  STATUS_PO_REQUEST_CANCEL,
  STATUS_PO_DRAFT,
  STATUS_PO_WAITING,
  ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL,
  ACTION_CODE_PO_PARTIAL_RECEIVED,
  ACTION_CODE_PO_RECEIVED,
  ACTION_CODE_PO_REJECT_REQUEST_CANCEL,
  ACTION_CODE_PO_REQUEST_CANCEL,
  ACTION_CODE_PO_CANCEL,
  ACTION_CODE_PO_EMAIL,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';
import moment from 'moment';
import InputIcon from '../icon/InputIcon.vue';

export default {
  components: {
    PurchaseOrderTable,
    UserInput,
    AttachmentInput,
    POSendEmailInput,
    NotePopup,
    InputIcon,
    PurchaseOrderSendEmailPopup,
  },
  mixins: [constBoard],

  data() {
    return {
      woTask: {},
      assigneeIds: [],
      trackingNumber: '',
      estimatedArrival: [],
      deliveredDate: [],
      items: [],
      attachmentFiles: [],

      purchaseOrderEmails: [],
      note: '',
      orderedDate: '',
      canceledDate: '',

      priceListId: '',
      roofType: '',

      response: {},

      ACTION_CODE_CONSTRUCTION,
      STATUS_PO_RECEIVED,
      STATUS_PO_PARTIAL_RECEIVED,
      STATUS_PO_CANCEL,
      STATUS_PO_REQUEST_CANCEL,
      STATUS_PO_DRAFT,
      STATUS_PO_WAITING,
      actionId: '',
      dueDate: [],
      isShowDueDate: '',

      isDeleted: false,
    };
  },

  created() {
    if (_.isEmpty(this.setting)) {
      this.bindSetting(this.tenantId);
    }

    if (_.isEmpty(this.roofTypeList)) {
      this.bindRoofTypeList();
    }
  },

  watch: {
    workOrderTask: {
      immediate: true,
      deep: true,
      handler(val) {
        this.woTask = _.cloneDeep(val);

        this.trackingNumber = this.woTask.trackingNumber || '';
        this.estimatedArrival =
          this.toDateCalendar(this.woTask.estimatedArrival) || [];
        this.deliveredDate =
          this.toDateCalendar(this.woTask.deliveredDate) || [];
        this.assigneeIds = this.woTask.assigneeIds || [];
        this.items = this.woTask.items || [];

        this.attachmentFiles = this.woTask.attachmentFiles || [];
        this.purchaseOrderEmails = this.woTask.purchaseOrderEmails || [];
        this.orderDate = this.toDateCalendar(this.woTask.orderDate) || [];

        this.priceListId = this.woTask.priceListId || '';
        this.roofType = this.woTask.roofType || '';
        this.dueDate = toDateCalendar(this.woTask.dueDate);
        this.isShowDueDate = this.currentAction(this.woTask.actions[0]).title;
        this.isDeleted = this.woTask.isDeleted || false;
      },
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    deliveredDate: {
      required,
    },
  },

  computed: {
    ...mapGetters('dashboard/project', { project: 'constructionProject' }),
    ...mapGetters('dashboard/project', ['actionByCode']),
    ...mapGetters('dashboard/client', ['companyById']),
    ...mapState('dashboard/client', ['property']),
    ...mapGetters('setting/app/system', ['setting']),
    ...mapGetters('dashboard/price-list', ['priceListItemList', 'priceList']),
    ...mapGetters('dashboard/roof-type', ['roofTypeList']),
    ...mapGetters({
      productItems: 'dashboard/price-list/productItems',
    }),
    ...mapGetters('dashboard/app-constant', [
      'purchaseOrderStatusBy',
      'roofTypeBy',
    ]),
    ...mapGetters('dashboard/work-order', ['workOrderTask']),
    ...mapGetters('dashboard/project', ['constructionActions']),
    deliveredDateErrorMessage() {
      if (!this.v$.deliveredDate.$error) return null;
      if (this.v$.deliveredDate.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return '';
    },

    ...mapGetters('common/app-constant', ['tenantId']),

    vendorEmails() {
      let result = '';
      const vendor = this.companyById(this.woTask.vendorId || '');
      if (!_.isEmpty(vendor)) {
        const emails = (vendor.others || []).filter(
          item => item.code === 'email'
        );

        if (!_.isEmpty(emails)) {
          result = emails.map(item => item.value).join('; ');
        }
      }
      return result;
    },

    poList() {
      return this.workOrderTaskList.filter(
        po => po.taskType === 'purchase-order'
      );
    },

    showNoTaxMessage() {
      return (
        this.project &&
        (this.project.taxExempt === true ||
          this.project.resalesCertificate === true)
      );
    },

    allAttachmentFiles() {
      const arr = [];
      if (!_.isEmpty(this.project.attachmentFiles)) {
        arr.push(...this.project.attachmentFiles);
      }
      if (!_.isEmpty(this.woTask.attachmentFiles)) {
        arr.push(...this.woTask.attachmentFiles);
      }
      return arr;
    },
    title() {
      return `${this.currentAction(this.actionId).title || ''}`;
    },

    currentAction() {
      return actionId => {
        return (
          this.constructionActions.find(action => action.id === actionId) || {}
        );
      };
    },
  },

  methods: {
    ...mapActions('dashboard/work-order', [
      'updateWorkOrderTask',
      'deleteWorkOrderTask',
    ]),
    ...mapActions('dashboard/project', ['updateCard']),
    ...mapActions('setting/app/system', ['bindSetting']),
    ...mapActions('dashboard/price-list', [
      'bindActivePriceList',
      'unbindPriceListItemList',
      'bindPriceListItem',
    ]),
    ...mapActions('dashboard/roof-type', ['bindRoofTypeList']),
    ...mapActions('dashboard/product-item', ['bindActiveProductItems']),

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    getEmailType() {
      if (this.woTask.status === STATUS_PO_DRAFT) {
        return 'purchase-order';
      } else if (this.woTask.status === STATUS_PO_WAITING) {
        return 'request-cancel-purchase-order';
      }

      return '';
    },

    getEmailLinkTitle(email) {
      let type = email.template === 'purchase-order' ? 'Order on' : '';
      type =
        email.template === 'request-cancel-purchase-order'
          ? 'Request Cancel Date'
          : type;

      return `${type}: ${toDisplayDateString(email.createdAt)} by ${
        email.createdBy
      }`;
    },

    // Implement do something before resolve action
    doAction(response) {
      this.response = response;
      this.note = '';

      switch (response.code) {
        case ACTION_CODE_PO_EMAIL:
          this.orderedDate = firebase.firestore.Timestamp.now();
          this.$refs.poSendEmailPopup.sendMail({
            defaultSubject: `${this.setting.companyName} - ${this.project.title} - ${this.woTask.taskNumber}`,
            emails: this.vendorEmails,
            vendorName:
              (this.companyById(this.woTask.vendorId || '') || {})
                .companyName || '',
            mainMessage: this.generateMainMessage(),
          });
          break;

        case ACTION_CODE_PO_CANCEL:
          if (this.workOrderTask.status === STATUS_PO_DRAFT) {
            this.isDeleted = true;
          }

          this.canceledDate = firebase.firestore.Timestamp.now();
          this.resolve(response);
          this.$emit('doClosePopup');
          break;

        case ACTION_CODE_PO_RECEIVED:
          if (this.invalidPO()) {
            return;
          }

          this.$refs.poNotePopup.open();
          break;

        case ACTION_CODE_PO_PARTIAL_RECEIVED:
          this.v$.deliveredDate.$touch();
          if (this.v$.deliveredDate.$invalid) {
            this.$refs.deliveredDate.$el.scrollIntoView();
            return;
          }

          this.$refs.poNotePopup.open();
          break;

        case ACTION_CODE_PO_REQUEST_CANCEL:
          this.$refs.poSendEmailPopup.sendMail({
            defaultSubject: 'Request Cancel Purchase Order',
            emails: this.vendorEmails,
            vendorName:
              (this.companyById(this.woTask.vendorId || '') || {})
                .companyName || '',
            mainMessage: this.generateMainMessage(),
          });
          break;

        case ACTION_CODE_PO_REJECT_REQUEST_CANCEL:
          this.$refs.poNotePopup.open();
          break;

        case ACTION_CODE_PO_ACCEPT_REQUEST_CANCEL:
          this.canceledDate = firebase.firestore.Timestamp.now();
          this.$refs.poNotePopup.open();
          break;
      }
    },

    // Implement  resolve action
    async resolve(response) {
      this.$f7.preloader.show();
      await this.save();
      this.$emit('doResolve', response);
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      await this.updateWorkOrderTask({
        id: this.woTask.id,
        doc: {
          estimatedArrival:
            this.toDateFirebase(this.estimatedArrival || '') || '',
          deliveredDate: this.toDateFirebase(this.deliveredDate || '') || '',
          trackingNumber: this.trackingNumber,
          assigneeIds: this.assigneeIds,
          items: this.items,
          attachmentFiles: this.attachmentFiles,
          purchaseOrderEmails: this.purchaseOrderEmails,
          orderedDate: this.orderedDate || '',
          canceledDate: this.canceledDate || '',
          priceListId: this.priceListId,
          roofType: this.roofType,
          isDeleted: this.isDeleted,
        },
      });
    },

    generateMainMessage() {
      let poInfo = '';
      let footer = '';

      if (this.showNoTaxMessage) {
        footer += `<div><p>This purchase order does <strong> NOT</strong> pay Sale Tax.</p></div>`;
      }

      if (!_.isEmpty(this.trackingNumber)) {
        poInfo += `<div><p><strong>Tracking #: </strong>${this.trackingNumber}</p></div>`;
      }

      if (!_.isEmpty(this.estimatedArrival)) {
        poInfo += `<div><p><strong>Estimated Arrival: </strong>${this.toDisplayDateString(
          this.toDateFirebase(this.estimatedArrival)
        )}</p></div>`;
      }

      if (!_.isEmpty(this.deliveredDate)) {
        poInfo += `<div><p><strong>Delivered Date: </strong>${this.toDisplayDateString(
          this.toDateFirebase(this.deliveredDate)
        )}</p></div>`;
      }

      let tr = '';
      for (const product of this.$refs.purchaseOrder.workOrderTaskItems) {
        if (!product.footer) {
          // let product = this.$refs.purchaseOrder.getItem(item);

          tr += `<tr>
          <td style="border: 1px solid black;">${product.sku || ''}</td>
          <td style="border: 1px solid black;">${product.productItem || ''}</td>
          <td style="border: 1px solid black;">${
            product.manufacturer || ''
          }</td>
          <td style="border: 1px solid black;">${product.orderQty}</td>

          <td style="border: 1px solid black;">${product.packaging || ''}</td>

          <td style="border: 1px solid black;">${product.unitSize || ''}</td>
          <td style="border: 1px solid black;">${product.uom || ''}</td>
          <td style="border: 1px solid black;">$
            ${(product.price || 0).toFixed(2)}
          </td>
          <td style="border: 1px solid black;">$
            ${((product.price || 0) * product.orderQty).toFixed(2)}
          </td>
        </tr>`;
        } else {
          tr += `<tr>
          <td colspan="7" style="border: 1px solid black;"></td>
          <td style="border: 1px solid black;"><strong>Total:</strong></td>
          <td style="border: 1px solid black;"><strong>$
          ${(product.total || 0).toFixed(2)}</strong></td>
        </tr>`;
        }
      }

      return `<div>${poInfo}</div><div><br/></div><div><table style="border-collapse: collapse;">
      <thead >
        <tr >
          <th style="border: 1px solid black;">
            SKU
          </th>
          <th style="border: 1px solid black;">
            Items
          </th>
          <th style="border: 1px solid black;">
            Manufacturer
          </th>
          <th style="border: 1px solid black;">
            Order quantity
          </th>
          <th style="border: 1px solid black;">
            Packaging
          </th>
          
          <th style="border: 1px solid black;">
            Unit Size
          </th>
          <th style="border: 1px solid black;">
            UoM
          </th>
          <th style="border: 1px solid black;">
            Price
          </th>
          <th style="border: 1px solid black;">
            Sub-Total
          </th>
        </tr>
      </thead>
      <tbody>
        ${tr}
      </tbody>
    </table></div>
    <div>${footer}</div>`;
    },

    handleEmailSent(data) {
      this.purchaseOrderEmails = data;

      this.resolve(this.response);
      this.$emit('doClosePopup');
    },

    invalidPO() {
      this.v$.deliveredDate.$touch();
      if (this.v$.deliveredDate.$invalid) {
        this.$refs.deliveredDate.$el.scrollIntoView();
        return true;
      }

      let qtyValid = this.items.reduce((acc, curr) => {
        return acc + (parseFloat(curr.receivedQty) || 0.0);
      }, 0.0);

      if (qtyValid === 0.0) {
        this.$ri.dialog.openWarningDialog({
          title: 'Purchase Order',
          content: 'Received quantity must be greater 0!',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
        return true;
      } else {
        return false;
      }
    },

    saveNote() {
      const commentAndNote = _.cloneDeep(this.project.commentAndNote || []);

      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'Purchase Order Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });

        return this.updateCard({
          id: this.project.id,
          doc: {
            commentAndNote,
          },
        });
      }
    },

    handleNotePopupDone() {
      const self = this;
      this.saveNote().then(() => {
        self.resolve(self.response);
        self.$emit('doClosePopup');
      });
    },

    handlePOLink(poId) {
      if (poId === this.woTask.id) {
        return;
      }

      this.gotoTaskDetails(
        this.actionByCode(ACTION_CODE_CONSTRUCTION).id,
        poId,
        this.woTask.taskNumber
      );
    },

    gotoTaskDetails(actionId, taskId, taskNumber) {
      this.$emit('gotoDetails', { actionId, cardId: taskId, taskNumber });
    },

    initData() {
      const self = this;
      this.canceledDate = '';
      if (this.priceListId) {
        this.bindPriceListItem(this.priceListId).then(() => {
          return self.bindActiveProductItems(self.priceListId);
        });
      }
    },
    autoSaveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.woTask.dueDate)[0]),
            'day'
          )
        ) {
          this.updateWorkOrderTask({
            id: this.woTask.id,
            doc: { dueDate: toDateFirebase(this.dueDate) },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.po-status {
  &::v-deep .input-with-value {
    color: var(--f7-theme-color);
  }
}

.current-po {
  color: var(--f7-theme-color);
}

.current-po-list-item {
  background-color: #f8d4d4;
}
</style>
