<template>
  <div>
    <f7-block-header>Information</f7-block-header>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        label="Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
          },
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          saveDate('dueDate', $event);
          $f7.calendar.close();
        "
      >
        <input-icon
          slot="media"
          icon="timer"
        ></input-icon>
      </f7-list-input>

      <f7-list-input
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: false,
          dateFormat: 'mm/dd/yyyy',
        }"
        label="Presentation Date"
        placeholder="Enter Presentation Date"
        readonly
        :value="presentationDate"
        @calendar:change="
          saveDate('presentationDate', $event);
          $f7.calendar.close();
        "
      >
        <input-icon
          slot="media"
          icon="scope"
        ></input-icon>
      </f7-list-input>
    </f7-list>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <!-- attachment -->
    <attachment-input
      :projectId="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
      @save="save"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-input
      :value="displayCard ? displayCard.commentAndNote || [] : []"
      :card="card"
      @change="saveNote($event)"
    ></note-input>

    <!-- Popup -->
    <date-popup
      ref="followUpDueDatePopup"
      title="Enter Follow up Date"
      label="Follow up Date"
      v-model="followUpDueDate"
      @done="resolveDueDate(followUpDueDate)"
    ></date-popup>

    <date-popup
      ref="presentationDueDatePopup"
      title="Enter New Presentation Date"
      label="New Presentation Date"
      v-model="newPresentationDueDate"
      @done="resolveDueDate(newPresentationDueDate)"
      validate
      not-allow-past
    ></date-popup>

    <note-popup
      ref="archiveNotePopup"
      title="Archive Note"
      label="Note"
      v-model="archiveNote"
      @done="onDone"
      validate
    ></note-popup>
  </div>
</template>

<script>
import UserInput from '../input/UserInput.vue';
import DatePopup from '../popup/DatePopup.vue';
import NotePopup from '../popup/NotePopup.vue';
import InputIcon from '../icon/InputIcon.vue';
import AttachmentInput from '../input/AttachmentInput.vue';
import PurchaseOrderInput from '../input/PurchaseOrderInput.vue';
import NoteInput from '@/plugins/swimlane/components/inputs/NoteInput.vue';

import moment from 'moment';

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString,
} from '../../../../utility/datetime';

import { firebase, auth } from '../../../../services/firebase.service';
import { mapState, mapActions } from 'vuex';

import _ from 'lodash';
import methodsMixins from '../../mixin/methods';
import { handleDetectLanguage } from '@/lang/translation';

export default {
  mixins: [methodsMixins],
  components: {
    UserInput,
    DatePopup,
    NotePopup,
    InputIcon,
    AttachmentInput,
    PurchaseOrderInput,
    NoteInput
  },

  data: () => {
    return {
      note: '',
      archiveNote: '',
      currentResponse: {},

      displayCard: {},
      dueDate: [],
      presentationDate: [],
      followUpDueDate: [],
      newPresentationDueDate: [],
    };
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      },
    },
  },

  computed: {
    ...mapState('dashboard/project', ['card', 'action', 'response', 'users']),
  },
  methods: {
    ...mapActions('dashboard/project', ['updateCard']),
    changeValueNotes(value) {
      this.note = value;
    },

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);
        this.presentationDate = toDateCalendar(
          this.displayCard.presentationDate
        );
      }
    },
    onDone() {
      this.resolve(() => {});
      this.$f7router.updateCurrentUrl('/dashboard/swimlane/commercial/sales');
    },
    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        case 'move-followup-closing':
          this.currentResponse = response;
          this.$refs.followUpDueDatePopup.open();
          break;

        case 'reschedule':
          this.currentResponse = response;
          this.$refs.presentationDueDatePopup.open();
          break;

        case 'unsold':
          this.currentResponse = response;
          this.$refs.archiveNotePopup.open();
          break;
      }
    },

    resolveDueDate(dueDate) {
      this.dueDate = dueDate;
      this.resolve();
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit('doResolve', this.currentResponse);
      this.$emit('doClosePopup');
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);

      if (this.archiveNote) {
        const { message } = await handleDetectLanguage(this.archiveNote);

        commentAndNote.push({
          code: 'note',
          title: 'Archive Note',
          htmlContent: this.archiveNote,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
          createdById: auth.currentUser.uid,
          language: message,
        });

        this.displayCard.archiveDate = firebase.firestore.Timestamp.now();
      }

      this.displayCard.commentAndNote = commentAndNote;

      if (!_.isEmpty(this.dueDate)) {
        this.displayCard.dueDate = toDateFirebase(this.dueDate);
      }

      if (!_.isEmpty(this.presentationDate)) {
        this.displayCard.presentationDate = toDateFirebase(
          this.presentationDate
        );
      }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard,
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },

    async saveNote(event) {
      if (event) {
        const commentAndNote = event.map(item => ({
          ...item,
          title: 'Presentation Note',
        }));

        this.displayCard.commentAndNote = commentAndNote;

        await this.updateCard({
          id: this.displayCard.id,
          doc: {
            commentAndNote: commentAndNote
          },
        })
      }
    },

    saveDate(dateProp, $event) {
      this[dateProp] = $event;
      if (!_.isEmpty(this[dateProp])) {
        if (
          !moment(this[dateProp][0]).isSame(
            moment(toDateCalendar(this.displayCard[dateProp])[0]),
            'day'
          )
        ) {
          let temp = {};
          temp[dateProp] = toDateFirebase(this[dateProp]);
          this.updateCard({
            id: this.card.id,
            doc: { ...temp },
          });
        }
      }
    },
  },
};
</script>

<style></style>
