<template>
  <div>
    <f7-list no-hairlines-md>
      <f7-list-input
        type="datepicker"
        label="Due Date"
        :calendar-params="{
          backdrop: true,
          header: true,
          footer: false,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
          },
        }"
        placeholder="Enter due date"
        :value="dueDate"
        @calendar:change="
          saveDueDate($event);
          $f7.calendar.close();
        "
      >
        <input-icon
          slot="media"
          icon="timer"
        ></input-icon>
      </f7-list-input>
    </f7-list>
    <!-- Manufactures -->
    <f7-block-header>Manufactures</f7-block-header>
    <f7-list>
      <f7-list-item title="Approved Design System">
        <f7-radio
          slot="media"
          value="approved-design-system"
          name="manufactures"
          :checked="
            displayCard.manufacturesApproved === 'approved-design-system'
          "
          @change="
            saveOptionManufactures(
              {
                clientApprovedAlternativeDisabled: true,
                clientApprovedDesignDisabled: false,
              },
              $event
            )
          "
        ></f7-radio>
      </f7-list-item>

      <f7-list-item title="Proposed Alternative System">
        <f7-radio
          slot="media"
          value="proposed-alternative-system"
          name="manufactures"
          :checked="
            displayCard.manufacturesApproved === 'proposed-alternative-system'
          "
          @change="
            saveOptionManufactures(
              {
                clientApprovedAlternativeDisabled: false,
                clientApprovedDesignDisabled: true,
              },
              $event
            )
          "
        ></f7-radio>
      </f7-list-item>

      <f7-list-input
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: false,
          dateFormat: 'mm/dd/yyyy',
        }"
        autofocus
        label="Approve/Proposed Date"
        placeholder="Enter Approve/Proposed Date"
        readonly
        :value="manufacturesApprovedDate"
        @calendar:change="saveDate('manufacturesApprovedDate', $event)"
      >
        <input-icon
          slot="media"
          icon="calendar_today"
        ></input-icon>
      </f7-list-input>
    </f7-list>

    <!-- Client -->
    <f7-block-header>Client</f7-block-header>
    <f7-list>
      <f7-list-item title="Approved Design System">
        <f7-radio
          slot="media"
          :disabled="clientApprovedDesignDisabled"
          value="approved-design-system"
          name="client"
          :checked="displayCard.clientApproved === 'approved-design-system'"
          @change="saveOptionClient($event)"
        ></f7-radio>
      </f7-list-item>
      <f7-list-item title="Approved Alternative System">
        <f7-radio
          slot="media"
          :disabled="clientApprovedAlternativeDisabled"
          value="approved-alternative-system"
          name="client"
          :checked="
            displayCard.clientApproved === 'approved-alternative-system'
          "
          @change="saveOptionClient($event)"
        ></f7-radio>
      </f7-list-item>
      <f7-list-input
        type="datepicker"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          header: true,
          footer: false,
          dateFormat: 'mm/dd/yyyy',
        }"
        label="Approve Date"
        placeholder="Enter Approve Date"
        readonly
        :value="clientApprovedDate"
        @calendar:change="saveDate('clientApprovedDate', $event)"
      >
        <input-icon
          slot="media"
          icon="calendar_today"
        ></input-icon>
      </f7-list-input>
    </f7-list>

    <estimate-input
      title="Re-create Estimate"
      :value="displayCard"
      :estimateList="estimateList"
      :action="action"
      boardId="pre-construction"
      @closePopup="closeActionPopup"
      @onUpdateEstimate="handleUpdateEstimate"
      @onCreateNewEstimate="handleCreateNewEstimate"
      @onDeleteEstimate="handleDeleteEstimate"
    ></estimate-input>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <!-- attachment -->
    <attachment-input
      :projectId="displayCard.id"
      title="Attachment"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>

    <user-input
      :value="displayCard.assigneeIDs"
      @input="setAssigneeIDs($event)"
    ></user-input>

    <note-editor
      @onChange="note = $event"
      :value="note"
      :isSaveButton="true"
      @onSave="
        onSaveNote({
          displayCard,
          title: 'Pre-Construction Note',
          note,
          callback: () => {
            note = '';
          },
        })
      "
    ></note-editor>

    <date-popup
      ref="dueDatePopup"
      v-model="newDueDate"
      title="Enter New Due Date"
      label="New Due Date"
      validate
      not-allow-past
      @done="resolveWithDueDate"
    ></date-popup>
  </div>
</template>

<script>
import EstimateInput from '@/components/inputs/EstimateInput.vue';

import UserInput from '../input/UserInput.vue';
import DatePopup from '../popup/DatePopup.vue';
import InputIcon from '../icon/InputIcon.vue';
import AttachmentInput from '../input/AttachmentInput.vue';
import PurchaseOrderInput from '../input/PurchaseOrderInput.vue';

import {
  toDateCalendar,
  toDateFirebase,
  toDisplayDateString,
} from '../../../../utility/datetime';

import { firebase, auth } from '../../../../services/firebase.service';

import { mapState, mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import _ from 'lodash';
import methodsMixins from '../../mixin/methods';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import moment from 'moment';
import { PROP_IS_DELETED, VALIDATION_MESSAGE } from '../../../../utility/const';
import NoteEditor from '../note/NoteEditor.vue';

export default {
  components: {
    UserInput,
    DatePopup,
    InputIcon,
    AttachmentInput,
    EstimateInput,
    PurchaseOrderInput,
    NoteEditor,
  },
  mixins: [methodsMixins],

  data: () => {
    return {
      manufacturesApprovedDate: [],
      clientApprovedDate: [],

      note: '',
      currentResponse: {},
      displayCard: {},
      dueDate: [],
      newDueDate: [],

      clientApprovedAlternativeDisabled: false,
      clientApprovedDesignDisabled: false,
    };
  },

  computed: {
    ...mapState('dashboard/project', ['card', 'action', 'response', 'users']),
    ...mapGetters('dashboard/estimate', ['estimateList']),
  },

  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      },
    },
  },

  methods: {
    ...mapActions('dashboard/project', ['updateCard']),
    ...mapActions('dashboard/estimate', [
      'updateEstimate',
      'deleteEstimate',
      'getEstimatesBys',
    ]),

    getEstimates() {
      if (!_.isEmpty(this.card)) {
        this.getEstimatesBys([
          {
            prop: 'projectId',
            val: this.card.id,
            op: '==',
          },
          {
            prop: PROP_IS_DELETED,
            val: false,
            op: '==',
          },
          { prop: 'status', val: ['incomplete', 'active', 'draft'], op: 'in' },
        ]);
      }
    },

    toDateCalendar(val) {
      return toDateCalendar(val);
    },
    toDateFirebase(val) {
      return toDateFirebase(val);
    },
    toDisplayDateString(val) {
      return toDisplayDateString(val);
    },

    setObject(obj, key, val) {
      Vue.set(obj, key, val);
    },

    initCardDisplayValues() {
      if (this.card) {
        this.displayCard = _.cloneDeep(this.card);
        this.dueDate = toDateCalendar(this.displayCard.dueDate);

        this.manufacturesApprovedDate = toDateCalendar(
          this.displayCard.manufacturesApprovedDate
        );
        this.clientApprovedDate = toDateCalendar(
          this.displayCard.clientApprovedDate
        );
        this.getEstimates();
      }
    },

    // Implement do something before resolve action
    doAction(response) {
      switch (response.code) {
        // case "save-and-close":
        // this.currentResponse = response;
        // this.$refs.dueDatePopup.open();

        // this.save();

        // this.$emit("doClosePopup");
        // break;

        case 'move-to-submittals':
          this.currentResponse = response;
          this.$refs.dueDatePopup.open();
          break;
      }
    },

    resolveWithDueDate() {
      this.dueDate = this.newDueDate;
      this.resolve();
    },

    // Implement  resolve action
    async resolve() {
      this.$f7.preloader.show();
      await this.save();
      this.$emit('doResolve', this.currentResponse);
      this.$emit('doClosePopup');
      this.$f7.preloader.hide();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      const commentAndNote = _.cloneDeep(this.displayCard.commentAndNote || []);
      if (this.note) {
        commentAndNote.push({
          code: 'note',
          title: 'Preinstallation Note',
          htmlContent: this.note,
          createdAt: firebase.firestore.Timestamp.now(),
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
        });
      }

      this.displayCard.commentAndNote = commentAndNote;

      this.displayCard.dueDate = toDateFirebase(this.dueDate);

      if (!_.isEmpty(this.manufacturesApprovedDate)) {
        this.displayCard.manufacturesApprovedDate = toDateFirebase(
          this.manufacturesApprovedDate
        );
      }

      if (!_.isEmpty(this.clientApprovedDate)) {
        this.displayCard.clientApprovedDate = toDateFirebase(
          this.clientApprovedDate
        );
      }

      await this.updateCard({
        id: this.displayCard.id,
        doc: this.displayCard,
      }).then(() => {
        this.$f7.preloader.hide();
      });
    },

    saveOptionManufactures(
      { clientApprovedAlternativeDisabled, clientApprovedDesignDisabled },
      event
    ) {
      this.clientApprovedAlternativeDisabled =
        clientApprovedAlternativeDisabled;
      this.clientApprovedDesignDisabled = clientApprovedDesignDisabled;
      this.setObject(
        this.displayCard,
        'manufacturesApproved',
        event.target.value
      );
      this.saveValueCard(
        'manufacturesApproved',
        this.displayCard.manufacturesApproved
      );
    },

    saveOptionClient(event) {
      this.setObject(this.displayCard, 'clientApproved', event.target.value);
      this.saveValueCard('clientApproved', this.displayCard.clientApproved);
    },

    saveDate(key, event) {
      this.$f7.calendar.close();
      // check is first render
      if (_.isEqual(this.displayCard[key], toDateFirebase(event))) return;

      this[key] = event;

      if (!_.isEmpty(this[key])) {
        this.displayCard[key] = toDateFirebase(this[key]);
        this.saveValueCard(key, this.displayCard[key]);
      }
    },

    async saveValueCard(key, value) {
      this.$f7.preloader.show();

      await this.updateCard({
        id: this.displayCard.id,
        doc: {
          [key]: value,
        },
      })
        .then(() => {
          this.$f7.preloader.hide();
        })
        .catch(() => {
          this.$f7.preloader.hide();
        });
    },

    closeActionPopup(callback) {
      this.$emit('doClosePopup', callback);
    },

    handleUpdateEstimate(data) {
      this.$f7.preloader.show();
      this.updateEstimate({
        id: data.id,
        doc: {
          ...data.doc,
        },
      })
        .then(() => this.getEstimates())
        .then(() => {
          this.$f7.preloader.hide();
        });
    },

    handleCreateNewEstimate() {
      const actionId = this.action.id;
      const projectId = this.displayCard.id;
      const propertyId = this.displayCard.propertyId || '-';
      const contactId = this.displayCard.contactId || '-';
      const self = this;

      this.$emit('doClosePopup', () => {
        this.$f7router.navigate(
          {
            name: 'new-estimate-swimlane-commercial',
            params: {
              boardType: self.$f7route.route.meta.boardType,
              businessCode: self.$f7route.route.meta.businessCode,
              boardId: 'pre-construction',
              actionId,
              projectId,
              propertyId,
              clientType: 'contact',
              clientId: contactId,
            },
          },
          { reloadAll: true, pushState: true }
        );
      });
    },

    handleDeleteEstimate(data) {
      this.$f7.preloader.show();
      this.deleteEstimate(data.id)
        .then(() => this.getEstimates())
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },
    saveDueDate($event) {
      this.dueDate = $event;
      if (!_.isEmpty(this.dueDate)) {
        if (
          !moment(this.dueDate[0]).isSame(
            moment(toDateCalendar(this.displayCard.dueDate)[0]),
            'day'
          )
        ) {
          this.updateCard({
            id: this.card.id,
            doc: { dueDate: toDateFirebase(this.dueDate) },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  color: var(--f7-input-error-text-color);
}
</style>
