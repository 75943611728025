<template>
  <div>
    <f7-block-title>Contact Information</f7-block-title>
    <f7-list media-list>
      <!-- Contact -->
      <contact-name-input
        ref="selectContact"
        media-list
        :displayCard="displayCard"
        :contactName="contactName"
        :companyId="displayCard.companyId"
        :queryFilters="`${
          company.contactRefs && company.contactRefs.length
            ? `companyIdRefs:${company.id}`
            : ''
        }`"
        @onSelected="handleSelectedContact"
        @onDeleteContact="onDeleteContact($event)"
        @onCreated="handleContactCreated"
        :autoFocus="true"
      />

      <!-- Company -->
      <company-name-input
        ref="selectCompany"
        media-list
        :displayCard="displayCard"
        :companyName="companyName"
        :contactId="displayCard.contactId"
        :errorMessage="selectCompanyMessage"
        :queryFilters="`${
          contact.companyRefs && contact.companyRefs.length
            ? `contactIdRefs:${contact.id} AND`
            : ''
        } NOT companyTypes:${COMPANY_TYPE_VENDOR}`"
        @onSelected="handleSelectedCompany"
        @onDeleteCompany="onDeleteContact($event)"
        @onCreated="handleCompanyCreated"
      />

      <!-- Lead Source -->
      <f7-list-input
        label="Lead Source"
        type="select"
        placeholder="Please choose..."
        :value="displayCard.leadSource"
        @input="set('leadSource', $event.target.value)"
      >
        <input-icon
          slot="media"
          icon="selection_pin_in_out"
        ></input-icon>
        <option
          value=""
          disabled
        >
          Select lead source
        </option>
        <option
          v-for="(type, index) in leadSourceCommercialList"
          :key="index"
          :value="type.value"
        >
          {{ type.displayName }}
        </option>
      </f7-list-input>
    </f7-list>

    <f7-block-title>Project Information</f7-block-title>
    <f7-list media-list>
      <!-- Property -->
      <property-name-input
        ref="selectProperty"
        media-list
        :displayCard="displayCard"
        :propertyName="propertyName"
        :companyId="displayCard.companyId"
        :errorMessage="selectPropertyMessage"
        :queryFilters="`${
          company.propertyRefs && company.propertyRefs.length
            ? `companyIdRefs:${company.id}`
            : ''
        }`"
        @onSelected="handleSelectedProperty"
        @onDeleteProperty="onDeleteProperty($event)"
        @onCreated="handlePropertyCreated"
      />

      <!-- title -->
      <f7-list-input
        label="Project Title"
        type="text"
        placeholder="Enter project title"
        clear-button
        :value="displayCard.title"
        @input="set('title', $event.target.value.trim())"
        :error-message="requiredErrorMessage('title')"
        error-message-force
      >
        <required-asterisk slot="label"></required-asterisk>
        <input-icon
          slot="media"
          icon="ticket"
        ></input-icon>
      </f7-list-input>

      <!-- Actions -->
      <f7-list-input
        :value="actionId"
        @input="actionId = $event.target.value"
        label="Move Project To"
        type="select"
      >
        <option
          v-for="action in actionListSorted"
          :key="action.id"
          :value="action.id"
        >
          {{ action.title }}
        </option>
        <input-icon
          slot="media"
          icon="arrow_2_squarepath"
        ></input-icon>
      </f7-list-input>

      <!--Due Date -->
      <f7-list-input
        label="Due Date"
        type="datepicker"
        placeholder="MM/DD/YYYY"
        :calendar-params="{
          backdrop: true,
          openIn: 'customModal',
          dateFormat: 'mm/dd/yyyy',
          disabled: {
            from: null,
            to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
          },
        }"
        :value="dueDate"
        @calendar:change="
          dueDate = $event;
          $f7.calendar.close();
        "
      >
        <input-icon
          slot="media"
          icon="timer"
        ></input-icon>
      </f7-list-input>

      <!-- Note -->
      <note-editor
        :value="note"
        @onChange="note = $event.trim()"
        :styleProp="`margin-top: 10px`"
      ></note-editor>
    </f7-list>
  </div>
</template>

<script>
import InputIcon from './icon/InputIcon.vue';

import { mapActions, mapGetters } from 'vuex';
import { firebase, auth } from '@/services/firebase.service';
import { toDateFirebase } from '@/utility/datetime';
import Vue from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import commonMixin from '../../../mixin/common';
import {
  BUSINESS_CODE_COMMERCIAL,
  COMPANY_TYPE_VENDOR,
  VALIDATION_MESSAGE,
} from '../../../../../utility/const';
import _ from 'lodash';
import { getFullAddress } from '@/utility/address';
import ContactNameInput from '@/components/inputs/ContactNameInput.vue';
import CompanyNameInput from '@/components/inputs/CompanyNameInput.vue';
import PropertyNameInput from '@/components/inputs/PropertyNameInput.vue';
import NoteEditor from '@/plugins/dashboard/components/note/NoteEditor.vue';

export default {
  mixins: [commonMixin],

  components: {
    ContactNameInput,
    CompanyNameInput,
    PropertyNameInput,
    InputIcon,
    NoteEditor,
  },

  props: { isNew: { type: Boolean, default: false } },

  data: () => {
    return {
      displayCard: {
        title: '',
        contactId: null,
        companyId: null,
        propertyId: null,
        leadSource: '',
      },
      dueDate: [],
      actionId: '',
      note: '',

      selectCompanyMessage: '',
      selectPropertyMessage: '',
      COMPANY_TYPE_VENDOR,

      newContact: false,
      newCompany: false,
    };
  },

  watch: {
    displayCard: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.contactId && this.selectCompanyMessage != '') {
          this.selectCompanyMessage = '';
        }
        if (
          val.contactId &&
          val.companyId &&
          this.selectPropertyMessage != ''
        ) {
          this.selectPropertyMessage = '';
        }
      },
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    displayCard: {
      title: { required },
      contactId: { required },
      companyId: { required },
    },
  },

  computed: {
    ...mapGetters('photo/project', ['actionListSorted', 'actionByCode']),

    ...mapGetters('photo/client', ['contact']),
    ...mapGetters('photo/client', ['company']),
    ...mapGetters('photo/client', ['property']),

    ...mapGetters('photo/app-constant', ['leadSourceCommercialList']),

    requiredErrorMessage() {
      return prop => {
        if (!this.v$.displayCard[prop].$error) return null;
        if (this.v$.displayCard[prop].required.$invalid)
          return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },

    contactName() {
      return this.getContactName(this.contact);
    },

    companyName() {
      return this.company ? this.company.companyName || '' : '';
    },

    propertyName() {
      return this.getPropertyName(this.property);
    },

    /**
     * Check for this company has property or not
     */
    hasPropertyRefs() {
      return this.company.propertyRefs && this.company.propertyRefs.length > 0;
    },
  },
  methods: {
    ...mapActions('photo/client', [
      'getContact',
      'getCompany',
      'getProperty',
      'setContact',
      'setCompany',
      'setProperty',
      'addPropertyCompanyRelationShip',
      'addCompanyContactRelationShip',
    ]),
    ...mapActions('photo/project', ['createNewProject']),

    initActionId() {
      if (!this.actionId) {
        this.actionId = this.actionByCode(110).id;
      }
    },
    reset() {
      this.displayCard = {
        title: '',
        contactId: null,
        companyId: null,
        propertyId: null,
        leadSource: '',
      };
      this.dueDate = [];
      this.actionId = '';
      this.note = '';
      this.selectCompanyMessage = '';
      this.selectPropertyMessage = '';
      this.v$.$reset();
    },

    navigateToSelectContact() {
      this.$refs.selectContact.open();
    },

    navigateToSelectCompany() {
      this.$refs.selectCompany.open();
    },

    navigateToSelectProperty() {
      this.$refs.selectProperty.open();
    },

    async handleContactCreated(contactId) {
      await this.getContact(contactId);
      this.set('contactId', contactId);
      // this.handleCompanySelected("");
      // this.$refs.selectCompany.autoFillCompany();
      this.newContact = true;
      this.changeProjectTitle();
    },

    async handleCompanyCreated(companyId) {
      this.$f7.preloader.show();

      await this.getCompany(companyId);
      this.set('companyId', companyId);
      // this.handlePropertySelected("");
      this.newCompany = true;

      if (companyId && this.contact && this.contact.id) {
        await this.addCompanyContactRelationShip({
          companyId,
          contactId: this.contact.id,
        });
      }

      this.$f7.preloader.hide();
    },

    async handlePropertyCreated(propertyId) {
      this.$f7.preloader.show();

      await this.getProperty(propertyId);
      this.set('propertyId', propertyId);
      if (propertyId && this.company && this.company.id) {
        await this.addPropertyCompanyRelationShip({
          propertyId,
          companyId: this.company.id,
        });
      }
      this.changeProjectTitle();

      this.$f7.preloader.hide();
    },

    async handleSelectedContact(contactId) {
      this.set('contactId', contactId);
      await this.getContact(contactId);
      this.$refs.selectCompany.autoFillCompany();
      // this.changeProjectTitle();
      this.newContact = false;
    },

    async handleSelectedCompany(companyId) {
      this.$f7.preloader.show();
      this.set('companyId', companyId);
      if (!companyId) await this.setCompany({});
      else await this.getCompany(companyId);
      this.$refs.selectProperty.autoFillProperty();
      if (
        this.newContact === true &&
        companyId &&
        this.contact &&
        this.contact.id
      ) {
        await this.addCompanyContactRelationShip({
          companyId,
          contactId: this.contact.id,
        });
      }

      if (!this.displayCard.contactId) {
        this.getContactByCompany();
      }

      this.$f7.preloader.hide();
      this.newCompany = false;
    },

    async handleSelectedProperty(propertyId) {
      this.$f7.preloader.show();

      this.set('propertyId', propertyId);
      if (!propertyId) await this.setProperty({});
      else await this.getProperty(propertyId);

      this.changeProjectTitle();

      if (this.newCompany && propertyId && this.company && this.company.id) {
        await this.addPropertyCompanyRelationShip({
          propertyId,
          companyId: this.company.id,
        });
      }

      if (!this.displayCard.companyId) {
        this.getCompanyAndContactByProperty();
      }

      this.$f7.preloader.hide();
    },

    async onDeleteContact(event) {
      event.stopPropagation();
      await this.setContact({});
      this.set('contactId', '');
      this.set('title', '');
      this.handleSelectedCompany('');
      this.handleSelectedProperty('');
    },

    async changeProjectTitle() {
      let projectTitle = '';
      if (!_.isEmpty(this.property)) {
        projectTitle = `${
          this.property.propertyName
        } - ${this.getPropertyAddress(this.property)}`;
      }
      this.set('title', projectTitle);
    },

    set(prop, val) {
      Vue.set(this.displayCard, prop, val);
    },

    invalid() {
      this.v$.$touch();
      return this.v$.$invalid;
    },
    create() {
      this.$f7.preloader.show();
      if (this.note) {
        this.displayCard.commentAndNote = [
          {
            code: 'note',
            title: 'Contact Lead Note',
            htmlContent: this.note,
            createdAt: firebase.firestore.Timestamp.now(),
            createdBy: auth.currentUser.displayName || auth.currentUser.email,
          },
        ];
      }

      let actionsHistory = [];
      actionsHistory.push({
        nextAction: 'Project Created From Roofer Cam',
        resolvedAt: firebase.firestore.Timestamp.now(),
        resolvedBy: auth.currentUser.displayName || auth.currentUser.email,
        previousAction: '',
      });

      this.displayCard.actionsHistory = actionsHistory;

      this.displayCard.status = 'open';
      this.displayCard.actions = [this.actionId];

      this.displayCard.dueDate = toDateFirebase(this.dueDate || new Date());
      this.displayCard.contactedDate = toDateFirebase(
        this.dueDate || new Date()
      );
      this.displayCard.businessCode = BUSINESS_CODE_COMMERCIAL;

      this.createNewProject(this.displayCard).then(() => {
        this.reset();
        this.$emit('doClosePopup');
        this.$f7.preloader.hide();
      });
    },
    getPropertyAddress(property) {
      if (!_.isEmpty(property)) {
        const addresses = property.addresses;
        if (!_.isEmpty(addresses) && _.isArray(addresses)) {
          let address = addresses.find(item => item.code === 'main');
          address = address || addresses[0] || [];

          if (!_.isEmpty(address)) {
            return getFullAddress(address) || address.value;
          }
        }
      }

      return '';
    },

    async getCompanyAndContactByProperty() {
      // check if has company then: get first company, catch: return
      if (this.property.companyIdRefs?.length) {
        const companyId = this.property.companyIdRefs[0];
        await this.getCompany(companyId);
        this.displayCard.companyId = companyId;

        // if company has contact, get firt contact, else return
        if (this.company.contactIdRefs.length) {
          const contactId = this.company.contactIdRefs[0];
          await this.getContact(contactId);
          this.displayCard.contactId = contactId;
        }
      }
    },

    async onDeleteProperty(event) {
      event.stopPropagation();
      await this.setProperty({});
      this.displayCard.propertyId = '';
    },

    async getContactByCompany() {
      if (this.company.contactIdRefs?.length) {
        const contactId = this.company.contactIdRefs[0];

        await this.getContact(contactId);
        this.displayCard.contactId = contactId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.item-title {
  flex: 1;
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  line-height: 26px;
}

.item-error-message {
  color: var(--f7-input-error-text-color);
  font-size: var(--f7-input-error-font-size);
  font-weight: var(--f7-input-error-font-weight);
}
</style>
