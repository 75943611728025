import _ from 'lodash';
import contact from './contact';
import company from './company';
import property from './property';
import globalSearch from './global-search';
import project from './project';
import appConstant from './app-constant';
import contract from './contract';
import emailNotification from './email-notification';
import customTable from './custom-table';
import user from './user';
import userSignature from './user-signature';
import common from './common';
import teamManagement from './team-management';
import administrationUser from './administration-user';
import scheduleValue from './schedule-value';
import scheduleSetting from './schedule-setting';
import proposalTemplate from './proposal-template';
import mileagePayRate from './mileage-pay-rate';
import employeeExpenses from './employee-expenses';
import albumTemplate from './album-template';
import notification from './notification';
import template from './template';
import assemblyTemplate from './assembly-template';
import photo from './photo';
import assemblyDrawing from './assembly-drawing';
import priceList from './price-list';
import proposalSection from './proposal-section';

export default tenant => {
  return {
    namespaced: true,
    modules: {
      user: user,
      common: common,
      contact: contact(tenant),
      company: company(tenant),
      branch: _.cloneDeep(company(tenant)),
      property: property(tenant),
      'global-search': globalSearch(tenant),
      project: project(tenant),
      'app-constant': appConstant(tenant),
      contract: contract(tenant),
      'email-notification': emailNotification(tenant),
      'custom-table': customTable(tenant),
      'team-management': teamManagement(tenant),
      'administration-user': administrationUser(tenant),
      'schedule-value': scheduleValue(tenant),
      'user-signature': userSignature(tenant),
      'schedule-setting': scheduleSetting(tenant),
      'proposal-template': proposalTemplate(tenant),
      'mileage-pay-rate': mileagePayRate(tenant),
      expense: employeeExpenses(tenant),
      'album-template': albumTemplate(tenant),
      notification: notification(tenant),
      'assembly-template': assemblyTemplate(tenant),
      photo: photo(tenant),
      'assembly-drawing': assemblyDrawing(tenant),
      'price-list': priceList(tenant),
      template: template(tenant),
      'proposal-section': proposalSection(tenant),
    },
  };
};
