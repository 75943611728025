<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link></f7-link>
      </f7-nav-left>
      <f7-nav-title>Technical Data Preview </f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div
      v-if="url"
      ref="wrapPreviewContent"
      class="display-flex flex-direction-column align-items-center justify-content-center text-color-black"
      style="height: calc(100vh - 50px)"
    >
      <img
        v-if="isImage(url)"
        style="width: 50%"
        :src="url"
        alt=""
      />
      <iframe
        v-else
        class="iframe-view"
        :src="`${url}#toolbar=0&view=fitH&scrollbar=0`"
        style="height: calc(100vh - 50px)"
        :width="$device.desktop ? '54%' : '100%'"
      ></iframe>
    </div>
    <div v-else>Not found data</div>
  </f7-page>
</template>

<script>
import { isImage } from '@/utility/common';

export default {
  data() {
    return {
      url: '',
    };
  },
  methods: {
    isImage,
  },
  async mounted() {
    this.url = atob(this.$f7route.params.url) || '';
  },
};
</script>
