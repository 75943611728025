<template>
  <div>
    <f7-list :inset="$device.desktop">
      <f7-row>
        <f7-col
          width="100"
          large="50"
        >
          <f7-list>
            <f7-list-item header="Field Area (SQ)">
              <f7-input
                slot="title"
                placeholder="Enter Field Area"
                :value="currentBuilding.fieldArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'fieldArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                  });
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                  });
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_FASTENERS,
                  });
                  updateQtyLabor({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_LABOR,
                    fieldArea: currentBuilding.fieldArea,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="arrow_right_square"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('fieldArea', 'Area', [
                    SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                    SECTION_TYPE_SHINGLE_FASTENERS,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>
            <f7-list-item header="Ridge Length (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Ridge Length"
                :value="currentBuilding.ridgeLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'ridgeLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                  }).then(() => {
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_LABOR,
                    });
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_VENTS,
                  });
                "
              >
              </f7-input>
              <input-icon
                slot="media"
                icon="arrow_right_square"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('ridgeLength', 'Linear', [
                    SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    SECTION_TYPE_SHINGLE_VENTS,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Hip Length (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Hip Length"
                :value="currentBuilding.hipLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'hipLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                  }).then(() => {
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_LABOR,
                    });
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="arrow_right_square"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('hipLength', 'Linear', [
                    SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>
          </f7-list>
        </f7-col>

        <f7-col
          width="100"
          large="50"
        >
          <f7-list>
            <f7-list-item header="Eave (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Eave"
                :value="currentBuilding.eaveLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'eaveLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  setField(
                    'perimeter',
                    Math.abs(parseFloat($event.target.value.trim()) || 0) +
                      (currentBuilding.rakeLength || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                  }).then(() => {
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_LABOR,
                    });
                  });
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="arrow_right_square"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('eaveLength', 'Linear', [
                    SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    SECTION_TYPE_SHINGLE_PREFAB_METAL,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>
            <f7-list-item header="Rake (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Rake"
                :value="currentBuilding.rakeLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'rakeLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  setField(
                    'perimeter',
                    (currentBuilding.eaveLength || 0) +
                      Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                  }).then(() => {
                    updateProducts({
                      roofType: ROOF_TYPE_SHINGLE,
                      sectionId: SECTION_TYPE_SHINGLE_LABOR,
                    });
                  });
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="arrow_right_square"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('rakeLength', 'Linear', [
                    SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
                    SECTION_TYPE_SHINGLE_PREFAB_METAL,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-input
              disabled
              label="Perimeter (LF)"
              placeholder="Enter Perimeter"
              :value="currentBuilding.perimeter"
              type="number"
              min="0"
            >
              <input-icon
                slot="media"
                icon="arrow_right_square"
              ></input-icon>
            </f7-list-input>

            <f7-list-item header="Valley Length (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Valley Length"
                :value="currentBuilding.valleyLength"
                type="number"
                min="0"
                @change="
                  setField(
                    'valleyLength',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_SHINGLE,
                    sectionId: SECTION_TYPE_SHINGLE_PREFAB_METAL,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="arrow_right_square"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('valleyLength', 'Linear', [
                    SECTION_TYPE_SHINGLE_UNDERLAYMENT,
                    SECTION_TYPE_SHINGLE_PREFAB_METAL,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>
          </f7-list>
        </f7-col>
      </f7-row>
    </f7-list>

    <section-product-table
      :items="tableData(SECTION_TYPE_SHINGLE_MAIN_SHINGLE)"
      :roofType="ROOF_TYPE_SHINGLE"
      :sectionId="SECTION_TYPE_SHINGLE_MAIN_SHINGLE"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_SHINGLE,
          SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      :items="tableData(SECTION_TYPE_SHINGLE_UNDERLAYMENT)"
      :roofType="ROOF_TYPE_SHINGLE"
      :sectionId="SECTION_TYPE_SHINGLE_UNDERLAYMENT"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_SHINGLE,
          SECTION_TYPE_SHINGLE_UNDERLAYMENT,
          $event
        )
      "
    ></section-product-table>

    <section-product-table
      :items="tableData(SECTION_TYPE_SHINGLE_FASTENERS)"
      :roofType="ROOF_TYPE_SHINGLE"
      :sectionId="SECTION_TYPE_SHINGLE_FASTENERS"
      @onChangeProductItem="
        onChangeProductItem(
          ROOF_TYPE_SHINGLE,
          SECTION_TYPE_SHINGLE_FASTENERS,
          $event
        )
      "
    ></section-product-table>
    <!-- Takeoff select value -->
    <dynamic-single-select
      ref="selectValue"
      search-placeholder="Search in Takeoffs"
      title="Takeoffs from StackCT"
      :options="takeoffListOptions"
      @changed="changeBuildingValue"
    >
    </dynamic-single-select>
  </div>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import SectionProductTable from '../tables/SectionProductTable.vue';
import DynamicSingleSelect from '../inputs/DynamicSingleSelect.vue';

import { mapGetters } from 'vuex';

import mixins from '../../utility/mixins';
import buildingMixins from '../../utility/building-mixins';
import {
  ROOF_TYPE_SHINGLE,
  SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
  SECTION_TYPE_SHINGLE_UNDERLAYMENT,
  SECTION_TYPE_SHINGLE_FASTENERS,
  SECTION_TYPE_SHINGLE_VENTS,
  SECTION_TYPE_SHINGLE_PREFAB_METAL,
  SECTION_TYPE_SHINGLE_LABOR,
} from '../../../../utility/const';

export default {
  components: { InputIcon, SectionProductTable, DynamicSingleSelect },

  mixins: [mixins, buildingMixins],

  data() {
    return {
      ROOF_TYPE_SHINGLE,
      SECTION_TYPE_SHINGLE_MAIN_SHINGLE,
      SECTION_TYPE_SHINGLE_UNDERLAYMENT,
      SECTION_TYPE_SHINGLE_FASTENERS,
      SECTION_TYPE_SHINGLE_VENTS,
      SECTION_TYPE_SHINGLE_PREFAB_METAL,
      SECTION_TYPE_SHINGLE_LABOR,
    };
  },

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', ['currentBuilding']),
  },

  methods: {
    validate() {},
    init() {},
    changeBuildingValue(property, value, sections = []) {
      if (!property) return;
      this.setField(property, value || 0);
      // update dependency data
      if (property == 'eaveLength' || property == 'rakeLength') {
        const perimeter =
          (parseFloat(this.currentBuilding.eaveLength) || 0) +
          (parseFloat(this.currentBuilding.rakeLength) || 0);
        this.setField('perimeter', perimeter);
      }
      // update items of sections
      sections.forEach(sectionId => {
        this.updateProducts({
          sectionId,
          roofType: ROOF_TYPE_SHINGLE,
        });
      });
    },
    openSelectValue(propery, type, sections, dependDataCallback = null) {
      this.$refs.selectValue.openSelectPopup(
        propery,
        type,
        sections,
        dependDataCallback
      );
    },
  },
};
</script>
