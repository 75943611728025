<template>
  <div :class="typeSwitchScreen !== 'step' && 'container-info'">
    <f7-list
      :inset="$device.desktop"
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.INFO
        )
      "
    >
      <div
        :class="
          typeSwitchScreen === 'step' && $device.desktop
            ? 'container-info-step-row'
            : 'container-info-step-column'
        "
      >
        <div style="flex: 1">
          <f7-list media-list>
            <f7-list-item>
              <div slot="before-title">Number of Insulation Layers</div>
              <template slot="inner">
                <f7-stepper
                  class="ins-layer-stepper"
                  raised
                  input-readonly
                  @stepper:plusclick="addInsulationLayer"
                  @stepper:minusclick="deleteInsulationLayer(0)"
                  :value="numberInsulationLayers"
                ></f7-stepper>
              </template>

              <input-icon
                slot="media"
                icon="number_circle"
              ></input-icon>
            </f7-list-item>
          </f7-list>

          <!-- insulationLayers -->
          <div
            v-for="(layer, index) in currentBuilding.insulationLayers || []"
            :key="index"
          >
            <f7-row>
              <f7-col
                ><f7-row class="justify-content-flex-start">
                  <f7-block-title>Layer# {{ index + 1 }}</f7-block-title>
                </f7-row></f7-col
              >
              <f7-col>
                <f7-row class="justify-content-flex-end">
                  <f7-button
                    class="align-content-flex-start margin-right"
                    @click="deleteInsulationLayer(index, true)"
                    ><f7-icon f7="trash"></f7-icon
                  ></f7-button>
                </f7-row>
              </f7-col>
            </f7-row>
            <layer-item
              ref="layerItem"
              :layer="layer"
              :subStrateType="currentBuilding.substrate"
              @setLayerValue="setLayerValue($event, index)"
            ></layer-item>
          </div>
        </div>

        <div style="flex: 1">
          <!-- number insulationLayers  -->
          <f7-list>
            <f7-list-item header="Field Area (SQ)">
              <f7-input
                slot="title"
                placeholder="Enter Field Area"
                :value="currentBuilding.fieldArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'fieldArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_MEMBRANE,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                  updateQtyLabor({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_LABOR,
                    fieldArea: currentBuilding.fieldArea,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="app_badge"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('fieldArea', 'Area', [
                    SECTION_TYPE_TPO_INSULATION,
                    SECTION_TYPE_TPO_MEMBRANE,
                    SECTION_TYPE_TPO_ACCESSORIES,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Wall Area (SQ)">
              <input-icon
                slot="media"
                icon="rectangle_split_3x1"
              ></input-icon>
              <f7-input
                slot="title"
                placeholder="Enter Wall Area"
                :value="currentBuilding.wallArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'wallArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_MEMBRANE,
                  });

                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_ACCESSORIES,
                  });
                "
              ></f7-input>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('wallArea', 'Area', [
                    SECTION_TYPE_TPO_INSULATION,
                    SECTION_TYPE_TPO_MEMBRANE,
                    SECTION_TYPE_TPO_ACCESSORIES,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Tapered Area (SQ)">
              <f7-input
                slot="title"
                placeholder="Enter Tapered Area"
                :value="currentBuilding.taperedArea"
                type="number"
                min="0"
                @change="
                  setField(
                    'taperedArea',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });
                "
              >
              </f7-input>
              <input-icon
                slot="media"
                icon="line_horizontal_3_decrease_circle"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('taperedArea', 'Area', [
                    SECTION_TYPE_TPO_INSULATION,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>
          </f7-list>

          <f7-list>
            <f7-list-item header="Perimeter (LF)">
              <f7-input
                slot="title"
                placeholder="Enter Perimeter"
                :value="currentBuilding.perimeter"
                type="number"
                min="0"
                @change="
                  setField(
                    'perimeter',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });
                  calculationTermBar(
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="app"
              ></input-icon>
              <f7-button
                v-if="currentBuilding.stackctIntegration"
                raised
                small
                class="display-flex align-items-center"
                slot="after"
                @click="
                  openSelectValue('perimeter', 'Linear', [
                    SECTION_TYPE_TPO_INSULATION,
                  ])
                "
                icon-f7="arrow_down_square"
              >
                &nbsp;&nbsp;StackCT
              </f7-button>
            </f7-list-item>

            <f7-list-item header="Number of Corner">
              <f7-input
                slot="title"
                placeholder="Enter Corner"
                :value="currentBuilding.corner"
                type="number"
                min="0"
                @change="
                  setField(
                    'corner',
                    Math.abs(parseFloat($event.target.value.trim()) || 0)
                  );
                  updateProducts({
                    roofType: ROOF_TYPE_TPO,
                    sectionId: SECTION_TYPE_TPO_INSULATION,
                  });
                "
              ></f7-input>
              <input-icon
                slot="media"
                icon="number_square"
              ></input-icon>
            </f7-list-item>

            <!-- building over 35 feet tall -->
            <f7-list-item
              title="Building Over 35 Feet Tall"
              name="demo-checkbox"
            >
              <input-icon
                slot="media"
                icon="square_stack_3d_down_dottedline"
              ></input-icon>
              <f7-toggle
                :checked="over35FeelTall"
                @toggle:change="onChangeOver35FeelTall"
              ></f7-toggle>
            </f7-list-item>
          </f7-list>
        </div>
      </div>
    </f7-list>

    <section-product-table
      v-if="
        checkShowContentScreenSingleInEstimate(
          showContentType,
          CONTENT_TYPE_EST.TABLE
        )
      "
      :items="tableData(SECTION_TYPE_TPO_INSULATION)"
      :roofType="ROOF_TYPE_TPO"
      :sectionId="SECTION_TYPE_TPO_INSULATION"
      @onChangeProductItem="onChangeProductItem"
    ></section-product-table>
    <!-- Takeoff select value -->
    <dynamic-single-select
      ref="selectValue"
      search-placeholder="Search in Takeoffs"
      title="Takeoffs from StackCT"
      :options="takeoffListOptions"
      @changed="changeBuildingValue"
    >
    </dynamic-single-select>
  </div>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import SectionProductTable from '../tables/SectionProductTable.vue';
import LayerItem from './LayerItem.vue';
import DynamicSingleSelect from '../inputs/DynamicSingleSelect.vue';

import { mapGetters } from 'vuex';
import _ from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { required, minValue, maxValue } from '@vuelidate/validators';

import mixins from '../../utility/mixins';
import buildingMixins from '../../utility/building-mixins';
import {
  ROOF_TYPE_TPO,
  SECTION_TYPE_TPO_INSULATION,
  SECTION_TYPE_TPO_MEMBRANE,
  SECTION_TYPE_TPO_ACCESSORIES,
  SECTION_TYPE_TPO_LABOR,
  CONTENT_TYPE_EST,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';
import { checkShowContentScreenSingleInEstimate } from '@/utility/common';

export default {
  components: {
    SectionProductTable,
    InputIcon,
    LayerItem,
    DynamicSingleSelect,
  },

  props: {
    showContentType: {
      type: String,
      default: 'all',
    },
  },

  mixins: [mixins, buildingMixins],

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations: {
    currentBuilding: {
      numberInsulationLayers: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(20),
      },
    },
  },

  data() {
    return {
      ROOF_TYPE_TPO,
      SECTION_TYPE_TPO_INSULATION,
      SECTION_TYPE_TPO_MEMBRANE,
      SECTION_TYPE_TPO_ACCESSORIES,
      CONTENT_TYPE_EST,
      SECTION_TYPE_TPO_LABOR,
      numberInsulationLayers: 0,
      over35FeelTall: false,
    };
  },

  computed: {
    ...mapGetters('estimate/estimate-page/estimate', [
      'currentBuilding',
      'typeSwitchScreen',
    ]),
    numberInsulationLayersErrorMessage() {
      if (!this.v$.currentBuilding.$error) return '';
      if (this.v$.currentBuilding.numberInsulationLayers.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currentBuilding.numberInsulationLayers.minValue.$invalid)
        return VALIDATION_MESSAGE.GREATER_THAN_ZERO;
      if (this.v$.currentBuilding.numberInsulationLayers.maxValue.$invalid)
        return VALIDATION_MESSAGE.LESS_THAN_OR_EQUAL_TO_200;
      return '';
    },
  },

  methods: {
    checkShowContentScreenSingleInEstimate,
    onChangeOver35FeelTall(value) {
      this.over35FeelTall = value;
      this.saveBuildingValue('over35FeelTall', value);
      this.updateProducts({
        roofType: ROOF_TYPE_TPO,
        sectionId: SECTION_TYPE_TPO_INSULATION,
      });
    },
    calculationTermBar(perimeter) {
      const productData = _.cloneDeep(this.currentBuilding.productData);

      const targetProduct = productData.find(
        item =>
          item.category === 'Miscellaneous' &&
          item.productList.some(
            product => product.subCategoryName === 'Term Bar'
          )
      );
      if (!targetProduct) return;

      const productToUpdate = targetProduct.productList.find(
        product => product.subCategoryName === 'Term Bar'
      );

      if (!productToUpdate) return;

      const qty =
        perimeter === 0
          ? 0
          : Math.round(
              (perimeter / productToUpdate.unitSize) *
                productToUpdate.wasterFactor
            );

      productToUpdate.orderQty = qty;

      productToUpdate.actualQty = qty;

      this.saveBuildingValue('productData', productData);
    },

    init() {
      this.isCreatingNewEstimate = false;

      this.numberInsulationLayers =
        this.currentBuilding.numberInsulationLayers || 0;

      this.over35FeelTall = !!this.currentBuilding.over35FeelTall;
    },
    changeBuildingValue(property, value, sections = []) {
      if (!property) return;
      this.setField(property, value || 0);
      sections.forEach(sectionId => {
        this.updateProducts({
          sectionId,
          roofType: ROOF_TYPE_TPO,
        });
      });
    },
    openSelectValue(propery, type, sections) {
      this.$refs.selectValue.openSelectPopup(propery, type, sections);
    },
    onChangenumberInsulationLayers(number) {
      if (isNaN(number)) return;
      number = number < 20 ? number : 20; //max value 20
      number = number < 0 ? 0 : number; //min value 0
      let insulationLayers =
        _.cloneDeep(this.currentBuilding.insulationLayers) || [];
      const layerLength = (this.currentBuilding.insulationLayers || []).length;
      if (layerLength < number) {
        for (let i = 0; i < number - layerLength; i++) {
          insulationLayers.push({
            insulationType: '',
            thickness: '',
            attached: '',
          });
        }
      } else {
        insulationLayers.length = number;
      }
      this.saveBuildingValue('numberInsulationLayers', number);
      this.saveBuildingValue('insulationLayers', insulationLayers);
    },

    setLayerValue(object, index) {
      if (index >= 0) {
        let layerEditing = _.cloneDeep(this.currentBuilding.insulationLayers);

        layerEditing[index][object.field] = object.value;
        // Clean layer
        if (object.field === 'insulationType') {
          layerEditing[index]['thickness'] = '';
          layerEditing[index]['attached'] = '';
          layerEditing[index]['coverageRate'] = 0;
          layerEditing[index]['perimeterCoverageRate'] = 0;
          layerEditing[index]['cornerCoverageRate'] = 0;
          layerEditing[index]['screwsPerBoard'] = 0;
        }

        this.setField('insulationLayers', layerEditing);
        this.updateProducts({
          roofType: ROOF_TYPE_TPO,
          sectionId: SECTION_TYPE_TPO_INSULATION,
        });
      }
    },

    validate() {
      // this.v$.$touch();
      // if (this.v$.$invalid) {
      //   return true;
      // }
      let error = 0;
      for (const item of this.$refs.layerItem || []) {
        item.v$.$touch();
        if (item.v$.$invalid) {
          error += 1;
        }
      }
      if (error > 0) return true;
      return false;
    },

    onChangeProductItem({ oldProduct, newProduct, index }) {
      return this.changeProductItem({
        roofType: ROOF_TYPE_TPO,
        sectionId: SECTION_TYPE_TPO_INSULATION,
        oldProduct,
        newProduct,
        index,
      });
    },

    deleteInsulationLayer(index, isClickIcon = false) {
      const self = this;

      let insulationLayers =
        _.cloneDeep(this.currentBuilding.insulationLayers) || [];

      insulationLayers.splice(index, 1);

      if (isClickIcon) {
        this.numberInsulationLayers = insulationLayers.length;
      }

      const refs = [];

      refs.push(
        this.saveBuildingValue(
          'numberInsulationLayers',
          insulationLayers.length
        )
      );
      refs.push(this.saveBuildingValue('insulationLayers', insulationLayers));

      Promise.all(refs)
        .then(() => {
          self.updateProducts({
            roofType: ROOF_TYPE_TPO,
            sectionId: SECTION_TYPE_TPO_INSULATION,
          });
        })
        .then(() => {
          if (this.currentBuilding.numberInsulationLayers === 0) {
            this.$ri.dialog.openInfoDialog({
              title: 'Insulation Step',
              content: 'The insulation layers must be greater than 1',
              hideCancelButton: true,
              onClick: (_sefl, index) => {
                if (index === 0) {
                  _sefl.app.dialog.close();
                } else if (index === 1) {
                  _sefl.app.dialog.close();
                }
              },
            });
          }
        });
    },

    addInsulationLayer() {
      if (this.currentBuilding.numberInsulationLayers === 20) {
        this.$ri.dialog.openInfoDialog({
          title: 'Insulation Step',
          content: 'The insulation layers must be less than or equal to 20',
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });

        return;
      }
      // this.v$.currentBuilding.numberInsulationLayers.$reset();
      // this.v$.currentBuilding.numberInsulationLayers.$touch();

      const self = this;
      let insulationLayers =
        _.cloneDeep(this.currentBuilding.insulationLayers) || [];

      insulationLayers.unshift({
        insulationType: '',
        thickness: '',
        attached: '',
      });

      const refs = [];

      refs.push(
        this.saveBuildingValue(
          'numberInsulationLayers',
          insulationLayers.length
        )
      );
      refs.push(this.saveBuildingValue('insulationLayers', insulationLayers));

      Promise.all(refs).then(() => {
        self.updateProducts({
          roofType: ROOF_TYPE_TPO,
          sectionId: SECTION_TYPE_TPO_INSULATION,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-input select.input-invalid {
  color: var(--f7-color-text-neutral) !important;
}

.ins-layer-stepper ::v-deep input[type='text'] {
  color: var(--f7-color-text-neutral);
}

.container-info-step-row {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 16px;
}

.container-info-step-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 5px;
  gap: 10px;
}

.container-info {
  background-color: var(--f7-color-bg-dark);
  padding: 8px 0 16px 0;
}
</style>
