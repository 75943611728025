<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left
        ><f7-link
          v-if="!$device.desktop"
          icon-f7="chevron_left"
          @click.native="handleBack"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ proposalTemplate.number }}</f7-nav-title>
      <f7-nav-right>
        <f7-link
          v-show="isEdited"
          @click.native="onSave"
          >Save</f7-link
        >
      </f7-nav-right>
    </f7-navbar>

    <div
      ref="scrollableContainer"
      style="height: 100%; overflow: auto; position: relative; width: 100%"
      @scroll="onViewerScroll"
    >
      <f7-row no-gap>
        <f7-col
          :width="$device.desktop ? 30 : 100"
          style="position: sticky; top: 0; z-index: 9999"
        >
          <f7-card v-if="$device.desktop">
            <f7-card-header class="justify-content-flex-start">
              <f7-icon
                class="margin-right-half"
                f7="square_list"
                color="primary"
              ></f7-icon>
              Table of Contents
            </f7-card-header>
            <f7-card-content>
              <f7-list>
                <draggable
                  :list="sortedSections"
                  :animation="200"
                  class="section-content-container"
                  ghost-class="ghost"
                  :delay="$device.desktop ? 0 : 100"
                  :delay-on-touch-only="$device.desktop ? true : false"
                  :touch-start-threshold="20"
                  group="sections"
                  draggable=".section-item"
                  :move="checkHeader"
                  tag="ul"
                  @change="onDndChange"
                >
                  <f7-list-item
                    v-for="(section, index) in sortedSections"
                    :key="index"
                    :title="section.sectionName"
                    class="section-item"
                    link="#"
                    :text-color="
                      index === currentSectionIndex ? 'primary' : 'default'
                    "
                    @click="sectionClick(section, index)"
                  >
                  </f7-list-item>
                </draggable>
              </f7-list> </f7-card-content
          ></f7-card>
          <f7-block
            v-else
            strong
            class="no-margin display-flex justify-content-space-between width-100"
            style="gap: 8px; position: fixed; height: 53px"
          >
            <div>
              <b>{{ proposalTemplate.name }}</b>
            </div>
            <div @click="isShowContentMobile = !isShowContentMobile">
              <f7-icon
                size="18"
                class="cursor-pointer"
                f7="square_list"
                color="primary"
              ></f7-icon>
              <div
                v-show="isShowContentMobile"
                class="dl-item"
              >
                <f7-list class="no-margin">
                  <f7-list-item
                    v-for="(section, index) in sortedSections"
                    :key="index"
                    :title="section.sectionName"
                    class="section-item"
                    link="#"
                    :text-color="
                      index === currentSectionIndex ? 'primary' : 'default'
                    "
                    @click="sectionClick(section, index)"
                  ></f7-list-item>
                </f7-list>
              </div>
            </div>
          </f7-block>
        </f7-col>
        <f7-col
          ref="wrapContent"
          :width="$device.desktop ? 70 : 100"
          class="display-flex flex-direction-column"
          :style="`
          margin-top: ${$device.desktop ? '0' : '53px'}`"
        >
          <div
            v-show="$device.desktop"
            class="scroll-template"
          >
            <f7-card-header
              style="background-color: var(--f7-color-bg-4-neutral)"
            >
              {{ proposalTemplate.name }}
            </f7-card-header>
          </div>
          <f7-block
            class="display-flex justify-content-center scroll-container"
          >
            <div
              class="editor-section align-self-center"
              :style="styleContent"
            >
              <div
                v-for="(section, index) in sortedSections"
                :key="index"
              >
                <section-editor
                  :ref="`editor_${index}`"
                  :section="section"
                  @editorChange="handleEditorChange"
                  @doAction="doAction"
                ></section-editor>
              </div>
              <div class="tail-section"></div>
            </div>
          </f7-block>
        </f7-col>
      </f7-row>
    </div>

    <proposal-verbiage-popup
      ref="verbiageProposalPopup"
      @on-save="saveVerbiageProposalTemplate"
    ></proposal-verbiage-popup>

    <edit-table-popup
      ref="editTablePopup"
      @on-save="editTable"
    ></edit-table-popup>
  </f7-page>
</template>

<script>
import ProposalVerbiagePopup from '../components/ProposalVerbiagePopup.vue';
import EditTablePopup from '../components/EditTablePopup.vue';
import sectionEditor from '../components/SectionEditor.vue';
import draggable from 'vuedraggable';
import _ from 'lodash';

import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    sectionEditor,
    draggable,
    ProposalVerbiagePopup,
    EditTablePopup,
  },

  data: () => {
    return {
      hasProject: false,
      isEdited: false,
      currentSectionIndex: 0,
      sortedSections: [],
      styleContent: '',
      isShowContentMobile: false,
      isAutoSave: false,
      autoSaveTimeoutId: null,
    };
  },

  mounted() {
    // this.unbindProposalTemplate();
    let proposalTemplateId = this.$f7route.params.id || '';
    // console.log(proposalTemplateId);
    if (proposalTemplateId != '') {
      const refs = [];
      this.$f7.preloader.show();

      refs.push(this.bindProposalTemplate(proposalTemplateId));

      Promise.all(refs).finally(() => {
        this.$f7.preloader.hide();
        this.lowResDesktopScreen();
        // console.log(this.proposalTemplate);
      });
    }
  },
  updated() {
    const elementEditorSection = document.querySelector('.editor-section');
    if (elementEditorSection) {
      const height = elementEditorSection.getBoundingClientRect().height;
      if (
        height > 0 &&
        this.styleContent &&
        !this.styleContent.includes('height')
      ) {
        this.styleContent =
          _.cloneDeep(this.styleContent) + ` height: ${height}px;`;
      }
    }
  },

  computed: {
    ...mapGetters('common/proposal-template', ['proposalTemplate']),
  },

  methods: {
    ...mapActions('common/proposal-template', [
      'bindProposalTemplate',
      'unbindProposalTemplate',
      'updateTemplateSectionValue',
      'updateProposalTemplate',
    ]),

    lowResDesktopScreen() {
      this.$nextTick(() => {
        const letterFullWidth = 1020;
        const width = this.$refs.wrapContent.$el.clientWidth - 30;
        if (width < letterFullWidth) {
          const scaleRatio = width / letterFullWidth;
          this.styleContent = `transform: scale(${scaleRatio}); margin-top: 10px; transform-origin: top center;`;
        } else {
          this.styleContent = '';
        }
      });
    },

    handleEditorChange({ sectionId, value }) {
      this.updateTemplateSectionValue({ sectionId, value });
    },

    sectionClick(section, index) {
      this.currentSectionIndex = index;
      this.$refs[`editor_${index}`][0].moveSectionToView(section);
    },

    onSave() {
      this.$f7.preloader.show();
      this.cancelAutoSaveTimeout();
      this.updateProposalTemplate({
        id: this.proposalTemplate.id,
        doc: { sections: this.proposalTemplate.sections },
      }).finally(() => {
        this.$f7.preloader.hide();
        setTimeout(() => {
          this.isEdited = false;
          this.isAutoSave = false;
        }, 500);
      });
    },
    checkHeader(value) {
      const indexFooterSection = (this.sortedSections || []).findIndex(
        item => item.sectionId === 'footer-section'
      );

      if (
        [0, 1, indexFooterSection].includes(
          value.draggedContext.element.index
        ) ||
        [0, 1, indexFooterSection].includes(value.relatedContext.index)
      ) {
        return false;
      }
      return true;
    },
    async onDndChange(dndEvent) {
      const sections = _.clone(this.sortedSections);
      const eventKeys = Object.keys(dndEvent);
      for (let index = 0; index < eventKeys.length; index++) {
        const key = eventKeys[index];
        const event = dndEvent[key];
        switch (key) {
          case 'moved':
            this.$f7.preloader.show();
            if (event.oldIndex < event.newIndex) {
              sections[event.newIndex].index =
                sections[event.newIndex - 1].index;
              for (let i = event.oldIndex; i < event.newIndex; i++) {
                sections[i].index = sections[i].index - 1;
              }
            } else {
              sections[event.newIndex].index =
                sections[event.newIndex + 1].index;
              for (let i = event.newIndex + 1; i <= event.oldIndex; i++) {
                sections[i].index = sections[i].index + 1;
              }
            }

            this.currentSectionIndex = event.newIndex;
            await this.updateProposalTemplate({
              id: this.proposalTemplate.id,
              doc: { sections },
            }).then(() => {
              this.sectionClick(event.element, event.newIndex);
              this.$f7.preloader.hide();
            });
            break;
        }
      }
    },

    updatePage(event, index) {},

    addNewPage() {},

    removePage() {},

    doAction({ actionCode, sectionId }) {
      switch (actionCode) {
        case 'edit-verbiage':
          this.$refs.verbiageProposalPopup.openPopup(sectionId);
          break;

        case 'edit-table':
          this.$refs.editTablePopup.openPopup();
          break;
      }
    },

    saveVerbiageProposalTemplate({ sectionId, blockData, callback }) {
      this.$f7.preloader.show();
      const sections = _.cloneDeep(this.proposalTemplate.sections);
      const orgSection = sections.find(
        section => section.sectionId === sectionId
      );
      orgSection.blockData = blockData;

      return this.updateProposalTemplate({
        id: this.proposalTemplate.id,
        doc: { sections },
      }).finally(() => {
        callback();
        this.$f7.preloader.hide();
      });
    },

    editTable({ columns, callback }) {
      this.$f7.preloader.show();

      return this.updateProposalTemplate({
        id: this.proposalTemplate.id,
        doc: { productTableColumns: columns },
      }).finally(() => {
        callback();
        this.$f7.preloader.hide();
      });
    },
    onViewerScroll() {
      const scrollContainer = this.$refs['scrollableContainer'];
      const scrollViewer = scrollContainer.getBoundingClientRect();

      if (scrollContainer.scrollTop < this.scrollPos) {
        // Scroll direction UP
        for (let index = this.sortedSections.length - 1; index >= 0; index--) {
          const editor = this.$refs[`editor_${index}`][0];
          if (editor) {
            const isInViewport = editor.isInViewport(scrollViewer.top);
            if (isInViewport === true) {
              this.currentSectionIndex = index;
            }
          }
        }
      } else {
        // Scroll direction DOWN
        const isAtBottom =
          scrollContainer.scrollTop + scrollContainer.clientHeight >=
          scrollContainer.scrollHeight;
        if (isAtBottom) {
          this.currentSectionIndex = this.sortedSections.length - 1;
          return;
        }
        for (let index = 0; index < this.sortedSections.length; index++) {
          const editor = this.$refs[`editor_${index}`][0];
          if (editor) {
            const isInViewport = editor.isInViewport(scrollViewer.top);
            if (isInViewport === true) {
              this.currentSectionIndex = index;
              break;
            }
          }
        }
      }

      this.scrollPos = scrollContainer.scrollTop;
    },
    handleBack() {
      this.$f7router.back('/proposal-template-v2', { force: true });
    },
    cancelAutoSaveTimeout() {
      if (this.autoSaveTimeoutId) {
        clearTimeout(this.autoSaveTimeoutId);
        this.autoSaveTimeoutId = null;
      }
    },
  },

  watch: {
    isAutoSave: {
      handler(val) {
        if (val && this.isEdited) {
          this.cancelAutoSaveTimeout();
          this.autoSaveTimeoutId = setTimeout(
            () => {
              this.onSave(); // isResetProposal = false : do not reset proposal after auto save
              // this.clearTempProposal();
            },
            5 * 60 * 1000
          ); //After 5 minutes of editing, perform auto save
        }
      },
    },
    proposalTemplate: {
      handler(val, old) {
        const oldSections = old ? old.sections || [] : [];
        const newSections = val ? val.sections || [] : [];
        if (_.differenceWith(newSections, oldSections, _.isEqual).length) {
          this.sortedSections = _.cloneDeep(newSections).sort(
            (a, b) => a.index - b.index
          );
          this.isEdited = false;
          this.isAutoSave = false;
        } else {
          this.isEdited = true;
          this.isAutoSave = true;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-container {
  margin: 0px 10px 0px 10px;
  direction: ltr;
  scroll-behavior: smooth;
}

.editor-section {
  // height: calc(100vh - 110px);
  direction: ltr;
}
.column-content-container {
  height: 100%;
  overflow-y: auto;
}
.ghost {
  opacity: 0.5;
  background: #f7fafc;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.tail-section {
  height: calc(100vh * 0.8);
}
.dl-item {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9999;
  max-height: 60vh;
  overflow-y: scroll;
  box-shadow: rgba(33, 35, 38, 0.15) 0px 15px 10px -10px;
}
.scroll-template {
  padding-top: 10px;
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: var(--f7-color-bg-dark);
}
</style>
