import Vue from 'vue';
import * as types from './types';

export default {
  setObjectSubscribe(state, payload) {
    Vue.set(state, 'objectSubscribe', payload);
  },
  setDetailId(state, id) {
    state.detailId = id;
  },

  setObjectList(state, payload) {
    Vue.set(state, 'objectList', payload);
  },

  [types.SET_CATEGORY_LIST](state, payload) {
    Vue.set(state, 'objectList', payload);
  }
};
