export const ESTIMATE_PRODUCT_TABLE_HEARDER = [
  // {
  //   text: "SKU",
  //   align: "left",
  //   sortable: false,
  //   value: "sku",
  //   cssClass: "label-cell"
  // },
  {
    text: 'Product Item',
    align: 'left',
    sortable: false,
    value: 'productItem',
    cssClass: 'label-cell',
  },
  {
    text: 'Proposal Note',
    value: 'proposalNote',
    sortable: false,
    align: 'left',
  },
  {
    text: 'Sub Category',
    value: 'subCategory',
    sortable: false,
    align: 'left',
  },
  {
    text: 'Qty',
    value: 'actualQty',
    sortable: false,
    align: 'center',
    cssClass: 'numeric-cell',
    width: 'width: 230px',
  },
  {
    text: 'Order Qty',
    value: 'orderQty',
    sortable: false,
    align: 'center',
    cssClass: 'numeric-cell',
    width: 'width: 150px',
  },
  // {
  //   text: "Package",
  //   value: "Package",
  //   sortable: false,
  //   align: "right",
  //   cssClass: "label-cell"
  // },
  // {
  //   text: "Unit Packs",
  //   value: "unitPack",
  //   sortable: false,
  //   align: "left",
  //   cssClass: "numeric-cell"
  // },
  // {
  //   text: "Unit Size",
  //   value: "unitSize",
  //   sortable: false,
  //   align: "left",
  //   cssClass: "numeric-cell"
  // },
  // {
  //   text: "UoM",
  //   value: "UoM",
  //   sortable: false,
  //   align: "right",
  //   cssClass: "label-cell"
  // },

  {
    text: 'Price',
    value: 'price',
    sortable: false,
    align: 'right',
    cssClass: 'numeric-cell',
    width: 'width: 60px',
  },

  {
    text: 'Total Cost',
    value: 'totalCost',
    sortable: false,
    align: 'right',
    cssClass: 'numeric-cell',
    width: 'width: 120px',
    tooltip: {
      id: 'total-cost-tooltip-header',
      content: 'Temporary cost estimate for 1 building/section',
    },
  },
  {
    text: 'Price Override',
    value: 'priceOverride',
    sortable: false,
    align: 'center',
    cssClass: 'label-cell',
  },

  {
    text: 'Color',
    value: 'color',
    sortable: false,
    align: 'left',
    cssClass: 'label-cell',
  },
  {
    text: 'Manufacturer',
    value: 'manufacturer',
    sortable: false,
    align: 'left',
    cssClass: 'label-cell',
  },
  {
    text: 'Technical Data',
    value: 'technicalData',
    sortable: false,
    align: 'left',
    cssClass: 'label-cell',
  },
  {
    text: 'Waste Factor',
    value: 'waster_factor',
    sortable: false,
    align: 'right',
    cssClass: 'numeric-cell',
  },
  // {
  //   text: "Actions",
  //   value: "actions",
  //   sortable: false,
  //   align: "right",
  //   cssClass: "numeric-cell"
  // }
];

export const METAL_PRODUCT_TABLE_HEARDER = [
  {
    text: 'Product Item',
    align: 'left',
    sortable: false,
    value: 'productItem',
    cssClass: 'label-cell',
  },

  {
    text: 'Proposal Note',
    value: 'proposalNote',
    sortable: false,
    align: 'left',
  },
  {
    text: 'Sub Category',
    value: 'subCategory',
    sortable: false,
    align: 'left',
  },
  {
    text: 'Per 100 LF',
    value: 'per100lf',
    sortable: false,
    align: 'right',
    cssClass: 'numeric-cell',
  },

  {
    text: 'Qty',
    value: 'actualQty',
    sortable: false,
    align: 'center',
    cssClass: 'numeric-cell',
    width: 'width: 200px',
  },
  {
    text: 'Order Qty',
    value: 'orderQty',
    sortable: false,
    align: 'right',
    cssClass: 'numeric-cell',
  },
  // {
  //   text: "Package",
  //   value: "Package",
  //   sortable: false,
  //   align: "left",
  //   cssClass: "label-cell"
  // },
  // {
  //   text: "Unit Packs",
  //   value: "unitPack",
  //   sortable: false,
  //   align: "left",
  //   cssClass: "numeric-cell"
  // },
  // {
  //   text: "Unit Size",
  //   value: "unitSize",
  //   sortable: false,
  //   align: "left",
  //   cssClass: "numeric-cell"
  // },
  // {
  //   text: "UoM",
  //   value: "UoM",
  //   sortable: false,
  //   align: "left",
  //   cssClass: "label-cell"
  // },

  {
    text: 'Price',
    value: 'price',
    sortable: false,
    align: 'right',
    cssClass: 'numeric-cell',
  },
  {
    text: 'Total Cost',
    value: 'totalCost',
    sortable: false,
    align: 'right',
    cssClass: 'numeric-cell',
    tooltip: {
      id: 'total-cost-metal-tooltip-header',
      content: 'Temporary cost estimate for 1 building/section',
    },
  },
  {
    text: 'Price Override',
    value: 'priceOverride',
    sortable: false,
    align: 'center',
    cssClass: 'label-cell',
  },

  {
    text: 'Color',
    value: 'color',
    sortable: false,
    align: 'left',
    cssClass: 'label-cell',
  },

  {
    text: 'Manufacturer',
    value: 'manufacturer',
    sortable: false,
    align: 'left',
    cssClass: 'label-cell',
  },
  {
    text: 'Waste Factor',
    value: 'waster_factor',
    sortable: false,
    align: 'right',
    cssClass: 'numeric-cell',
  },
];

export const ALL_ROOFTYPE_ESTIMATE_TEMPLATE = [
  {
    sectionId: 'tpo-membrane',
    category: 'Membrane',
    index: 0,
    subCategoryNames: [
      'Field Membrane',
      'Perimeter Membrane',
      'TPO Fasteners',
      'TPO Plates',
      'Bonding Adhesive',
      'Low Rise Foam Adhesive',
      'Non Reinforced',
    ],
  },
  {
    sectionId: 'tpo-insulation',
    category: 'Insulation',
    index: 1,
    subCategoryNames: [
      'Insulation Membrane',
      'Insulation Bonding Adhesive',
      'Tapered Insulation',
      'Insulation Fasteners',
      'Insulation Plates',
      'Flute Fill',
    ],
  },
  {
    category: 'Accessories',
    sectionId: 'accessories',
    index: 2,
    subCategoryNames: [
      'Pipe Boots',
      'Split Pipe Seals',
      'Inside/Outside Corner',
      'T-Joint Cover',
      'Pitch Pans',
      'Pourable Sealer',
      'Walkway Pad',
      'Star Vents',
      'Coverstrip',
      'Primer',
      'Cut Edge Sealant',
      'Multipurpose Sealant',
      'Water Cut-Off',
      'Membrane Cleaner',
      'Split Pipe Boots',
    ],
  },
  {
    index: 3,
    sectionId: 'shingles',
    category: 'Shingles',
    subCategoryNames: ['Field Square', 'Hip and Ridge', 'Starter'],
  },
  {
    index: 4,
    sectionId: 'underlayment',
    category: 'Underlayment',
    subCategoryNames: ['Felt', 'Ice and Water'],
  },
  {
    index: 5,
    sectionId: 'vents',
    category: 'Vents',
    subCategoryNames: [
      'Ridge/Hip Vent',
      'Airhawk',
      'Powervent',
      'Turbine',
      'Star',
    ],
  },
  {
    index: 6,
    sectionId: 'leads',
    category: 'Leads',
    subCategoryNames: ['Vent Pipe'],
  },

  {
    index: 7,
    sectionId: 'fasteners',
    category: 'Fasteners',
    subCategoryNames: ['Coil Nails', 'Caps'],
  },
  {
    index: 8,
    sectionId: 'prefab-metal',
    category: 'PreFab Metal',
    subCategoryNames: [
      'Side Wall',
      'Drip Edge',
      'Head Wall',
      'Chimney Counter Flashing',
      'Valley Flashing',
    ],
  },
  {
    index: 9,
    sectionId: 'panels-trim',
    category: 'Panels & Trim',
    subCategoryNames: [
      'Standing Seam Panels',
      'Rake & Eave Trim',
      'Ridge Trim',
      'Hip Trim',
      'Side Wall Flashing',
      'Zee Closure',
      'Perforated Zee Closure',
      'Valley Metal',
      'Counter Flashing',
      'Transition Flashing',
    ],
  },
  {
    index: 10,
    sectionId: 'underlayment',
    category: 'Underlayment',
    subCategoryNames: ['High Temp Ice & Water'],
  },

  {
    index: 11,
    sectionId: 'pipe-boots',
    category: 'Pipe Boots',
    subCategoryNames: ['Pipe Boots', 'Split Pipe Boots'],
  },
  {
    index: 12,
    sectionId: 'fasteners',
    category: 'Fasteners',
    subCategoryNames: ['Pancake Fasteners', 'Pop Rivets'],
  },
  {
    category: 'Metal and Edge',
    sectionId: 'metal-and-edge',
    index: 13,
    subCategoryNames: [
      'Counterflashing',
      'Edge Metal',
      'Coping',
      'Coping',
      'Coping',
      'Termination Bar',
      'Sheet Metal Fasteners',
      'Scuppers',
      'Collector Boxes',
      'Downspouts',
      'Gutters',
      'Custom Fabricated Material',
    ],
  },
  {
    category: 'Miscellaneous',
    sectionId: 'miscellaneous',
    index: 14,
    subCategoryNames: [
      'Skylight',
      'Lumber or Decking',
      'Delivery Cost',
      'Vacuum Truck',
      'Dumpster',
      'Crane',
      'Reach Forklift',
    ],
  },
  {
    category: 'Labor',
    sectionId: 'labor',
    index: 15,
    subCategoryNames: [
      'Windstorm',
      'Permit',
      '5 Yr',
      '10 Yr',
      '15 Yr',
      '20 Yr',
      '25 Yr',
      '30 Yr',
      'Labor',
      'Curbs',
      'Tear Off Labor',
      'Out of Town Work',
    ],
  },
];
