import { checkAuth } from '../../../services/authentication.service';
import {
  MODULE_NAME,
  destroyStore,
} from '@/plugins/safety/configs/store.config';

export default [
  {
    path: '',
    title: 'Safety Hub',
    name: MODULE_NAME,
    displayRoles: ['owner', 'user'],
    routes: [
      {
        path: '/safety',
        name: 'documents',
        displayRoles: ['owner', 'user'],
        parent: 'safety',
        title: 'Documents',
        icon: 'doc_plaintext',
        master: true,
        async: checkAuth(() => import('../pages/SafetyListPage.vue')),
        on: {
          pageBeforeOut: function () {},
          pageBeforeRemove: function () {
            // remove store
            destroyStore(MODULE_NAME);
          },
        },
        detailRoutes: [
          //detail
          {
            path: '/safety/:safetyNumber',
            title: 'Safety',
            async: checkAuth(() => import('../pages/SafetyDetailPage.vue')),
          },
        ],
      },
      {
        path: '/my-certifications',
        name: 'my-certifications',
        displayRoles: ['owner', 'user'],
        parent: 'safety',
        title: 'My Certifications',
        icon: 'checkmark_seal',
        master: true,
        async: checkAuth(() => import('../pages/MyCertificatesListPage.vue')),
        on: {
          pageBeforeOut: function () {},
          pageBeforeRemove: function () {
            // remove store
            destroyStore(MODULE_NAME);
          },
        },
        // detailRoutes: [
        //   //detail
        //   {
        //     path: "/safety/:safetyNumber",
        //     title: "Safety",
        //     async: checkAuth(() => import("../pages/SafetyDetailPage.vue"))
        //   },
        // ]
      },
      {
        path: '/employee-certifications',
        name: 'employee-certifications',
        displayRoles: ['owner', 'user'],
        parent: 'safety',
        title: 'Employee Certifications',
        icon: 'person_3',
        master: true,
        async: checkAuth(
          () => import('../pages/EmployeeCertificatesListPage.vue')
        ),
        on: {
          pageBeforeOut: function () {},
          pageBeforeRemove: function () {
            // remove store
            destroyStore(MODULE_NAME);
          },
        },
        detailRoutes: [
          //detail
          {
            path: '/employee-certifications/:employeeId',
            title: 'Employee Certificate Detail',
            async: checkAuth(
              () => import('../pages/EmployeeCertificatesPage.vue')
            ),
          },
        ],
      },
    ],
  },
];
