<template>
  <f7-popup
    class="demo-popup"
    :opened="popupEditTimeTracking"
    @popup:closed="cancel"
  >
    <f7-page class="time-tracking-form">
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>{{
            isMyTimeLog ? $t('timeTracking.add.cancel') : 'Cancel'
          }}</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{
          isMyTimeLog ? $t('timeTracking.edit.title') : 'Edit Time Tracking'
        }}</f7-nav-title>
        <f7-nav-right>
          <f7-link @click.native="edit()">{{
            isMyTimeLog ? $t('timeTracking.add.done') : 'Done'
          }}</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list>
        <!-- time log type -->
        <f7-list-item>
          <div slot="header">
            {{
              isMyTimeLog
                ? $t('timeTracking.add.timeLogType')
                : 'Time Log Type'
            }}<required-asterisk />
          </div>
          <f7-input
            v-if="currenttimeTracking.timeLogType !== TIME_LOG_TYPE_LUNCHTIME"
            slot="title"
            type="select"
            :value="currenttimeTracking.timeLogType"
            @change="changeTimeLogType"
          >
            <option
              v-for="item in timeLogTypeList.filter(
                r => r.value !== TIME_LOG_TYPE_LUNCHTIME
              )"
              :key="item.id"
              :value="item.value"
            >
              {{
                isMyTimeLog
                  ? $t('timeTracking.timeLogType.' + item.value)
                  : item.displayName
              }}
            </option>
          </f7-input>
          <div
            v-else
            slot="title"
          >
            {{
              isMyTimeLog
                ? $t(
                    'timeTracking.timeLogType.' +
                      currenttimeTracking.timeLogType
                  )
                : timeLogTypeByValue(currenttimeTracking.timeLogType)
                    .displayName
            }}
          </div>
          <input-icon
            slot="media"
            icon="square_fill_line_vertical_square"
          ></input-icon>
          <div
            slot="footer"
            style="color: red"
          >
            {{ timeLogTypeErrorMessage }}
          </div>
        </f7-list-item>

        <!-- project/timeoff/title -->
        <f7-list-item
          v-if="currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT"
          link
          @click.native="selectProject"
        >
          <div slot="header">
            {{ isMyTimeLog ? $t('timeTracking.add.project') : 'Project'
            }}<required-asterisk />
          </div>
          <div slot="title">
            {{
              currenttimeTracking.project
                ? currenttimeTracking.project.title
                : isMyTimeLog
                  ? $t('timeTracking.add.selectProject')
                  : 'Select project'
            }}
          </div>
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <div
            slot="footer"
            style="color: red"
          >
            {{ projectErrorMessage }}
          </div>
        </f7-list-item>

        <f7-list-input
          v-if="currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT"
          :label="
            isMyTimeLog
              ? $t('timeTracking.add.buildingOrSection')
              : 'Building/Section#'
          "
          :placeholder="
            isMyTimeLog
              ? $t('timeTracking.add.enterBuildingOrSection')
              : 'Enter Building/Section#'
          "
          type="text"
          :value="currenttimeTracking.buildingOrSection"
          @input="currenttimeTracking.buildingOrSection = $event.target.value"
        >
          <input-icon
            slot="media"
            icon="building_2_fill"
          ></input-icon>
        </f7-list-input>
        <!-- type off -->
        <f7-list-input
          v-if="currenttimeTracking.timeLogType === TIME_LOG_TYPE_TIME_OFF"
          type="select"
          :value="currenttimeTracking.timeOffType"
          @change="currenttimeTracking.timeOffType = $event.target.value"
          error-message-force
          :error-message="timeOffTypeErrorMessage"
        >
          <div
            slot="header"
            style="font-size: var(--f7-label-font-size)"
          >
            {{ isMyTimeLog ? $t('timeTracking.add.time-off') : 'Time Off'
            }}<required-asterisk />
          </div>
          <input-icon
            slot="media"
            icon="cube_box"
          ></input-icon>
          <option
            v-for="item in timeOffTypeList"
            :key="item.id"
            :value="item.value"
          >
            {{ item.displayName }}
          </option>
        </f7-list-input>
        <!-- title -->
        <f7-list-input
          type="text"
          v-if="currenttimeTracking.timeLogType === TIME_LOG_TYPE_OTHERS"
          :value="currenttimeTracking.title"
          @input="currenttimeTracking.title = $event.target.value.trim()"
          error-message-force
          :error-message="titleErrorMessage"
        >
          <div
            slot="header"
            style="font-size: var(--f7-label-font-size)"
          >
            {{ isMyTimeLog ? $t('timeTracking.add.otherTitle') : 'Title'
            }}<required-asterisk />
          </div>
          <input-icon
            slot="media"
            icon="pencil_ellipsis_rectangle"
          ></input-icon>
        </f7-list-input>

        <!-- date -->
        <f7-list-input
          :label="isMyTimeLog ? $t('timeTracking.add.date') : 'Date'"
          type="datepicker"
          :placeholder="
            isMyTimeLog ? $t('timeTracking.add.date') : 'MM/DD/YYYY'
          "
          error-message-force
          validate
          validate-on-blur
          :value="currenttimeTracking.date"
          :error-message="dateErrorMessage"
          :calendar-params="{
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            dateFormat: 'mm/dd/yyyy',
          }"
          @calendar:change="
            currenttimeTracking.date = $event;
            $f7.calendar.close();
          "
        >
          <input-icon
            slot="media"
            icon="calendar"
          ></input-icon>
          <required-asterisk slot="label" />
        </f7-list-input>

        <!-- billable -->
        <f7-list-item>
          <div slot="title">
            {{ isMyTimeLog ? $t('timeTracking.add.billable') : 'Billable' }}
          </div>
          <f7-toggle
            slot="after"
            :checked="currenttimeTracking.billable"
            @toggle:change="currenttimeTracking.billable = $event"
          ></f7-toggle>
          <input-icon
            slot="media"
            icon="money_dollar_circle"
          ></input-icon>
        </f7-list-item>
      </f7-list>

      <!-- override -->
      <f7-list>
        <f7-list-item
          :title="
            isMyTimeLog ? $t('timeTracking.add.override') : 'Manually Enter'
          "
          v-if="
            ![TIME_LOG_TYPE_TIME_OFF].includes(currenttimeTracking.timeLogType)
          "
        >
          <f7-toggle
            slot="after"
            :checked="currenttimeTracking.override"
            @toggle:change="currenttimeTracking.override = $event"
            :disabled="!currenttimeTracking.startTime"
          ></f7-toggle>
          <input-icon
            slot="media"
            :icon="currenttimeTracking.override ? 'pencil' : 'pencil_slash'"
          ></input-icon>
        </f7-list-item>

        <!-- enter time -->
        <f7-list-item>
          <div slot="header">{{ enterTimeTitle }}<required-asterisk /></div>
          <template
            v-if="currenttimeTracking.timeLogType !== TIME_LOG_TYPE_LUNCHTIME"
          >
            <f7-input
              v-if="!currenttimeTracking.override"
              slot="title"
              type="text"
              readonly
              :placeholder="
                isMyTimeLog ? $t('timeTracking.add.hours') : 'Hours'
              "
              :value="currenttimeTracking.loggedHour | floatFixed"
            ></f7-input>
            <input-spent-time
              v-else
              slot="title"
              :spentTime="spentTime"
              :maxValue="maxTimeValue"
              @changeSpentTime="changeSpentTime"
            ></input-spent-time>
            <div
              slot="footer"
              style="color: red"
            >
              {{ spentTimeErrorMessage }}
            </div>
          </template>
          <template v-else>
            <f7-input
              slot="title"
              type="number"
              min="0"
              :placeholder="
                isMyTimeLog ? $t('timeTracking.add.hours') : 'Hours'
              "
              :value="currenttimeTracking.overrideHour"
              @input="currenttimeTracking.overrideHour = $event.target.value"
            ></f7-input>
            <div
              slot="footer"
              style="color: red"
            >
              {{ overrideHourErrorMessage }}
            </div>
          </template>

          <input-icon
            slot="media"
            icon="clock"
          ></input-icon>
        </f7-list-item>

        <f7-list-item
          :title="'Lunch Break'"
          v-if="currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT"
        >
          <f7-toggle
            slot="after"
            :checked="currenttimeTracking.hasLunchBreak"
            @toggle:change="handleLunchBreak"
          ></f7-toggle>
          <input-icon
            slot="media"
            :icon="
              currenttimeTracking.override
                ? 'arrow_clockwise'
                : 'arrow_clockwise_circle'
            "
          ></input-icon>
        </f7-list-item>
      </f7-list>

      <f7-list>
        <!-- approved hours -->
        <f7-list-input
          v-if="isApprovedHour"
          :label="
            isMyTimeLog
              ? $t('timeTracking.add.approvedHours')
              : 'Approved Hours'
          "
          type="text"
          :placeholder="
            isMyTimeLog
              ? $t('timeTracking.add.approvedHours')
              : 'Approved Hours'
          "
          class="input-search"
          :value="currenttimeTracking.approvedHour"
          @input="currenttimeTracking.approvedHour = $event.target.value"
        >
          <input-icon
            slot="media"
            icon="doc_checkmark"
          ></input-icon>
        </f7-list-input>

        <template>
          <!-- class -->
          <f7-list-item>
            <div slot="header">
              {{ isMyTimeLog ? $t('timeTracking.add.class') : 'Class' }}
            </div>
            <f7-input
              slot="title"
              type="select"
              :value="currenttimeTracking.classItem"
              @change="currenttimeTracking.classItem = $event.target.value"
            >
              <option
                value=""
                hidden
              >
                {{
                  isMyTimeLog
                    ? $t('timeTracking.add.selectClass')
                    : 'Select Class'
                }}
              </option>
              <option
                v-for="item in classQBList"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </f7-input>
            <input-icon
              slot="media"
              icon="rectangle_grid_2x2"
            ></input-icon>
          </f7-list-item>

          <!-- service item -->
          <f7-list-item>
            <div
              slot="header"
              class="display-flex justify-content-space-between"
            >
              <div>
                {{
                  isMyTimeLog
                    ? $t('timeTracking.add.serviceItem')
                    : 'Service Item'
                }}
              </div>
              <f7-link
                v-if="!isMyTimeLog"
                color="primary"
                icon-f7="gear_alt_fill"
                @click.native="openServiceItemSettingPopup"
              ></f7-link>
            </div>
            <f7-input
              slot="title"
              type="select"
              :value="currenttimeTracking.serviceItem"
              @change="currenttimeTracking.serviceItem = $event.target.value"
            >
              <option
                value=""
                hidden
              >
                {{
                  isMyTimeLog
                    ? $t('timeTracking.add.selectServiceItem')
                    : 'Select Service Item'
                }}
              </option>
              <option
                v-for="item in serviceItemOptions"
                :key="item.value"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </f7-input>
            <input-icon
              slot="media"
              icon="list_bullet_below_rectangle"
            ></input-icon>
          </f7-list-item>
        </template>

        <!-- note -->
        <f7-list-input
          :label="isMyTimeLog ? $t('timeTracking.add.note') : 'Note'"
          type="textarea"
          :placeholder="
            isMyTimeLog
              ? $t('timeTracking.add.notePlaceholder')
              : 'Enter note...'
          "
          :value="currenttimeTracking.note"
          @input="currenttimeTracking.note = $event.target.value"
        >
          <input-icon
            slot="media"
            icon="square_pencil"
          ></input-icon>
        </f7-list-input>
      </f7-list>
      <project-list-popup
        ref="selectProject"
        @onSelected="onSelectedProject"
      ></project-list-popup>
      <service-item-setting-popup
        ref="serviceItemSettingPopup"
      ></service-item-setting-popup>
    </f7-page>
  </f7-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { minValue, required } from '@vuelidate/validators';
import InputIcon from '@/components/icons/InputIcon.vue';
import ProjectListPopup from '@/components/popups/ProjectListPopup.vue';

import { googlePlaceApiMixin } from '@/services/place.google.service';
import InputSpentTime from '../../components/inputs/InputSpentTime.vue';
import _ from 'lodash';
import { getFullAddress } from '@/utility/address';
import ServiceItemSettingPopup from './ServiceItemSettingPopup.vue';
import moment from 'moment';
import { overtimeMixin } from '../../mixin/overtime-mixin';
import { roundNumber } from '@/utility/number-tool';

import {
  STATUS_TIME_TRACKING_WFA,
  TIME_LOG_TYPE_PROJECT,
  TIME_LOG_TYPE_TIME_OFF,
  TIME_LOG_TYPE_OTHERS,
  TIME_OFF_TYPE_PTO,
  TIME_LOG_TYPE_LUNCHTIME,
  BUSINESS_CODE_COMMERCIAL,
  BUSINESS_CODE_SERVICE,
  BUSINESS_CODE_RESIDENTIAL,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';

export default {
  props: {
    popupEditTimeTracking: Boolean,
    item: Object,
    redirect: Boolean,
    isApprovedHour: Boolean,
  },

  components: {
    InputIcon,
    ProjectListPopup,
    InputSpentTime,
    ServiceItemSettingPopup,
  },

  mixins: [googlePlaceApiMixin, overtimeMixin],

  data() {
    return {
      TIME_LOG_TYPE_PROJECT,
      TIME_LOG_TYPE_TIME_OFF,
      TIME_LOG_TYPE_OTHERS,
      TIME_OFF_TYPE_PTO,
      TIME_LOG_TYPE_LUNCHTIME,

      currenttimeTracking: {
        date: [new Date()],
        projectId: '',
        project: {},
        note: '',
        reject_reason: '',
        approvedHour: 0,
        override: false,
        timeLogType: TIME_LOG_TYPE_PROJECT,
        timeOffType: TIME_OFF_TYPE_PTO,
        title: '',
        buildingOrSection: '',
        classItem: {},
        serviceItem: {},
        overrideHour: 1,
        hasLunchBreak: false,
        lunchTimeInfo: {},
        billable: true,
      },
      spentTime: {
        hours: '-1',
        minutes: '-1',
      },
      tempTimeTracking: {},
      isChangedSpentTime: false,
    };
  },

  methods: {
    ...mapActions({
      updateTimeTracking: 'time-tracking/time-tracking/update',
      getProject: 'time-tracking/project/getProject',
    }),
    openServiceItemSettingPopup() {
      this.$refs.serviceItemSettingPopup.open();
    },
    changeSpentTime(field, val) {
      this.isChangedSpentTime = true;
      this.spentTime[field] = Number(val);
    },
    changeTimeLogType($event) {
      if ($event.target.value === TIME_LOG_TYPE_TIME_OFF) {
        this.currenttimeTracking.override = true;
        if (
          (this.spentTime.hours <= 0 && this.spentTime.minutes <= 0) ||
          this.spentTime.hours >= 8
        ) {
          this.spentTime.hours = 8;
          this.spentTime.minutes = 0;
        }
      }
      this.currenttimeTracking.timeLogType = $event.target.value;
    },
    calcOverrideHour() {
      const { hours, minutes } = this.spentTime;
      return this.currenttimeTracking.override
        ? Number(hours) + Number(minutes) / 60
        : 0;
    },
    selectProject() {
      this.$refs.selectProject.open();
    },
    async onSelectedProject(projectId = '') {
      this.currenttimeTracking.projectId = projectId || '';
      this.currenttimeTracking.project = await this.getProject(projectId);
    },
    async edit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      } else {
        let {
          date,
          projectId,
          project,
          note,
          override,
          reject_reason,
          approvedHour,
          timeLogType,
          timeOffType,
          title,
          buildingOrSection,
          startLocation,
          endLocation,
          classItem,
          serviceItem,
          overrideHour,
          user_id,
          hasLunchBreak,
          lunchTimeInfo,
          billable,
        } = this.currenttimeTracking;
        const self = this;
        if (
          timeLogType === TIME_LOG_TYPE_LUNCHTIME &&
          !moment(date[0]).isSame(moment(this.item.date.toDate()), 'day')
        ) {
          const lunchTimeByDateAndUser = await this.getWeeklyTimeLogs({
            fromDate: date[0],
            toDate: date[0],
            userId: user_id,
          });
          if (
            (lunchTimeByDateAndUser.filter(item => item.hasLunchBreak) || [])
              .length
          ) {
            this.$ri.dialog
              .openErrorDialog({
                title: '',
                content:
                  'The lunchtime has already been logged in the time log. Each employee is limited to one lunchtime per day.',
                hideCancelButton: true,
                onClick: (_sefl, index) => {
                  if (index === 0) {
                    _sefl.app.dialog.close();
                  } else if (index === 1) {
                    _sefl.app.dialog.close();
                  }
                },
              })
              .open();
            return;
          }
        }

        let distance = 0;
        let gResult = null;
        // get project address

        let propertyAddress = {};

        if (timeLogType === TIME_LOG_TYPE_PROJECT && !_.isEmpty(project)) {
          if (
            project.businessCode === BUSINESS_CODE_COMMERCIAL ||
            (project.businessCode === BUSINESS_CODE_SERVICE &&
              project.customerType === 'commercial')
          ) {
            propertyAddress = project.propertyAddress;
          } else if (
            project.businessCode === BUSINESS_CODE_RESIDENTIAL ||
            (project.businessCode === BUSINESS_CODE_SERVICE &&
              project.customerType === 'residential')
          ) {
            propertyAddress = project.projectAddress;
          }
        }

        if (
          timeLogType === TIME_LOG_TYPE_PROJECT &&
          !_.isEmpty(project) &&
          !_.isEmpty(propertyAddress) &&
          startLocation &&
          endLocation
        ) {
          try {
            const distanceResult = await this.calculateDistanceFromThreePoints(
              getFullAddress(propertyAddress),

              {
                lat: startLocation.latitude,
                lng: startLocation.longitude,
              },
              {
                lat: endLocation.latitude,
                lng: endLocation.longitude,
              }
            );
            distance = distanceResult.distance;
            gResult = distanceResult.gResult;
          } catch {
            gResult = [];
            distance = 0;
          }
        }

        let isUpdateQBTimeActivity = false;

        if (
          !_.isEqual(this.currenttimeTracking, this.tempTimeTracking) ||
          this.isChangedSpentTime
        ) {
          isUpdateQBTimeActivity = true;
        }
        let data = {
          id: this.currenttimeTracking.id,
          doc: {
            date: this.$google.firebase.firestore.Timestamp.fromDate(date[0]),
            projectId: timeLogType === TIME_LOG_TYPE_PROJECT ? projectId : null,
            project: timeLogType === TIME_LOG_TYPE_PROJECT ? project : {},
            note,
            overrideHour:
              timeLogType === TIME_LOG_TYPE_LUNCHTIME
                ? parseFloat((-1 * parseFloat(overrideHour)).toFixed(2))
                : this.calcOverrideHour(),
            reject_reason,
            approvedHour,
            override,
            isDeleted: false,
            timeLogType,
            timeOffType:
              timeLogType === TIME_LOG_TYPE_TIME_OFF ? timeOffType : '',
            title:
              timeLogType === TIME_LOG_TYPE_OTHERS
                ? title
                : timeLogType === TIME_LOG_TYPE_LUNCHTIME
                  ? 'Lunchtime'
                  : '',
            buildingOrSection:
              timeLogType === TIME_LOG_TYPE_PROJECT ? buildingOrSection : '',
            distance,
            gResult: JSON.parse(JSON.stringify(gResult)) || '',
            status: STATUS_TIME_TRACKING_WFA,
            qbClassRef:
              timeLogType === TIME_LOG_TYPE_PROJECT ||
              timeLogType === TIME_LOG_TYPE_OTHERS
                ? this.classQBList.find(r => r.value === classItem) || {}
                : {},
            qbServiceItemRef:
              timeLogType === TIME_LOG_TYPE_PROJECT ||
              timeLogType === TIME_LOG_TYPE_OTHERS
                ? this.serviceItemQBList.find(r => r.value === serviceItem) ||
                  {}
                : {},
            hasLunchBreak,
            lunchTimeInfo,
            billable,
            qbTimeActivityId: isUpdateQBTimeActivity
              ? null
              : this.currenttimeTracking.qbTimeActivityId || null,
          },
        };

        this.updateTimeTracking(data)
          .then(() => {
            return self.composeOvertime(data.doc.date.toDate(), user_id);
          })
          .then(() => {
            if (
              self.$f7router.currentRoute.path.startsWith(
                '/employees-time-logs'
              )
            ) {
              return self.reloadTimelogList();
            }
          })
          .then(() => {
            if (this.redirect) {
              this.$f7router.navigate(`/my-time-log`, { reloadAll: true });
            }
          });

        this.cancel();
      }
    },
    cancel() {
      this.currenttimeTracking = {
        date: [new Date()],
        projectId: null,
        project: null,
        note: '',
        reject_reason: '',
        approvedHour: 0,
        override: false,
        timeLogType: TIME_LOG_TYPE_PROJECT,
        timeOffType: TIME_OFF_TYPE_PTO,
        title: '',
        buildingOrSection: '',
        classItem: null,
        serviceItem: null,
        overrideHour: 1,
        billable: true,
      };
      this.isChangedSpentTime = false;
      this.spentTime = {
        hours: '-1',
        minutes: '-1',
      };
      this.v$.$reset();
      this.$emit('close');
    },

    handleLunchBreak(checked) {
      this.currenttimeTracking.hasLunchBreak = checked;
      if (checked === true) {
        this.currenttimeTracking.lunchTimeInfo = {
          hour: -1,
          title: 'Lunch Break Included',
        };
      }
    },

    getHours(value) {
      return parseInt(value || 0);
    },

    getMinutes(value) {
      const floatValue = parseFloat(value || 0);
      return roundNumber((floatValue % 1) * 60, 0);
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    const projectRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_PROJECT &&
        !this.currenttimeTracking.projectId
      )
        return false;
      return true;
    };
    const timeOffTypeRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_TIME_OFF &&
        !this.currenttimeTracking.timeOffType
      )
        return false;
      return true;
    };
    const titleRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_OTHERS &&
        !this.currenttimeTracking.title
      )
        return false;
      return true;
    };
    const spentRequiredRule = () => {
      if (this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_LUNCHTIME)
        return true;
      if (!this.currenttimeTracking.override) return true;

      let condition1 = this.spentTime.hours >= 0;
      let condition2 = this.spentTime.minutes >= 0;
      let condition3 = +this.spentTime.hours + +this.spentTime.minutes > 0;

      if (!(condition1 && condition2 && condition3)) return false;
      return true;
    };
    const overrideHourRule = () => {
      if (
        this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_LUNCHTIME &&
        !this.currenttimeTracking.overrideHour
      )
        return false;
      return true;
    };
    return {
      currenttimeTracking: {
        date: {
          required,
        },
        timeLogType: {
          required,
        },
        projectId: {
          projectRule,
        },
        timeOffType: {
          timeOffTypeRule,
        },
        title: {
          titleRule,
        },
        overrideHour: {
          overrideHourRule,
          minValue: this.currenttimeTracking.override ? minValue(0) : true,
        },
      },
      spentTime: {
        spentRequiredRule,
      },
    };
  },

  computed: {
    ...mapGetters({
      isMyTimeLog: 'time-tracking/time-tracking/isMyTimeLog',
      time_trackingList: 'time-tracking/time-tracking/objectList',
    }),

    ...mapGetters('time-tracking/app-constant', [
      'timeLogTypeList',
      'timeOffTypeList',
      'timeLogTypeByValue',
    ]),
    ...mapGetters('time-tracking/quickbooks', [
      'classQBList',
      'serviceItemQBList',
    ]),
    ...mapGetters('time-tracking/service-item-setting', ['serviceItemOptions']),

    isDisableLunchBreak() {
      const list = (this.time_trackingList || []).filter(x => {
        return (
          x.date &&
          this.item.date &&
          moment(x.date.toDate()).isSame(
            moment(this.item.date.toDate()),
            'day'
          ) &&
          x.hasLunchBreak
        );
      });
      return !!list.length;
    },

    maxTimeValue() {
      if (this.currenttimeTracking.timeLogType === TIME_LOG_TYPE_TIME_OFF)
        return 8;
      else return 24;
    },
    enterTimeTitle() {
      let messageForMyTimeLog = ![
        TIME_LOG_TYPE_TIME_OFF,
        TIME_LOG_TYPE_LUNCHTIME,
      ].includes(this.currenttimeTracking.timeLogType)
        ? this.$t('timeTracking.add.time')
        : this.$t('timeTracking.add.numberOfHours');
      let messageForEmployeeTimeLog = ![
        TIME_LOG_TYPE_TIME_OFF,
        TIME_LOG_TYPE_LUNCHTIME,
      ].includes(this.currenttimeTracking.timeLogType)
        ? 'Hours Worked'
        : 'Number of Hours (per day)';
      let message = this.isMyTimeLog
        ? messageForMyTimeLog
        : messageForEmployeeTimeLog;
      return message;
    },
    dateErrorMessage() {
      if (!this.v$.currenttimeTracking.date.$error) return null;
      if (this.v$.currenttimeTracking.date.required.$invalid)
        return this.isMyTimeLog
          ? this.$t('timeTracking.errorMessages.required')
          : VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
    timeLogTypeErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.timeLogType.required.$invalid)
        return this.isMyTimeLog
          ? this.$t('timeTracking.errorMessages.required')
          : VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    projectErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.projectId.projectRule.$invalid)
        return this.isMyTimeLog
          ? this.$t('timeTracking.errorMessages.required')
          : VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    timeOffTypeErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.timeOffType.timeOffTypeRule.$invalid)
        return this.isMyTimeLog
          ? this.$t('timeTracking.errorMessages.required')
          : VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    titleErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return '';
      if (this.v$.currenttimeTracking.title.titleRule.$invalid)
        return this.isMyTimeLog
          ? this.$t('timeTracking.errorMessages.required')
          : VALIDATION_MESSAGE.REQUIRED_FIELD;
      return '';
    },
    overrideHourErrorMessage() {
      if (!this.v$.currenttimeTracking.$error) return null;
      if (this.v$.currenttimeTracking.overrideHour.overrideHourRule.$invalid)
        return this.isMyTimeLog
          ? this.$t('timeTracking.errorMessages.required')
          : VALIDATION_MESSAGE.REQUIRED_FIELD;
      if (this.v$.currenttimeTracking.overrideHour.minValue.$invalid)
        return this.isMyTimeLog
          ? this.$t('timeTracking.errorMessages.minIsO')
          : VALIDATION_MESSAGE.GREATER_THAN_OR_EQUAL_TO_ZERO;
      return null;
    },
    spentTimeErrorMessage() {
      if (!this.v$.spentTime.$error) return null;
      if (this.v$.spentTime.spentRequiredRule.$invalid)
        return this.isMyTimeLog
          ? this.$t('timeTracking.errorMessages.required')
          : VALIDATION_MESSAGE.REQUIRED_FIELD;
      return null;
    },
  },

  watch: {
    async popupEditTimeTracking(val) {
      if (val && !_.isEmpty(this.item)) {
        this.currenttimeTracking = {
          ...this.currenttimeTracking,
          ...this.item,
          timeOffType: this.item.timeOffType || TIME_OFF_TYPE_PTO,
          date: this.item.date && [this.item.date.toDate()],
          classItem: this.item.qbClassRef && this.item.qbClassRef.value,
          serviceItem:
            this.item.qbServiceItemRef && this.item.qbServiceItemRef.value,
          hasLunchBreak: this.item.hasLunchBreak || false,
          lunchTimeInfo: this.item.lunchTimeInfo || {},
          billable:
            this.item.billable !== undefined ? this.item.billable : true,
        };

        if (!_.isEmpty(this.item.projectId)) {
          const project = await this.getProject(this.item.projectId);
          if (!_.isEmpty(project)) {
            this.currenttimeTracking.project = project;
          }
        }
        this.tempTimeTracking = _.cloneDeep(this.currenttimeTracking);

        this.spentTime = {
          hours:
            this.item.overrideHour > 0
              ? this.getHours(this.item.overrideHour)
              : '-1',
          minutes:
            this.item.overrideHour > 0
              ? this.getMinutes(this.item.overrideHour)
              : `-1`,
        };
      }
    },
  },
};
</script>

<style scoped>
.icon-clock {
  padding-bottom: 2px;
}

.timer-count {
  margin-left: 5px;
}

.button-action {
  margin-top: 10px;
}

.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
}
</style>

<style lang="scss">
.time-tracking-form .item-title {
  width: 100%;
}
</style>
