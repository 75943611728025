<template>
  <f7-popup
    :opened="popupOpened"
    @popup:closed="onPopupClosed()"
  >
    <f7-page>
      <f7-navbar>
        <f7-nav-left>
          <f7-link popup-close>Cancel</f7-link>
        </f7-nav-left>
        <f7-nav-title>{{
          `New Task - ${project ? project.title || '' : ''}`
        }}</f7-nav-title>
        <f7-nav-right>
          <f7-link
            v-show="workOrderType === 'purchase-order'"
            @click.native="processNextStep()"
            >Next</f7-link
          >
          <f7-link
            v-show="workOrderType !== 'purchase-order'"
            @click.native="done()"
            >Done</f7-link
          >
        </f7-nav-right>
      </f7-navbar>

      <f7-list media-list>
        <!-- Task Type -->
        <f7-list-input
          label="Task Type"
          type="select"
          placeholder="Select task type"
          :value="workOrderType"
          @change="handleWorkOrderTypeChange($event.target.value)"
        >
          <input-icon
            slot="media"
            icon="selection_pin_in_out"
          ></input-icon>
          <option
            v-for="(type, index) in woTaskTypeList"
            :key="index"
            :value="type.value"
          >
            {{ type.displayName }}
          </option>
        </f7-list-input>
      </f7-list>

      <!-- For Preparation Task -->
      <f7-list media-list>
        <f7-list-input
          v-show="workOrderType === 'preparation'"
          label="Checklist"
          :value="checklist"
          type="select"
          :error-message="checklistErrorMessage"
          error-message-force
          @change="checklist = $event.target.value"
        >
          <input-icon
            slot="media"
            icon="list_dash"
          ></input-icon>
          <option
            v-for="(item, index) in preparationTaskChecklistList"
            :key="index"
            :value="item.value"
          >
            {{ item.displayName }}
          </option>
          <required-asterisk slot="label"></required-asterisk>
        </f7-list-input>

        <f7-list-input
          v-show="workOrderType === 'qa-qc' || workOrderType === 'general'"
          label="Description"
          :value="description"
          type="textarea"
          @change="description = $event.target.value"
        >
          <input-icon
            slot="media"
            icon="doc_text"
          ></input-icon>
        </f7-list-input>

        <!-- Due date -->
        <f7-list-input
          type="datepicker"
          placeholder="Select due date"
          :value="dueDate"
          :calendar-params="{
            closeOnSelect: true,
            backdrop: true,
            openIn: 'customModal',
            header: true,
            footer: false,
            dateFormat: 'mm/dd/yyyy',
            disabled: {
              from: null,
              to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
            },
            closeOnSelect: true,
          }"
          @calendar:change="dueDate = $event"
        >
          <div slot="label">Due Date</div>
          <input-icon
            slot="media"
            icon="alarm"
          ></input-icon>
        </f7-list-input>
      </f7-list>

      <attachment-input
        :projectId="workOrderTaskId"
        modelType="work-order-tasks"
        title="Attachments"
        attachment-type="attachment"
        add-button-title="Add an Attachment"
        :value="attachmentFiles"
        @input="attachmentFiles = $event"
      ></attachment-input>

      <user-input
        :value="assigneeIds"
        modelType="wo-task"
        @input="assigneeIds = $event"
        @onUserAdded="handleUserAdded"
      ></user-input>

      <wo-task-select-estimate-popup
        ref="woTaskSelectEstimatePopup"
        v-on="$listeners"
        @onCreatePO="handleCreatePO"
      ></wo-task-select-estimate-popup>
    </f7-page>
  </f7-popup>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
import WoTaskSelectEstimatePopup from '../../components/popup/WoTaskSelectEstimatePopup.vue';
import UserInput from '../input/UserInput.vue';
import AttachmentInput from '../input/AttachmentInput';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapGetters, mapActions } from 'vuex';

import { ACTION_CODE_CONSTRUCTION, VALIDATION_MESSAGE } from '@/utility/const';

import dashboardService from '../../../../services/dashboard.service';

import _ from 'lodash';
import {
  // toDateCalendar
  toDateFirebase,
  // toDisplayDateString
} from '../../../../utility/datetime';

export default {
  components: {
    InputIcon,
    WoTaskSelectEstimatePopup,
    UserInput,
    AttachmentInput,
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  data() {
    return {
      popupOpened: false,
      needDelete: true,

      workOrderId: '',
      workOrderType: 'preparation',
      dueDate: [],
      propertyId: '',

      workOrderTaskId: '',
      checklist: '',
      assigneeIds: [],
      attachmentFiles: [],
      description: '',
    };
  },

  validations: {
    checklist: {
      required,
    },
  },

  computed: {
    ...mapGetters('dashboard/project', ['constructionActions', 'actionByCode']),
    ...mapGetters('dashboard/client', ['property', 'companyById']),
    ...mapGetters('dashboard/project', { project: 'constructionProject' }),
    ...mapGetters('dashboard/work-order', [
      'workOrderActionList',
      'workOrderTaskList',
    ]),
    ...mapGetters('setting/app/profile', ['user']),
    ...mapGetters('dashboard/app-constant', [
      'preparationTaskChecklistList',
      'woTaskTypeList',
      'roofTypeBy',
    ]),

    checklistErrorMessage() {
      if (!this.v$.checklist.$error) return null;
      if (this.v$.checklist.required.$invalid)
        return VALIDATION_MESSAGE.REQUIRED_FIELD;

      return '';
    },

    currentWorkOrderActionId() {
      return this.constructionActions.find(
        item => item.code === ACTION_CODE_CONSTRUCTION
      ).id;
    },

    propertyName() {
      return (this.property || {}).propertyName || '';
    },
  },

  methods: {
    ...mapActions('dashboard/work-order', [
      // "bindWorkOrderList",
      // "bindWorkOrder",
      'createWorkOrderTask',
      'createWorkOrder',
      'deleteWorkOrderTask',
      'getWorkOrderTaskListBys',
    ]),
    ...mapActions('dashboard/project', ['setCurrentActionIdsAfterMoveOrAdd']),

    handleUserAdded(val) {
      // console.log("handleUserAdded", val);
    },

    done() {
      if (this.workOrderType === 'preparation') {
        this.v$.checklist.$touch();
      }

      // Save new Work order task
      this.saveWOTaskInfo();
    },

    saveWOTaskInfo() {
      this.$f7.preloader.show();
      dashboardService
        .updateWOTaskDoc(this.user.tenantId, this.workOrderTaskId, {
          checklist: this.checklist,
          assigneeIds: this.assigneeIds,
          attachmentFiles: this.attachmentFiles,
          description: this.description,
          dueDate: toDateFirebase(this.dueDate),

          actions: [this.currentWorkOrderActionId],
          taskType: this.workOrderType,
        })
        .then(() => {
          this.needDelete = false;
          this.closePopup();
          // save actions after moving the card to scroll top columns of those actions
          this.setCurrentActionIdsAfterMoveOrAdd([
            this.currentWorkOrderActionId,
          ]);
        })
        .finally(() => {
          this.$f7.preloader.hide();
        });
    },

    /**
     * selectProperty
     */
    selectProperty() {
      this.$refs.selectProperty.open();
    },

    initPopupData() {},

    /**
     * Open this popup
     *
     * DEV-183 [Preparation] Input checklist, Assignee when create new Preparation Task (create new with 1 step))
     */
    openPopup() {
      this.needDelete = true;
      // create new temp wo task
      this.createTempWOTask().then(id => {
        this.workOrderTaskId = id;
        this.popupOpened = true;
      });
    },

    /**
     * Close popup
     *
     * DEV-183 [Preparation] Input checklist, Assignee when create new Preparation Task (create new with 1 step))
     */
    closePopup() {
      if (this.needDelete && !_.isEmpty(this.workOrderTaskId)) {
        // delete temp wo task

        this.deleteWorkOrderTask(this.workOrderTaskId);
      }

      this.clearData();
      this.popupOpened = false;
      this.$f7route.query = {};
    },

    /**
     * handleWorkOrderTypeChange
     *
     * DEV-183 [Preparation] Input checklist, Assignee when create new Preparation Task (create new with 1 step))
     */
    handleWorkOrderTypeChange(type) {
      this.workOrderType = type;
    },

    createTempWOTask() {
      const newTask = {
        projectId: this.project.id,
        propertyId: this.project.propertyId,
        propertyName: this.propertyName,
        status: 'draft',
      };

      return this.createWorkOrderTask(newTask);
    },

    /**
     * Create the task and go to next step
     */
    processNextStep() {
      this.gotoCreateNewTask();
      return;
    },

    gotoCreateNewTask() {
      if (this.workOrderType === 'purchase-order') {
        // If Purchase Order => select estimate
        // Select estimate
        this.gotoSelectEstimate(this.currentWorkOrderActionId);
      }
    },

    gotoSelectEstimate(actionId) {
      // this.popupOpened = false;
      this.$refs.woTaskSelectEstimatePopup.openPopup({ actionId });
    },

    gotoTaskDetails(actionId, taskId, taskNumber) {
      this.$emit('gotoDetails', { actionId, cardId: taskId, taskNumber });
    },

    handlePOButtonClick(poNumber) {
      const self = this;

      this.getWorkOrderTaskListBys([
        {
          prop: 'taskNumber',
          op: '==',
          val: poNumber,
        },
        {
          prop: 'projectId',
          op: '==',
          val: self.project.id,
        },
      ]).then(docs => {
        if (docs.length > 0) {
          const actionId = self.actionByCode(ACTION_CODE_CONSTRUCTION).id;
          const taskId = docs[0].id;
          const taskNumber = docs[0].taskNumber;
          self.gotoTaskDetails(actionId, taskId, taskNumber);
        }
      });
    },

    async handleCreatePO({ isBuildYourOwn, data }) {
      const self = this;
      if (data.length === 0) {
        return;
      }

      this.$f7.preloader.show();

      const poNumbers = [];
      for (const po of data) {
        const woTask = await this.createNewWorkOrderTask(po);
        poNumbers.push(woTask.docNumber);
      }

      this.$f7.preloader.hide();
      this.$refs.woTaskSelectEstimatePopup.closePopup();

      // save actions after moving the card to scroll top columns of those actions
      this.setCurrentActionIdsAfterMoveOrAdd([this.currentWorkOrderActionId]);

      const dialogButtons = [];
      for (const poNumber of poNumbers) {
        dialogButtons.push({
          text: poNumber,
          onClick: () => {
            self.handlePOButtonClick(poNumber);
          },
        });
      }

      if (isBuildYourOwn) {
        this.handlePOButtonClick(poNumbers[0]);
      } else {
        this.$f7.dialog
          .create({
            title: 'Generate Purchase Order',
            text: `We have created ${
              poNumbers.length
            } drafts PO(s): ${poNumbers.join(
              ', '
            )}. Please double check quantity before sending it to Vendors`,
            buttons: dialogButtons,
            cssClass: 'po-dialog',
            verticalButtons: !this.$f7.device.desktop,
          })
          .open();
      }
    },

    /**
     * TODO: Need to merge with Generate PO
     */
    createNewWorkOrderTask(po = null) {
      const newTask = {
        actions: [this.currentWorkOrderActionId],
        projectId: this.project.id,
        projectNumber: this.project.cardNumber, //for search
        projectName: this.project.title, //for search
        propertyId: '',
        propertyName: '', //for search
        taskType: this.workOrderType,
        dueDate: toDateFirebase(this.dueDate),
        status: 'draft', //for search
      };
      if (po !== null) {
        newTask.items = po.items;
        newTask.vendorId = po.vendorId || '';
        newTask.vendorName =
          (this.companyById(po.vendorId) || {}).companyName || ''; //for search
        newTask.isBuildYourOwn = po.isBuildYourOwn;
        newTask.estimateId = po.estimateId || '';
        newTask.estimateNumber = po.estimateNumber || ''; //for search
        newTask.roofType = po.roofType || '';
        newTask.roofTypeName =
          (this.roofTypeBy(po.roofType) || {}).displayName || ''; //for search
        newTask.priceListId = po.priceListId || '';
        newTask.buildingId = po.buildingId;
        newTask.buildingName = po.buildingName; //for search
        newTask.propertyId = this.project?.propertyId || po.propertyId || '';
        newTask.propertyName =
          this.project?.propertyName || po.propertyName || '';
      }
      return dashboardService.addWOTaskDoc(this.user.tenantId, newTask);
    },

    /**
     * handle popup closed
     */
    onPopupClosed() {
      this.clearData();
      this.popupOpened = false;
      this.$emit('navigateToBoard');
    },

    clearData() {
      this.workOrderId = '';
      this.workOrderType = 'preparation';
      this.dueDate = [];
      this.propertyId = '';

      this.workOrderTaskId = '';
      this.checklist = '';
      this.assigneeIds = [];
      this.attachmentFiles = [];
      this.description = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
  padding-top: 8px;
}
</style>
