export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const SET_NUMBER_OF_ROWS = 'SET_NUMBER_OF_ROWS';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const SET_SEARCH_ORDER = 'SET_SEARCH_ORDER';
export const DELETE_SEARCH_ITEM = 'DELETE_SEARCH_ITEM';
export const RESET_SEARCH = 'RESET_SEARCH';
export const ADD_MORE_RESULT = 'ADD_MORE_RESULT';
export const SET_ACTION_LIST = 'SET_ACTION_LIST';
export const SET_QUERY_FILTER = 'SET_QUERY_FILTER';
export const SET_PROJECT_FILTER_FIELD = 'SET_PROJECT_FILTER_FIELD';
export const SET_IS_HIDDEN_RES_JOB = 'SET_IS_HIDDEN_RES_JOB';
