<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link panel-open="left">
          <Menu></Menu>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>Purchase Orders</f7-nav-title>
      <f7-nav-right>
        <f7-link
          icon-f7="plus"
          @click.native="openAddPopup()"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="display-flex">
      <f7-searchbar
        style="flex: 1"
        disable-button-text
        placeholder="Search Purchase Order"
        :clear-button="true"
        class="text-color-white search-list"
        @input="
          setPOSearchText($event.target.value.trim());
          onSearch();
        "
        @searchbar:clear="
          setPOSearchText('');
          onSearch();
        "
        :value="searchText"
        @searchbar:disable="
          setPOSearchText('');
          onSearch();
        "
      >
        <f7-button
          style="margin-left: 5px; text-overflow: inherit"
          popover-open=".popover-menu-filter"
        >
          <f7-icon f7="funnel"></f7-icon> </f7-button
      ></f7-searchbar>

      <f7-popover :class="`popover-menu-filter`">
        <f7-list>
          <f7-list-item
            v-for="(status, index) in purchaseOrderStatusList"
            :key="index"
            popover-close
            :title="status.displayName"
            style="cursor: pointer"
            @change="filterByStatus($event.target.checked, status.value)"
            :checked="selectedStatusFilter.includes(status.value)"
            checkbox
          >
          </f7-list-item>
        </f7-list>
      </f7-popover>
    </div>
    <div
      class="no-padding-top page-content infinite-scroll-content"
      @infinite="loadMore"
    >
      <f7-list class="no-margin">
        <f7-list-group media-list>
          <f7-list-item
            v-for="item in hits"
            :title="item.taskNumber"
            :key="item.id"
            :reload-detail="true"
            swipeout
            :link="`/purchase-order/${item.taskNumber}`"
          >
            <div
              slot="text"
              class="display-flex justify-content-space-between align-item-center"
            >
              <div
                class="font-text hidden-text"
                style="flex: 1"
              >
                Project Name : {{ item._highlightResult.projectName.value }}
              </div>
            </div>
            <div slot="footer">
              <div
                class="font-text"
                v-if="item.buildingName"
              >
                System #: {{ item.buildingName }}
              </div>
            </div>
            <f7-chip
              slot="after"
              :text="purchaseOrderStatusBy(item.status).displayName"
              :color="purchaseOrderStatusBy(item.status).color"
            ></f7-chip>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
      <div
        v-show="hasMoreItems"
        class="preloader infinite-scroll-preloader"
      ></div>
      <f7-block
        class="text-align-center"
        v-show="!hasData"
        >No Data</f7-block
      >
    </div>

    <!-- popup -->
    <new-purchase-order-popup
      ref="newPurchaseOrderPopup"
    ></new-purchase-order-popup>
  </f7-page>
</template>
<script>
import NewPurchaseOrderPopup from '../components/popups/NewPurchaseOrderPopup.vue';
import Menu from '../../../components/menu/Menu.vue';

import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  components: { NewPurchaseOrderPopup, Menu },

  data: () => {
    return {
      attributesToRetrieve: [
        'id',
        'taskNumber',
        'status',
        'propertyName',
        'buildingName',
      ],
      allowInfinite: true,
      hasMoreItems: true,
      hasData: false,
      selectedStatuses: [],
    };
  },

  created() {
    this.setDestroyFlag(false);

    if (_.isEmpty(this.purchaseOrderStatusList)) {
      this.bindConstantTypeList();
    }
    if (_.isEmpty(this.users)) {
      this.getUserData();
    }
    this.selectedStatuses = _.cloneDeep(this.selectedStatusFilter);
    this.setPOSearchText('');
    this.onSearch();
  },

  beforeDestroy() {
    if (this.destroyFlag === true) {
      this.resetSearch();
      const currentPath = (
        (this.$f7 && this.$f7.views.main.router.currentRoute.route) ||
        {}
      ).path;
      if (!currentPath.includes('/purchase-order')) {
        this.selectedStatuses = [];
        this.setSelectedStatusFilter([]);
      }
    }
  },

  computed: {
    ...mapGetters('purchase-order/app-constant', [
      'purchaseOrderStatusBy',
      'purchaseOrderStatusList',
    ]),

    ...mapGetters('purchase-order/purchase-order', [
      'searchText',
      'hits',
      'nbPages',
      'page',
      'selectedStatusFilter',
    ]),

    ...mapGetters('purchase-order/purchase-order-list-page/common', [
      'destroyFlag',
    ]),

    ...mapGetters('purchase-order/purchase-order-details-page/project', [
      'users',
    ]),
  },

  methods: {
    ...mapActions('purchase-order/purchase-order', [
      'searchPurchaseOrder',
      'setPOSearchText',
      'resetSearch',
      'loadMorePO',
      'setSelectedStatusFilter',
    ]),
    ...mapActions('purchase-order/app-constant', ['bindConstantTypeList']),
    ...mapActions('purchase-order/purchase-order-list-page/common', [
      'setDestroyFlag',
    ]),
    ...mapActions('purchase-order/purchase-order-details-page/project', [
      'getUserData',
    ]),

    openAddPopup() {
      this.$refs.newPurchaseOrderPopup.openPopup();
    },

    onSearch: _.debounce(function () {
      if (!this.hasMoreItems) {
        this.$f7.preloader.show();
      }
      const self = this;
      this.hasMoreItems = true;
      this.allowInfinite = true;
      this.hasData = true;
      return this.searchPurchaseOrder({
        attributesToRetrieve: this.attributesToRetrieve,
      })
        .then(() => {
          if (self.page + 1 === self.nbPages) {
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
          if (self.hits.length === 0 && self.nbPages === 0) {
            self.hasData = false;
            self.hasMoreItems = false;
            self.allowInfinite = false;
          }
        })
        .finally(() => {
          self.hasMoreItems = false;
          this.setDestroyFlag(true);
          if (!this.hasMoreItems) {
            self.$f7.preloader.hide();
          }
        });
    }, 500),

    loadMore() {
      const self = this;
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      this.hasMoreItems = true;
      this.hasData = true;

      this.loadMorePO({
        page: this.page + 1,
      }).then(() => {
        if (self.hits.length === 0 && self.nbPages === 0) {
          self.hasData = false;
          self.hasMoreItems = false;
          return;
        }

        if (this.page + 1 === this.nbPages) {
          self.hasMoreItems = false;
          return;
        }
        self.allowInfinite = true;
      });
    },
    filterByStatus(checked, status) {
      // update checked status for statusFilter
      if (checked) {
        this.selectedStatuses.push(status);
      } else {
        this.selectedStatuses = this.selectedStatuses.filter(
          item => item !== status
        );
      }
      this.setSelectedStatusFilter(_.cloneDeep(this.selectedStatuses));
      this.onSearch();
    },
  },
};
</script>
<style scoped>
.hidden-text {
  min-width: 0;
  white-space: var(--f7-list-item-title-white-space);
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.list-filter ::v-deep .item-link .item-inner {
  display: none;
}
</style>
