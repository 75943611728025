export default {
  order: 'desc',
  searchText: '',

  hits: [],
  hitsPerPage: 25,
  nbHits: 0,
  nbPages: 0,
  page: 0,

  actionList: [],
  queryFilters: '',
  projectFilter: {
    jobTypes: [],
    collection: 'project-open',
    createdFromDate: [],
    createdToDate: [],
  },
  isHiddenResJob: false,
};
