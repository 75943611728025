<template>
  <f7-popup
    class="popup-color"
    :opened="showPopupChangeColor"
    :style="suggestColorsList.length > 0 ? 'height: 310px' : 'height: 230px'"
    @popup:close="closePopup()"
  >
    <f7-list media-list>
      <f7-list-item>
        <div
          class="list-item-inner-start"
          slot="inner-start"
        >
          Custom color
        </div>
        <div style="display: flex; flex-direction: column">
          <div
            style="
              margin-top: 10px;
              display: flex;
              align-items: center;
              gap: 10px;
            "
          >
            <input
              type="color"
              :value="selectedColor"
              @change="onChangeObject($event.target.value)"
            />
            <input
              type="text"
              :value="selectedColor"
              @input="onChangeColorText($event.target.value)"
              placeholder="Select or enter color code"
            />
          </div>
          <div v-if="suggestColorsList.length > 0">
            <p>Suggest color</p>
            <div style="display: flex; gap: 10px; align-items: center">
              <div
                v-for="(color, index) in suggestColorsList"
                :key="index"
                style="display: flex; align-items: center; gap: 5px"
              >
                <div
                  class="color-item"
                  :style="`background-color: ${color}; margin-right: 0;`"
                  style="display: grid; place-items: center"
                  @click="onChangeObject(color)"
                >
                  <f7-icon
                    v-show="color === selectedColor"
                    f7="checkmark"
                    color="white"
                  ></f7-icon>
                </div>
                <div @click="copyColor(color)">
                  <f7-icon
                    class="copy-color"
                    f7="rectangle_on_rectangle"
                  ></f7-icon>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p>Theme color</p>
            <div
              class="color-item"
              v-for="(color, index) in colors"
              :key="index"
              :style="`background-color: ${color}`"
              @click="onChangeObject(color)"
            >
              <f7-icon
                v-show="color === selectedColor"
                class="check-item"
                f7="checkmark"
                color="white"
              ></f7-icon>
            </div>
          </div>
        </div>
      </f7-list-item>
    </f7-list>
    <!-- popups -->
  </f7-popup>
</template>

<script>
import InputIcon from '@/components/icons/InputIcon.vue';
const colors = [
  '#000000',
  '#c0c0c0',
  '#808080',
  '#ffffff',
  '#800000',
  '#ff0000',
  '#800080',
  '#ff00ff',
  '#008000',
  '#00ff00',
  '#808000',
  '#ffff00',
  '#000080',
  '#0000ff',
  '#008080',
  '#00ffff',
  '#ffa500',
  '#f0f8ff',
  '#faebd7',
  '#7fffd4',
  '#ffe4c4',
  '#8a2be2',
  '#a52a2a',
  '#deb887',
  '#5f9ea0',
  '#7fff00',
  '#d2691e',
  '#ff7f50',
  '#6495ed',
  '#778899',
  '#2f7da6',
];

export default {
  components: {
    InputIcon,
  },
  data() {
    return {
      colors,
      suggestColorsList: [],
      showPopupChangeColor: false,
      selectedColor: '',
    };
  },
  methods: {
    openPopup(colors) {
      this.showPopupChangeColor = true;
      this.suggestColorsList = colors;
      return new Promise(resolve => {
        this.selectCallback = resolve;
      });
    },
    onChangeColorText(val) {
      if (this.isValidHexColor(val)) {
        this.selectedColor = val;
        this.done();
      }
    },
    isValidHexColor(hex) {
      if (typeof hex !== 'string' || (hex.length !== 7 && hex.length !== 4)) {
        return false;
      }

      const hexPattern = /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/;
      return hexPattern.test(hex);
    },
    closePopup() {
      this.showPopupChangeColor = false;
      this.suggestColorsList = [];
      this.selectedColor = '';
    },
    copyColor(color) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(color);
      }
      this.selectedColor = color;
      this.closePopup();
      this.showToast('Copied!');
    },
    showToast(message) {
      this.$f7.toast
        .create({
          text: message,
          closeOnClick: true,
          closeButton: false,
          closeTimeout: 5000,
        })
        .open();
    },
    done() {
      this.selectCallback(this.selectedColor);
      this.closePopup();
    },
    onChangeObject(val) {
      this.selectedColor = val;
      this.done();
    },
  },
};
</script>

<style lang="scss" scoped>
.color-item {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
}
.check-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup-color {
  width: 410px;
  left: auto;
  right: calc(50% - 150px);
  bottom: 55%;
  top: auto;
  z-index: 99999;
}
.copy-color {
  cursor: pointer;
  font-size: 12px;
  color: white;
}
.copy-color:hover {
  color: var(--f7-theme-color);
}
</style>
