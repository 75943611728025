<template>
  <div class="discount-col">
    {{ column.text }}
    <div v-if="column.text === 'Tax'">
      <f7-toggle
        class="first-input"
        :checked="isApproveTax"
        @toggle:change="onToggleTax"
      ></f7-toggle>
    </div>
    <div
      v-else
      class="label-select"
    >
      (
      <select v-model="selected">
        <option
          :value="option.value"
          v-for="option in options"
          :key="option.value"
        >
          {{ displayType === 'symbol' ? option.symbol : option.fullText }}
        </option>
      </select>
      )
    </div>
  </div>
</template>

<script>
export default {
  props: {
    column: Object,
    discountType: String,
    isApproveTax: {
      type: Boolean,
      default: true,
    },
    displayType: {
      type: String,
      default: 'symbol', //fullText or symbol
    },
  },

  data() {
    return {
      selected: '',
      options: {
        percent: {
          symbol: '%',
          fullText: 'percent',
          value: 'percent',
        },
        fixed: {
          symbol: '$',
          fullText: 'fixed',
          value: 'fixed',
        },
      },
    };
  },

  watch: {
    selected(value) {
      this.onchange(value);
    },
  },

  created() {
    this.selected = this.discountType;
  },

  methods: {
    onchange(value) {
      this.$emit('change-select', value);
    },
    onToggleTax(value) {
      this.$emit('toggleTax', value);
    },
  },
};
</script>

<style scoped lang="scss">
.discount-col {
  display: flex;
  justify-content: flex-end;
}

.first-input {
  --f7-toggle-width: 32px;
  --f7-toggle-height: 16px;
  margin-left: 8px;
}

.label-select {
  margin-left: 10px;
  display: flex;
  &:hover {
    cursor: pointer;
    color: var(--f7-theme-color);
  }
}

select {
  text-align: center;
}
</style>
