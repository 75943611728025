<template>
  <div>
    <f7-block-header>Project Checklist</f7-block-header>
    <f7-list
      accordion-list
      class="accordion-schedule"
    >
      <!-- <f7-list-item accordion-item class="accordion-schedule-item"> -->
      <!-- <div class="title-accordion" slot="title">Ordered Material</div>
        <f7-checkbox
          class="schedule-checkbox"
          :checked="scheduleProductionTaskStatus.orderedMaterial === true"
          @change="onUpdateTaskStatus('orderedMaterial', $event.target.checked)"
        ></f7-checkbox>
        <f7-accordion-content>
          <f7-list>
            <f7-list-item
              v-for="(po, index) in purchaseOrderList"
              :key="index"
              :title="po.taskNumber"
              link
              @click="openPODetail(po.id)"
            >
              <f7-chip
                slot="after"
                :text="purchaseOrderStatusBy(po.status).displayName"
                :color="purchaseOrderStatusBy(po.status).color"
              ></f7-chip>
            </f7-list-item>
            <f7-list-item>
              <f7-button
                slot="title"
                @click="navigateToAddNewPO"
                small
                outline
                class="display-flex justify-content-center align-items-center"
              >
                <f7-icon
                  size="18"
                  f7="plus"
                  class="margin-right-half"
                ></f7-icon>
                Add New PO
              </f7-button>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item> -->
      <f7-list-item
        accordion-item
        class="accordion-schedule-item"
      >
        <div
          class="title-accordion"
          slot="title"
        >
          Cost Tracking
        </div>
        <f7-checkbox
          class="schedule-checkbox"
          :checked="scheduleProductionTaskStatus.costTracking === true"
          @change="onUpdateTaskStatus('costTracking', $event.target.checked)"
        ></f7-checkbox>
        <f7-accordion-content>
          <f7-list class="no-margin">
            <f7-list-input
              label="Roof Size (SQFT)"
              type="number"
              v-model="roofSize"
              placeholder="Enter roof size"
              @input="roofSize = $event.target.value.trim()"
              @blur="
                onUpdateCard('roofSize', Number($event.target.value.trim()))
              "
            >
              <input-icon
                slot="media"
                icon="app_badge"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="Material Cost"
              type="text"
              v-model="materialCose"
              placeholder="Enter material cost"
              @input="formatBudget('materialCose', $event.target.value.trim())"
              @blur="onUpdateBudget('materialCose', $event.target.value.trim())"
            >
              <input-icon
                slot="media"
                icon="money_dollar_circle"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="Labor Cost Estimate"
              type="text"
              v-model="laborCoseEstimate"
              placeholder="Enter labor cost estimate"
              @input="
                formatBudget('laborCoseEstimate', $event.target.value.trim())
              "
              @blur="
                onUpdateBudget('laborCoseEstimate', $event.target.value.trim())
              "
            >
              <input-icon
                slot="media"
                icon="money_dollar_circle"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              label="General Contractor for Non Roof Repair Estimate"
              type="text"
              v-model="nonRoofRepairEstimate"
              placeholder="Enter general contractor for non roof repair estimate"
              @input="
                formatBudget(
                  'nonRoofRepairEstimate',
                  $event.target.value.trim()
                )
              "
              @blur="
                onUpdateBudget(
                  'nonRoofRepairEstimate',
                  $event.target.value.trim()
                )
              "
            >
              <input-icon
                slot="media"
                icon="money_dollar_circle"
              ></input-icon>
            </f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item
        accordion-item
        class="accordion-schedule-item"
      >
        <div
          class="title-accordion"
          slot="title"
        >
          Material Tracking
        </div>
        <f7-checkbox
          class="schedule-checkbox"
          :checked="scheduleProductionTaskStatus.materialTracking === true"
          @change="
            onUpdateTaskStatus('materialTracking', $event.target.checked)
          "
        ></f7-checkbox>
        <f7-accordion-content>
          <f7-list class="no-margin">
            <f7-list-input
              label="Delivery Date"
              placeholder="Enter delivery date"
              type="datepicker"
              :calendar-params="{
                backdrop: true,
                header: true,
                footer: false,
                openIn: 'customModal',
                dateFormat: 'mm/dd/yyyy',
              }"
              :value="deliveryDate || []"
              @calendar:change="
                if ($event && $event !== deliveryDate) {
                  onUpdateDate('deliveryDate', $event);
                  $f7.calendar.close();
                }
              "
              clear-button
              @input:clear="
                deliveryDate = [];
                onUpdateCard('deliveryDate', '');
              "
            >
              <input-icon
                slot="media"
                icon="calendar"
              ></input-icon>
            </f7-list-input>
            <f7-list-input
              type="text"
              placeholder="Enter material inspection"
              label="Material Inspection"
              clear-button
              :value="materialInspection"
              @input="materialInspection = $event.target.value.trim()"
              @blur="onUpdateCard('materialInspection', $event.target.value)"
            >
              <input-icon
                slot="media"
                icon="doc_text"
              ></input-icon>
            </f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
      <f7-list-item
        accordion-item
        class="accordion-schedule-item"
      >
        <div
          class="title-accordion"
          slot="title"
        >
          Schedule Production Date
        </div>
        <f7-checkbox
          class="schedule-checkbox"
          :checked="
            scheduleProductionTaskStatus.scheduleProductionDate === true
          "
          @change="
            onUpdateTaskStatus('scheduleProductionDate', $event.target.checked)
          "
        ></f7-checkbox>
        <f7-accordion-content>
          <f7-list class="no-margin">
            <f7-list-input
              label="Production Date"
              type="datepicker"
              :calendar-params="{
                backdrop: true,
                header: true,
                footer: false,
                openIn: 'customModal',
                dateFormat: 'mm/dd/yyyy',
                disabled: {
                  from: null,
                  to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000), // yesterday
                },
              }"
              placeholder="Enter production date"
              :value="productionDate || []"
              @calendar:change="
                onUpdateDate('productionDate', $event);
                $f7.calendar.close();
              "
              error-message-force
              validate
              validate-on-blur
              :error-message="requireErrorMessage('productionDate')"
            >
              <required-asterisk slot="label"></required-asterisk>
              <input-icon
                slot="media"
                icon="flag"
              ></input-icon>
            </f7-list-input>
          </f7-list>
        </f7-accordion-content>
        <div
          slot="after"
          class="error-message"
        >
          {{ requireErrorMessage('productionDate') }}
        </div>
      </f7-list-item>
      <f7-list-item
        accordion-item
        class="accordion-schedule-item"
      >
        <div
          class="title-accordion"
          slot="title"
        >
          Schedule Crews
        </div>
        <f7-checkbox
          class="schedule-checkbox"
          :checked="scheduleProductionTaskStatus.scheduleCrews === true"
          @change="onUpdateTaskStatus('scheduleCrews', $event.target.checked)"
        ></f7-checkbox>
        <f7-accordion-content>
          <f7-list media-list>
            <f7-list-input
              label="Team Member"
              type="select"
              v-model="teamId"
              @input="selectTeam($event.target.value)"
            >
              <input-icon
                slot="media"
                icon="person_3_fill"
              ></input-icon>
              <option value="">Select Team Member</option>
              <option
                v-for="(team, index) in teamList"
                :key="index"
                :value="team.id"
              >
                {{ team.teamName }}
              </option>
            </f7-list-input>
          </f7-list>
          <f7-block-header class="margin-top"> Member </f7-block-header>
          <f7-list>
            <f7-list-item
              v-for="(member, index) in memberList"
              :key="index"
              :title="`${member.displayName} ${
                member.uid === managerId ? '(Manager)' : ''
              }`"
            >
              <div slot="media">
                <user-avatar
                  :avatarSize="30"
                  :imageUrl="member ? member.photoURL : ''"
                  :name="member ? member.displayName : ''"
                ></user-avatar>
              </div>
              <f7-link
                slot="after"
                icon-f7="trash"
                @click.native="removeMember(member)"
              ></f7-link>
            </f7-list-item>
            <f7-list-item>
              <f7-button
                slot="title"
                @click="addMember"
                small
                class="btn-fill-gray display-flex justify-content-center align-items-center"
              >
                <f7-icon
                  size="18"
                  color="primary"
                  f7="person_badge_plus"
                  class="margin-right-half"
                ></f7-icon>
                Add Member
              </f7-button>
            </f7-list-item>
          </f7-list>
        </f7-accordion-content>
        <div
          slot="after"
          class="error-message"
        >
          {{ requireErrorMessage('memberIds') }}
        </div>
      </f7-list-item>
    </f7-list>

    <purchase-order-input :value="displayCard"></purchase-order-input>

    <attachment-input
      :projectId="displayCard.id"
      title="Attachments"
      attachment-type="attachment"
      add-button-title="Add an Attachment"
      :value="displayCard.attachmentFiles"
      @input="setAttachment('attachmentFiles', $event)"
    ></attachment-input>
    <user-input
      @input="setAssigneeIDs($event)"
      :value="displayCard.assigneeIDs"
    ></user-input>
    <note-input
      :value="displayCard ? displayCard.commentAndNote || [] : []"
      :card="card"
      @change="saveNote($event)"
    ></note-input>

    <add-new-purchase-order-popup
      ref="addNewPO"
      @openPODetail="openPODetail"
    ></add-new-purchase-order-popup>
    <purchase-order-detail-popup ref="poDetail"></purchase-order-detail-popup>
    <date-popup
      ref="completionDueDatePopup"
      title="Enter Completion Due Date"
      label="Completion Due Date"
      v-model="completedDate"
      @done="resolveScheduleProduction"
      not-allow-past
    ></date-popup>
    <member-select-popup
      ref="memberSelectPopup"
      :managerID="managerId"
      :userIDs="memberIds"
      @selectMember="selectMember"
    ></member-select-popup>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import methodsMixins from '../../mixin/methods';
import residentialMixins from '../../mixin/residential';
import UserInput from '../input/UserInput.vue';
import NoteInput from '@/plugins/swimlane/components/inputs/NoteInput.vue';
import InputIcon from '../icon/InputIcon.vue';
import AttachmentInput from '../../../dashboard/components/input/AttachmentInput.vue';
import AddNewPurchaseOrderPopup from '../popup/AddNewPurchaseOrderPopup.vue';
import PurchaseOrderDetailPopup from '../popup/PurchaseOrderDetailPopup.vue';
import MemberSelectPopup from '../popup/MemberSelectPopup.vue';
import DatePopup from '../popup/DatePopup.vue';
import UserAvatar from '@/components/avatars/UserAvatar.vue';
import PurchaseOrderInput from '../input/PurchaseOrderInput.vue';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { toDateCalendar, toDateFirebase } from '../../../../utility/datetime';
import _ from 'lodash';
import moment from 'moment';
import {
  TIME_LOG_TYPE_PROJECT,
  VALIDATION_MESSAGE,
} from '../../../../utility/const';
import { firebase, auth } from '../../../../services/firebase.service';

export default {
  components: {
    UserInput,
    InputIcon,
    AttachmentInput,
    NoteInput,
    AddNewPurchaseOrderPopup,
    PurchaseOrderDetailPopup,
    DatePopup,
    MemberSelectPopup,
    UserAvatar,
    PurchaseOrderInput,
  },
  mixins: [methodsMixins, residentialMixins],
  data: () => {
    return {
      currentResponse: {},
      displayCard: {},
      roofSize: '',
      materialCose: '',
      laborCoseEstimate: '',
      nonRoofRepairEstimate: '',
      deliveryDate: [],
      materialInspection: '',
      productionDate: [],
      teamId: '',
      memberIds: [],
      managerId: '',
      scheduleProductionTaskStatus: {
        // orderedMaterial: false,
        costTracking: false,
        materialTracking: false,
        scheduleProductionDate: false,
        scheduleCrews: false,
      },
      completedDate: [],

      oldCardId: '',
    };
  },

  computed: {
    ...mapGetters('dashboard/project', ['card', 'activeUsers']),
    ...mapGetters('dashboard/team-management', ['teamList']),
    ...mapGetters('dashboard/work-order', ['purchaseOrderList']),
    ...mapGetters('dashboard/app-constant', ['purchaseOrderStatusBy']),

    requireErrorMessage() {
      return prop => {
        if (!this.v$[prop].$error) return null;
        if (this.v$[prop].required.$invalid)
          return VALIDATION_MESSAGE.REQUIRED_FIELD;
        return null;
      };
    },

    memberList() {
      return this.activeUsers.filter(r => this.memberIds.includes(r.uid));
    },
  },

  methods: {
    ...mapActions('dashboard/project', ['updateCard']),
    ...mapActions('dashboard/team-management', ['bindTeamListBys']),
    ...mapActions('dashboard/employee-scheduling', [
      'getSchedules',
      'deleteSchedule',
      'createSchedule',
      'updateAdditionalAssigneeIdsProjectAlgolia',
    ]),

    async initCardDisplayValues() {
      if (!_.isEmpty(this.card)) {
        this.$f7.preloader.show();
        let promises = [];
        if (_.isEmpty(this.teamList)) {
          promises.push(
            this.bindTeamListBys([
              {
                prop: 'disabled',
                val: false,
                op: '==',
              },
              {
                prop: 'isDeleted',
                val: false,
                op: '==',
              },
            ])
          );
        }

        await Promise.all(promises);
        this.$f7.preloader.hide();

        this.displayCard = _.cloneDeep(this.card);

        if (this.displayCard.roofSize) {
          this.roofSize = this.displayCard.roofSize;
        }
        if (this.displayCard.materialCose) {
          this.formatBudget(
            'materialCose',
            this.displayCard.materialCose.toString()
          );
        }
        if (this.displayCard.laborCoseEstimate) {
          this.formatBudget(
            'laborCoseEstimate',
            this.displayCard.laborCoseEstimate.toString()
          );
        }
        if (this.displayCard.nonRoofRepairEstimate) {
          this.formatBudget(
            'nonRoofRepairEstimate',
            this.displayCard.nonRoofRepairEstimate.toString()
          );
        }

        if (
          this.displayCard.deliveryDate &&
          (_.isEmpty(this.deliveryDate) ||
            (!_.isEmpty(this.deliveryDate) &&
              !moment(this.deliveryDate[0]).isSame(
                moment(toDateCalendar(this.displayCard.deliveryDate)[0]),
                'day'
              )))
        ) {
          this.deliveryDate = toDateCalendar(this.displayCard.deliveryDate);
        }

        this.materialInspection = this.displayCard.materialInspection || '';

        if (
          this.displayCard.productionDate &&
          (_.isEmpty(this.productionDate) ||
            (!_.isEmpty(this.productionDate) &&
              !moment(this.productionDate[0]).isSame(
                moment(toDateCalendar(this.displayCard.productionDate)[0]),
                'day'
              )))
        ) {
          this.productionDate = toDateCalendar(this.displayCard.productionDate);
        }
        this.teamId = this.displayCard.teamId || '';
        if (this.displayCard.memberIds) {
          this.memberIds = this.displayCard.memberIds;
        }
        this.managerId = this.displayCard.managerId || '';

        if (this.displayCard.scheduleProductionTaskStatus) {
          this.scheduleProductionTaskStatus =
            this.displayCard.scheduleProductionTaskStatus;
        }
      }
    },

    formatBudget(prop, val) {
      this[prop] = val ? this.formatPriceNumber(val) : '';
    },

    onUpdateBudget(prop, val) {
      const cost = this.formatDots(val).replaceAll('$', '').replaceAll(',', '');
      this.onUpdateCard(prop, parseFloat(cost) || null);
    },

    openPODetail(id) {
      this.$refs.poDetail.open(id);
    },

    async removeMemberSchedules(userIds = [], date) {
      let promises = [];
      for (const uid of userIds) {
        promises.push(
          this.getSchedules([
            {
              prop: 'projectId',
              val: this.card.id,
              op: '==',
            },
            {
              prop: 'userId',
              val: uid,
              op: '==',
            },
            {
              prop: 'date',
              val: date,
              op: '==',
            },
          ])
        );
      }
      const res = await Promise.all(promises);
      const mergeRes = [].concat.apply([], res);
      let deletePromises = [];
      for (const scheduling of mergeRes) {
        deletePromises.push(this.deleteSchedule(scheduling.id));
      }
      await Promise.all(deletePromises);
      // delete additionalAssigneeIDs to operation_card algolia with businessCode is commercial/residential
      this.updateAdditionalAssigneeIdsProjectAlgolia([
        {
          projectId: this.card.id,
          userIds: mergeRes.map(r => r.userId),
          action: 'delete',
        },
      ]);
    },

    async checkMemberSchedule(userIds) {
      await this.removeMemberSchedules(
        this.card.memberIds,
        this.card.productionDate
      );
      let memberIdNotAvailable = [];
      let memberIdAvailable = [];
      let promises = [];
      if (!_.isEmpty(this.productionDate)) {
        for (const uid of userIds) {
          promises.push(
            this.getSchedules([
              {
                prop: 'userId',
                val: uid,
                op: '==',
              },
              {
                prop: 'date',
                val: this.$google.firebase.firestore.Timestamp.fromDate(
                  this.productionDate[0]
                ),
                op: '==',
              },
            ]).then(r => {
              if (_.isEmpty(r)) {
                memberIdAvailable.push(uid);
              } else {
                memberIdNotAvailable.push(uid);
              }
            })
          );
        }
        await Promise.all(promises);
      } else {
        memberIdAvailable = [...userIds];
      }
      return {
        memberIdAvailable,
        memberIdNotAvailable,
      };
    },

    async doAfterCheckMemberSchedule(userIds, managerId) {
      this.$f7.preloader.show();
      const data = await this.checkMemberSchedule(userIds);
      this.memberIds = [...data.memberIdAvailable];
      this.managerId = this.memberIds.includes(managerId) ? managerId : '';

      this.onUpdateCard('memberIds', this.memberIds);
      this.onUpdateCard('managerId', this.managerId);

      // add scheduling for member
      if (!_.isEmpty(this.productionDate) && !_.isEmpty(this.memberIds)) {
        let promises = [];
        for (const uid of this.memberIds) {
          promises.push(
            this.createSchedule({
              timeLogType: TIME_LOG_TYPE_PROJECT,
              projectId: this.card.id,
              project: {
                id: this.card.id,
                cardNumber: this.card.cardNumber || '',
                title: this.card.title || '',
                businessCode: this.card.businessCode || '',
                customerType: this.card.customerType || '',
                contactName: this.card.contactName || '',
                propertyName: this.card.propertyName || '',
                propertyAddress: this.card.propertyAddress || {},
                projectAddress: this.card.projectAddress || {},
              },
              userId: uid,
              timeOffType: null,
              title: '',
              notes: '',
              startTime: '08:00',
              finishTime: '17:00',
              date: toDateFirebase(this.productionDate),
              color: '#ff3b30',
            })
          );
        }
        await Promise.all(promises);
        // add additionalAssigneeIDs to operation_card algolia with businessCode is commercial/residential
        this.updateAdditionalAssigneeIdsProjectAlgolia([
          {
            projectId: this.card.id,
            userIds: this.memberIds,
            action: 'create',
          },
        ]);
      }
      this.$f7.preloader.hide();
      if (!_.isEmpty(data.memberIdNotAvailable)) {
        let nameArr = [];
        data.memberIdNotAvailable.forEach(userId => {
          const memberName = (
            this.activeUsers.find(i => i.uid === userId) || {}
          ).displayName;
          if (memberName) {
            nameArr.push(memberName);
          }
        });

        this.$ri.dialog.openWarningDialog({
          title: 'Add member',
          content: `<b>${nameArr.join(', ')}</b> ${
            nameArr.length > 1 ? 'have' : 'has'
          } another schedule. Please choose another member.`,
          hideCancelButton: true,
          onClick: (_sefl, index) => {
            if (index === 0) {
              _sefl.app.dialog.close();
            } else if (index === 1) {
              _sefl.app.dialog.close();
            }
          },
        });
      }
    },

    selectTeam(value) {
      this.teamId = value;
      this.onUpdateCard('teamId', value);
      if (value) {
        const teamDetail = this.teamList.find(r => r.id === value);
        this.selectMember(teamDetail.userIDs, teamDetail.managerID);
      }
    },

    addMember() {
      this.$refs.memberSelectPopup.open();
    },

    async selectMember(userIds, managerId) {
      await this.doAfterCheckMemberSchedule(userIds, managerId);
    },

    removeMember(member) {
      const app = this;
      this.$ri.dialog.openWarningDialog({
        title: 'Delete member',
        content: 'Are you sure you want to delete the member?',
        textButton: 'Delete',
        onClick: async (_sefl, index) => {
          if (index === 0) {
            _sefl.app.dialog.close();
          } else if (index === 1) {
            app.$f7.preloader.show();
            app.memberIds = app.memberIds.filter(r => r !== member.uid);
            app.onUpdateCard('memberIds', app.memberIds);
            if (member.uid === app.managerId) {
              app.managerId = '';
              app.onUpdateCard('managerId', app.managerId);
            }
            await app.removeMemberSchedules(
              [member.uid],
              app.card.productionDate
            );
            app.$f7.preloader.hide();
          }
        },
      });
    },

    onUpdateTaskStatus(field, value) {
      this.scheduleProductionTaskStatus = {
        ...this.scheduleProductionTaskStatus,
        [field]: value,
      };
      this.onUpdateCard(
        'scheduleProductionTaskStatus',
        this.scheduleProductionTaskStatus
      );
    },

    async onUpdateDate(prop, value) {
      if (!_.isEmpty(value)) {
        if (
          (prop === 'productionDate' && _.isEmpty(this.productionDate)) ||
          (!_.isEmpty(this.productionDate) &&
            !moment(value[0]).isSame(moment(this.productionDate[0]), 'day'))
        ) {
          this.productionDate = value;
          this.onUpdateCard('productionDate', toDateFirebase(value));
          this.onUpdateCard('dueDate', toDateFirebase(value));
          if (!_.isEmpty(this.memberIds)) {
            await this.doAfterCheckMemberSchedule(
              this.memberIds,
              this.managerId
            );
          }
        }
        if (
          (prop === 'deliveryDate' && _.isEmpty(this.deliveryDate)) ||
          (!_.isEmpty(this.deliveryDate) &&
            !moment(value[0]).isSame(moment(this.deliveryDate[0]), 'day'))
        ) {
          this.deliveryDate = value;
          this.onUpdateCard('deliveryDate', toDateFirebase(value));
        }
      }
    },

    onUpdateCard(field, value, isShowLoading) {
      if (value === undefined) return;
      isShowLoading && this.$f7.preloader.show();
      this.updateCard({
        id: this.card.id,
        doc: {
          [field]: value,
        },
      }).finally(() => {
        isShowLoading && this.$f7.preloader.hide();
      });
    },

    // Implement do something before resolve action
    doAction(response) {
      if (response.code === 'move-to-completion') {
        this.v$.$touch();
        if (this.v$.$invalid) return;
        if (
          // !this.scheduleProductionTaskStatus.orderedMaterial ||
          !this.scheduleProductionTaskStatus.costTracking ||
          !this.scheduleProductionTaskStatus.materialTracking ||
          !this.scheduleProductionTaskStatus.scheduleProductionDate ||
          !this.scheduleProductionTaskStatus.scheduleCrews
        ) {
          this.$ri.dialog.openWarningDialog({
            title: 'Please make sure all checkboxes are checked.',
            content: '',
            hideCancelButton: true,
            onClick: (_sefl, index) => {
              if (index === 0) {
                _sefl.app.dialog.close();
              } else if (index === 1) {
                _sefl.app.dialog.close();
              }
            },
          });
          return;
        }
        this.currentResponse = response;
        this.$refs.completionDueDatePopup.open();
      }
    },

    resolveScheduleProduction() {
      this.displayCard.completedDate = this.completedDate;
      this.displayCard.dueDate = this.completedDate;
      this.resolve();
    },

    // Implement save action
    async save() {
      this.$f7.preloader.show();
      let doc = {};
      doc.dueDate = toDateFirebase(this.displayCard.dueDate);
      doc.completedDate = toDateFirebase(this.displayCard.completedDate);
      await this.updateCard({
        id: this.card.id,
        doc,
      }).then(() => {
        this.displayCard = {};
        this.$f7.preloader.hide();
      });
    },
    async saveNote(event) {
      if (event) {
        const commentAndNote = event.map(item => ({
          ...item,
          title: 'Schedule Build Note',
        }));
        
        this.onUpdateCard('commentAndNote', commentAndNote);
      }
    },

    navigateToAddNewPO() {
      this.$refs.addNewPO.openPopup();
    },
  },

  setup: () => ({ v$: useVuelidate({ $scope: false }) }),

  validations() {
    return {
      productionDate: {
        required,
      },
      memberIds: {
        required,
      },
    };
  },
  watch: {
    card: {
      deep: true,
      immediate: true,
      handler() {
        this.initCardDisplayValues();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.list-item-inner-start {
  font-size: var(--f7-label-font-size);
}

.list-item-title {
  font-size: var(--f7-input-font-size);
  font-weight: 400;
}

.accordion-schedule ::v-deep {
  .accordion-schedule-item {
    background-color: var(--f7-color-bg-9-neutral);
  }
}

.schedule-checkbox {
  z-index: 9999;
  margin: -24px 0 0 15px;
  position: absolute;
}

.title-accordion {
  margin-left: 25px;
}

.error-message {
  font-size: 12px;
  color: var(--f7-theme-color);
}
</style>
